import { logOnce } from './logger/log-once';

declare const geq: any;

export const Retention = {
  page: () => {
    if (geq) {
      geq.page();
      console.debug('Retention page called.');
    } else {
      logOnce.Error(
        'retention-page',
        'Retention page call failed. geq not found.'
      );
    }
  },

  suppress: () => {
    if (geq) {
      geq.suppress();
      console.debug('Retention suppress called.');
    } else {
      logOnce.Error(
        'retention-suppress',
        'Retention suppress call failed. geq not found.'
      );
    }
  },

  trackOrder: ({
    order_number,
    order_amount,
    order_email,
  }: RetentionTrackOrderData) => {
    if (geq) {
      geq.trackOrder({
        order_number,
        order_amount,
        order_email,
      });
      console.debug('Retention trackOrder called.');
    } else {
      logOnce.Error(
        'retention-track-order',
        'Retention trackOrder call failed. geq not found.'
      );
    }
  },
};

export type RetentionTrackOrderData = {
  order_number: string;
  order_amount: string;
  order_email: string;
};
