import { Duration } from 'lib/types/trip';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DurationCard } from './DurationCard';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import styles from './DurationCarousel.module.css';

export type Props = {
  durations: Duration[];
  selectedDuration: Duration | null;
  setTempSelectedDuration: Dispatch<SetStateAction<Duration | null>>;
  unit: string;
};

function DurationCarousel({
  durations,
  selectedDuration,
  setTempSelectedDuration,
  unit,
}: Props) {
  const carouselOptions: EmblaOptionsType = {
    containScroll: 'trimSnaps',
    dragFree: true,
  };
  const [carouselRef, emblaApi] = useEmblaCarousel(carouselOptions);

  const clickHandler = (duration: Duration) => {
    setTempSelectedDuration(duration);
  };

  const [scrollProgress, setScrollProgress] = useState(0);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [emblaApi]);

  const returnNumber = (text: string) => {
    let numb = text.replace(/\D/g, '');
    return numb ? Number(numb) : 0;
  };

  useEffect(() => {
    if (!emblaApi) return;
    onScroll();
    emblaApi.on('scroll', onScroll);
    emblaApi.on('reInit', onScroll);
  }, [emblaApi, onScroll]);

  return (
    <div className="max-w-full md:mb-4">
      <div className="px-5 sm:px-10 text-lg font-semibold leading-5 text-dark-700 text-left">
        Duration
      </div>
      <div className="relative flex py-5 max-w-full cursor-grab">
        <div className={styles.embla} ref={carouselRef}>
          <div className={styles.emblaContainer}>
            {durations
              .sort(function (a, b) {
                return returnNumber(a.text) - returnNumber(b.text);
              })
              .map((item, index) => (
                <div className={styles.emblaSlide} key={index}>
                  <DurationCard
                    key={index}
                    duration={item}
                    isActive={selectedDuration?.id === item.id}
                    click={(duration) => {
                      clickHandler(duration);
                    }}
                    unit={unit}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className={`hidden md:block ${styles.emblaProgressBar}`}>
          <div
            className={styles.emblaProgressBarFilled}
            style={{ transform: `translateX(${scrollProgress}%)` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export { DurationCarousel };
