import { AppointmentsGateway } from 'services/http/appointments-gateway';
import { Timeslot } from './types';
import dayjs from 'dayjs';
import { useOffice } from '../../../hooks/useOffice/useOffice';
import { useQuery } from '@tanstack/react-query';

// Define the type for the query result
export type TimeslotsByDate = {
  [date: string]: Timeslot[] | null;
};

const useGetMonthTimeSlots = (tz: string, monthEnabledDates?: string[]) => {
  const office = useOffice();

  return useQuery<TimeslotsByDate, Error>(
    ['get-month-time-slots', tz, monthEnabledDates, office.gCalendar.eventId],
    async () => {
      const results = await Promise.all(
        (monthEnabledDates || []).map(async (date) => {
          try {
            const data = await AppointmentsGateway.get<Timeslot[]>(
              `/public/booking/timeslots?event_id=${office.gCalendar.eventId}&timezone=${tz}&date=${date}`
            );
            return [date, data] as [string, Timeslot[]]; // Type assertion
          } catch (error) {
            console.error(`Error fetching time slots for date: ${date}`, error);
            return [date, []] as [string, Timeslot[]]; // Type assertion
          }
        })
      );

      // Transform the results into an object keyed by date
      return results.reduce<TimeslotsByDate>((acc, [date, data]) => {
        let isFirstEnabled = true;
        acc[date] =
          data.length > 0
            ? data.map((timeslot) => {
                const [hours, minutes] = timeslot.from.split(':');
                const displayTime = dayjs()
                  .hour(+hours)
                  .minute(+minutes)
                  .format('h:mma');

                let displayMessage: string | null = null;
                if (timeslot.leftSpots > 0 && timeslot.enabled) {
                  if (isFirstEnabled || timeslot.bookedSpots === 1) {
                    displayMessage = 'Limited spots <span>left</span>';
                  } else if (timeslot.bookedSpots > 1) {
                    displayMessage = `Only ${timeslot.leftSpots} ${
                      timeslot.leftSpots === 1 ? 'spot' : 'spots'
                    } <span>left</span>`;
                  }
                  isFirstEnabled = false;
                } else if (timeslot.bookedSpots > 0) {
                  displayMessage = 'Full';
                }

                return {
                  ...timeslot,
                  displayTime,
                  displayMessage,
                };
              })
            : null; // Use null if data is empty
        return acc;
      }, {});
    },
    {
      enabled: !!monthEnabledDates?.length && !!office.gCalendar.eventId,
      onError: (error) => {
        console.error('Error fetching month time slots:', error);
      },
    }
  );
};

export { useGetMonthTimeSlots };
