import { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { usePaymentClientConfig } from 'lib/react-query/Queries/usePaymentClientConfig';
import { usePaymentSetup } from 'lib/react-query/Queries/usePaymentSetup';
import { PaymentOption } from 'lib/types/payment';
import { DateHelper } from 'lib/helper/date/date';
import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import { AppToastService } from '../../components/Toast/AppToastService';
import { useAppSelector } from 'state/hooks';

type Props = {
  paymentOption: PaymentOption;
};

const useStripe = ({ paymentOption }: Props) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string>('');
  const selectedOption = useAppSelector((state) => state.product.paymentOption);
  const { isLoading: promotionsLoading, data: promotionsData } =
    usePromotionsQuery();
  const { isLoading: isClientConfigLoading, data: clientConfigData } =
    usePaymentClientConfig({ provider: 'stripe' });
  const promotions =
    promotionsData &&
    PromotionHelper.getSelectedPromotionObject(
      promotionsData.result.promotions,
      selectedOption
    );
  // dash-separated promo codes, so we update client secret if user adds promotion
  const promoCodes = promotions
    ? promotions.items.map((p) => p.code).join('-')
    : '';

  const {
    isLoading: isPaymentSetupLoading,
    isRefetching: isPaymentSetupRefetching,
    data: paymentSetupData,
  } = usePaymentSetup({
    paymentOption,
    timezoneOffset: DateHelper.getTimezoneOffset(),
    provider: 'stripe',
    method: 'stripe_card',
    promoCodes,
    promosLoading: promotionsLoading,
  });

  useEffect(() => {
    if (isClientConfigLoading || !clientConfigData) return;

    if (!clientConfigData.success) {
      AppToastService.error("Uh oh! Couldn't load payment config!");
      return;
    }

    try {
      setStripePromise(loadStripe(clientConfigData.result.publishable_key));
    } catch (e) {
      console.error('useStripe: Loading stripe failed, trying again...');
      setTimeout(() => {
        setStripePromise(loadStripe(clientConfigData.result.publishable_key));
      }, 2000);
    }
  }, [isClientConfigLoading, clientConfigData]);

  useEffect(() => {
    if (isPaymentSetupLoading || isPaymentSetupRefetching || !paymentSetupData)
      return;

    if (!paymentSetupData.success) {
      AppToastService.error("Uh oh! Couldn't load payment setup data!");
      return;
    }

    setClientSecret(paymentSetupData.result.client_secret);
  }, [isPaymentSetupLoading, isPaymentSetupRefetching, paymentSetupData]);

  return {
    stripePromise,
    clientSecret,
    isLoading: isPaymentSetupLoading || isPaymentSetupRefetching,
  };
};

export { useStripe };
