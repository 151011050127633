// eslint-disable-next-line no-restricted-imports
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { QueryParam } from 'services/query-param/query-param';
import omit from 'lodash/omit';
import { usedQueryParams } from 'data/used-query-params';
import { ParamObject } from 'lib/types/query-param';

type TOptions = {
  params?: ParamObject;
} & NavigateOptions;

const useCustomNavigate = () => {
  const navigate = useNavigate();
  const existingParams = QueryParam.getAll();

  const customNavigate = (pathname: string, options?: TOptions) => {
    let otherOptions = Object.assign({}, options);
    delete otherOptions.params;

    const filteredExistingParams = omit(existingParams, usedQueryParams);
    const filteredWithNewParams = {
      ...filteredExistingParams,
      ...options?.params,
    };
    const stringifiedSearchParams = QueryParam.stringify(filteredWithNewParams);
    navigate({ pathname, search: stringifiedSearchParams }, otherOptions);
  };

  return customNavigate;
};

export { useCustomNavigate };
