import { TenantTheme } from 'lib/types/theme';
import { useTenant } from './useTenant';

const useTenantTheme = (): TenantTheme => {
  const { isGwat } = useTenant();

  if (isGwat) {
    return {
      primary: {
        '900': '#0083A0',
        '800': '#00C3EE',
        '900-transparent': '#0083A026',
      },
      shadow: {
        '800': 'shadow-[0px_5px_10px_#00c3ee26]',
      },
    };
  }

  return {
    primary: {
      '900': '#FB2F54',
      '800': '#FB2F54',
      '900-transparent': '#FB2F5426',
    },
    shadow: {
      '800': 'shadow-[0px_5px_10px_#fb2f5426]',
    },
  };
};

export { useTenantTheme };
