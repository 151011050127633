import { CalendarIcon } from '@mui/x-date-pickers';
import { ClockIcon } from '../../assets/icons/Clock';
import { GlobeIcon } from '../../assets/icons/globe';
import { TCreateAppointmentResponse } from '../../lib/react-query/Queries/gCalendar/types';
import { TimeZoneName } from './TimeZoneName';
import { appointmentLocalStorageService } from '@/services/localstorage/appointment';
import { useEffect } from 'react';
import { useGetTraveller } from '../../lib/react-query/Queries/useGetTraveller';

type Props = {
  appointment: TCreateAppointmentResponse;
  duration: number;
};

const AppoitmentStatus = ({ appointment, duration }: Props) => {
  const { data: travellerData } = useGetTraveller({ isEnabled: true });

  const travellerName =
    travellerData?.result.first_name + ' ' + travellerData?.result.last_name;

  useEffect(() => {
    appointmentLocalStorageService.set(
      JSON.stringify({
        appointment: appointment,
        duration: duration,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center justify-center max-w-[406px] mx-auto">
      <div className="w-full h-full bg-[#FCFCFC] rounded-md p-3 flex flex-col items-center justify-center">
        <div className="text-center text-2xl font-semibold text-dark-800 py-4 border-b border-light-900 leading-8">
          Meet & Greet with your personal Trip Coordinator
        </div>
        <div className="flex flex-row gap-8 text-center text-lg font-semibold text-dark-800 py-4">
          <div className="flex flex-row gap-2 items-center justify-center">
            <span className="relative bottom-0.5">
              <ClockIcon width={32} height={32} />
            </span>
            {duration} min
          </div>
          <div className="flex flex-row gap-1 items-center justify-center">
            <GlobeIcon width={24} height={24} />
            <TimeZoneName region={appointment.timezone} />
          </div>
        </div>
        <div className="flex flex-col gap-2 items-center text-center justify-center text-lg font-semibold text-teal-800 text-xl">
          <CalendarIcon className="text-teal-800" />
          <div>{appointment.date}</div>
          <div>
            {appointment.start} to {appointment.end}
          </div>
        </div>
        <div className="flex flex-col gap-2 text-center text-lg text-dark-800 border-b border-light-900 py-4">
          <div className="font-semibold">
            {travellerData?.result.first_name} {travellerData?.result.last_name}
          </div>
          <div>{travellerData?.result.email}</div>
          <div>{travellerData?.result.phone_number}</div>
        </div>
        <div className="flex flex-col gap-2 text-center text-lg font-semibold text-dark-800 pt-4">
          <div>Thank you {travellerData?.result.first_name}!</div>
          <div className="font-normal leading-5">
            Your meet & greet is scheduled. We've emailed you the details.
            Welcome to the Global Work & Travel fam!
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppoitmentStatus;
