import { Input } from 'components/Input/Input';
import { useCharityStripe } from 'lib/hooks/useCharityStripe';
import { useCallback, useEffect, useState } from 'react';
import { DonationButtons } from './DonationButtons';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import {
  StripeElementsOptions,
  StripePaymentElementOptions,
  TermOption,
} from '@stripe/stripe-js';
import { DonationSubmit } from './DonationSubmit';
import { debounce } from 'lib/helper/debounce/debounce';
import { DonationResult } from './DonationResult';
import { DonationSkeleton } from './DonationSkeleton';
import { PaymentElementSkeleton } from './PaymentElementSkeleton';
import { validatePositiveNumber } from 'lib/helper/validation/validation';
import { stripeConfig } from 'data/stripe-config';
import { useTenantTheme } from 'lib/hooks/useTenantTheme';
import { Image } from 'components/Image/Image';

type Props = {
  alreadyDonated: boolean;
  isLoading: boolean;
};

const DonationCard = ({ isLoading, alreadyDonated }: Props) => {
  const options = [2, 5, 10, 20];
  const [selected, _setSelected] = useState<number | null>(options[0]);
  const [customAmount, setCustomAmount] = useState<string>('');
  const [tempCustomAmount, setTempCustomAmount] = useState<string>('');
  const [customAmountIsValid, setCustomAmountIsValid] = useState<boolean>(true);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const {
    clientSecret,
    stripePromise,
    isLoading: isStripeLoading,
  } = useCharityStripe({
    amount: finalAmount,
  });
  const theme = useTenantTheme();

  const stripeOptions: StripeElementsOptions = {
    clientSecret,
    loader: 'always',
  };

  const setSelected = useCallback((value: number | null) => {
    if (value !== null) {
      setTempCustomAmount('');
      setCustomAmount('');
    }
    _setSelected(value);
  }, []);

  useEffect(() => {
    if (customAmount.length > 0 && validatePositiveNumber(customAmount)) {
      const customAmountNumber = Number(customAmount);
      setFinalAmount(customAmountNumber);
    } else {
      if (!selected) return;
      setFinalAmount(selected);
    }
  }, [customAmount, selected]);

  const inputHandler = (input: string) => {
    setTempCustomAmount(input);
    updateCustomAmount(input);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateCustomAmount = useCallback(
    debounce((input: string) => {
      if (!input.length) {
        setCustomAmount(input);
        setSelected(options[0]);
        setCustomAmountIsValid(true);
        return;
      }
      const isValid = validatePositiveNumber(input);
      if (!isValid) {
        setCustomAmount('');
        setSelected(options[0]);
        setCustomAmountIsValid(false);
        return;
      }
      setCustomAmount(input);
      setSelected(null);
      setCustomAmountIsValid(true);
    }, 1000),
    []
  );

  const terms: { [key: string]: TermOption } = {
    card: 'never',
    ideal: 'never',
    sofort: 'never',
    sepaDebit: 'never',
    bancontact: 'never',
    auBecsDebit: 'never',
    usBankAccount: 'never',
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
    terms,
    fields: {
      billingDetails: {
        address: {
          // NOTE: hidden dependency: make sure to send user
          // location in payment-submit-stripe.tsx
          country: 'never',
        },
      },
    },
  };

  if (isLoading) {
    return (
      <div id="donation-card">
        <DonationSkeleton />
      </div>
    );
  }

  if (alreadyDonated) {
    return (
      <div id="donation-card">
        <DonationResult />
      </div>
    );
  }

  return (
    <div
      className="bg-light-700 border border-dashed border-dark-500 rounded-md"
      id="donation-card"
    >
      <div className="p-5 pb-0 md:p-10 md:pb-0 bg-light-600 flex flex-col gap-5 rounded-t-md">
        <div className="flex gap-2.5 items-center">
          <div className="w-8 h-8">
            <Image path="booking/icons/donate.svg" alt="donate icon" />
          </div>
          <h2 className="font-semibold text-base leading-5 text-dark-500">
            Animals have dreams too!
          </h2>
        </div>
        <h3 className="font-semibold text-sm leading-5 text-dark-700">
          Can you help us raise much needed funds for the Global Animal Welfare
          Fund?{' '}
          <a
            target="_blank"
            href="https://www.globalworkandtravel.com/global-animal-welfare-fund"
            rel="noreferrer"
          >
            ⓘ
          </a>
        </h3>
        <p className="font-semibold text-xs leading-5 text-dark-600 max-w-[30rem]">
          Your voluntary donation to this charity helps us fund initiatives to
          reduce the exploitation and suffering of animals.
        </p>
        <div className="flex flex-col gap-5">
          <DonationButtons
            selected={selected}
            setSelected={setSelected}
            options={options}
          />
          <div>
            <div className="text-dark-600 text-base font-semibold leading-5 mb-2.5">
              Or enter your desired amount
            </div>
            <div className="md:flex-grow">
              <Input
                placeholder="Amount"
                value={tempCustomAmount}
                changeInput={inputHandler}
                isError={!customAmountIsValid}
                ErrorMessage={
                  customAmountIsValid ? undefined : 'Must be a positive number'
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 md:px-10 md:pt-5 md:pb-0 bg-light-600 flex flex-col gap-5 rounded-t-md rounded-b-md">
        {!isStripeLoading && clientSecret && stripePromise && (
          <Elements
            stripe={stripePromise}
            options={{
              fonts: stripeConfig.fonts,
              appearance: stripeConfig.getAppearance(theme),
              ...stripeOptions,
            }}
            key={clientSecret}
          >
            <PaymentElement
              id="stripe-payment-element"
              options={paymentElementOptions}
            />
            <DonationSubmit
              customAmount={customAmount}
              setCustomAmountIsValid={setCustomAmountIsValid}
            />
          </Elements>
        )}
        {!clientSecret || !stripePromise || isStripeLoading ? (
          <PaymentElementSkeleton />
        ) : null}
      </div>
    </div>
  );
};

export { DonationCard };
