import { useTenant } from 'lib/hooks/useTenant';
import { Image } from '../../Image/Image';

const Logo = () => {
  const { isDA } = useTenant();

  return (
    <div className="relative text-base pointer-events-auto">
      <Image
        path={
          isDA
            ? 'https://s3.amazonaws.com/dealsaway.website.assets/logo_2.svg'
            : 'website/logo/global-work-and-travel-logo.svg'
        }
        alt="global work and travel logo"
        className={`!object-contain !w-28 md:!w-auto ${
          isDA ? ' h-6 ' : ' h-10 '
        }`}
        isCloudinary={!isDA}
        isLazyLoad={false}
      />
    </div>
  );
};

export { Logo };
