import { TDropDownItem } from '../../lib/types/dropdown';
import { Dispatch, SetStateAction } from 'react';
import { DropdownMenuItem } from './Dropdown.menu-item';

type Props = {
  options: TDropDownItem[];
  selected: TDropDownItem | null;
  setSelected: Dispatch<SetStateAction<TDropDownItem<{}> | null>>;
  hasFlagImage: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onChange?: () => void;
  isShowValue?: boolean;
};

const DropdownMenuItems = ({
  options,
  selected,
  setSelected,
  hasFlagImage,
  setIsOpen,
  onChange,
  isShowValue,
}: Props) => {
  return (
    <div className="py-2.5 space-y-2.5 max-h-[15rem] overflow-y-auto overflow-x-hidden">
      {options.map((option) => (
        <DropdownMenuItem
          key={option.value}
          option={option}
          isSelected={option.value === selected?.value}
          setSelected={setSelected}
          hasFlagImage={hasFlagImage}
          setIsOpen={setIsOpen}
          onChange={onChange}
          isShowValue={isShowValue}
        />
      ))}
      {!options.length && (
        <div className="flex flex-col items-center py-5">
          <p className="font-semibold text-dark-800 mb-2">Not found</p>
          <p className="font-semibold text-dark-600 text-sm">
            No search results were found
          </p>
        </div>
      )}
    </div>
  );
};
export { DropdownMenuItems };
