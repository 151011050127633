import { useTenant } from 'lib/hooks/useTenant';
import { useContext } from 'react';
import { TncApprovalContext } from './tnc-approval.context';

const useTncApproval = () => {
  const ctx = useContext(TncApprovalContext);
  const { isDA } = useTenant();

  if (!ctx) {
    return {
      isApproved: false,
      toggle: () => {},
      isRequired: isDA,
    } as const;
  }

  const toggle = () => {
    ctx.setIsApproved((value) => !value);
  };

  return {
    isApproved: ctx.isApproved,
    toggle,
    isRequired: isDA,
  } as const;
};

export { useTncApproval };
