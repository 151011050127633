import { userSessionLocalStorageService } from 'services/localstorage/session';
import { QueryParam } from 'services/query-param/query-param';
import { userSessionSessionStorageService } from 'services/session-storage/user-session';

class SessionIdService {
  constructor(private id: string | null) {}

  set(session: string) {
    this.id = session;
    userSessionLocalStorageService.set(session);
    userSessionSessionStorageService.remove();
  }

  get() {
    const sessionId = QueryParam.getFirst('session_id');
    if (sessionId) return sessionId;

    return (
      this.id ||
      userSessionLocalStorageService.get() ||
      userSessionSessionStorageService.get() ||
      ''
    );
  }

  remove() {
    this.id = null;
    userSessionLocalStorageService.remove();
    userSessionSessionStorageService.remove();
  }

  degradeToSessionStorage() {
    const sessionId = userSessionLocalStorageService.get();
    if (sessionId.length) {
      userSessionLocalStorageService.remove();
      userSessionSessionStorageService.set(sessionId);
    }
  }
}

const sessionIdService = new SessionIdService(null);

export { sessionIdService };
