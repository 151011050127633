import DaSteps from './DaSteps';
import GwatSteps from './GwatSteps';
import { Step } from './Step';
import { useTenant } from 'lib/hooks/useTenant';

type Props = {
  stripeIsLoading: boolean;
};

const Steps = ({ stripeIsLoading }: Props) => {
  const { isDA, isGwat } = useTenant();

  return (
    <div className="bg-light-600 md:border border-light-800 rounded-md md:shadow-[0px_5px_5px_0px_#17181a0d]">
      <div className="px-0 md:px-10 pt-0 md:pt-10">
        <Step
          order={1}
          status="complete"
          border="green"
          title="Complete your booking"
          description="Well done! You've taken the first & hardest step. Now the fun begins!"
          isLoading={stripeIsLoading}
        />
        <Step
          order={2}
          status="complete"
          border="green"
          title="Confirmation email"
          description="In your inbox, you'll find a copy of your booking details and payment receipt."
          isLoading={stripeIsLoading}
        />
      </div>
      {isGwat ? <GwatSteps isLoading={stripeIsLoading} /> : null}
      {isDA ? <DaSteps isLoading={stripeIsLoading} /> : null}
    </div>
  );
};

export { Steps };
