class LocalStorageService {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  set(session: string) {
    localStorage.setItem(this.key, session);
  }

  get() {
    return localStorage.getItem(this.key) || '';
  }

  remove() {
    localStorage.removeItem(this.key);
  }
}

export { LocalStorageService };
