import { Dropdown } from '../../../../Dropdown/Dropdown';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { TDropDownItem } from '../../../../../lib/types/dropdown';
import { Input } from '../../../../Input/Input';
import { usePhonePrefixQuery } from 'lib/react-query/Queries/usePhonePrefixQuery';
import { useDevice } from 'lib/hooks/useDevice';
import { TValidation } from 'lib/types/traveller-form';

type Props = {
  phonePrefix: TDropDownItem | null;
  setPhonePrefix: (value: TDropDownItem | null) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  phonePrefixValidation: TValidation;
  phoneNumberValidation: TValidation;
  defaultPhoneCode: string | null;
};

const Phone = ({
  phonePrefix,
  setPhonePrefix,
  phoneNumber,
  setPhoneNumber,
  phonePrefixValidation,
  phoneNumberValidation,
  defaultPhoneCode,
}: Props) => {
  const { isLoading, data } = usePhonePrefixQuery();
  const { isMobile } = useDevice();

  useEffect(() => {
    if (data && defaultPhoneCode) {
      setPhonePrefix(
        data?.filter((item) => item.value === defaultPhoneCode)[0]
      );
    }
  }, [data, defaultPhoneCode]);

  return (
    <div className="text-left">
      <div
        className={
          'md:border bg-light-600 transition-all border-light-900 focus-within:md:border focus-within:border-teal-900 focus-within:md:shadow-[0px_0px_4px_4px_#0083A010] rounded-md flex items-center justify-between gap-5 md:gap-0' +
          (phoneNumberValidation.isValid
            ? ''
            : ' border-red-900 md:shadow-[4px_4px_4px_4px_#FE575410] focus-within:md:border-red-900 focus-within:md:shadow-[4px_4px_4px_4px_#FE575410] md:text-red-900')
        }
      >
        {!isLoading && data ? (
          <div
            className={
              'flex-grow ' +
              (!phoneNumberValidation.isValid &&
                isMobile &&
                ' border rounded-md border-red-900 shadow-[4px_4px_4px_4px_#FE575410] focus-within:border-red-900 focus-within:shadow-[4px_4px_4px_4px_#FE575410] text-red-900 ')
            }
          >
            <Dropdown
              options={data}
              selected={phonePrefix}
              setSelected={
                setPhonePrefix as Dispatch<SetStateAction<TDropDownItem | null>>
              }
              placeholder="Code"
              hasBorder={!!isMobile && !!phoneNumberValidation.isValid}
              hasFlagImage
            />
          </div>
        ) : null}
        <div className="hidden md:block w-px h-8 border-l border-light-900"></div>
        <div className="flex-grow">
          <Input
            placeholder="Mobile Number"
            value={phoneNumber}
            type="tel"
            changeInput={setPhoneNumber}
            hasBorder={!!isMobile}
            isError={isMobile && !phoneNumberValidation.isValid}
          />
        </div>
      </div>
      {phoneNumberValidation && !phoneNumberValidation.isValid && (
        <div className="text-red-900 text-xs text-semibold mt-2 ml-1 whitespace-nowrap">
          {phoneNumberValidation.message}
        </div>
      )}
    </div>
  );
};
export { Phone };
