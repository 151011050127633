import { ComponentType } from 'react';
import { withErrorBoundary as withSentryErrorBoundary } from '@sentry/react';

type Props = {
  Component: ComponentType<Record<string, any>>;
  Fallback?: (props?: any) => JSX.Element;
};

const DefaultFallback = () => {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <div>An error has occured!</div>
    </div>
  );
};

const withErrorBoundary = ({
  Component,
  Fallback = DefaultFallback,
}: Props) => {
  return withSentryErrorBoundary(Component, { fallback: <Fallback /> });
};

export { withErrorBoundary };
