import { useIsMutating, useQuery } from '@tanstack/react-query';
import { mainPaymentOptions } from 'data/payment-option';
import { checkTravellerData } from 'lib/helper/checkTravellerData';
import { PaymentOption, PaymentSetupQueryResult } from 'lib/types/payment';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useGetTraveller } from './useGetTraveller';

type Props = {
  paymentOption: PaymentOption;
  provider: string;
  timezoneOffset: number;
  method: string;
  promoCodes: string;
  promosLoading: boolean;
};

const usePaymentSetup = ({
  paymentOption,
  provider,
  timezoneOffset,
  method,
  promoCodes,
  promosLoading,
}: Props) => {
  const URL = '/public/payment/setup';
  const sessionId = sessionIdService.get();

  const createTravellerIsMutating = useIsMutating({
    mutationKey: ['create-traveller'],
  });

  const { data: travellerData } = useGetTraveller({ isEnabled: true });
  const hasTravellerData = travellerData?.result
    ? checkTravellerData(travellerData?.result)
    : false;

  const payload = {
    payment_option: paymentOption,
    provider,
    timezone_offset: timezoneOffset,
    method,
  };

  return useQuery(
    ['payment-setup', sessionId, payload, promoCodes],
    async () => {
      if (paymentOption !== 'paid-deposit' && paymentOption !== 'paid-full') {
        const response = await BookingGateway.post<PaymentSetupQueryResult>(
          URL,
          payload
        );
        return response;
      }
      return null;
    },
    {
      enabled:
        !promosLoading &&
        paymentOption !== null &&
        mainPaymentOptions.includes(paymentOption) &&
        !createTravellerIsMutating &&
        !!sessionId.length &&
        hasTravellerData,
    }
  );
};

export { usePaymentSetup };
