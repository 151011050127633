import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { LineContainer } from './LineContainer';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useTenant } from 'lib/hooks/useTenant';
import { useLocation } from 'react-router-dom';
import { stagePathConfig } from 'data/stage';
import { useExperimentalFeatureVariant } from 'lib/hooks/useExperimentalFeature';
import { AB_TEST_FEATURE } from 'data/flags';

type Props = {
  due: number;
};

const PaymentDue = ({ due }: Props) => {
  const { pathname } = useLocation();
  const { isEnabled, variant } = useExperimentalFeatureVariant(
    AB_TEST_FEATURE.due_now
  );
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();
  const { isDA } = useTenant();
  const shouldShow = !isEnabled || (isEnabled && variant === 'Original');
  const isInPaymentStage = pathname === stagePathConfig.payment;

  return shouldShow || isInPaymentStage ? (
    <div
      className={`p-5 ${
        isDA ? ' md:bg-dealsaway-800 ' : ' bg-teal-600 bg-opacity-20 '
      } `}
    >
      <LineContainer gap={2} isCentered>
        <div className="font-semibold !leading-5 text-[1.25rem] md:!leading-6 text-dark-700">
          Due Now
        </div>
        <div className="flex items-center">
          <div
            className={`font-semibold leading-7 text-[1.5rem] md:leading-8 ${
              isDA ? ' text-dealsaway-900 ' : ' text-teal-900 '
            }`}
          >
            {numberFormatter.format(due)}
          </div>
          {currencyShouldBeShown ? (
            <div
              className={`font-semibold text-lg ml-0.5 mb-0.5 ${
                isDA ? ' text-dealsaway-900 ' : ' text-teal-900 '
              }`}
            >
              ({currency})
            </div>
          ) : null}
        </div>
      </LineContainer>
    </div>
  ) : (
    <></>
  );
};

export { PaymentDue };
