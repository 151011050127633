import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TLocation } from 'lib/types/location';

type LocationState = {
  location: TLocation | null;
};

const initialState: LocationState = {
  location: null,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation(state, action: PayloadAction<TLocation>) {
      state.location = action.payload;
    },
  },
});

export const { setLocation } = locationSlice.actions;
export default locationSlice.reducer;
