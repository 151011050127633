import { ThemeOptions } from '@mui/material';
import { TenantTheme } from 'lib/types/theme';

export const getCommonTheme = (theme: TenantTheme): ThemeOptions => {
  return {
    palette: {
      text: {
        primary: '#333539',
        disabled: '#737882',
        secondary: '#737882',
      },
      primary: {
        main: theme.primary[800],
        light: theme.primary[800],
        dark: theme.primary[800],
      },
    },
    typography: {
      fontFamily: 'proxima-nova',
      fontSize: 12.5,
      fontWeightRegular: 500,
    },
  };
};
