import { CSSProperties, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { BookingModalHeader } from './BookingModalHeader';

const customModalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: '#17181Ad9',
  },
  content: {
    border: 'none',
    backgroundColor: 'transparent',
  },
};

export type Props = {
  isOpen: boolean;
  close: Function;
  title: string;
  children: JSX.Element[] | JSX.Element;
  position?: 'bottom' | 'top' | 'center';
  cssClasses?: string;
  isCloseButtonHidden?: boolean;
  isCloseClickOutside?: boolean;
  titleCssCalss?: string;
  mobileTitleCss?: string;
  isScrollable?: boolean;
  fullHeight?: boolean;
  style?: CSSProperties;
  hasTitle?: boolean;
};

const BookingModal = ({
  isOpen,
  close,
  title,
  children,
  position = 'bottom',
  cssClasses,
  isCloseButtonHidden = false,
  isCloseClickOutside = true,
  titleCssCalss = ' p-10 ',
  mobileTitleCss,
  isScrollable = true,
  fullHeight = false,
  style = {},
  hasTitle = true,
}: Props) => {
  const mainRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    isCloseClickOutside &&
      document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseClickOutside]);

  const handleClickOutside = (e: any) => {
    if (
      mainRef?.current?.contains(e.target) &&
      !contentRef?.current?.contains(e.target)
    ) {
      close();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      // make sure overflow-hidden is removed from body once dismounted
      // this usually happens when user clicks on browser back button instead
      // of closing the dialog like a normal human being!!! Just kidding though!
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      style={customModalStyles}
      closeTimeoutMS={300}
      ariaHideApp={false}
    >
      <div
        data-testid="modal"
        ref={mainRef}
        className={
          'z-50 fixed overflow-hidden top-0 right-0 left-0 bottom-0 w-full h-full max-h-screen bg-transparent flex justify-center items-center' +
          (isCloseButtonHidden ? '' : ' cursor-pointer') +
          (isScrollable ? '' : ' unscrollable')
        }
      >
        <div
          ref={contentRef}
          className={
            `modal-content bg-light-700 shadow-modalfilter cursor-auto m-auto left-0 right-0 rounded-md w-[96%] ` +
            (position === 'bottom' ? ' absolute sm:relative bottom-2.5 ' : '') +
            (position === 'top' ? ' absolute sm:relative top-2.5 ' : '') +
            (position === 'center' ? '' : '') +
            cssClasses
          }
          style={style}
        >
          <div
            className={
              'modal-content-inner relative text-center' +
              (fullHeight ? ' h-full md:h-auto' : '')
            }
          >
            {hasTitle && (
              <BookingModalHeader
                title={title}
                titleCssClasses={titleCssCalss}
                isCloseButtonHidden={isCloseButtonHidden}
                mobileTitleCss={mobileTitleCss}
                close={close}
              />
            )}
            <div
              data-testid="child"
              className={
                'modal-content-children-wrapper ' +
                (fullHeight ? ' h-full md:h-auto' : '')
              }
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { BookingModal };
