import { useTenant } from 'lib/hooks/useTenant';
import { useEffect } from 'react';
import { QueryParam } from 'services/query-param/query-param';
import { sessionIdService } from 'services/session-id/session-id.service';

const useRedirectIfNoSid = () => {
  const { isGwat } = useTenant();

  const defaultUrl = isGwat
    ? 'https://www.globalworkandtravel.com/'
    : 'https://dealsaway.com/';

  useEffect(() => {
    const storageSessionId = sessionIdService.get();
    const paramSessionId = QueryParam.getFirst('session_id');

    if (!storageSessionId && !paramSessionId) {
      window.location.replace(
        `https://${process.env.REACT_APP_WEBSITE_DOMAIN_NAME}` || defaultUrl
      );
    }
  }, [defaultUrl]);
};

export { useRedirectIfNoSid };
