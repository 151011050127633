export type TOffice = {
  id: number;
  office: string;
  officeAlternativeName: string;
  lat?: number;
  lng?: number;
  name: {
    region: string;
    business: string;
    legal: string[];
    legalName: string;
  };

  address?: {
    street: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
  };

  phone: {
    number: string | null;
    countryCode: string | null;
  }[];

  open: {
    offset: number;
    hours: string;
    times: {
      0: string[] | null;
      1: string[] | null;
      2: string[] | null;
      3: string[] | null;
      4: string[] | null;
      5: string[] | null;
      6: string[] | null;
    };
  };
  gCalendar: {
    eventId: string;
  };
};

export enum OfficeName {
  Australia = 'Australia',
  UnitedKingdom = 'United Kingdom',
  Americas = 'Americas',
}

export type TOffices = {
  [key in OfficeName]: TOffice;
};
