type Props = {
  time: string;
  isUrgent: boolean;
};

const Timer = ({ time, isUrgent }: Props) => {
  return (
    <div
      className={
        'relative md:border md:rounded-md flex justify-center items-center min-w-[8.75rem] md:min-w-[7.3rem] h-full md:h-auto' +
        (isUrgent
          ? ' border-red-600 md:bg-red-500'
          : ' border-[#0083a033] md:bg-teal-500')
      }
    >
      <div
        className={
          'text-xl leading-5 font-semibold my-[0.5625rem] mx-5' +
          (isUrgent ? ' text-red-900' : ' text-dark-700')
        }
      >
        {time}
      </div>
      <div
        className={
          'block md:hidden w-full h-2.5 absolute bottom-0' +
          (isUrgent
            ? ' timer-repeating-gradient-red'
            : ' timer-repeating-gradient-teal')
        }
      ></div>
    </div>
  );
};
export { Timer };
