import { Warning } from 'assets/icons/Warning';
import { Button } from 'components/Button/Button';
import { ContinueRightArrowIcon } from '../Traveller/traveller-stage/continue-right-icon';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import { useDevice } from 'lib/hooks/useDevice';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { usePaymentQueryParams } from 'lib/hooks/usePaymentQueryParams';
import { Elements } from '@stripe/react-stripe-js';
import { Payment } from './payment';
import { stagePathConfig } from 'data/stage';
import { useEffect } from 'react';
import { paymentInitiationLocalStorageService } from 'services/localstorage/payment-initiation';

const TransactionFailedStage = () => {
  const navigate = useCustomNavigate();
  const { isMobile } = useDevice();
  const { isLoading: travellerDataIsLoading, data } = useGetTraveller({
    isEnabled: true,
  });

  const { clientSecret, stripePromise } = usePaymentQueryParams();

  const travellerID =
    !travellerDataIsLoading &&
    data &&
    data.result.cid &&
    data.result.sales_register_id
      ? data.result.cid + '-' + data.result.sales_register_id
      : '...';

  useEffect(() => {
    paymentInitiationLocalStorageService.remove();
  }, []);

  return (
    <>
      <div className="bg-light-600 md:border border-light-800 md:shadow-[0px_5px_5px_0px_#17181a0d] rounded-md">
        <div className="p-5 md:p-10">
          <div className="flex items-center pb-5">
            <div className="w-10 h-10 mr-2.5">
              <Warning color="#FE5754" />
            </div>
            <div className="text-xl md:text-2xl text-dark-900 !leading-6 font-medium">
              Transaction failed
            </div>
          </div>
          <div className="text-dark-700 text-sm md:text-base font-medium !leading-5">
            Looks like your transaction has either been rejected or was
            cancelled by you or the payment provider. If this was not
            intentional, or you think it should have been successful and you
            would like to report an issue your Traveller ID is:
          </div>
          <div className="py-5 ">
            <div className="bg-light-700 border border-light-900 rounded-md h-[3.125rem] px-5 flex  items-center text-dark-900 !leading-[1.375rem] text-lg w-fit">
              {travellerID || ''}
            </div>
          </div>
          <div className="text-dark-700 text-sm md:text-base font-medium !leading-5">
            Or return to payment page and try a different method.
          </div>
        </div>
        <div className="hidden md:flex p-5 border-t border-light-800  justify-end items-center">
          <Button
            text={'Go To Payment'}
            clickHandler={() => {
              navigate(stagePathConfig.payment);
            }}
            size="medium"
            type="red"
            icon={<ContinueRightArrowIcon color="#FFF" />}
            className="min-w-[15.625rem]"
          />
        </div>
      </div>
      {isMobile && (
        <div className="fixed z-50 bottom-0 left-0 right-0 bg-light-600 border-t border-light-900">
          <div className="w-full flex items-center justify-center p-5 h-[6.25rem]">
            <Button
              text={'Go To Payment'}
              clickHandler={() => {
                navigate(stagePathConfig.payment);
              }}
              size="medium"
              type="red"
              icon={<ContinueRightArrowIcon color="#FFF" />}
              className="w-full"
            />
          </div>
        </div>
      )}
      <>
        {clientSecret && stripePromise && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
            }}
          >
            <Payment clientSecret={clientSecret} />
          </Elements>
        )}
      </>
    </>
  );
};

export { TransactionFailedStage };
