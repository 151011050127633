import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/animations/shift-away.css';
import Tippy from '@tippyjs/react';
import { Image } from 'components/Image/Image';

type Props = {
  content: string;
};

const PaymentOptionPopup = ({ content }: Props) => {
  return (
    <Tippy
      className="tooltip"
      theme="light-border"
      animation="shift-away"
      maxWidth={370}
      arrow={true}
      duration={200}
      delay={[75, 0]}
      content={
        <div className="bg-light-600">
          <p className="sm:hidden absolute top-0 right-0 cursor-pointer">x</p>
          <p
            className="text-center"
            style={{ whiteSpace: 'break-spaces' }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></p>
        </div>
      }
    >
      <div className="inline-block ml-0.5 align-middle relative top-px">
        <div className="w-4 h-4 font-bold flex justify-center items-center -top-0.5 left-0.5 md:left-[unset] relative">
          <Image path="booking/icons/info.svg" alt="info icon" />
        </div>
      </div>
    </Tippy>
  );
};

export { PaymentOptionPopup };
