import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LocalStorageService } from 'services/localstorage';

type Props = {
  setStripeIsLoading: Dispatch<SetStateAction<boolean>>;
};

const useHandleSkipPayment = ({ setStripeIsLoading }: Props) => {
  const localStorageSkip = new LocalStorageService('skip-payment');
  const [skipState, setSkipState] = useState('init');

  useEffect(() => {
    if (skipState === 'skip-success') {
      setStripeIsLoading(false);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipState]);

  useEffect(() => {
    setSkipState(localStorageSkip.get());
  }, []);

  useEffect(() => {
    if (skipState === 'init') return;

    localStorageSkip.remove();
  }, [skipState]);

  return { skipState };
};

export { useHandleSkipPayment };
