export type Props = {
  id: string;
  value: string;
  name: string;
  isSelected: boolean;
  isDisabled?: boolean;
  label: string;
  selectedOption: any;
  labelClassName?: string;
};

function Radiobutton({
  id,
  value,
  name,
  isSelected,
  label,
  isDisabled = false,
  selectedOption,
  labelClassName = '',
}: Props) {
  return (
    <div className="flex items-center flex-shrink-0">
      <input
        data-testid="radiobutton__input"
        disabled={isDisabled}
        id={id}
        value={value}
        name={name}
        checked={isSelected}
        onChange={selectedOption}
        type="radio"
        className={
          'w-5 h-5 border rounded-full appearance-none outline-none border-dark-500 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left relative' +
          (!isDisabled
            ? ' custom-radio text-red-900 cursor-pointer checked:border-teal-900 checked:bg-teal-900 '
            : ' bg-transparent')
        }
      />
      <label
        data-testid="radiobutton"
        htmlFor={id}
        className={
          'text-sm font-semibold leading-4 text-dark-700 ml-2.5 cursor-pointer' +
          ` ${labelClassName} ` +
          (isDisabled ? ' !bg-transparent' : '')
        }
      >
        {label}
      </label>
    </div>
  );
}

export { Radiobutton };
