import Joi from 'joi';
import tlds from 'tlds';

export function getEmailValidation(email: string): boolean {
  // check to avoid spaces
  if (email.includes(' ')) {
    return false;
  }
  // Reference: https://stackoverflow.com/a/201378
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailRegex.test(email.toLocaleLowerCase());
}

export function getJoiEmailValidation(email: string): boolean {
  const result = Joi.string()
    .email({ tlds: { allow: tlds } })
    .min(1)
    .validate(email);
  return !result.error;
}

export function getStringValidation(input: string): boolean {
  const regex = /^[^0-9]+$/;
  return regex.test(input);
}

export function getNumberValidation(input: string): boolean {
  const regex = /^\d+$/;
  return regex.test(input);
}

export function validatePositiveNumber(input: string): boolean {
  const regex = /^\d*[1-9]\d*$/;
  return regex.test(input);
}
