import { useQuery } from '@tanstack/react-query';
import { PaymentInfoQueryResult } from 'lib/types/payment';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

const usePaymentInfo = (token: string | null) => {
  const URL = `/public/payment/info/token/${token}`;
  const sessionId = sessionIdService.get();

  return useQuery(
    ['payment-info', sessionId],
    async () => {
      const response = await BookingGateway.get<PaymentInfoQueryResult>(URL);

      return response;
    },
    {
      staleTime: 20 * 1000,
      enabled: !!sessionId.length && !!token,
    }
  );
};

export { usePaymentInfo };
