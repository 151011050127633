import { useMutation } from '@tanstack/react-query';
import { GotToGoQueryResult } from 'lib/types/got-to-go';
import { BookingGateway } from 'services/http/booking-gateway';

const useGotToGo = () => {
  const URL = '/public/session/got-to-go';

  return useMutation(['got-to-go'], async (email: string) => {
    const response = await BookingGateway.put<GotToGoQueryResult>(URL, {
      email,
    });
    return response;
  });
};

export { useGotToGo };
