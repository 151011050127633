import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

type Props = {
  children: JSX.Element | JSX.Element[];
};

const Layout = ({ children }: Props) => {
  return (
    <div>
      <Header />
      <div>
        <div className="max-w-screen-lg md:mx-auto">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export { Layout };
