class SessionStorageService {
  key: string;

  constructor(key: string) {
    this.key = key;
  }

  set(session: string) {
    sessionStorage.setItem(this.key, session);
  }

  get() {
    return sessionStorage.getItem(this.key) || '';
  }

  remove() {
    sessionStorage.removeItem(this.key);
  }
}

export { SessionStorageService };
