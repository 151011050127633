import { TDropDownItem } from '../../../../../lib/types/dropdown';
import { Input } from '../../../../Input/Input';
import { Phone } from './phone';
import { TValidation } from 'lib/types/traveller-form';

type Props = {
  phonePrefix: TDropDownItem | null;
  setPhonePrefix: (value: TDropDownItem | null) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  phonePrefixValidation: TValidation;
  phoneNumberValidation: TValidation;
  emailValidation: TValidation;
  isEmailDisable: boolean;
  defaultPhoneCode: string | null;
};

const TravellerContactSection = ({
  phonePrefix,
  setPhonePrefix,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  phonePrefixValidation,
  phoneNumberValidation,
  emailValidation,
  isEmailDisable,
  defaultPhoneCode,
}: Props) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      <Phone
        phonePrefix={phonePrefix}
        setPhonePrefix={setPhonePrefix}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        phonePrefixValidation={phonePrefixValidation}
        phoneNumberValidation={phoneNumberValidation}
        defaultPhoneCode={defaultPhoneCode}
      />
      <Input
        placeholder="Email"
        value={email}
        changeInput={setEmail}
        type="email"
        isError={!emailValidation.isValid}
        ErrorMessage={emailValidation.message}
        isDisable={isEmailDisable}
        inputId="email-input"
      />
    </div>
  );
};

export { TravellerContactSection };
