import { getCurrencyByLocation } from 'lib/helper/currency/currency';
import { CurrencyKeys } from 'lib/types/trip';
import { useAppSelector } from 'state/hooks';
import { useUserLocation } from './useUserLocation';

type ReturnType = {
  currency: CurrencyKeys;
  currencyShouldBeShown: boolean;
  currencyHasLoaded: boolean;
};

const useCurrency = (): ReturnType => {
  const location = useUserLocation();
  const userCurrency = useAppSelector((state) => state.user.currency);

  const currency: CurrencyKeys =
    userCurrency ?? getCurrencyByLocation(location);
  const countryCode = location ? location.country.code : '';

  const countriesWithDominantCurrencies = ['US', 'AU'];
  const currenciesWithOnlyOneValue = ['GBP', 'EUR'];
  const currencyShouldBeShown =
    !countriesWithDominantCurrencies.includes(countryCode) &&
    !currenciesWithOnlyOneValue.includes(currency);

  return {
    currency,
    currencyShouldBeShown,
    currencyHasLoaded: !!userCurrency || !!location,
  };
};

export { useCurrency };
