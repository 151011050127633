import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TErrorModal } from 'lib/types/modal';

type ModalState = {
  error: TErrorModal;
};

const initialState: ModalState = {
  error: {
    isOpen: false,
    message: '',
    buttonText: '',
  },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<TErrorModal>) {
      state.error = action.payload;
    },
  },
});

export const { setError } = modalSlice.actions;
export default modalSlice.reducer;
