import { withErrorBoundary } from 'lib/HOC/withErrorBoundary/withErrorBoundary';
import { useExternalSurvey } from 'lib/hooks/useExternalSurvey';

const SurveyFairingComponent = () => {
  useExternalSurvey();

  return (
    <div className="pt-[0.3125rem] md:pt-2.5 pl-[0.3125rem] md:pl-2.5 pr-[0.3125rem] md:pr-2.5 bg-teal-900 rounded-md">
      <div className="bg-light-600 rounded-md overflow-hidden px-4 md:px-7 py-5 md:py-7">
        <div id="external-survey-container"></div>
      </div>
      <p className="text-xs !leading-5 font-semibold py-1.5 md:py-2.5 text-light-600 opacity-50 uppercase">
        Powered by Fairing
      </p>
    </div>
  );
};

export const SurveyFairing = withErrorBoundary({
  Component: SurveyFairingComponent,
  Fallback: () => <></>,
});
