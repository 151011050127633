import { Inclusion } from 'lib/types/trip';

export const filterInclusions = (
  inclusions: Inclusion[],
  userCountry: string | null
) => {
  return inclusions.filter((inclusion) => {
    if (!userCountry) {
      return true;
    }
    if (!inclusion.validCountries || !inclusion.validCountries.length) {
      return true;
    }
    return inclusion.validCountries.includes(userCountry);
  });
};
