import { OfficeName, TOffice, TOffices } from './types';

export const officeNameByLocation: {
  [key: string]: OfficeName;
} = {
  Americas: OfficeName.Americas,
  'North America': OfficeName.Americas,
  'South America': OfficeName.Americas,
  'Central America': OfficeName.Americas,
  Europe: OfficeName.UnitedKingdom,
  Ocenia: OfficeName.Australia,
  default: OfficeName.Australia,
};

export const officeData: TOffices = {
  [OfficeName.Australia]: {
    id: 1,
    office: 'Australia',
    officeAlternativeName: 'Gold Coast, Australia',
    name: {
      region: 'Australia & New Zealand',
      business: 'The Global Work & Travel Co. (Asia Pacific) Pty. Ltd.',
      legal: ['ABN: 38 600 280 331'],
      legalName: 'Asia Pacific',
    },
    lat: -28.00204,
    lng: 153.42673,
    address: {
      street: 'Level 16, 50 Cavill Avenue',
      city: 'Surfers Paradise',
      state: 'Queensland',
      country: 'Australia',
      postCode: '4217',
    },
    phone: [
      {
        number: '1300 40 11 90',
        countryCode: 'AU',
      },
      {
        number: '09 925 0387',
        countryCode: 'NZ',
      },
    ],
    open: {
      offset: 10,
      hours: 'Monday to Friday 9am - 6pm AEST',
      times: {
        0: null,
        1: ['09:00', '18:00'],
        2: ['09:00', '18:00'],
        3: ['09:00', '18:00'],
        4: ['09:00', '18:00'],
        5: ['09:00', '18:00'],
        6: null,
      },
    },
    gCalendar: {
      eventId: '17',
    },
  },

  [OfficeName.Americas]: {
    id: 2,
    office: 'Canada',
    officeAlternativeName: 'Vancouver, Canada',
    name: {
      region: 'Canada & USA',
      business: 'The Global Work & Travel Co. Inc.',
      legal: ['Inc.#: BC0848469', 'Travel Agency License: 66496'],
      legalName: 'North America',
    },
    lat: 49.28531,
    lng: -123.11247,
    address: {
      street: 'Level 8, 333 Seymour Street',
      city: 'Vancouver',
      state: 'British Columbia',
      country: 'Canada',
      postCode: 'V6B 5A6',
    },
    phone: [
      {
        number: '1877 744 5622',
        countryCode: 'AU',
      },
    ],
    open: {
      offset: -8,
      hours: 'Monday to Friday 9am - 6pm PST',
      times: {
        0: null,
        1: ['06:00', '18:00'],
        2: ['06:00', '18:00'],
        3: ['06:00', '18:00'],
        4: ['06:00', '18:00'],
        5: ['06:00', '18:00'],
        6: null,
      },
    },
    gCalendar: {
      eventId: '18',
    },
  },

  [OfficeName.UnitedKingdom]: {
    id: 3,
    office: 'United Kingdom',
    officeAlternativeName: 'London, United Kingdom',
    name: {
      region: 'UK & Europe',
      business: 'The Global Work & Travel Co. Ltd.',
      legal: ['Reg.#: 8087039', 'ABTA#: Y6197 / ATOL#: 11103'],
      legalName: 'Europe',
    },
    lat: 51.518694,
    lng: -0.110139,
    address: {
      street: '3 Waterhouse Square, 138 Holborn',
      city: 'London',
      state: 'England',
      country: 'UK',
      postCode: 'EC1N 2SW',
    },
    phone: [
      {
        number: '020 3002 2138',
        countryCode: 'UK',
      },
      {
        number: '+44 20 3002 2138',
        countryCode: 'EU',
      },
      {
        number: '02 320 55 07',
        countryCode: 'BE',
      },
      {
        number: '020 532 0028',
        countryCode: 'NL',
      },
      {
        number: '01 513 5536',
        countryCode: 'IE',
      },
    ],
    open: {
      offset: 1,
      hours: 'Monday to Friday 9am - 6pm GMT',
      times: {
        0: null,
        1: ['09:00', '18:00'],
        2: ['09:00', '18:00'],
        3: ['09:00', '18:00'],
        4: ['09:00', '18:00'],
        5: ['09:00', '18:00'],
        6: null,
      },
    },
    gCalendar: {
      eventId: '19',
    },
  },
};

export const dealsAwayOfficeData: TOffice = {
  id: 1,
  office: 'Australia',
  officeAlternativeName: 'Gold Coast, Australia',
  name: {
    region: 'Australia & New Zealand',
    business: 'The Global Work & Travel Co. (Asia Pacific) Pty. Ltd.',
    legal: ['ABN: 38 600 280 331'],
    legalName: 'Asia Pacific',
  },
  lat: -28.00204,
  lng: 153.42673,
  address: {
    street: 'Level 16, 50 Cavill Avenue',
    city: 'Surfers Paradise',
    state: 'Queensland',
    country: 'Australia',
    postCode: '4217',
  },
  phone: [
    {
      number: '1300 95 60 58',
      countryCode: 'AU',
    },
  ],
  open: {
    offset: 10,
    hours: 'Monday to Friday 9am - 6pm AEST',
    times: {
      0: null,
      1: ['09:00', '18:00'],
      2: ['09:00', '18:00'],
      3: ['09:00', '18:00'],
      4: ['09:00', '18:00'],
      5: ['09:00', '18:00'],
      6: null,
    },
  },
  gCalendar: {
    eventId: '17',
  },
};
