const LinkedinIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 1124 1054"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#737882"
        d="M948 9H75Q44 9 22 30.5T0 83v876q0 31 22 52.5t53 21.5h874q31 0 52.5-21.5T1024 959V83q-1-31-22.5-52.5T949 9h-1zM304 881H152V393h152v488zm-76-555q-37 0-62.5-25.5T140 238q0-36 25.5-62t62.5-26q36 0 62 26t26 62q0 37-26 62.5T228 326zm644 555H721V643q0-43-10-86.5T642 513q-60 0-75.5 39T551 638v242H399V393h147v67q21-36 57.5-57.5T684 381h6q115 0 148.5 67.5T872 614v267z"
      />
    </svg>
  );
};

export { LinkedinIcon };
