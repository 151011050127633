import { RudderStackSetUser, RudderStackTrack } from 'components/RudderStack';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useCurrency } from './useCurrency';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import { useGetTripReview } from 'lib/react-query/Queries/useGetTripReview';
import { useUserLocation } from './useUserLocation';
import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { defaultLocation } from 'data/location';
import {
  TCheckoutStarted,
  TCouponApplied,
  TCouponDenied,
  TCouponEntered,
  TCouponRemoved,
  TCustomerRegistration,
  TGotToGo,
  TInitiatePayment,
  TOrderCompleted,
  TTravellerPageInitiated,
  TTrackableLinkClicked,
} from '../types/track-events';
import { sessionIdService } from 'services/session-id/session-id.service';
import * as Sentry from '@sentry/react';
import { useProductQuery } from '../react-query/Queries/useProductQuery';
import { useAppSelector } from 'state/hooks';

const useRudderStack = () => {
  const { isLoading: isTripDataLoading, data: tripData } = useTripQuery();

  const { isLoading: isProductDataLoading, data: productData } =
    useProductQuery();

  const { isLoading: isReviewDataLoading, data: reviewData } =
    useGetTripReview();

  const location = useUserLocation();
  const country = location ? location.country : defaultLocation.country;

  const selectedDuration = useAppSelector((state) => state.product.duration);
  const duration = selectedDuration?.text ?? '';
  const availability = useAppSelector((state) => state.product.availability);
  const startDate = AvailabilityHelper.getDepartureDate(availability);

  const { currency } = useCurrency();
  const { isLoading: isPromotionDataLoading, data: promotionData } =
    usePromotionsQuery();
  const activePaymentOption = useAppSelector(
    (state) => state.product.paymentOption
  );

  const activePromotions =
    promotionData &&
    PromotionHelper.getSelectedPromotionObject(
      promotionData.result.promotions,
      activePaymentOption
    );

  const formatStartDate = productData?.result.is_departure_flexible
    ? 'Flexible'
    : startDate && new Date(startDate * 1000).toISOString();

  const deposite =
    (activePromotions?.invoice.deposit || 0) -
    (activePromotions?.invoice.deposit_discount || 0);

  const price =
    (activePromotions?.invoice.total_price || 0) -
    (activePromotions?.invoice.total_price_discount || 0);

  const promos = PromotionHelper.getActiveAndAppliedPromotions(
    activePromotions?.items,
    promotionData?.result.applied_promotions
  );
  const promotionsValue = promos.map((item) => item.code).join(', ');

  const roundedRating = Math.ceil(
    parseFloat(reviewData?.result.rating || '0') || 0
  );
  const starRatingImage = `https://d34jukv1sh5rvw.cloudfront.net/email/booking/abandoned-cart/${roundedRating}stars.png`;

  const salesRegisterId =
    useAppSelector((state) => state.user.saleRegisterId) ?? 0;
  const travellerNumber = useAppSelector(
    (state) => state.product.travellerNumber
  );

  const gotTogo = (email: string) => {
    if (tripData) {
      const rudderObj: TGotToGo = {
        product_name: tripData.result.trip.trip_name,
        division: tripData.result.division.division,
        destination: tripData.result.destination.destination,
        duration,
        start_date: formatStartDate || '',
        currency: currency,
        revenue: price,
        price: price,
        deposit: deposite,
        total: price,
        value: price,
        original_price: tripData.result.prices.total[currency],
        original_deposit: tripData.result.prices.deposit[currency],
        promotions: promotionsValue,
        email: email,
        destination_city: tripData?.result.destination.city,
        country: country.name,
        sessionId: sessionIdService.get(),
      };

      RudderStackTrack('Got to Go', rudderObj);
    }
  };

  const initiatePayment = (invoice_id: number) => {
    if (tripData) {
      const rudderObj: TInitiatePayment = {
        product_name: tripData.result.trip.trip_name,
        division: tripData.result.division.division,
        destination: tripData.result.destination.destination,
        duration,
        start_date: formatStartDate || '',
        currency: currency,
        revenue: price,
        price: price,
        deposit: deposite,
        original_price: tripData.result.prices.total[currency],
        original_deposit: tripData.result.prices.deposit[currency],
        promotions: promotionsValue,
        reviews_count: reviewData?.result?.count || 0,
        number_of_travellers: travellerNumber || 1,
        encoded_sales_register_id: window.btoa(
          unescape(encodeURIComponent(salesRegisterId))
        ),
        destination_city: tripData?.result.destination.city,
        country: country.name,
        sessionId: sessionIdService.get(),
        total: price,
        value: price,
        invoice_id: invoice_id,
        product_image_link: tripData.result.trip.banner,
        rating_image: starRatingImage,
        reviews_rating: parseFloat(reviewData?.result.rating || '0') || 0,
      };

      RudderStackTrack('Initiate Payment', rudderObj);
    }
  };

  const orderComplete = (
    cid: number,
    salesRegisterId: number,
    paid: number
  ) => {
    if (tripData && productData) {
      const rudderObj: TOrderCompleted = {
        product_name: tripData.result.trip.trip_name,
        division: tripData?.result.division.division,
        destination: tripData?.result.destination.destination,
        duration: tripData?.result.duration.text || '',
        start_date: formatStartDate || '',
        currency: currency,
        revenue: price,
        price: price,
        value: price,
        total: price,
        deposit: deposite,
        original_price: tripData.result.prices.total[currency],
        original_deposit: tripData.result.prices.deposit[currency],
        promotions: promotionsValue,
        total_paid: paid,
        payment_method: 'stripe_card',
        transaction_id: cid ? cid + '-' + tripData?.result.trip.id : '',
        destination_city: tripData?.result.destination.city,
        country: country.name,
        order_id: tripData?.result.trip.id,
        products: [
          {
            product_id: tripData?.result.trip.id,
            name:
              tripData?.result.division.division +
              ' ' +
              tripData?.result.destination.destination,
            price: activePromotions?.invoice.total_price || 0,
            quantity: 1,
            category: tripData?.result.division.division,
          },
        ],
        num_items: 1,
        sales_register_id: salesRegisterId,
        sessionId: sessionIdService.get(),
      };

      if (!rudderObj.sales_register_id || !rudderObj.transaction_id) {
        Sentry.captureException(
          `Prevented raising OrderComplete with bad data.`,
          {
            tags: {
              event: 'OrderComplete',
            },
            extra: {
              description: `sales_register_id=${rudderObj.sales_register_id} transaction_id=${rudderObj.transaction_id}`,
            },
          }
        );
        return;
      }
      RudderStackTrack('Order Completed', rudderObj);
    }
  };

  const customerRegistration = (
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    cid: number,
    sales_register_id: number
  ) => {
    const rudderObj: TCustomerRegistration = {
      cid,
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      sales_register_id: sales_register_id.toString(),
      location: country.name,
      sessionId: sessionIdService.get(),
    };
    RudderStackSetUser(email, rudderObj);
    console.debug(`Identify`, rudderObj);
  };

  const checkoutStarted = () => {
    if (tripData) {
      const rudderobj: TCheckoutStarted = {
        product_name: tripData.result.trip.trip_name,
        division: tripData.result.division.division,
        destination: tripData.result.destination.destination,
        duration,
        start_date: formatStartDate || '',
        currency: currency,
        revenue: price,
        price: price,
        value: price,
        total: price,
        deposit: deposite,
        original_price: tripData.result.prices.total[currency],
        original_deposit: tripData.result.prices.deposit[currency],
        promotions: promotionsValue,
        destination_city: tripData?.result.destination.city,
        country: country.name,
        order_id: tripData?.result.trip.id,
        products: [
          {
            product_id: tripData?.result.trip.id,
            name: tripData.result.trip.trip_name,
            price: price,
            category: tripData.result.division.division,
            quantity: 1,
          },
        ],
        num_items: 1,
        sessionId: sessionIdService.get(),
      };
      RudderStackTrack('Checkout Started', rudderobj);
    }
  };

  const travellerPageInitiated = () => {
    if (tripData) {
      const rudderObj: TTravellerPageInitiated = {
        product_name: tripData?.result.trip.trip_name,
        division: tripData?.result.division.division,
        destination: tripData?.result.destination.destination,
        duration,
        start_date: formatStartDate || '',
        currency: currency,
        revenue: price,
        price: price,
        total: price,
        value: price,
        deposit: deposite,
        original_price: tripData.result.prices.total[currency],
        original_deposit: tripData.result.prices.deposit[currency],
        promotions: promotionsValue,
        destination_city: tripData?.result.destination.city,
        country: country.name,
        number_of_travellers: travellerNumber || 1,
        product_image_link: tripData?.result.trip.banner,
        rating_image: starRatingImage,
        reviews_count: reviewData?.result?.count || 0,
        reviews_rating: parseFloat(reviewData?.result.rating || '0'),
        sessionId: sessionIdService.get(),
        num_items: 1,
      };

      RudderStackTrack('Traveller Page Initiated', rudderObj);
    }
  };

  const couponEntered = (promoCode: string) => {
    if (tripData) {
      const rudderObj: TCouponEntered = {
        promo_code: promoCode,
        product_id: [tripData?.result.trip.id],
        division: tripData?.result.division.division,
        destination_city: tripData?.result.destination.city,
        country: country.name,
        destination: tripData?.result.destination.destination,
        currency: currency,
        full_price: activePromotions?.invoice.total_price || 0,
        deposit_price: activePromotions?.invoice.deposit || 0,
        sessionId: sessionIdService.get(),
      };

      RudderStackTrack('Coupon Entered', rudderObj);
    }
  };

  const couponDenied = (
    promoCode: string,
    promoId: number,
    promoName: string | null,
    reason: string
  ) => {
    if (tripData) {
      const rudderObj: TCouponDenied = {
        promo_code: promoCode,
        promo_id: promoId,
        promo_item_name: promoName || '',
        reason,
        product_id: [tripData?.result.trip.id],
        division: tripData?.result.division.division,
        destination_city: tripData?.result.destination.city,
        country: country.name,
        destination: tripData?.result.destination.destination,
        currency: currency,
        full_price: activePromotions?.invoice.total_price || 0,
        deposit_price: activePromotions?.invoice.deposit || 0,
        sessionId: sessionIdService.get(),
      };
      RudderStackTrack('Coupon Denied', rudderObj);
    }
  };

  const couponApplied = (
    promoCode: string,
    promoId: number,
    promoName: string | null
  ) => {
    if (tripData) {
      const rudderObj: TCouponApplied = {
        promo_code: promoCode,
        promo_id: promoId.toString(),
        promo_item_name: promoName || '',
        product_id: [tripData.result.trip.id],
        division: [tripData.result.division.division],
        destination_city: tripData.result.destination.city,
        country: country.name,
        destination: tripData.result.destination.destination,
        currency: currency,
        full_price: activePromotions?.invoice.total_price || 0,
        deposit_price: activePromotions?.invoice.deposit || 0,
        sessionId: sessionIdService.get(),
      };
      RudderStackTrack('Coupon Applied', rudderObj);
    }
  };

  const couponRemoved = (promoCode: string) => {
    if (tripData) {
      const rudderObj: TCouponRemoved = {
        promo_code: promoCode,
        product_id: tripData.result.trip.id,
        division: tripData.result.division.division,
        destination_city: tripData.result.destination.city,
        country: country.name,
        destination: tripData.result.destination.destination,
        currency: currency,
        full_price: activePromotions?.invoice.total_price || 0,
        deposit_price: activePromotions?.invoice.deposit || 0,
        sessionId: sessionIdService.get(),
      };
      RudderStackTrack('Coupon Removed', rudderObj);
    }
  };

  const trackableLinkClicked = (eventName: string) => {
    const payload: TTrackableLinkClicked = {
      country: country.name,
    };

    RudderStackTrack(eventName, payload);
  };

  return {
    isLoading:
      isTripDataLoading ||
      isProductDataLoading ||
      isReviewDataLoading ||
      isPromotionDataLoading,
    gotTogo,
    initiatePayment,
    orderComplete,
    customerRegistration,
    checkoutStarted,
    travellerPageInitiated,
    couponEntered,
    couponDenied,
    couponApplied,
    couponRemoved,
    trackableLinkClicked,
  };
};

export { useRudderStack };
