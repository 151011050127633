import { Month } from 'lib/types/availability';
import dayjs from 'dayjs';

export type Props = {
  month: Month;
  isActive: boolean;
  click: (month: Month) => void;
};

function MonthCard({ month, isActive, click }: Props) {
  const clickHandler = (e: any) => {
    e.stopPropagation();
    click(month);
  };

  const availableDatesCount = month.dates.filter(
    (d) =>
      ['Available', 'Limited Availability'].includes(d.status) &&
      !d.tags.includes('sold out')
  ).length;

  return (
    <div
      className={
        'w-[5.5rem] h-[5.313rem] min-w-[5.5rem] md:h-[6.25rem] py-[0.625rem] md:py-[0.938rem]  border rounded-md flex flex-col justify-center items-center select-none cursor-pointer  ' +
        (isActive
          ? ' border-teal-900 bg-teal-900  shadow-[0_5px_10px_rgba(0,131,160,0.15)]'
          : ' border-light-800 bg-light-600')
      }
      onClick={clickHandler}
    >
      <div
        className={
          'flex flex-col justify-center w-full items-center gap-y-[0.313rem]  md:gap-y-[0.3625rem] pb-[0.625rem] border-b  ' +
          (isActive ? ' border-[#ffffff33]' : ' border-light-800')
        }
      >
        <div
          className={
            ' font-semibold !leading-[0.625rem] text-xs  ' +
            (isActive ? ' text-light-600 opacity-50' : ' text-dark-600')
          }
        >
          {dayjs(month.startOfMonth * 1000).year()}
        </div>
        <div
          className={
            ' text-lg md:text-xl !leading-5  font-semibold ' +
            (isActive ? ' text-light-600' : ' ')
          }
        >
          {dayjs(month.startOfMonth * 1000).format('MMM')}
        </div>
      </div>
      <div
        className={
          ' text-sm font-semibold !leading-[0.625rem] pt-[0.625rem] w-full ' +
          (isActive ? ' text-light-600' : ' text-dark-600')
        }
      >
        {availableDatesCount
          ? ` ${availableDatesCount} date${availableDatesCount > 0 ? 's' : ''}`
          : 'sold out'}
      </div>
    </div>
  );
}

export { MonthCard };
