import { TValidation } from 'lib/types/traveller-form';
import { Input } from '../../../../Input/Input';

type Props = {
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  firtNameValidation: TValidation;
  lastNameValidation: TValidation;
};

const ReferralNameSection = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  firtNameValidation,
  lastNameValidation,
}: Props) => {
  return (
    <div>
      <div className="grid md:grid-cols-2 gap-4 md:gap-5  mb-5">
        <Input
          tabIndex={0}
          placeholder="First name"
          value={firstName}
          changeInput={setFirstName}
          isError={!firtNameValidation.isValid}
          ErrorMessage={firtNameValidation.message}
        />
        <Input
          tabIndex={0}
          placeholder="Last name"
          value={lastName}
          changeInput={setLastName}
          isError={!lastNameValidation.isValid}
          ErrorMessage={lastNameValidation.message}
        />
      </div>
    </div>
  );
};
export { ReferralNameSection };
