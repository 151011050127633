import { useEffect } from 'react';
import { useFallbackLocationQuery } from 'lib/react-query/Queries/useFallbackLocationQuery';
import { TLocation } from 'lib/types/location';
import { defaultLocation } from 'data/location';
import { useAppDispatch } from 'state/hooks';
import { setLocation } from 'state/slices/location';

const useSetLocation = () => {
  const { isLoading, data } = useFallbackLocationQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading) return;

    if (!data || !data.country) {
      dispatch(setLocation(defaultLocation));
      return;
    }

    const formattedData: TLocation = {
      continent: data.continent.name,
      region: data.region.name,
      country: {
        code: data.country.code,
        name: data.country.name,
      },
    };

    dispatch(setLocation(formattedData));
  }, [data, dispatch, isLoading]);
};

export { useSetLocation };
