import { Price, Room, RoomFormat, SelectedRoom } from 'lib/types/trip';
import { AccomodationCard } from './card';
import { AccomodationHead } from './head';
import { TDropDownItem } from 'lib/types/dropdown';
import { useCurrency } from 'lib/hooks/useCurrency';
import { Dispatch, SetStateAction } from 'react';
import { FallbackCard } from './skeleton-card';

type Props = {
  rooms: Room[] | null | undefined;
  travellerDropdown: TDropDownItem | null;
  setSelectedRooms: Dispatch<SetStateAction<SelectedRoom[]>>;
  selectedRooms: SelectedRoom[];
  defaultRoom: RoomFormat[] | null;
};

const Accomodation = ({
  rooms,
  travellerDropdown,
  setSelectedRooms,
  selectedRooms,
  defaultRoom,
}: Props) => {
  const { currency } = useCurrency();

  const filterRoom = (
    roomCapacity: number,
    roomCanShare: number,
    roomIsIncluded: number,
    roomPrice: Price
  ) => {
    if (!travellerDropdown) return false;
    const roomStatus =
      roomCapacity <= parseInt(travellerDropdown?.value) ||
      (roomCanShare && roomCapacity > parseInt(travellerDropdown?.value));
    const priceStatus =
      roomIsIncluded ||
      (!roomIsIncluded &&
        roomPrice !== null &&
        JSON.stringify(roomPrice) !== '{}');
    return roomStatus && priceStatus;
  };

  const checkSelectedRoom = (roomId: number) => {
    const result = defaultRoom?.map((room) => room.id).includes(roomId)
      ? defaultRoom.filter((item) => item.id === roomId)[0].travellers_number
      : 0;
    return result;
  };

  return (
    <div className="flex flex-col gap-5">
      <h3 className="text-lg font-semibold text-dark-800">
        Select Accommodation
      </h3>
      <AccomodationHead />
      {!rooms ? (
        <>
          <FallbackCard />
          <FallbackCard />
        </>
      ) : (
        rooms
          .sort(function (a, b) {
            return a.prices[currency] - a.prices[currency];
          })
          .map((room, index) => {
            return filterRoom(
              room.capacity,
              room.can_share,
              room.is_included,
              room.prices
            ) ? (
              <AccomodationCard
                key={index}
                room={room}
                travellerDropdown={travellerDropdown}
                setSelectedRooms={setSelectedRooms}
                selectedRooms={selectedRooms}
                defaultSelected={checkSelectedRoom(room.id)}
              />
            ) : null;
          })
      )}
    </div>
  );
};

export { Accomodation };
