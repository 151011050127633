import { useIsMutating } from '@tanstack/react-query';
import { stagePathConfig } from 'data/stage';
import { checkTravellerData } from 'lib/helper/checkTravellerData';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { useProductQuery } from 'lib/react-query/Queries/useProductQuery';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from './useCustomNavigate';

type Props = {
  forceReload: boolean;
};
const useNavigator = ({ forceReload }: Props) => {
  const { pathname } = useLocation();
  const navigate = useCustomNavigate();
  const { isLoading: tripLoading, data: tripData } = useTripQuery();
  const { data: productData, isFetching: productFetching } = useProductQuery();
  const {
    isError: travellerError,
    data: travellerData,
    isFetching: travellerFetching,
  } = useGetTraveller({ isEnabled: true });

  const isMutating = useIsMutating({
    mutationKey: ['create-traveller'],
  });

  useEffect(() => {
    if (tripLoading || productFetching || travellerFetching || isMutating === 1)
      return;

    const hasRoomStage =
      tripData?.result.rooms && tripData.result.rooms.length > 0;

    const hasDepartureDate = Boolean(
      productData?.result.is_departure_flexible ||
        (productData?.result.departure_date &&
          productData?.result.departure_date > 0)
    );
    const hasSelectedRoom = Boolean(
      productData?.result &&
        productData.result.inclusions &&
        productData?.result.inclusions.length &&
        productData?.result.inclusions.length > 0
    );

    const hasTravellerData = travellerData?.result
      ? checkTravellerData(travellerData?.result)
      : false;

    switch (pathname) {
      case '/':
        hasRoomStage &&
          hasSelectedRoom &&
          hasTravellerData &&
          navigate(stagePathConfig.payment, { replace: true });

        hasRoomStage &&
          hasSelectedRoom &&
          !hasTravellerData &&
          navigate(stagePathConfig.traveller, { replace: true });

        hasRoomStage &&
          !hasSelectedRoom &&
          navigate(stagePathConfig.rooms, { replace: true });

        !hasRoomStage &&
          hasTravellerData &&
          hasDepartureDate &&
          navigate(stagePathConfig.payment, { replace: true });

        !hasRoomStage &&
          !hasTravellerData &&
          navigate(stagePathConfig.traveller, { replace: true });

        !hasRoomStage &&
          hasTravellerData &&
          !hasDepartureDate &&
          navigate(stagePathConfig.traveller, { replace: true });

        break;
      case stagePathConfig.rooms:
        !hasRoomStage && navigate('/', { replace: true });
        break;

      case stagePathConfig.traveller:
        hasRoomStage &&
          !hasSelectedRoom &&
          navigate(stagePathConfig.rooms, { replace: true });
        break;

      case stagePathConfig.payment:
        (!hasTravellerData ||
          (hasRoomStage && !hasSelectedRoom) ||
          !hasDepartureDate) &&
          navigate('/', { replace: true });
        break;

      case stagePathConfig.transactionFailed:
        break;
      case stagePathConfig.paymentProcessing:
        break;
      default:
        navigate('/404');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tripLoading,
    tripData,
    pathname,
    productFetching,
    productData,
    travellerFetching,
    travellerError,
    travellerData,
    forceReload,
  ]);
};

export { useNavigator };
