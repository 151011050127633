import { Image } from 'components/Image/Image';
import { useTenant } from 'lib/hooks/useTenant';
import { useTransformation } from 'lib/hooks/useTransformation';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { BannerFallback } from './Banner.fallback';

type Props = {
  isLoading: boolean;
};

const Banner = ({ isLoading }: Props) => {
  const transformation = useTransformation();
  const { data } = useGetTraveller({ isEnabled: true });
  const travellerName = data
    ? `${data.result.first_name} ${data.result.last_name}`
    : 'Traveller';
  const { isDA } = useTenant();

  const paragraph = isDA ? (
    <>
      You’ve just booked your next amazing deal away. Now let’s get
      going!…Here’s what happens next
    </>
  ) : (
    <>
      Congratulations {travellerName}! You're officially on your way overseas
      with <span className="whitespace-nowrap">Global Work & Travel.</span>{' '}
      Below's what we've got to do next.
    </>
  );

  return (
    <div className="w-full md:h-[31.6875rem] md:relative">
      <Image
        path="booking/finish-page/banner.jpg"
        alt="young adults walking on a beach with smile on their faces"
        className="h-[8.25rem] md:h-full object-center select-none"
        transformation={transformation}
      />
      <div className="bg-teal-500 py-5 md:p-10 text-center space-y-5 border-b border-teal-900 border-opacity-10 md:absolute md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-3/4 md:bg-light-600 md:bg-opacity-80 md:backdrop-blur-[1.25rem] md:rounded-md max-w-[37.5rem]">
        {isLoading && <BannerFallback />}
        {!isLoading && (
          <>
            <Image
              path="booking/finish-page/congrats"
              alt="congratulations in handwritten script"
              className="w-60 md:w-[22.5rem] !object-contain mx-auto select-none"
            />
            <div className="space-y-2.5">
              <h1
                className={
                  'font-semibold text-xl md:text-2xl' +
                  (isDA ? ' text-dark-900' : ' text-teal-800')
                }
              >
                We’ve received your booking!
              </h1>
              <p className="font-medium text-sm text-dark-700 mx-5 md:mx-0 leading-[1.125rem] md:leading-5">
                {paragraph}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { Banner };
