const TermsAndConditionsFallback = () => {
  return (
    <div className="p-5 bg-teal-600 md:bg-teal-500 bg-opacity-25 md:bg-opacity-80 rounded-md m-5 mt-0 md:m-10 md:mt-0 space-y-3">
      <div className="bg-light-800 rounded-full animate-pulse h-4 w-28 md:w-52"></div>
      <div className="bg-light-800 rounded-full animate-pulse h-4 w-56 md:w-[28.125rem]"></div>
      <div className="bg-light-800 rounded-full animate-pulse h-4 w-44 md:w-80"></div>
      <div className="bg-light-800 rounded-full animate-pulse h-4 w-36 md:w-72"></div>
    </div>
  );
};

export { TermsAndConditionsFallback };
