import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DropdownController } from './Dropdown.controller';
import { DropdownMenu } from './Dropdown.menu';
import { TDropDownItem } from '../../lib/types/dropdown';

export type Props = {
  options: TDropDownItem[];
  selected: TDropDownItem | null;
  setSelected: Dispatch<SetStateAction<TDropDownItem<{}> | null>>;
  placeholder?: string;
  hasBorder?: boolean;
  hasFlagImage?: boolean;
  hasGlobeIcon?: boolean;
  hasArrow?: boolean;
  hasCustomArrow?: boolean;
  hasSearch?: boolean;
  menuStretchesToController?: boolean;
  controllerClassName?: string;
  menuClassName?: string;
  onChange?: () => void;
  isFilterByValue?: boolean;
  isShowValue?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

const Dropdown = ({
  options,
  selected,
  setSelected,
  placeholder,
  hasBorder = true,
  hasFlagImage = false,
  hasGlobeIcon = false,
  hasArrow,
  hasCustomArrow = false,
  hasSearch = true,
  menuStretchesToController = false,
  controllerClassName = '',
  menuClassName = '',
  isFilterByValue = false,
  isShowValue,
  onChange,
  isLoading = false,
  disabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');
  const handleFilterStringChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterString(e.target.value);
  };
  const dropdownRef = useRef<HTMLInputElement>(null);

  const filteredOptions = options.filter((option) =>
    isFilterByValue
      ? option.value.toLowerCase().includes(filterString.toLowerCase())
      : option.label.toLowerCase().includes(filterString.toLowerCase())
  );

  const handleClickOutside = (e: any) => {
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef} data-testid="dropdown">
      <DropdownController
        selected={selected}
        placeholder={placeholder}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hasBorder={hasBorder}
        hasFlagImage={hasFlagImage}
        hasGlobeIcon={hasGlobeIcon}
        hasArrow={hasArrow}
        hasCustomArrow={hasCustomArrow}
        className={controllerClassName}
        isLoading={isLoading}
        disabled={disabled}
      />
      {!isLoading && isOpen && (
        <DropdownMenu
          filterString={filterString}
          handleFilterStringChange={handleFilterStringChange}
          options={filteredOptions}
          selected={selected}
          setSelected={setSelected}
          hasFlagImage={hasFlagImage}
          setIsOpen={setIsOpen}
          hasSearch={hasSearch}
          stretchesToController={menuStretchesToController}
          className={menuClassName}
          onChange={onChange}
          isShowValue={isShowValue}
        />
      )}
    </div>
  );
};
export { Dropdown };
