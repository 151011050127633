import {
  getEmailValidation,
  getJoiEmailValidation,
  getNumberValidation,
  getStringValidation,
} from './validation';
import { TTravellerFormValidationAction } from '../../types/traveller';
import { TValidation } from '../../types/traveller-form';
import dayjs from 'dayjs';

export class ValidationHelper {
  constructor(
    private dispatch?: React.Dispatch<TTravellerFormValidationAction>
  ) {}
  setFirstNameValidation(value: TValidation) {
    if (this.dispatch) {
      this.dispatch({ type: 'firtNameValidation', value });
    }
  }

  setLastNameValidation(value: TValidation) {
    if (this.dispatch) {
      this.dispatch({ type: 'lastNamevalidation', value });
    }
  }

  setPhoneNumberValidation(value: TValidation) {
    if (this.dispatch) {
      this.dispatch({ type: 'phoneNumberValidation', value });
    }
  }

  setEmailValidation(value: TValidation) {
    if (this.dispatch) {
      this.dispatch({ type: 'emailValidation', value });
    }
  }

  setDateValidation(value: TValidation) {
    if (this.dispatch) {
      this.dispatch({ type: 'dateValidation', value });
    }
  }

  checkFirstName(firstName: string) {
    if (firstName.length === 0) {
      this.setFirstNameValidation({
        isValid: false,
        message: 'Please enter your first name.',
      });
      return false;
    }

    if (firstName.length > 0 && !getStringValidation(firstName)) {
      this.setFirstNameValidation({
        isValid: false,
        message: 'Please enter your valid first name.',
      });
      return false;
    } else if (firstName.length > 0 && getStringValidation(firstName)) {
      this.setFirstNameValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  }

  checkLastName(lastName: string) {
    if (lastName.length === 0) {
      this.setLastNameValidation({
        isValid: false,
        message: 'Please enter your last name.',
      });
      return false;
    }

    if (lastName.length > 0 && !getStringValidation(lastName)) {
      this.setLastNameValidation({
        isValid: false,
        message: 'Please enter your valid last name.',
      });
      return false;
    } else if (lastName.length > 0 && typeof lastName === 'string') {
      this.setLastNameValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  }

  checkPhoneNumber(phoneNumber: string, phonePrefix?: string) {
    if (!phonePrefix || phonePrefix.length === 0) {
      this.setPhoneNumberValidation({
        isValid: false,
        message: 'Please select your phone code.',
      });
      return false;
    }
    if (phonePrefix && phonePrefix.length > 0 && phoneNumber.length === 0) {
      this.setPhoneNumberValidation({
        isValid: false,
        message: 'Please enter your phone number.',
      });
      return false;
    }

    if (
      phonePrefix &&
      phonePrefix.length > 0 &&
      phoneNumber.length > 0 &&
      !getNumberValidation(phoneNumber)
    ) {
      this.setPhoneNumberValidation({
        isValid: false,
        message: 'Please enter your valid phone number.',
      });
      return false;
    } else if (
      phonePrefix &&
      phonePrefix.length > 0 &&
      phoneNumber.length > 0 &&
      getNumberValidation(phoneNumber)
    ) {
      this.setPhoneNumberValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  }

  checkEmail(email: string) {
    if (email.length === 0) {
      this.setEmailValidation({
        isValid: false,
        message: 'Please enter your email.',
      });
      return false;
    }

    if (email.length > 0 && !getEmailValidation(email)) {
      this.setEmailValidation({
        isValid: false,
        message: 'Please enter your valid email.',
      });
      return false;
    } else if (email.length > 0 && !getJoiEmailValidation(email)) {
      this.setEmailValidation({
        isValid: false,
        message: 'Please enter your valid email.',
      });
      return false;
    } else if (email.length > 0 && getEmailValidation(email)) {
      this.setEmailValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  }

  checkDateOfBirth = (date: Date | null) => {
    if (date === null) {
      this.setDateValidation({
        isValid: false,
        message: 'Please enter your date of birth.',
      });
      return false;
    }

    const newDate = dayjs(new Date());
    const diff = newDate.diff(dayjs(date), 'year');
    if (diff < 15) {
      this.setDateValidation({
        isValid: false,
        message: 'Age must be greater than 15 years old.',
      });
      return false;
    } else if (!dayjs(date).isValid()) {
      this.setDateValidation({
        isValid: false,
        message: 'Age must be entered properly.',
      });
      return false;
    } else {
      this.setDateValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  };

  checkFieldsValidation(
    firstName: string,
    lastName: string,
    phonePrefix: string | undefined,
    phoneNumber: string,
    date: Date | null,
    email: string
  ) {
    const firtNameResult = this.checkFirstName(firstName);
    const lastNameResult = this.checkLastName(lastName);
    const phoneNumberResult = this.checkPhoneNumber(phoneNumber, phonePrefix);
    const emailResult = this.checkEmail(email);
    const dateResult = this.checkDateOfBirth(date);
    return (
      firtNameResult &&
      lastNameResult &&
      phoneNumberResult &&
      emailResult &&
      dateResult
    );
  }
}
