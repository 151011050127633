import { Button } from '../../Button/Button';
import { scrollToElementById } from 'lib/helper/scroll/scroll';
import { Image } from 'components/Image/Image';

type Props = {
  isVisible?: boolean;
};
const FAQ = ({ isVisible = true }: Props) => {
  // TODO: this scrolls down to the FAQ section of the page
  const clickHandler = () => {
    scrollToElementById('faq');
  };
  return isVisible ? (
    <>
      <div className="hidden md:block">
        <Button
          text="FAQ"
          clickHandler={clickHandler}
          type="secondary-border"
          className="!border-dark-500"
        />
      </div>
      <div className="md:hidden">
        <div className="w-8 h-8" onClick={clickHandler}>
          <Image path="booking/icons/question.svg" alt="question icon" />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export { FAQ };
