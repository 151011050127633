import { useOffice } from 'lib/hooks/useOffice/useOffice';
import { usePhoneNumber } from 'lib/hooks/usePhoneNumber/usePhoneNumber';
import { Image } from 'components/Image/Image';

const Contact = () => {
  const { phoneNumber } = usePhoneNumber();
  const office = useOffice();

  return (
    <a
      href={`tel:${phoneNumber}`}
      className="flex items-center gap-2.5 md:gap-8"
      data-testid="header__contact-number"
    >
      <div className="flex flex-row md:flex-col gap-2.5 md:gap-1 items-center md:items-start">
        <div className="text-dark-700 text-sm font-semibold text-right">
          Do you need help?
        </div>
        <div className="hidden md:block text-xs font-medium text-dark-500">
          ({office.open.hours})
        </div>
        <div className="md:hidden min-w-[2rem] min-h-[2rem] w-8 h-8">
          <Image path="booking/icons/headphone.svg" alt="headphone icon" />
        </div>
      </div>
      <div className="hidden md:flex items-center gap-1">
        <div className="w-8 h-8">
          <Image path="booking/icons/phone.svg" alt="phone icon" />
        </div>
        <div className="text-lg font-semibold text-teal-800">{phoneNumber}</div>
      </div>
    </a>
  );
};

export { Contact };
