import { useEffect } from 'react';
import { useSkipPaymentQuery } from 'lib/react-query/Queries/useSkipPaymentQuery';
import { getSkipPaymentOption } from 'lib/helper/payment-options/payment-options';
import loglevel from 'loglevel';
import { PaymentOption } from 'lib/types/payment';
import { LocalStorageService } from 'services/localstorage';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import * as Sentry from '@sentry/react';
import { stagePathConfig } from 'data/stage';
import { useAppDispatch } from 'state/hooks';
import { setError } from 'state/slices/modal';

const useSkipPayment = (selectedOption: PaymentOption) => {
  const navigate = useCustomNavigate();
  const localStorageSkip = new LocalStorageService('skip-payment');
  const dispatch = useAppDispatch();

  const {
    isLoading: skipPaymentIsLoading,
    data: skipPaymentData,
    mutate: skipPayment,
    isError: skipIsError,
    error: skipError,
  } = useSkipPaymentQuery({
    paymentOption: getSkipPaymentOption(selectedOption),
  });

  const skipPaymentHandler = () => {
    skipPayment();
  };

  const raiseSentryError = (message: string) => {
    Sentry.captureException(message, {
      tags: {
        query: 'skip payment',
      },
    });
  };

  useEffect(() => {
    if (skipPaymentIsLoading || !skipPaymentData) return;
    if (skipIsError) {
      loglevel.error('Skip payment failed with http error.', skipError);
      dispatch(
        setError({
          isOpen: true,
          message: 'Skip payment failed with http error.',
          buttonText: 'Return to payment',
        })
      );
      raiseSentryError('Skip payment failed with http error.');
    }

    if (skipPaymentData?.result.skipped) {
      localStorageSkip.set('skip-success');
      navigate(stagePathConfig.finished);
    } else {
      loglevel.error(
        'Skip payment returned status: ' + skipPaymentData?.result
      );
      dispatch(
        setError({
          isOpen: true,
          message: 'Skip payment returned status: ' + skipPaymentData?.result,
          buttonText: 'Return to payment',
        })
      );
      raiseSentryError(
        'Skip payment returned status: ' + skipPaymentData?.result
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipPaymentIsLoading, skipPaymentData, skipIsError]);

  return { skipPaymentHandler, skipPaymentIsLoading };
};

export { useSkipPayment };
