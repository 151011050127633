type Props = {
  data: string;
};
const Label = ({ data }: Props) => {
  return (
    <div className="font-semibold text-sm leading-5 text-dark-600">{data}</div>
  );
};

export { Label };
