import { useTenantTheme } from 'lib/hooks/useTenantTheme';
import { MouseEvent } from 'react';
import { CheckMark } from '../../../assets/icons/checkmark';

export type Props = {
  id: string;
  isChecked: boolean;
  isEnabled?: boolean;
  toggleChecked: () => void;
};

function Checkbox({
  id,
  isChecked = false,
  toggleChecked,
  isEnabled = true,
}: Props) {
  const theme = useTenantTheme();

  const handleChange = (e: MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    isEnabled && toggleChecked();
  };

  return (
    <label
      data-testid="checkbox"
      className={
        'h-6 w-6 min-h-[1.5rem] min-w-[1.5rem] flex justify-center items-center rounded-md transition duration-200' +
        (isEnabled ? ' cursor-pointer' : ' bg-light-800') +
        (isChecked
          ? ` bg-teal-900 border-teal-900 ${theme.shadow[800]}`
          : ' bg-light-600 border border-light-900')
      }
      onClick={handleChange}
    >
      <input
        data-testid="checkbox__input"
        className="w-0 h-0 appearance-none outline-none"
        id={id}
        type="checkbox"
        checked={isChecked}
        readOnly
      />
      <div className="w-3.5 h-3.5 z-10">
        <CheckMark color={isEnabled ? '#fff' : '#E8E8E8'} />
      </div>
    </label>
  );
}

export { Checkbox };
