import dayjs from 'dayjs';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { usePaymentInfo } from 'lib/react-query/Queries/usePaymentInfo';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useEffect } from 'react';
import { QueryParam } from 'services/query-param/query-param';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPaymentOption } from 'state/slices/product';
import { Row } from './Row';
import { Separator } from './Separator';

type Props = {
  isSkipPayment: boolean;
  stripeIsLoading: boolean;
  charityPaymentWasSuccessful: boolean;
  paidAmount: number;
  isCharity: boolean;
};

const BookingSummary = ({
  isSkipPayment,
  stripeIsLoading,
  charityPaymentWasSuccessful,
  paidAmount,
  isCharity,
}: Props) => {
  const { data } = useTripQuery();
  const dispatch = useAppDispatch();
  const { data: promotions } = usePromotionsQuery();
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();
  // TODO: use QueryParam.getFirst once it is merged
  const paymentIntentParam = QueryParam.get('payment_intent');
  const paymentIntent =
    typeof paymentIntentParam === 'string'
      ? paymentIntentParam
      : paymentIntentParam
      ? paymentIntentParam[0]
      : null;
  const originalPaymentIntentParam = QueryParam.get('opi');
  const originalPaymentIntent =
    typeof originalPaymentIntentParam === 'string'
      ? originalPaymentIntentParam
      : originalPaymentIntentParam
      ? originalPaymentIntentParam[0]
      : null;
  const { isLoading: paymentInfoLoading, data: paymentInfoData } =
    usePaymentInfo(originalPaymentIntent ?? paymentIntent);

  const availability = useAppSelector((state) => state.product.availability);
  const flexibleDeparture = useAppSelector(
    (state) => state.product.flexibleDeparture
  );
  const startDate = AvailabilityHelper.getDepartureDate(availability);
  const startDateString = flexibleDeparture
    ? 'Flexible'
    : dayjs(startDate && startDate * 1000).format('MMM YYYY');

  const activePaymentOption = useAppSelector(
    (state) => state.product.paymentOption
  );
  const activePromotions =
    promotions &&
    PromotionHelper.getSelectedPromotionObject(
      promotions.result.promotions,
      activePaymentOption
    );
  const invoice = activePromotions?.invoice;

  const standard = promotions?.result.promotions.standard?.invoice;
  const payInFull = promotions?.result.promotions.trip_in_full?.invoice;
  const hasPaidStandardDeposit = standard?.deposit === 0;
  const hasPaidFull = payInFull?.outstanding === 0;

  useEffect(() => {
    if (hasPaidStandardDeposit && !hasPaidFull) {
      dispatch(setPaymentOption('paid-deposit'));
    }
    if (hasPaidFull) {
      dispatch(setPaymentOption('paid-full'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPaidStandardDeposit, hasPaidFull]);

  const trip = {
    name: data && data.result.trip.trip_name,
    duration: data && data.result.duration.text,
    startDate: startDateString,
    totalPrice:
      isSkipPayment || (isCharity && !originalPaymentIntent)
        ? invoice && String(numberFormatter.format(invoice?.outstanding))
        : paymentInfoData?.result.outstanding
        ? String(numberFormatter.format(paymentInfoData.result.outstanding))
        : undefined,
    currentPayment: String(numberFormatter.format(paidAmount)),
  };
  const invoicePaidAmount =
    invoice && String(numberFormatter.format(invoice.paid));

  const totalPriceHasLoaded =
    !paymentInfoLoading ||
    isSkipPayment ||
    (isCharity && !originalPaymentIntent);
  const hasLoaded = Boolean(
    !stripeIsLoading &&
      totalPriceHasLoaded &&
      trip.name &&
      trip.duration &&
      trip.startDate &&
      trip.totalPrice &&
      trip.currentPayment
  );

  return (
    <div className="bg-light-600 border border-light-800 rounded-md flex flex-col gap-5 p-5">
      {hasLoaded ? (
        <div className="font-semibold text-lg leading-5 text-center text-dark-800">
          {trip.name}
        </div>
      ) : (
        <div className="animate-pulse rounded-full bg-light-800 w-48 h-7 mb-3 self-center"></div>
      )}
      <div className="flex flex-col gap-2.5">
        <Row label="Duration" value={trip.duration} isLoading={!hasLoaded} />
        <Row label="Start date" value={trip.startDate} isLoading={!hasLoaded} />
      </div>
      <Separator />
      <div className="flex flex-col gap-2.5">
        <Row
          label="Total price"
          value={trip.totalPrice}
          additionalValue={currencyShouldBeShown ? `(${currency})` : undefined}
          isLoading={!hasLoaded}
        />
        <Row
          label={"You've paid"}
          value={isCharity ? invoicePaidAmount : trip.currentPayment}
          additionalValue={currencyShouldBeShown ? `(${currency})` : undefined}
          isLoading={!hasLoaded}
        />
        {isCharity && charityPaymentWasSuccessful ? (
          <Row
            label={'Your donation'}
            value={trip.currentPayment}
            additionalValue={
              currencyShouldBeShown ? `(${currency})` : undefined
            }
            isLoading={!hasLoaded}
          />
        ) : null}
      </div>
    </div>
  );
};

export { BookingSummary };
