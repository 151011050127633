import dayjs from 'dayjs';
import { SelectionAvailability } from 'lib/types/availability';
import { InclusionFormat, SelectedRoom } from 'lib/types/trip';
import { AvailabilityHelper } from '../availability/availability';

type TTraveller = {
  selectedAvailability: SelectionAvailability;
  selectedDuration: number | undefined;
  phonePrefix: string | undefined;
  phoneNumber: string;
  email: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  travellerNumber: number;
  is_departure_flexible: boolean;
  selectedRooms: SelectedRoom[];
};

type TTravellerPayload = {
  traveller: {
    contact: {
      phone_code: string | undefined;
      phone_number: string;
    };
    personal: {
      email: string;
      date_of_birth: string;
      first_name: string;
      last_name: string;
    };
  };
  product: {
    duration_id: number | undefined;
    availability_id?: number;
    departure_date: number | null;
    is_departure_flexible: boolean;
    travellers_number: number;
    inclusions?: InclusionFormat[];
  };
};

export const getTravellerPayload = ({
  selectedAvailability,
  selectedDuration,
  phonePrefix,
  phoneNumber,
  email,
  firstName,
  lastName,
  dateOfBirth,
  travellerNumber,
  is_departure_flexible,
  selectedRooms,
}: TTraveller): TTravellerPayload => {
  const inclusions: InclusionFormat[] = selectedRooms.map((item) => ({
    type: 'room',
    data: { id: item.id, travellers_number: item?.count },
  }));

  let result: TTravellerPayload = {
    traveller: {
      contact: {
        phone_code: phonePrefix,
        phone_number: phoneNumber,
      },
      personal: {
        email: email,
        date_of_birth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
        first_name: firstName,
        last_name: lastName,
      },
    },
    product: {
      duration_id: selectedDuration,
      departure_date: AvailabilityHelper.getDepartureDate(selectedAvailability),
      is_departure_flexible,
      travellers_number: travellerNumber,
      inclusions,
    },
  };

  if (result.product.is_departure_flexible) {
    result.product.departure_date = null;
  }

  if (selectedAvailability && 'availability_id' in selectedAvailability) {
    result.product.availability_id = selectedAvailability.availability_id;
  }

  if (result.product.inclusions && !result.product.inclusions.length) {
    delete result.product.inclusions;
  }

  return result;
};
