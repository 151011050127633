import dayjs from 'dayjs';
import { envs, getEnv } from 'lib/helper/env/env';
import { useFakeTravellerQuery } from 'lib/react-query/Queries/useFakeTravellerQuery';
import { usePhonePrefixQuery } from 'lib/react-query/Queries/usePhonePrefixQuery';
import { TDropDownItem } from 'lib/types/dropdown';
import { TTravellerFormUserAction } from 'lib/types/traveller';
import { Dispatch, useEffect, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from '@tanstack/react-query';
dayjs.extend(utc);

type Props = {
  dispatch: Dispatch<TTravellerFormUserAction>;
};

const useFakeTraveller = ({ dispatch }: Props) => {
  const env = getEnv();
  const { isLoading: prefixIsLoading, data: prefixData } =
    usePhonePrefixQuery();
  const isEnabled = [envs.LOCAL, envs.DEVELOPMENT].includes(env);
  const { isLoading, data } = useFakeTravellerQuery(isEnabled);
  const [prefix, setPrefix] = useState<TDropDownItem | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLoading || !data || prefixIsLoading || !prefixData) return;

    const phoneCode = prefixData.filter(
      (d) => d.label === data.result.phone.code
    )[0];

    setPrefix(phoneCode || null);
  }, [isLoading, data, prefixData, prefixIsLoading]);

  const fillWithFakeTraveller = () => {
    if (isLoading || !data) return;
    dispatch({ type: 'firstName', value: data.result.first_name });
    dispatch({ type: 'lastName', value: data.result.last_name });
    dispatch({ type: 'phonePrefix', value: prefix });
    dispatch({ type: 'phoneNumber', value: data.result.phone.number });
    dispatch({ type: 'email', value: data.result.email });

    const date = dayjs(data.result.date_of_birth * 1000).toDate();
    dispatch({ type: 'date', value: date });
    queryClient.invalidateQueries({ queryKey: ['fake-traveller'] });
  };

  return { isEnabled, isLoading, data, fillWithFakeTraveller };
};

export { useFakeTraveller };
