import { CheckMark } from 'assets/icons/checkmark';
import Close from 'assets/icons/Close';
import { TStatus } from './types';

type Props = {
  order: number;
  status: TStatus;
  isLoading: boolean;
};

const getBackgroundColor = (status: TStatus) => {
  if (status === 'complete') return ' bg-green-900';
  if (status === 'todo') return ' bg-yellow-900';
  if (status === 'failed') return ' bg-red-900';
};

const Anchor = ({ order, status, isLoading }: Props) => {
  const isCompleted = status === 'complete';
  const isTodo = status === 'todo';
  const isFailed = status === 'failed';
  const backgroundColor = getBackgroundColor(status);
  return (
    <div
      className={
        'w-8 h-8 md:w-10 md:h-10 rounded-full flex justify-center items-center' +
        backgroundColor +
        (isLoading ? ' !bg-light-800 animate-pulse' : '')
      }
    >
      {isLoading && <div></div>}
      {!isLoading && isCompleted && (
        <div className="w-5 h-5 md:w-6 md:h-6">
          <CheckMark color="#fff" />
        </div>
      )}
      {!isLoading && isFailed && (
        <div className="w-5 h-5 md:w-6 md:h-6">
          <Close color="#fff" />
        </div>
      )}
      {!isLoading && isTodo && (
        <div className="text-dark-800 font-semibold md:text-xl">{order}</div>
      )}
    </div>
  );
};

export { Anchor };
