import { useCallback, useEffect, useRef } from 'react';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith/isomorphic';
import debounce from 'lodash/debounce';
import { useRudderstackID } from '../rudderStack/useRudderstackID';
import { ABTestSetter } from './setter';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setFlagsAreLoading } from 'state/slices/flags';

type Props = {
  children: React.ReactNode;
};

const ENVIRONMENTID =
  process.env.REACT_APP_FLAGSMIT ?? 'F6ZXHh76imxwSqXzML8ZPK';

const ABTestProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { isIdentified, isIdentifiedRef, setIsIdentified } = useUserStatus();
  const anonymousId = useRudderstackID();

  const setHasLoaded = () => {
    dispatch(setFlagsAreLoading(false));
  };
  const debouncedSetHasLoaded = debounce(setHasLoaded, 2000);

  // identify user once and only once
  useEffect(() => {
    if (!isIdentified && anonymousId && setIsIdentified) {
      flagsmith.identify(anonymousId);
      setIsIdentified(true);
    }
  }, [anonymousId, isIdentified, setIsIdentified]);

  return (
    <FlagsmithProvider
      options={{
        environmentID: ENVIRONMENTID,
        api: 'https://flags.globalworkandtravel.com/api/v1/',
        cacheFlags: false,
        onChange: () => {
          if (isIdentifiedRef.current) {
            debouncedSetHasLoaded();
          }
        },
      }}
      flagsmith={flagsmith}
    >
      <ABTestSetter>{children}</ABTestSetter>
    </FlagsmithProvider>
  );
};

// NOTE: dear future reader of this hook below, please don't be
// a smart-ass moving this into its own file. We specifically
// don't want this to be exported so it won't be used anywhere
// except for here, thanks.

const useUserStatus = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(false);
  const userIdentifiedStatusForAnalytics = useAppSelector(
    (state) => state.flag.userIdentifiedStatusForAnalytics
  );
  const set = useCallback(
    (value: boolean): void => {
      dispatch(setFlagsAreLoading(value));
      ref.current = value;
    },
    [dispatch]
  );

  return {
    isIdentified: userIdentifiedStatusForAnalytics,
    isIdentifiedRef: ref,
    setIsIdentified: set,
  };
};

export { ABTestProvider };
