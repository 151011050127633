import {
  ReferralFormValidation,
  TReferralFormValidationAction,
} from 'lib/types/referral';

export const referralValidationReducer = (
  state: ReferralFormValidation,
  action: TReferralFormValidationAction
) => {
  switch (action?.type) {
    case 'firtNameValidation':
      return { ...state, firtNameValidation: action.value };
    case 'lastNamevalidation':
      return { ...state, lastNamevalidation: action.value };
    case 'phonePrefixValidation':
      return { ...state, phonePrefixValidation: action.value };
    case 'phoneNumberValidation':
      return { ...state, phoneNumberValidation: action.value };
    case 'emailValidation':
      return { ...state, emailValidation: action.value };
    case 'locationValidation':
      return { ...state, locationValidation: action.value };
    default:
      return state;
  }
};
