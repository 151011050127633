import { AppointmentsGateway } from 'services/http/appointments-gateway';
import { useOffice } from '../../../hooks/useOffice/useOffice';
import { useQuery } from '@tanstack/react-query';

const useGetEnabledMonthDays = (year: number, month: number, tz: string) => {
  const office = useOffice();

  const { data, isLoading, error } = useQuery(
    ['get-enabled-month-days', year, month, tz, office.gCalendar.eventId],
    async () => {
      return await AppointmentsGateway.get<string[]>(
        `/public/booking/enabled-month-days?event_id=${office.gCalendar.eventId}&timezone=${tz}&year=${year}&month=${month}`
      );
    },
    {
      enabled: !!office.gCalendar.eventId,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};

const useGetNextMonthsEnabledDays = (options: {
  year: number;
  month: number;
  monthsCount: number;
  tz: string;
}) => {
  const { year, month, monthsCount, tz } = options;

  const office = useOffice();

  const { data, isLoading, error } = useQuery(
    [
      'get-enabled-monthes-days',
      year,
      month,
      monthsCount,
      tz,
      office.gCalendar.eventId,
    ],
    async () => {
      const months = [];

      for (let i = -1; i < monthsCount - 1; i++) {
        const currentMonth = month + i;
        const currentYear = year + Math.floor(currentMonth / 12);
        const adjustedMonth = (currentMonth % 12) + 1; // Adjust for 1-based months

        months.push({
          year: currentYear,
          month: adjustedMonth,
        });
      }

      const results = await Promise.all(
        months.map(({ year, month }) =>
          AppointmentsGateway.get<string[]>(
            `/public/booking/enabled-month-days?event_id=${office.gCalendar.eventId}&timezone=${tz}&year=${year}&month=${month}`
          )
        )
      );

      // Combine the results from all three months and make unique
      const combinedResults = results
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index);

      return {
        results: combinedResults,
        lastAvailableDateInfo: {
          year: months[monthsCount - 1].year,
          month: months[monthsCount - 1].month,
        },
      };
    },
    {
      enabled: !!office.gCalendar.eventId,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetEnabledMonthDays, useGetNextMonthsEnabledDays };
