type TKeyValues = {
  [key: string]: any;
};

type Props = {
  scope: string;
  message: string;
  keyValues?: TKeyValues;
};

// TODO: just remove the whole thing entirely?
export const generateErrorString = ({ scope, message, keyValues }: Props) => {
  const mainLine = `${scope}: ${message}`;

  if (!keyValues) return mainLine;

  const keyValueLines = Object.keys(keyValues).map(
    (key) => `${key}: ${keyValues[key]}`
  );
  const keyValueParagraph = keyValueLines.join('\n');

  return [mainLine, keyValueParagraph].join('\n---\n');
};
