import { Star } from 'assets/icons/Star';

type Props = {
  rating: number;
};

const Stars = ({ rating }: Props) => {
  const rounded = Math.round(rating);

  return (
    <div className="flex h-full items-center gap-0.5">
      {Array.from(Array(5)).map((_, index) => (
        <div className="h-3 w-3" key={index}>
          <Star color={index < rounded ? '#FFC649' : '#575B63'} />
        </div>
      ))}
    </div>
  );
};

export { Stars };
