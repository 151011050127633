import { useQuery } from '@tanstack/react-query';
import { TDropDownItem } from 'lib/types/dropdown';
import { TPhoneCodes, TPhoneCodesQueryResult } from 'lib/types/phone-codes';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

const formatToDropdownData = (phoneCodes: TPhoneCodes[]): TDropDownItem[] => {
  return phoneCodes.map((phoneCode) => ({
    label: `${phoneCode.dial_code}`,
    value: `${phoneCode.name}: ${phoneCode.dial_code}`,
    image: phoneCode.code.split(',')[0].trim().toLowerCase(),
  }));
};

const usePhonePrefixQuery = () => {
  const URL = '/public/config/phone-codes';
  const sessionId = sessionIdService.get();

  return useQuery<TDropDownItem[]>(
    ['phone-codes', sessionId],
    async () => {
      const response = await BookingGateway.get<TPhoneCodesQueryResult>(URL);
      return formatToDropdownData(response.result);
    },
    {
      staleTime: Infinity,
      enabled: !!sessionId.length,
    }
  );
};

export { usePhonePrefixQuery };
