import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { TTravellerFormUserAction } from 'lib/types/traveller';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useAppDispatch } from 'state/hooks';
import { setCid, setSalesRegisterId } from 'state/slices/user';

type Props = {
  dispatch: Dispatch<TTravellerFormUserAction>;
  setDefaultPhoneCode: Dispatch<SetStateAction<string | null>>;
  setIsEmailDisable: Dispatch<SetStateAction<boolean>>;
};

const useFillTraveller = ({
  dispatch,
  setDefaultPhoneCode,
  setIsEmailDisable,
}: Props) => {
  const appDispatch = useAppDispatch();
  const shouldFetchTravellerRef = useRef(false);
  const {
    isLoading: getLoading,
    isError: getError,
    data: getData,
  } = useGetTraveller({ isEnabled: shouldFetchTravellerRef.current });

  const getTraveller = () => {
    shouldFetchTravellerRef.current = true;
  };

  useEffect(() => {
    if (getLoading || !getData || !getData.success || getError) return;

    appDispatch(setSalesRegisterId(getData.result.sales_register_id));

    getData.result.cid && appDispatch(setCid(getData.result.cid));

    getData.result.first_name &&
      dispatch({ type: 'firstName', value: getData?.result.first_name });
    getData.result.last_name &&
      dispatch({ type: 'lastName', value: getData.result.last_name });
    getData.result.date_of_birth &&
      dispatch({
        type: 'date',
        value: new Date(getData.result.date_of_birth * 1000),
      });
    getData.result.phone_number &&
      dispatch({ type: 'phoneNumber', value: getData.result.phone_number });
    getData.result.email &&
      dispatch({ type: 'email', value: getData.result.email });

    getData.result.phone_code && setDefaultPhoneCode(getData.result.phone_code);
    getData.result.email && setIsEmailDisable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLoading, getData, getError]);

  return {
    isLoading: getLoading,
    fillTraveller: getTraveller,
    travellerData: getData,
    hasTravellerData: !!getData?.result.email,
  };
};

export { useFillTraveller };
