export const daFaqList = [
  {
    id: 1,
    title: `How is DealsAway a better service than my last travel providers?`,
    context: `<p>We pride ourselves on our customer service. Unlike the other online travel agencies, we still provide real human dedicated old fashioned service! Once your trip is locked in, you'll have a designated Trip Coordinator with you every step of the way. They're here to answer all your questions and organise your trip so you can sit back and relax. It's real travel agent service, online.</p>`,
  },
  {
    id: 2,
    title: `Are my flights included?`,
    context: `<p>DealsAway has a dedicated Travel Concierge team, able to find flights which synchronise perfectly with your holiday. If you have preferences about airlines, seats or what class you want to fly, just let us know and we will get it all sorted for you.  </p>`,
  },
  {
    id: 3,
    title: `What about travel insurance?`,
    context: `<p>Travel insurance is strongly recommended for all domestic or international travel. The cost of not having insurance if something happens is much greater than an insurance policy ever is. DealsAway has a broad range of policies that will cover any type of holiday.  We will give you the best options and you can choose from the different levels of cover to find the exact policy that suits your circumstances. Remember, your trip is covered from the minute you buy insurance. So to be sure you are covered for any unforeseen circumstances, we totally recommend booking it at the same time as your trip.</p>`,
  },
  {
    id: 4,
    title: `Who are DealsAway?`,
    context: `<p>Australian owned and operated, we are proudly developed by the team behind Global Work & Travel, one of the world's leading youth travel companies. We combine this pedigree with a team of outstanding, Australian travel-lovers, who will wow you with their knowledge, friendliness and desire to get you the best holiday they possibly can. If you want the full picture, just pay a visit to our About Us 
        <a href="https://www.dealsaway.com/about-us" target="_blank">page</a>.  </p>`,
  },
];

export const faqList = [
  {
    id: 1,
    title: 'Why go with Global Work & Travel?',
    context: `<p>
           As the world’s leading gap-year and “work & travel” company, we offer you the ability to not only choose from a number of awesome experiences all over the world but, we'll be your support system from the very first moment you become a Global Traveller! We provide a premium service that allows you to pick the destination of your dreams, get our visa & pre-departure assistance, book great flights and organise the perfect travel insurance or tours for when you arrive. Plus, we’ll back it all up with some cool and exclusive tech features that are with you the entire time.
          </p>
          <br />
          <p>As a Global Traveller, you’ll get exclusive access to gWorld, our personalised app where you can keep all of your important documents and trip details in one spot and gain insider access to everything Global. This is where you will find exclusive Marketplace deals for discounts with our brand partners, a unique social network to connect you with other Global Travellers when you’re overseas, access to our Academy with an ever-growing range of skills & languages to learn as you travel, invites to bucket-list side trips with other travellers, local meet-ups and so much more. Think all of your favourite apps merged into one, but like, better?</p>
          <br />
          <p>And it doesn't even stop there. We continue to be there from the moment you land with a dedicated 24/7 emergency team, over 90 staff and ongoing support ensuring that you have the tools you need to feel like a local. That’s why 65,000+ Global Travellers from all over the world have chosen to turn their travel dream into reality with us and we can proudly brag that we’ve got the most & best reviews in the biz!
          </p>`,
  },
  {
    id: 2,
    title: 'Do many people go solo?',
    context:
      '<p>Around 90% of our customers do! Travelling solo can be a bit nerve-racking, but once you’re on that plane and we’ve organised everything for your arrival, it’s possibly the best thing you’ll ever do. For those who go-it-solo, we’ve made it easier than ever to meet other Global Travellers in the same region as you. Just like any other social platform, you’ll be able to interact, share, chat and meet-up in-person with some really cool people who are doing trips just like you inside our app, gWorld.</p>',
  },
  {
    id: 3,
    title: 'What happens if I change my mind?',
    context: `<p>We understand that unexpected things may happen! Therefore we offer a lot of flexibility too. If you find yourself suddenly unable to travel, we’re usually able to change your dates at no cost to you, or you can transfer your deposit to a friend or relative (or another trip of your choosing), or, you can put your trip on hold under our Lifetime Deposit Guarantee. That means you can focus on overcoming the hurdle in your way and come back to us when the time’s right.</p>`,
  },
  {
    id: 4,
    title: 'How old do I need to be?',
    context: `<p>We do accept bookings from 16 onwards, however, we will need parental permission before confirming bookings for a minor (we do this automatically once a new booking is made). You will need to be at least 18 years old at the time of travel. Please be aware that you cannot travel with minors or dependents.</p>`,
  },
  {
    id: 5,
    title: 'What are my flights & insurance options?',
    context: `<p>Flights vary greatly depending on when you wish to travel, so they are not included in the trip cost. We are a full-service travel agency with access to airfares through dozens of airline carriers and we specialise in offering flexible flights to suit the nature of your trip. Down the track a little and once you’ve advised us of your flight preferences, your dedicated Travel Concierge will work with you to build your ideal itinerary.</p>
    <br/>
    <p>We strongly recommend that all of our Global Travellers have comprehensive travel insurance as it is a participation requirement for most of the trips that we offer and for some countries, even a visa eligibility requirement. Later on, our team will be more than happy to assist you in choosing the most suitable cover for you and your needs.</p>
    `,
  },

  {
    id: 6,
    title: 'What are my payment options?',
    context: `<p>You can book any trip with us using all the usual methods you find online, including a credit or debit card, or Apple Pay and Google Pay. In some regions or trips, we may also offer a Buy Now Pay Later option (if so, you’ll see it at the payment page).</p>
    <br/>
    <p>We take pride in offering the best flexibility we know of in the industry with our  <span class="font-semibold">Book Now, Decide Later</span>  feature, enabling you to book your trip without having to choose your travel dates just yet. Plus, you can set up an  <span class="font-semibold">interest-free payment plan</span>  to suit your budget inside your gWorld account or with your dedicated Trip Coordinator.</p>`,
  },
  {
    id: 7,
    title: 'Do I need a visa/passport?',
    context: `<p>Depending on your specific trip and destination, we’ll be able to give you directions for obtaining your visa, although you will need to apply and pay for it yourself. You can also visit the homepage of the government/embassy for the country you are travelling to.</p>
    <br/>
    <p>You will need a valid passport in order to travel. The general rule is that your passport must be valid for at least 6 months as some airlines and countries have a 6-month validity rule. If you’re getting a passport for the first time, you’ll need to fill out a passport application form, which you can download from your government's website, local post office or government buildings, dependent on your country of residence. There is a charge for passports and they normally take 3-6 weeks to be approved and delivered (depending on your country of residence). Your Trip Coordinator can help with your passport application and required documents.</p>
    `,
  },
];
