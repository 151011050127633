import { PaymentOptionsContent } from 'lib/types/payment';

export const gwatFallbackData: PaymentOptionsContent[] = [
  {
    title: 'Promotional Booking',
    type: 'Promotional',
    entries: [
      {
        content: 'Choose payment plan later. Cancel anytime.',
      },
      {
        content: 'Limited spaces. Get in today.',
      },
      {
        content: 'Date, length & destination still changeable.',
        popup: {
          content:
            'You can change your choices for free with your Trip Coordinator well after you get started today, until usually 84 days before departure. (TCs apply)',
        },
      },
    ],
  },
  {
    title: 'Standard Booking',
    type: 'Standard',
    entries: [
      {
        content: 'Secure trip preferences faster.',
      },
      {
        content: 'Flexible trip changes. Cancel anytime.',
        popup: {
          content:
            'You can change your choices for free with your Trip Coordinator well after you get started today, until usually 84 days before departure. (TCs apply)',
        },
      },
      {
        content: 'Protected by our Lifetime Deposit Guarantee.',
        popup: {
          content:
            'Swap your trip, gift it or save it for later with our Lifetime Deposit Guarantee. (TCs apply)',
        },
      },
      {
        content: "100% refundable if you don't qualify today.",
      },
    ],
  },
  {
    title: 'VIP Booking',
    type: 'VIP',
    entries: [
      {
        content: 'Get our best discount possible.',
      },
      {
        content: 'Fast-track to top of the queue.',
      },
      {
        content: 'VIP priority for trip dates.',
      },
      {
        content: 'Date, length & destination still changeable.',
        popup: {
          content:
            'You can change your choices for free with your Trip Coordinator well after you get started today, until usually 84 days before departure. (TCs apply)',
        },
      },
      {
        content: "100% refundable if you don't qualify today.",
      },
    ],
  },
];

export const dealsawayFallbackData: PaymentOptionsContent[] = [
  {
    title: 'VIP Booking',
    type: 'VIP',
    entries: [
      {
        content: 'Fast-Track Your Booking',
      },
      {
        content: 'Get Priority Confirmation',
      },
      {
        content: 'Lock-In Our Best Price',
      },
      {
        content: 'Interest-Free Payment Options (TCs Apply)',
      },
    ],
  },
  {
    title: 'Standard Booking',
    type: 'Standard',
    entries: [
      {
        content: 'Claim Deal Now',
      },
      {
        content: 'Pay Balance Later',
      },
      {
        content: 'Get Your Trip Coordinator',
      },
    ],
  },
];

export const mainPaymentOptions = ['deposit', 'standard', 'trip_in_full'];
