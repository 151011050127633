import {
  TTravellerFormValidationAction,
  TravellerFormUser,
} from 'lib/types/traveller';
import { Dispatch } from 'react';
import { ValidationHelper } from '../helper/validation/validationHelper';

type Props = {
  state: TravellerFormUser;
  validationDispatch: Dispatch<TTravellerFormValidationAction>;
};

const useTravellerValidation = ({ state, validationDispatch }: Props) => {
  const validationHelper = new ValidationHelper(validationDispatch);

  const checkFieldsValidation = () => {
    return validationHelper.checkFieldsValidation(
      state.firstName,
      state.lastName,
      state.phonePrefix?.value,
      state.phoneNumber,
      state.date,
      state.email
    );
  };

  return {
    checkFieldsValidation,
  };
};

export { useTravellerValidation };
