import { useAppDispatch, useAppSelector } from 'state/hooks';

import { Elements } from '@stripe/react-stripe-js';
import { PaymentCta } from 'components/MobileCta/Payment';
import { PaymentDetails } from 'components/PaymentDetails/PaymentDetails';
import { PaymentMethod } from './payment-method/payment-method';
import { PaymentSubmitStripe } from './payment-submit/payment-submit-stripe';
import { useStripe } from 'lib/hooks/useStripe';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import { useEffect, useRef, useState } from 'react';
import { PaymentMethodFallback } from './payment-method/payment-method-fallback';
import { PaymentOption } from 'lib/types/payment';
import { PaymentOptions } from './payment-options/payment-options';
import { PaymentOptionsFallback } from './payment-options/payment-options-fallback';
import { PaymentSubmitSkip } from './payment-submit/payment-submit-skip';
import { Retention } from '../../../../lib/helper/retention';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions';
import { TncApprovalProvider } from './tnc-approval/tnc-approval.provider';
import { appointmentLocalStorageService } from '@/services/localstorage/appointment';
import { setPaymentOption } from 'state/slices/product';
import { stripeConfig } from 'data/stripe-config';
import { useDevice } from 'lib/hooks/useDevice';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { useTenantTheme } from 'lib/hooks/useTenantTheme';

const PaymentStage = () => {
  const { isMobile } = useDevice();

  const selectedOption = useAppSelector((state) => state.product.paymentOption);
  const dispatch = useAppDispatch();
  const setSelectedOption = (option: PaymentOption) => {
    dispatch(setPaymentOption(option));
  };
  const invoiceId = useAppSelector((state) => state.product.invoiceId);
  const initiatePaymentEventHasBeenRaised = useRef(false);
  const theme = useTenantTheme();
  const [isAgreeButtonDisable, setIsAgreeButtonDisable] = useState(true);

  const {
    isFetching: promotionsFetching,
    isLoading: promotionsLoading,
    data: promotionsData,
  } = usePromotionsQuery();

  const {
    isLoading: isStripeLoading,
    stripePromise,
    clientSecret,
  } = useStripe({
    paymentOption: selectedOption,
  });

  const { isLoading: isRudderstackLoading, initiatePayment } = useRudderStack();

  const stripeOptions: StripeElementsOptions = {
    clientSecret,
    loader: 'always',
  };

  const userPays =
    selectedOption !== 'paid-deposit' && selectedOption !== 'paid-full';

  useEffect(() => {
    if (
      isRudderstackLoading ||
      !invoiceId ||
      initiatePaymentEventHasBeenRaised.current
    ) {
      return;
    }

    initiatePayment(invoiceId);
    initiatePaymentEventHasBeenRaised.current = true;
  }, [isRudderstackLoading, invoiceId, initiatePayment]);

  useEffect(() => {
    Retention.suppress();
    appointmentLocalStorageService.remove();
  }, []);

  return (
    <TncApprovalProvider>
      <div className="bg-light-600 md:border border-light-800 md:shadow-[0px_5px_5px_0px_#17181a0d] rounded-md overflow-hidden">
        <div className="p-5 md:p-10 flex flex-col gap-5">
          {(promotionsLoading || !promotionsData) && <PaymentOptionsFallback />}
          {!promotionsLoading && promotionsData && (
            <PaymentOptions
              selected={selectedOption}
              setSelected={setSelectedOption}
              promotions={promotionsData}
              isLoading={promotionsLoading || promotionsFetching}
            />
          )}
        </div>
        {isMobile && (
          <PaymentDetails
            isMobileViewOpen={false}
            closeMobileView={() => {}}
            forceMobileView
            hasAltMobileDesign
          />
        )}
        {!promotionsLoading && promotionsData && !userPays && (
          <>
            <TermsAndConditions />
            <PaymentSubmitSkip paymentOption={selectedOption} />
          </>
        )}
        {(!clientSecret ||
          !stripePromise ||
          promotionsLoading ||
          isStripeLoading) &&
          userPays && (
            <div className="p-5 md:p-10 md:pt-0 flex flex-col gap-5 md:gap-10">
              <PaymentMethodFallback />
            </div>
          )}
        {clientSecret &&
        stripePromise &&
        !promotionsLoading &&
        !isStripeLoading &&
        promotionsData &&
        userPays ? (
          <div className="flex flex-col">
            {/* make sure not to delete `key={clientSecret}` prop. */}
            {/* re-rendering stripe element (based on payment option)requires it */}
            <Elements
              stripe={stripePromise}
              options={{
                fonts: stripeConfig.fonts,
                appearance: stripeConfig.getAppearance(theme),
                ...stripeOptions,
              }}
              key={clientSecret}
            >
              <PaymentMethod
                setIsAgreeButtonDisable={setIsAgreeButtonDisable}
              />
              <TermsAndConditions />
              <PaymentSubmitStripe
                isAgreeButtonDisable={isAgreeButtonDisable}
              />
            </Elements>
          </div>
        ) : null}
        <div className="fixed z-50 bottom-0 left-0 right-0 bg-light-600">
          <PaymentCta />
        </div>
      </div>
    </TncApprovalProvider>
  );
};

export { PaymentStage };
