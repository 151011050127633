import { BOOKING_GATEWAY } from 'config';
import { sessionIdService } from 'services/session-id/session-id.service';
import { HttpService } from './http';

const getDefaultHeaders = (): Headers => {
  return {
    'X-BOOKING-SESSION-ID': sessionIdService.get(),
  } as unknown as Headers;
};

const getHeaders = (additionalHeaders?: Headers): Headers => {
  const defaultHeaders = getDefaultHeaders();
  if (!additionalHeaders) return defaultHeaders;

  const combinedHeaders = new Headers(defaultHeaders);
  const entries = additionalHeaders.entries();

  // the reason I'm bending over backwards, is that I want to
  // prevent adding --downlevelIteration flag.
  for (let n = entries.next(); !n.done; n = entries.next()) {
    combinedHeaders.set(n.value[0], n.value[1]);
  }

  return combinedHeaders;
};

const getUrl = (path: string): string => {
  return `${BOOKING_GATEWAY}${path}`;
};

export const BookingGateway = new HttpService({
  gateway: BOOKING_GATEWAY,
  options: {
    getHeaders,
    getUrl,
  },
});
