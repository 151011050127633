import { useMutation } from '@tanstack/react-query';
import { SkipPaymentOption, SkipPaymentQueryResult } from 'lib/types/payment';
import { BookingGateway } from 'services/http/booking-gateway';

type Props = {
  paymentOption: SkipPaymentOption;
};

const useSkipPaymentQuery = ({ paymentOption }: Props) => {
  const URL = '/public/payment/skip';

  const payload = {
    payment_option: paymentOption,
  };

  return useMutation(['skip-payment', payload], async () => {
    const response = await BookingGateway.post<SkipPaymentQueryResult>(
      URL,
      payload
    );
    return response;
  });
};

export { useSkipPaymentQuery };
