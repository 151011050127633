import { Dispatch, SetStateAction } from 'react';
import { TDropDownItem } from '../../../../../lib/types/dropdown';
import { Input } from '../../../../Input/Input';
import { Phone } from './phone';
import { TValidation } from 'lib/types/traveller-form';
import { Dropdown } from 'components/Dropdown/Dropdown';

type Props = {
  phonePrefix: TDropDownItem | null;
  setPhonePrefix: (value: TDropDownItem | null) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  phonePrefixValidation: TValidation;
  phoneNumberValidation: TValidation;
  emailValidation: TValidation;
  defaultPhoneCode: string | null;
  location: TDropDownItem | null;
  setLocation: (value: TDropDownItem | null) => void;
  locationValidation: TValidation;
};

const ReferralContactSection = ({
  phonePrefix,
  setPhonePrefix,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  phonePrefixValidation,
  phoneNumberValidation,
  emailValidation,
  defaultPhoneCode,
  location,
  setLocation,
  locationValidation,
}: Props) => {
  const optionsData: TDropDownItem[] = [
    { value: 'Australia', label: 'Australia' },
    { value: 'Canada', label: 'Canada' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'United States', label: 'United States' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Other', label: 'Other' },
  ];
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-5">
        <Phone
          phonePrefix={phonePrefix}
          setPhonePrefix={setPhonePrefix}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phonePrefixValidation={phonePrefixValidation}
          phoneNumberValidation={phoneNumberValidation}
          defaultPhoneCode={defaultPhoneCode}
        />
        <Input
          placeholder="Email"
          value={email}
          changeInput={setEmail}
          type="email"
          isError={!emailValidation.isValid}
          ErrorMessage={emailValidation.message}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-4 md:gap-5 mt-4">
        <div>
          <div
            className={
              'flex-grow text-left ' +
              (!locationValidation.isValid &&
                ' border rounded-md border-red-900 shadow-[4px_4px_4px_4px_#FE575410] focus-within:border-red-900 focus-within:shadow-[4px_4px_4px_4px_#FE575410] text-red-900 ')
            }
          >
            <Dropdown
              options={optionsData}
              selected={location}
              setSelected={
                setLocation as Dispatch<SetStateAction<TDropDownItem | null>>
              }
              hasBorder={!!locationValidation.isValid}
              placeholder="Location"
              hasFlagImage
              hasArrow
              controllerClassName="bg-light-600"
            />
          </div>
          {locationValidation && !locationValidation.isValid && (
            <div className="text-red-900 text-xs text-semibold mt-2 ml-1 whitespace-nowrap text-left">
              {locationValidation.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ReferralContactSection };
