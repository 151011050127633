import { useGeoDataQuery } from 'lib/react-query/Queries/useGeoDataQuery';
import { TGeoData } from 'lib/types/geo-data';
import { useEffect, useMemo, useRef } from 'react';
import { useOffice } from './useOffice/useOffice';
import loglevel from 'loglevel';
import { useUserLocation } from './useUserLocation';
import { sessionIdService } from 'services/session-id/session-id.service';
import { stagePathConfig } from 'data/stage';
import { useCurrency } from './useCurrency';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setHasBeenSet } from 'state/slices/currency';

const useGeoData = () => {
  const office = useOffice();
  const location = useUserLocation();
  const countryName = location ? location.country.name : '';
  const { isLoading, data, mutate: submitGeoData } = useGeoDataQuery();
  const { currency } = useCurrency();
  const sessionId = sessionIdService.get();
  const haveSubmitted = useRef(false);
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );
  const dispatch = useAppDispatch();

  const { isLoading: travellerIsLoading, data: travellerData } =
    useGetTraveller({ isEnabled: true });

  const payload: TGeoData = useMemo(() => {
    return {
      currency: currency,
      country: countryName,
      office_id: office.id,
    };
  }, [currency, countryName, office.id]);

  useEffect(() => {
    // must not send if previously user had set their currency
    if (!travellerIsLoading && travellerData?.result.currency) {
      dispatch(setHasBeenSet(true));
      return;
    }
    if (
      !location ||
      !sessionId.length ||
      haveSubmitted.current ||
      travellerIsLoading ||
      currencyHasBeenSet
    )
      return;
    if (window.location.pathname === stagePathConfig.finished) return;

    loglevel.info(
      'useGeoData: Called /public/session/geo with payload:',
      payload
    );

    submitGeoData(payload);
    haveSubmitted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, sessionId, travellerIsLoading, currencyHasBeenSet]);

  useEffect(() => {
    if (isLoading || !data) return;

    loglevel.info('useGeoData: /public/session/geo responded with: ', data);
  }, [isLoading, data]);
};

export { useGeoData };
