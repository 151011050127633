export const envs = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  LIVE: 'live',
};

export const getEnv = () => {
  if (process.env.NODE_ENV === 'development') return envs.LOCAL;
  if (process.env.REACT_APP_ENVIRONMENT !== 'live') return envs.DEVELOPMENT;
  return envs.LIVE;
};
