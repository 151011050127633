import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Finished } from './pages/Finished/Finished';
import { Home } from './pages/Home/Home';
import { ErrorPage } from 'pages/Errors/Error';
import { RudderStackPageWrapper } from 'components/RudderStackPageWrapper/RudderStackPageWrapper';
import { AppLevelRouterAccessWrapper } from 'components/AppLevelRouterAccessWrapper/AppLevelRouterAccessWrapper';
import { stagePathConfig } from 'data/stage';

function Router() {
  return (
    <BrowserRouter>
      <RudderStackPageWrapper>
        <AppLevelRouterAccessWrapper>
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path={stagePathConfig.finished} element={<Finished />} />
            <Route path="/404" element={<ErrorPage errorCode={404} />} />
            <Route path="/500" element={<ErrorPage errorCode={500} />} />
          </Routes>
        </AppLevelRouterAccessWrapper>
      </RudderStackPageWrapper>
    </BrowserRouter>
  );
}

export { Router };
