import { Image } from 'components/Image/Image';

type Props = {
  capacity: number;
};

const RoomIcon = ({ capacity }: Props) => {
  return (
    <>
      {(capacity === 1 || capacity === 2) && (
        <div className="w-10 w-min-10 ">
          {capacity === 1 && (
            <Image
              path="booking/icons/single-user.svg"
              alt="single user icon"
            />
          )}
          {capacity === 2 && (
            <Image path="booking/icons/multi-users.svg" alt="multi user icon" />
          )}
        </div>
      )}
      {capacity > 2 && (
        <div className="flex justify-center items-center">
          <div className="flex justify-start items-start w-10">
            <div className="w-[1.688rem] w-min-[1.688rem]">
              <Image
                path="booking/icons/single-user.svg"
                alt="single user icon"
              />
            </div>
            <div className="whitespace-nowrap text-xs text-dark-500 font-semibold">
              x {capacity}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export { RoomIcon };
