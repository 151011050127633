type Props = {
  color: string;
};

const Close = ({ color }: Props) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="transition-colors"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80277 9.80277C10.2324 9.3732 10.9288 9.3732 11.3584 9.80277L15 13.4444L18.6416 9.80277C19.0712 9.3732 19.7677 9.3732 20.1972 9.80277C20.6268 10.2324 20.6268 10.9288 20.1972 11.3584L16.5556 15L20.1972 18.6416C20.6268 19.0712 20.6268 19.7677 20.1972 20.1972C19.7677 20.6268 19.0712 20.6268 18.6416 20.1972L15 16.5556L11.3584 20.1972C10.9288 20.6268 10.2324 20.6268 9.80277 20.1972C9.3732 19.7677 9.3732 19.0712 9.80277 18.6416L13.4444 15L9.80277 11.3584C9.3732 10.9288 9.3732 10.2324 9.80277 9.80277Z"
        fill={color}
      />
    </svg>
  );
};

export default Close;
