import { MouseEventHandler } from 'react';
import Close from '../../assets/icons/Close';
import { useDevice } from '../../lib/hooks/useDevice';

type Props = {
  title: string;
  titleCssClasses: string;
  isCloseButtonHidden: boolean;
  mobileTitleCss?: string;
  close: Function;
};

const BookingModalHeader = ({
  isCloseButtonHidden,
  title,
  titleCssClasses,
  mobileTitleCss,
  close,
}: Props) => {
  const { isMobile } = useDevice();

  return (
    <>
      {isMobile && (
        <div
          id="modal-header-mobile"
          className={
            'px-5 flex gap-5 justify-center sm:hidden' + (title ? ' pt-5' : '')
          }
        >
          <div className="relative flex-grow">
            {!isCloseButtonHidden && (
              <div className="absolute top-0 left-0 h-full flex justify-center items-center">
                <div
                  data-testid="btn"
                  onClick={close as MouseEventHandler<HTMLElement>}
                  className="min-w-[2.5rem] min-h-[2.5rem] w-10 h-10 rounded-md border border-light-900 flex justify-center items-center cursor-pointer"
                >
                  <div className="w-[1.375rem] h-[1.375rem] cursor-pointer ">
                    <Close color="#737882" />
                  </div>
                </div>
              </div>
            )}

            {title && (
              <h1
                data-testid="title-mobile"
                className={
                  'min-h-[2.5rem] flex justify-center items-center text-xl md:text-2xl text-dark-900 font-semibold px-16 ' +
                  mobileTitleCss
                }
              >
                {title}
              </h1>
            )}
          </div>
        </div>
      )}
      {title && <hr className={'sm:hidden border-none w-full h-px mt-5'} />}
      {!isMobile && title && (
        <div
          id="modal-header-desktop"
          className={
            'hidden sm:flex justify-between items-center ' + titleCssClasses
          }
        >
          <h3
            data-testid="title-desktop"
            className="text-2xl font-semibold leading-[1.875] text-dark-800"
          >
            {title}
          </h3>
          {!isCloseButtonHidden && (
            <div
              onClick={close as MouseEventHandler<HTMLElement>}
              className="w-10 h-10 cursor-pointer "
            >
              <Close color="#9CA0A8" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { BookingModalHeader };
