import { useState } from 'react';
import { FAQCard } from './FAQ-card';
import { daFaqList, faqList } from './FAQ.mock';
import { withErrorBoundary } from '../../lib/HOC/withErrorBoundary/withErrorBoundary';
import { useTenant } from 'lib/hooks/useTenant';
import { Image } from 'components/Image/Image';

function FAQComponent() {
  const [index, setIndex] = useState(-1);
  const { isDA } = useTenant();
  const faqs = isDA ? daFaqList : faqList;

  return (
    <div
      className="p-5 sm:p-10 border border-light-800 md:rounded-md bg-light-600"
      id="faq"
    >
      <h2
        className={`text-2xl sm:text-3xl  leading-[1.875rem] font-bold ${
          isDA ? ' text-dark-800' : ' text-teal-900'
        }`}
      >
        FAQ
      </h2>
      <div className="mt-5 mb-0 p-3 rounded-md border border-yellow-900 bg-[#fff1d34d] text-xs font-semibold text-dark-700 flex items-center">
        <div className="w-6 h-6 mr-[0.625rem]">
          <Image path="booking/icons/content.svg" alt="content icon" />
        </div>
        Looking for some answers? Here's some of our top FAQ's.
      </div>
      {faqs.map((item, i) => (
        <FAQCard
          key={item.id}
          title={item.title}
          context={item.context}
          isExpand={item.id === index}
          click={() => {
            item.id === index ? setIndex(-1) : setIndex(item.id);
          }}
          hasBorder={faqs.length - 1 !== i}
        />
      ))}
    </div>
  );
}

export const FAQ = withErrorBoundary({ Component: FAQComponent });
