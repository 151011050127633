import { useEffect, useState } from 'react';

import { BookingModal } from 'components/Modal/BookingModal';
import { Checkbox } from 'components/Form/Checkbox/Checkbox';
import { TTripTermsAndConditions } from 'lib/types/terms-and-conditions';
import { TermsAndConditionsFallback } from './terms-and-conditions.fallback';
import { useLegalDeclarations } from 'lib/react-query/Queries/useLegalDeclarations';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { useTenant } from 'lib/hooks/useTenant';
import { useTncApproval } from '../tnc-approval/useTncApproval';
import { useTripTermsAndConditions } from 'lib/react-query/Queries/useTripTermsAndConditions';

const TermsAndConditions = () => {
  const { isLoading, data } = useLegalDeclarations();
  const { isLoading: tripTermsLoading, data: tripTermsData } =
    useTripTermsAndConditions();
  const { trackableLinkClicked } = useRudderStack();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeSpecialTnc, setActiveSpecialTnc] = useState<string>('');
  const { isDA } = useTenant();
  const {
    isApproved: isTncApproved,
    toggle: toggleTncApproval,
    isRequired: isTncApprovalRequired,
  } = useTncApproval();

  useEffect(() => {
    if (isLoading || !data) return;
    if (!trackableLinkClicked) return;

    const tncAnchors = document.querySelectorAll('#terms-and-conditions > a');
    const clickListener = (event: Event) => {
      const target = event.target as HTMLAnchorElement;
      const eventName = target.dataset.event;
      if (eventName) {
        trackableLinkClicked(eventName);
      }
    };

    tncAnchors.forEach((anchor) => {
      anchor.addEventListener('click', clickListener);
    });

    return () => {
      tncAnchors.forEach((anchor) => {
        anchor.removeEventListener('click', clickListener);
      });
    };
  }, [trackableLinkClicked, isLoading, data]);

  useEffect(() => {
    if (tripTermsLoading || !tripTermsData) return;
    if (isLoading || !data) return;

    const specialTermsAnchors = document.querySelectorAll(
      '#terms-and-conditions > a.show-terms'
    );

    const clickListener = (event: Event) => {
      const target = event.target as HTMLAnchorElement;
      const id = target.dataset.id as keyof TTripTermsAndConditions;
      if (id) {
        const value = tripTermsData.result[id];
        if (!value) {
          console.error(
            `terms-and-conditions: Supplier dynamic data id:${id} doesn't exist!`
          );
          return;
        }

        setActiveSpecialTnc(value);
        setIsModalOpen(true);
      }
    };
    specialTermsAnchors.forEach((anchor) => {
      anchor.addEventListener('click', clickListener);
    });

    return () => {
      specialTermsAnchors.forEach((anchor) => {
        anchor.removeEventListener('click', clickListener);
      });
    };
  }, [tripTermsData, tripTermsLoading, isLoading, data]);

  return (
    <>
      {isLoading || !data ? <TermsAndConditionsFallback /> : null}
      {!isLoading && data && data.result.length > 0 ? (
        <>
          <div
            id="terms-and-conditions"
            className={
              'p-5 rounded-md m-5 mt-0 md:m-10 md:mt-0' +
              (isDA
                ? ' bg-teal-500 bg-opacity-80'
                : ' bg-teal-600 md:bg-teal-500 bg-opacity-25 md:bg-opacity-80')
            }
          >
            <div className="flex gap-2.5">
              {isTncApprovalRequired ? (
                <Checkbox
                  id="t&c"
                  isChecked={isTncApproved}
                  toggleChecked={toggleTncApproval}
                />
              ) : null}
              <div className="space-y-5 select-none">
                {data.result.map((ld, index) => (
                  <div
                    onClick={() => {
                      toggleTncApproval();
                    }}
                    key={index}
                    className={
                      isDA ? 'inner-html-content-da' : 'inner-html-content'
                    }
                    id="terms-and-conditions"
                    dangerouslySetInnerHTML={{ __html: ld.declaration }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
          <BookingModal
            isOpen={isModalOpen}
            close={() => setIsModalOpen(false)}
            title={tripTermsData?.result['supplier.name'] ?? ''}
            cssClasses="sm:w-[35.75rem]"
          >
            <div className="mb-10">
              <div dangerouslySetInnerHTML={{ __html: activeSpecialTnc }}></div>
            </div>
          </BookingModal>
        </>
      ) : null}
    </>
  );
};

export { TermsAndConditions };
