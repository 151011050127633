import { MouseEvent } from 'react';
import { Button } from '../Button/Button';

type Props = {
  clickHandler: (e: MouseEvent<HTMLElement>) => void;
  isDisabled: boolean;
  isLoading: boolean;
};

const EmailMeButton = ({ clickHandler, isDisabled, isLoading }: Props) => {
  return (
    <Button
      text="Email me!"
      clickHandler={clickHandler}
      type="secondary"
      size="small"
      className="mr-2"
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );
};

export { EmailMeButton };
