import { useDevice } from 'lib/hooks/useDevice';
import { TTransformation } from 'lib/types/transformation';

const defaultTransformations: TTransformation = {
  mobile: 'banner-sm',
  desktop: 'banner-lg',
};

const useTransformation = (transformation?: TTransformation) => {
  const { isDesktop } = useDevice();

  const appliedTransformation = transformation || defaultTransformations;

  if (isDesktop) return appliedTransformation.desktop;
  return appliedTransformation.mobile;
};

export { useTransformation };
