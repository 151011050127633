import { Contact } from './Contact';
import { FAQ } from './FAQ';
import { Logo } from './Logo';
import { Separator } from './Separator';
import { withErrorBoundary } from '../../../lib/HOC/withErrorBoundary/withErrorBoundary';
import { useLocation } from 'react-router-dom';
import { useDevice } from 'lib/hooks/useDevice';
import { stagePathConfig } from 'data/stage';

type Props = {
  showFAQ?: boolean;
};

const Header = ({ showFAQ }: Props) => {
  const { pathname } = useLocation();
  const { isMobile, isDesktop } = useDevice();

  const isInFinishedPage = pathname === stagePathConfig.finished;
  const isInPaymentStage = pathname === stagePathConfig.payment;
  const shouldFAQButtonBeShown = !isInFinishedPage && !isInPaymentStage;

  return (
    <>
      <header
        id="top-header"
        className="h-20 px-5 sticky top-0 z-20 bg-light-600 border-b border-light-800"
        data-testid="header"
      >
        <div className="h-full max-w-screen-lg lg:mx-auto flex items-center justify-between">
          <Logo />
          <div className="flex-1 flex items-center justify-end gap-2.5 md:gap-5">
            {isMobile ? <Contact /> : null}
            {shouldFAQButtonBeShown && (
              <>
                {isMobile ? <Separator /> : null}
                <div className="flex items-center gap-2.5">
                  <FAQ isVisible={showFAQ} />
                </div>
                {isDesktop ? <Separator /> : null}
              </>
            )}
            {isDesktop ? <Contact /> : null}
          </div>
        </div>
      </header>
    </>
  );
};

export { Header };
