import { useMutation } from '@tanstack/react-query';
import { GeoDataQueryResult, TGeoData } from 'lib/types/geo-data';
import { BookingGateway } from 'services/http/booking-gateway';
import * as Sentry from '@sentry/react';
import { QueryError } from 'lib/types/utility';
import { useAppDispatch } from 'state/hooks';
import { setHasBeenSet } from 'state/slices/currency';

const useGeoDataQuery = () => {
  const URL = '/public/session/geo';
  const dispatch = useAppDispatch();

  return useMutation<GeoDataQueryResult, QueryError, TGeoData, unknown>(
    ['geo-data'],
    async (payload: TGeoData) => {
      const response = await BookingGateway.put<GeoDataQueryResult>(
        URL,
        payload
      );
      return response;
    },
    {
      onSuccess: () => {
        dispatch(setHasBeenSet(true));
      },
      onError: (error: any, variables) => {
        Sentry.captureException(`Setting geo data failed: ${error.message}`, {
          tags: {
            query: 'set geo data',
          },
          extra: {
            error,
            variables,
          },
        });
      },
      retry: 3,
    }
  );
};

export { useGeoDataQuery };
