import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CurrencyState = {
  hasBeenSet: boolean;
};

const initialState: CurrencyState = {
  hasBeenSet: false,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setHasBeenSet(state, action: PayloadAction<boolean>) {
      state.hasBeenSet = action.payload;
    },
  },
});

export const { setHasBeenSet } = currencySlice.actions;
export default currencySlice.reducer;
