import { Collapse } from 'react-collapse';
import { useTenant } from 'lib/hooks/useTenant';
import { Image } from 'components/Image/Image';

type Props = {
  title: string;
  context: string;
  click: any;
  isExpand: boolean;
  hasBorder: boolean;
};

const FAQCard = ({ title, context, click, isExpand, hasBorder }: Props) => {
  const { isDA } = useTenant();

  return (
    <div className={'border-light-800' + (!hasBorder ? '' : ' border-b')}>
      <div
        className="flex justify-between items-center cursor-pointer h-[3.75rem] "
        onClick={click}
      >
        <h3
          className={
            'text-sm sm:text-base font-semibold transition-all duration-300 ' +
            (isExpand
              ? `  ${isDA ? ' text-dealsaway-900 ' : ' text-teal-800  '}`
              : ' text-dark-800 ')
          }
        >
          {title}
        </h3>
        <div className="w-4">
          {isExpand ? (
            <Image path="booking/icons/minus.svg" alt="minus icon" />
          ) : (
            <Image path="booking/icons/plus.svg" alt="plus icon" />
          )}
        </div>
      </div>
      <Collapse
        isOpened={isExpand}
        initialStyle={{ height: 0, overflow: 'hidden' }}
      >
        <div className="pb-5">
          <div
            className={
              'bg-light-700 rounded-md p-5 sm:p-10 text-xs sm:text-sm text-dark-600 !leading-5 overflow-hidden inner-html '
            }
            dangerouslySetInnerHTML={{ __html: context }}
          ></div>
        </div>
      </Collapse>
    </div>
  );
};

export { FAQCard };
