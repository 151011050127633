import { useEffect } from 'react';
import { useAddPromotionQuery } from 'lib/react-query/Queries/useAddPromotionQuery';
import { useRemovePromotionQuery } from 'lib/react-query/Queries/useRemovePromotionQuery';
import { PromotionsQueryResult } from 'lib/types/promotion';
import { AppToastService } from '../../components/Toast/AppToastService';

type Props = {
  callback: (
    success: boolean,
    code: string,
    message: PromotionsQueryResult | string
  ) => void;
};

const usePromotionOperations = ({ callback }: Props) => {
  const {
    isLoading: addIsLoading,
    mutate: submitAddPromotion,
    isError: addHasError,
    error: addError,
  } = useAddPromotionQuery({ callback });

  const {
    isLoading: removeIsLoading,
    mutate: submitRemovePromotion,
    isError: removeHasError,
    error: removeError,
  } = useRemovePromotionQuery();

  useEffect(() => {
    if (addIsLoading) return;
    if (addHasError) {
      AppToastService.error(addError?.message || "Couldn't add promotion");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addIsLoading, addHasError]);

  useEffect(() => {
    if (removeIsLoading) return;
    if (removeHasError) {
      AppToastService.error(
        removeError?.message || "Couldn't remove promotion"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeIsLoading, removeHasError]);

  const eitherAddOrRemoveAreLoading = addIsLoading || removeIsLoading;

  return {
    submitAddPromotion,
    addIsLoading,
    removeIsLoading,
    submitRemovePromotion,
    eitherAddOrRemoveAreLoading,
  };
};

export { usePromotionOperations };
