import { useMutation } from '@tanstack/react-query';
import { ReferralPayLoad, ReferralResult } from 'lib/types/referral';
import { QueryError } from 'lib/types/utility';
import { BookingGateway } from 'services/http/booking-gateway';

const useReferredFriends = () => {
  const URL = '/public/traveller/referred-friends';

  type Props = {
    referralArray: ReferralPayLoad[];
  };

  return useMutation<ReferralResult, QueryError, Props, unknown>(
    ['referred-friends'],
    async ({ referralArray }: Props) => {
      const payload = referralArray;
      const response = await BookingGateway.post<ReferralResult>(URL, payload);
      return response;
    }
  );
};

export { useReferredFriends };
