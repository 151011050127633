import { IconButton, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { ArrowLeft } from 'assets/icons/ArrowLeft';
import { ArrowRight } from 'assets/icons/ArrowRight';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import { useAppSelector } from '@/state/hooks';

function CustomCalendarHeader(props: PickersCalendarHeaderProps<Dayjs>) {
  const { currentMonth, onMonthChange, disablePast, disabled } = props;

  const lastEnabledDate = useAppSelector(
    (state) => state.gcalendar.lastEnabledDate
  );

  const isPrevMonthDisabled =
    disablePast &&
    currentMonth
      .clone()
      .subtract(1, 'month')
      .isBefore(dayjs().startOf('month'));

  const isNextMonthDisabled = currentMonth
    .clone()
    .add(1, 'month')
    .isAfter(dayjs(lastEnabledDate).startOf('month'));

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 0px',
      }}
    >
      <IconButton
        onClick={() =>
          onMonthChange(currentMonth.subtract(1, 'month'), 'right')
        }
        style={{
          visibility: isPrevMonthDisabled ? 'hidden' : 'visible',
          padding: '0px',
        }}
        disabled={disabled}
      >
        <ArrowLeft color="#B6BCC0" />
      </IconButton>
      <Typography
        variant="h6"
        className="text-teal-800"
        fontWeight={500}
        fontSize={20}
      >
        {`${currentMonth.format('MMMM YYYY')}`}
      </Typography>
      <IconButton
        onClick={() => onMonthChange(currentMonth.add(1, 'month'), 'left')}
        className="!p-0"
        style={{
          visibility: isNextMonthDisabled ? 'hidden' : 'visible',
          padding: '0px',
        }}
        disabled={disabled}
      >
        <ArrowRight color="#B6BCC0" width="32" height="32" />
      </IconButton>
    </div>
  );
}

export default CustomCalendarHeader;
