export const scrollToElementById = (
  elementId: string,
  yOffset: number = -(80 + 20)
) => {
  const section = document.querySelector(`#${elementId}`);
  if (section) {
    const y =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
