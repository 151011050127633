const useTenant = () => {
  const TENANT = process.env.REACT_APP_TENANT || 'gwat';

  const isGwat = TENANT === 'gwat';
  const isDA = TENANT === 'dealsaway';

  return { isGwat, isDA };
};

export { useTenant };
