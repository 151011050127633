import { useCurrency } from 'lib/hooks/useCurrency';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { Duration } from 'lib/types/trip';
import { Radiobutton } from '../Form/Radiobutton/Radiobutton';

export type Props = {
  duration: Duration;
  isActive: boolean;
  click: (duration: Duration) => void;
  unit: string;
};

function DurationCard({ duration, isActive, click, unit }: Props) {
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();

  const clickHandler = (e: any) => {
    e.stopPropagation();
    click(duration);
  };

  return (
    <div
      className={
        'w-[7.813rem] h-[3.75rem] min-w-[7.813rem] border rounded-md flex justify-center items-center select-none cursor-pointer bg-light-600' +
        (isActive
          ? ' border-teal-900 bg-light-600 shadow-primary-900'
          : ' border-light-800')
      }
      onClick={clickHandler}
    >
      <Radiobutton
        id="1"
        value="1"
        name="1"
        label=""
        isSelected={isActive}
        selectedOption={() => {}}
      />
      <div className="flex flex-col justify-center items-start">
        <div className="text-xs text-dark-600 font-semibold">
          {duration.text}
        </div>
        <div className="flex items-end">
          {unit !== 'Days' && (
            <>
              <div className="text-sm text-dark-700 font-semibold mt-2">
                {numberFormatter.format(duration.prices[currency])}
              </div>
              {currencyShouldBeShown ? (
                <div className="text-[0.625rem] text-dark-700 font-semibold ml-0.5 mb-px">
                  ({currency})
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { DurationCard };
