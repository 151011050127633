import { AdditionalValue } from './AdditionalValue';
import { Label } from './Label';
import { Value } from './Value';

type Props = {
  label: string;
  value?: string;
  additionalValue?: string;
  hasBiggerValue?: boolean;
  isLoading: boolean;
};

const Row = ({
  label,
  value,
  hasBiggerValue = false,
  isLoading,
  additionalValue,
}: Props) => {
  return (
    <div className="flex justify-between items-center">
      <Label data={label} />
      <div className="flex items-center">
        <Value
          data={value}
          hasBiggerValue={hasBiggerValue}
          isLoading={isLoading}
        />
        {additionalValue ? (
          <AdditionalValue
            data={additionalValue}
            hasBiggerValue={hasBiggerValue}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

export { Row };
