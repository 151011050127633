import { useQuery } from '@tanstack/react-query';
import { ProductQueryResult } from 'lib/types/product';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

const useProductQuery = () => {
  const URL = '/public/booking/product';
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  const sessionId = sessionIdService.get();

  const result = useQuery(
    ['product', sessionId],
    async () => {
      const res = await BookingGateway.get<ProductQueryResult>(URL);
      return res;
    },
    {
      staleTime: 20 * 1000,
      enabled: !!sessionId.length && currencyHasBeenSet,
      retry: 3,
    }
  );

  return result;
};

export { useProductQuery };
