import { getSelectedRoomsFull } from 'lib/helper/room/room';
import { useCurrency } from 'lib/hooks/useCurrency';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setRooms } from 'state/slices/room';

const useSetRoom = () => {
  const { currency } = useCurrency();
  const { data } = useTripQuery();
  // TODO: refactor this, I just refactored global state, hence the
  // mess of rawRooms & rooms
  const rawRooms = useAppSelector((state) => state.room.rawRooms);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!data || !data.result.rooms) return;

    dispatch(
      setRooms(getSelectedRoomsFull(rawRooms, data.result.rooms, currency))
    );
  }, [dispatch, data, rawRooms, currency]);
};

export { useSetRoom };
