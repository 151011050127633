import { AppToast } from './AppToast';
import { toast } from 'react-toastify';

export class AppToastService {
  static getElement(
    type: 'success' | 'error' | 'info' | 'warning' | 'default',
    message: string,
    title?: string
  ) {
    return <AppToast title={title} message={message} type={type} />;
  }

  static error(message: string, title?: string) {
    toast.error(this.getElement('error', message, title));
  }

  static success(message: string, title?: string) {
    toast.success(this.getElement('success', message, title));
  }

  static info(message: string, title?: string) {
    toast.info(this.getElement('info', message, title));
  }

  static warning(message: string, title?: string) {
    toast.warning(this.getElement('warning', message, title));
  }

  static default(message: string, title?: string) {
    toast(this.getElement('default', message, title));
  }
}
