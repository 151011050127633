import { TLocation } from 'lib/types/location';
import { CurrencyKeys } from 'lib/types/trip';

export const getCurrencyByLocation = (
  location: TLocation | null
): CurrencyKeys => {
  if (!location) return 'USD';

  if (location.country.code === 'GB') return 'GBP';
  if (location.country.code === 'GG') return 'GBP'; // Guernsey
  if (location.country.code === 'NZ') return 'NZD';
  if (location.country.code === 'AU') return 'AUD';
  if (location.country.code === 'CA') return 'CAD';

  if (location.region === 'South West Asia') return 'EUR';
  if (location.continent === 'Europe') return 'EUR';
  if (location.continent === 'Africa') return 'EUR';

  if (location.continent === 'Americas') return 'USD';
  if (location.continent === 'North America') return 'USD';
  if (location.continent === 'South America') return 'USD';
  if (location.continent === 'Central America') return 'USD';

  if (location.continent === 'Asia') return 'AUD';
  if (location.continent === 'Oceania') return 'AUD';

  return 'USD';
};
