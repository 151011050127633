import { ReferralFormSection } from './section';
import { ReferralNameSection } from './name-section';
import { ReferralContactSection } from './contact-section';
import { TDropDownItem } from 'lib/types/dropdown';
import { ReferralFormValidation, ReferralFormT } from 'lib/types/referral';
import { Image } from 'components/Image/Image';

type Props = {
  state: ReferralFormT;
  validationState: ReferralFormValidation;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setPhonePrefix: (value: TDropDownItem<{}> | null) => void;
  setPhoneNumber: (value: string) => void;
  setLocation: (value: TDropDownItem<{}> | null) => void;
};
const ReferralForm = ({
  state,
  validationState,
  setFirstName,
  setLastName,
  setEmail,
  setPhonePrefix,
  setPhoneNumber,
  setLocation,
}: Props) => {
  return (
    <>
      <ReferralFormSection
        icon={<Image path="booking/icons/profile.svg" alt="profile icon" />}
        text="Name"
      >
        <ReferralNameSection
          firstName={state.firstName}
          lastName={state.lastName}
          firtNameValidation={validationState.firtNameValidation}
          lastNameValidation={validationState.lastNamevalidation}
          setFirstName={setFirstName}
          setLastName={setLastName}
        />
      </ReferralFormSection>
      <ReferralFormSection
        icon={<Image path="booking/icons/location.svg" alt="location icon" />}
        text="Contact"
      >
        <ReferralContactSection
          phonePrefix={state.phonePrefix}
          phoneNumber={state.phoneNumber}
          email={state.email}
          phonePrefixValidation={validationState.phonePrefixValidation}
          phoneNumberValidation={validationState.phoneNumberValidation}
          emailValidation={validationState.emailValidation}
          defaultPhoneCode={null}
          setPhonePrefix={setPhonePrefix}
          setPhoneNumber={setPhoneNumber}
          setEmail={setEmail}
          location={state.location}
          locationValidation={validationState.locationValidation}
          setLocation={setLocation}
        />
      </ReferralFormSection>
    </>
  );
};
export { ReferralForm };
