import { Button } from '../Button/Button';
import { useDevice } from 'lib/hooks/useDevice';
import { useLocation } from 'react-router-dom';
import { stagePathConfig } from 'data/stage';
import { Image } from 'components/Image/Image';

type Props = {
  openChangeDateModal: () => void;
};

const ChangeDate = ({ openChangeDateModal }: Props) => {
  const clickHandler = () => openChangeDateModal();
  const { isMobile, isDesktop } = useDevice();
  const location = useLocation();
  const isDisabled =
    location.pathname === stagePathConfig.payment ||
    location.pathname === stagePathConfig.paymentProcessing ||
    location.pathname === stagePathConfig.transactionFailed;

  return (
    <div className="block">
      <Button
        text={isDesktop ? 'Change Date' : ''}
        size="extra-small"
        type="secondary-border"
        icon={
          isMobile ? (
            <Image path="booking/icons/calendar.svg" alt="calendar icon" />
          ) : undefined
        }
        clickHandler={clickHandler}
        className={isMobile ? ' !border-dark-500' : ''}
        isDisabled={isDisabled}
        disabledTooltip={"You can't change date in this stage"}
      />
    </div>
  );
};

export { ChangeDate };
