import { useEffect } from 'react';
import { sentryService } from 'services/sentry/sentry';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useCurrency } from './useCurrency';
import { useUserLocation } from './useUserLocation';

const useSetSentryUser = () => {
  const { currency, currencyHasLoaded } = useCurrency();

  const location = useUserLocation();
  const countryName = location ? location.country.name : '';

  useEffect(() => {
    if (!currencyHasLoaded || !countryName) return;

    sentryService.setUser({
      extra: {
        currency,
        location: countryName,
        sessionId: sessionIdService.get(),
      },
    });
  }, [countryName, currency, currencyHasLoaded]);
};

export { useSetSentryUser };
