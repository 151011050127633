import { Dispatch, SetStateAction } from 'react';

export type Props = {
  items: string[];
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
};

function WhenTab({ items, selectedItem, setSelectedItem }: Props) {
  return (
    <div className="flex flex-col">
      <div className="text-lg font-semibold leading-5 text-dark-700 text-left ">
        When
      </div>
      <div className="pb-5 pt-2.5 md:py-5">
        <div
          className="p-[0.313rem] flex h-10 bg-[#575B6350] rounded-md font-semibold text-light-600 min-w-full max-w-full justify-between"
          style={{ width: `${111 * items.length}px` }}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={
                'py-2.5 px-[0.938rem] text-sm leading-5 flex justify-center items-center flex-grow rounded-md cursor-pointer ' +
                (item === selectedItem && ' bg-light-600 text-dark-700')
              }
              onClick={() => setSelectedItem(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { WhenTab };
