const DonationSkeleton = () => {
  return (
    <div className="bg-light-700 border border-dashed border-dark-500 rounded-md">
      <div className="p-5 md:p-10 bg-light-600 border-b md:border-none border-light-800 flex flex-col items-center rounded-md">
        <div className="w-10 h-10 mb-6 md:mb-8 bg-light-800 animate-pulse rounded-full"></div>
        <div className="w-80 md:w-[27.5rem] h-4 mb-2 bg-light-800 animate-pulse rounded-full"></div>
        <div className="w-56 md:w-72 h-2.5 md:h-4 mb-1 md:mb-0 bg-light-800 animate-pulse rounded-full"></div>
        <div className="block md:hidden w-36 h-2.5 bg-light-800 animate-pulse rounded-full"></div>
      </div>
    </div>
  );
};

export { DonationSkeleton };
