import { Provider } from 'react-redux';
import { SetterProvider } from './setter/provider';
import { store } from './store';

type Props = {
  children: React.ReactNode;
};

const StateProvider = ({ children }: Props) => {
  return (
    <Provider store={store}>
      <SetterProvider>{children}</SetterProvider>
    </Provider>
  );
};

export { StateProvider };
