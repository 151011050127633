import { Stage } from '../../lib/types/stageIndicator';

export const stages: Stage[] = [
  {
    index: 0,
    title: 'Rooms',
    label: 'Rooms',
    loadAnimation: false,
  },
  {
    index: 1,
    title: 'Traveller',
    label: 'Traveller',
    loadAnimation: false,
  },
  {
    index: 2,
    title: 'Payment',
    label: 'Confirmation',
    loadAnimation: true,
  },
];
