import {
  TravellerFormUser,
  TravellerFormValidation,
} from 'lib/types/traveller';
import { initValidation } from 'lib/types/traveller-form';

export const formUserInit: TravellerFormUser = {
  firstName: '',
  lastName: '',
  phonePrefix: null,
  phoneNumber: '',
  email: '',
  date: null,
};

export const userValidationInit: TravellerFormValidation = {
  firtNameValidation: initValidation,
  lastNamevalidation: initValidation,
  phoneNumberValidation: initValidation,
  emailValidation: initValidation,
  dateValidation: initValidation,
  phonePrefixValidation: initValidation,
};
