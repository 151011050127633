import { TripInfoFallback, TripInfoLoadingFallback } from './TripInfo.fallback';

import { Button } from '../Button/Button';
import { Inclusions } from 'components/Inclusions/Inclusions';
import { SessionTimer } from 'components/SessionTimer/SessionTimer';
import { TravellersBookInfo } from '../TravellersBookInfo/TravellersBookInfo';
import { TripInfoDetails } from './TripInfo.Details';
import { TripInfoImage } from './TripInfo.image';
import { filterInclusions } from '../Inclusions/filterInclusions';
import { stagePathConfig } from 'data/stage';
import { useDevice } from 'lib/hooks/useDevice';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTravellerCountQuery } from 'lib/react-query/Queries/useTravellerCountQuery';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useUserLocation } from '@/lib/hooks/useUserLocation';
import { withErrorBoundary } from 'lib/HOC/withErrorBoundary/withErrorBoundary';

function TripInfoComponent() {
  const [isInclusionsOpen, setIsInclusionsOpen] = useState<boolean>(false);
  const location = useLocation();
  const userLocation = useUserLocation();
  const { isDesktop } = useDevice();
  const { isLoading: tripLoading, data: tripData } = useTripQuery();
  const {
    isLoading: travellerCountLoading,
    data: travellerCount,
    shouldShow,
  } = useTravellerCountQuery();

  const inclusions = !tripLoading && tripData ? tripData.result.inclusions : [];

  const userCountry = userLocation ? userLocation.country.code : null;

  const inclusionsWithConditionsApplied = filterInclusions(
    inclusions,
    userCountry
  );

  const openInclusionsModal = () => setIsInclusionsOpen(true);
  const closeInclusionsModal = () => setIsInclusionsOpen(false);

  return !tripLoading && tripData && !travellerCountLoading ? (
    <>
      {location.pathname === stagePathConfig.payment && isDesktop ? (
        <SessionTimer />
      ) : null}
      <div
        className={
          'sm:w-[23.125rem]  border-light-800  bg-light-600 ' +
          (location.pathname === stagePathConfig.payment && isDesktop
            ? ' sm:border-r sm:border-l sm:border-b sm:rounded-b-md  '
            : ' sm:border sm:rounded-md ')
        }
      >
        {!(location.pathname === stagePathConfig.payment && isDesktop) &&
        !travellerCountLoading &&
        travellerCount ? (
          <TravellersBookInfo
            travellerCount={travellerCount}
            shouldShow={shouldShow}
          />
        ) : null}
        <TripInfoImage isLoading={tripLoading} data={tripData} />
        <TripInfoDetails
          openInclusionsModal={openInclusionsModal}
          hasInclusions={inclusionsWithConditionsApplied.length >= 1}
        />
        {inclusionsWithConditionsApplied.length >= 1 ? (
          <div className="p-5 hidden sm:block">
            <div className="text-dark-800 text-lg leading-5 font-semibold">
              Inclusions
            </div>
            {inclusionsWithConditionsApplied.length >= 1 ? (
              <ul className="mt-5 trip-info-list text-sm leading-5 font-medium text-dark-700">
                <li>{inclusionsWithConditionsApplied[0]?.title}</li>
                <li>{inclusionsWithConditionsApplied[1]?.title}</li>
              </ul>
            ) : null}
            <div className="mt-5">
              <Button
                size="extra-small"
                text="Show More"
                type="primary-border"
                clickHandler={openInclusionsModal}
              />
            </div>
          </div>
        ) : null}
      </div>
      <Inclusions
        isOpen={isInclusionsOpen}
        close={closeInclusionsModal}
        inclusions={inclusions}
      />
    </>
  ) : (
    <TripInfoLoadingFallback />
  );
}

export const TripInfo = withErrorBoundary({
  Component: TripInfoComponent,
  Fallback: TripInfoFallback,
});
