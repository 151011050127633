import { TDropDownItem } from '../../lib/types/dropdown';
import { Dispatch, SetStateAction } from 'react';
import { Image } from '../Image/Image';
import 'flagpack/dist/flagpack.css';

type Props = {
  option: TDropDownItem;
  isSelected: boolean;
  setSelected: Dispatch<SetStateAction<TDropDownItem<{}> | null>>;
  hasFlagImage: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onChange?: () => void;
  isShowValue?: boolean;
};

const DropdownMenuItem = ({
  option,
  isSelected,
  setSelected,
  hasFlagImage,
  setIsOpen,
  onChange,
  isShowValue = false,
}: Props) => {
  const clickHandler = (option: TDropDownItem): void => {
    setSelected(option);
    setIsOpen(false);
    onChange && onChange();
  };
  return (
    <div
      onClick={() => clickHandler(option)}
      className={
        'cursor-pointer flex flex-row justify-start items-center gap-2.5 px-2 py-2.5 transition-colors duration-300 group-last:mb-0 rounded-md hover:bg-light-700' +
        (isSelected ? ' bg-teal-500' : '')
      }
    >
      {!hasFlagImage && option.image ? (
        <div className={'w-7 h-7 overflow-hidden'}>
          <Image alt={option.label} path={option.image} />
        </div>
      ) : null}
      {hasFlagImage && option.image ? (
        <span className={`fp ${option.image} fp-md fp-rounded`}></span>
      ) : null}
      <div className="font-semibold text-sm text-dark-800">
        {isShowValue ? option.value : option.label}
      </div>
    </div>
  );
};

export { DropdownMenuItem };
