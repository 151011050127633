import { ReferralFormT, TReferralFormUserAction } from 'lib/types/referral';

export const ReferralReducer = (
  state: ReferralFormT,
  action: TReferralFormUserAction
) => {
  switch (action.type) {
    case 'firstName':
      return { ...state, firstName: action.value };
    case 'lastName':
      return { ...state, lastName: action.value };
    case 'phonePrefix':
      return { ...state, phonePrefix: action.value };
    case 'phoneNumber':
      return { ...state, phoneNumber: action.value };
    case 'email':
      return { ...state, email: action.value };
    case 'location':
      return { ...state, location: action.value };
    default:
      return state;
  }
};
