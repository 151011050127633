import { useEffect } from 'react';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useProductQuery } from 'lib/react-query/Queries/useProductQuery';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import { AppToastService } from '../../components/Toast/AppToastService';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { useAppDispatch } from 'state/hooks';
import { setCurrency } from 'state/slices/user';
import {
  setAvailability,
  setDuration,
  setFlexibleDeparture,
  setHasDepartureDate,
  setRooms,
  setTravellerNumber,
  setHasRestoredContext,
} from 'state/slices/product';

const useRestoreContext = () => {
  const { isLoading: tripLoading, data: tripData } = useTripQuery();
  const { isLoading: productLoading, data: productData } = useProductQuery();
  const { isLoading: travellerLoading, data: travellerData } = useGetTraveller({
    isEnabled: true,
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tripLoading || !tripData || productLoading || !productData) return;
    if (travellerLoading) return;

    if (!productData.success) {
      AppToastService.error('Oops! Something went wrong!');
    }
    if (!tripData.success) {
      AppToastService.error('Oops! Something went wrong!');
    }
    const availabilityUnit = tripData.result.availabilities[0].unit;

    const durationId = productData?.result.duration_id;

    const durationFilteredFromProductQuery =
      tripData.result.tripDurations.filter((item) => item.id === durationId)[0];

    const duration =
      durationFilteredFromProductQuery ||
      tripData?.result.duration ||
      tripData.result.tripDurations[0];

    dispatch(setDuration(duration));

    const day =
      AvailabilityHelper.getDayBasedByIdOrDepartureDate(
        tripData.result.availabilities,
        productData.result.availability_id,
        productData.result.departure_date,
        duration
      ) || null;

    const month =
      AvailabilityHelper.getMonthBasedByDepartureDate(
        tripData.result.availabilities,
        productData.result.departure_date
      ) || null;

    dispatch(setAvailability(availabilityUnit === 'Days' ? day : month));

    dispatch(setFlexibleDeparture(productData.result.is_departure_flexible));

    const travellersNumber = productData.result.travellers_number || 1;
    dispatch(setTravellerNumber(travellersNumber));

    const rooms = productData.result.inclusions;
    dispatch(setRooms(rooms ?? []));

    const userCurrency = travellerData?.result.currency;
    if (userCurrency) {
      dispatch(setCurrency(userCurrency));
    }

    const hasDepartureDate = Boolean(
      productData?.result.is_departure_flexible ||
        (productData?.result.departure_date &&
          productData?.result.departure_date > 0)
    );
    const hasAvailabilitySet = Boolean(
      productData?.result.availability_id &&
        productData?.result.availability_id > 0
    );
    dispatch(setHasDepartureDate(hasDepartureDate || hasAvailabilitySet));
    dispatch(setHasRestoredContext(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripLoading, tripData, productLoading, productData, travellerLoading]);
};

export { useRestoreContext };
