import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { AppToastService } from 'components/Toast/AppToastService';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import { QueryParam } from 'services/query-param/query-param';
import * as Sentry from '@sentry/react';
import { stagePathConfig } from 'data/stage';

type Props = {
  clientSecret: string;
};

const usePaymentResult = ({ clientSecret }: Props) => {
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const navigate = useCustomNavigate();
  const params = QueryParam.getAll();

  const filteredParams = {
    payment_intent: params.payment_intent,
    payment_intent_client_secret: params.payment_intent_client_secret,
  };

  const redirectToSuccess = () => {
    navigate(stagePathConfig.finished, { params: filteredParams });
  };

  const redirectToFailure = () => {
    navigate(stagePathConfig.transactionFailed, { params: filteredParams });
  };

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    try {
      stripe.retrievePaymentIntent(clientSecret).then((obj) => {
        const { paymentIntent } = obj;

        if (!paymentIntent) {
          AppToastService.error("Couldn't contact stripe!");
          return;
        }

        switch (paymentIntent.status) {
          case 'succeeded':
          case 'processing':
            redirectToSuccess();
            break;
          default:
            redirectToFailure();
            break;
        }
      });
    } catch (e) {
      Sentry.captureException(
        'Contacting stripe failed in payment-processing stage.',
        {
          tags: {
            stripe: 'payment-processing stage error',
          },
        }
      );
      redirectToFailure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret, searchParams, stripe]);
};

export { usePaymentResult };
