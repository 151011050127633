import { useQuery } from '@tanstack/react-query';
import { TLegalDeclarationsResult } from 'lib/types/terms-and-conditions';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

const useLegalDeclarations = () => {
  const URL = '/public/inventory/trip/legal-declarations';

  const sessionId = sessionIdService.get();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  return useQuery(
    ['legal-declarations', sessionId],
    async () => {
      const response = await BookingGateway.get<TLegalDeclarationsResult>(URL);

      return response;
    },
    {
      enabled: !!sessionId.length && currencyHasBeenSet,
    }
  );
};

export { useLegalDeclarations };
