import { usePaymentResult } from 'lib/hooks/usePaymentResult';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  clientSecret: string;
  setStripeIsLoading: Dispatch<SetStateAction<boolean>>;
  setPaidAmount: Dispatch<SetStateAction<number>>;
  setIsCharity: Dispatch<SetStateAction<boolean>>;
  setCharityPaymentWasSuccessful: Dispatch<SetStateAction<boolean>>;
};

const Payment = ({
  clientSecret,
  setStripeIsLoading,
  setPaidAmount,
  setIsCharity,
  setCharityPaymentWasSuccessful,
}: Props) => {
  usePaymentResult({
    clientSecret,
    setStripeIsLoading,
    setPaidAmount,
    setIsCharity,
    setCharityPaymentWasSuccessful: setCharityPaymentWasSuccessful,
  });

  return null;
};

export { Payment };
