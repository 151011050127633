import { usePaymentResult } from './usePaymentResult';

type Props = {
  clientSecret: string;
};

const Payment = ({ clientSecret }: Props) => {
  usePaymentResult({ clientSecret });

  return null;
};

export { Payment };
