import { MouseEvent } from 'react';
import { Button } from '../../../Button/Button';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { ContinueRightArrowIcon } from './continue-right-icon';
import { useCustomNavigate } from '../../../../lib/hooks/useCustomNavigate';
import { stagePathConfig } from 'data/stage';

type Props = {
  clickHandler: (e: MouseEvent<HTMLElement>) => void;
  isDisabled: boolean;
  isLoading: boolean;
  fillWithFakeTraveller: any;
  hasFakeTravellerButton: boolean;
  fakeTravellerIsLoading: boolean;
  submitButtonText: string;
};

const TravellerSubmit = ({
  clickHandler,
  isDisabled,
  isLoading,
  hasFakeTravellerButton,
  fillWithFakeTraveller,
  fakeTravellerIsLoading,
  submitButtonText,
}: Props) => {
  const navigate = useCustomNavigate();
  const { data: tripData } = useTripQuery();

  const hasRoomStage =
    tripData?.result.rooms && tripData.result.rooms.length > 0;

  return (
    <div
      className={
        'flex pt-0 md:pt-5 p-5 bg-light-600 md:bg-light-700 rounded-md gap-5 items-center justify-between '
      }
    >
      <div className="hidden md:flex space-x-5">
        {hasRoomStage && (
          <Button
            text="Back"
            clickHandler={() => navigate(stagePathConfig.rooms)}
            size="medium"
            type="secondary-border"
            arrow="left"
            className="min-w-[6.25rem]"
            textClassName="!text-sm"
          />
        )}
        {hasFakeTravellerButton && (
          <Button
            text="Set Fake Traveller"
            clickHandler={fillWithFakeTraveller}
            type="secondary-border"
            size="medium"
            isLoading={fakeTravellerIsLoading}
            textClassName="!text-sm"
          />
        )}
      </div>
      <Button
        text={submitButtonText}
        clickHandler={clickHandler}
        size="medium"
        type="primary"
        isDisabled={isDisabled}
        isLoading={isLoading}
        icon={<ContinueRightArrowIcon color="#FFF" />}
        className="min-w-full md:min-w-[15.625rem]"
      />
    </div>
  );
};

export { TravellerSubmit };
