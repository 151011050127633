import { useElements, useStripe } from '@stripe/react-stripe-js';
import { validatePositiveNumber } from 'lib/helper/validation/validation';
import { Button } from 'components/Button/Button';
import { Dispatch, SetStateAction, useState } from 'react';
import { useUserLocation } from 'lib/hooks/useUserLocation';
import { QueryParam } from 'services/query-param/query-param';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import * as Sentry from '@sentry/react';
import { sessionIdService } from 'services/session-id/session-id.service';
import { stagePathConfig } from 'data/stage';
import { useQueryClient } from '@tanstack/react-query';
import { useAppDispatch } from 'state/hooks';
import { setError } from 'state/slices/modal';

type Props = {
  customAmount: string;
  setCustomAmountIsValid: Dispatch<SetStateAction<boolean>>;
};

const DonationSubmit = ({ customAmount, setCustomAmountIsValid }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useUserLocation();
  const navigate = useCustomNavigate();
  const sessionId = sessionIdService.get();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const validate = (input: string) => {
    const isValidNumber = validatePositiveNumber(input);
    return isValidNumber;
  };

  const paymentHandler = async () => {
    if (customAmount.length > 0) {
      const isValid = validate(customAmount);
      setCustomAmountIsValid(isValid);

      if (!isValid) return;
    } else {
      setCustomAmountIsValid(true);
    }

    if (!stripe || !elements) return;

    setIsLoading(true);

    // TODO: use QueryParam.getFirst once merged
    const originalPaymentIntentParam = QueryParam.get('payment_intent');
    const originalPaymentIntent =
      typeof originalPaymentIntentParam === 'string'
        ? originalPaymentIntentParam
        : originalPaymentIntentParam
        ? originalPaymentIntentParam[0]
        : null;
    const opi = originalPaymentIntent ? `&opi=${originalPaymentIntent}` : '';
    const queryParams = QueryParam.getAllUnused();
    const stringQueryParams = QueryParam.stringify(queryParams);
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}${stagePathConfig.finished}?donation=true${opi}&${stringQueryParams}`,
        payment_method_data: {
          billing_details: {
            address: {
              // NOTE: this is needed since we disable the stripe element
              // dropdown to collect user country, see payment-method.tsx
              country: location?.country.code || 'AU',
            },
          },
        },
      },
    });

    // below is when on-session payments are happening, off-sessions
    // are redirected and don't reach this point
    if (
      error &&
      (error.type === 'card_error' ||
        error.type === 'validation_error' ||
        error.type === 'invalid_request_error')
    ) {
      dispatch(
        setError({
          isOpen: true,
          message:
            error.message ||
            'Your payment was unsuccessful. Please select a different payment method and try again.',
          buttonText: 'Return to payment',
        })
      );
    } else if (error) {
      Sentry.captureException('Payment failed unexpectedly.', {
        tags: {
          stripe: 'on-session payment error',
        },
        extra: {
          sessionId,
          message: error.message,
          type: error.type,
          paymentIntent: {
            id: error.payment_intent?.id,
            amount: error.payment_intent?.amount,
            currency: error.payment_intent?.currency,
          },
          paymentMethod: {
            type: error.payment_method?.type,
          },
        },
      });
    }

    const allParams = QueryParam.getAll();
    if (paymentIntent?.status === 'succeeded') {
      let params: any = {
        payment_intent: paymentIntent.id,
        payment_intent_client_secret: paymentIntent.client_secret ?? '',
        redirect_status: paymentIntent.status,
        donation: 'true',
      };
      let opi = allParams.payment_intent;
      if (opi) {
        params.opi = opi;
      }
      // NOTE: since gw updates the invoice objects rather late
      // we force fetch invoice objects here so we don't show an
      // awkward 0 value
      queryClient.invalidateQueries({ queryKey: ['promotions'] });
      navigate(stagePathConfig.finished, { params });
    }

    setIsLoading(false);
  };

  return (
    <div className="md:pb-10 flex flex-col gap-5 md:bg-light-600">
      <Button
        text="Donate"
        clickHandler={paymentHandler}
        size="medium"
        className="w-full md:w-48 py-2"
        isLoading={isLoading}
        isDisabled={isLoading}
      />
    </div>
  );
};

export { DonationSubmit };
