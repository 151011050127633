import { Button } from 'components/Button/Button';

type Props = {
  submitChooseDateModal: () => void;
  updateProductIsLoading: boolean;
  noAvailabilityWasChosen: boolean;
  isDisabled: boolean;
};

const AvailabilitySubmit = ({
  submitChooseDateModal,
  updateProductIsLoading,
  noAvailabilityWasChosen,
  isDisabled,
}: Props) => {
  return (
    <div id="modal-footer" className="mb-5 flex flex-col gap-5">
      <div className="w-full border-t border-light-800"></div>
      <Button
        text="Confirm"
        isLoading={updateProductIsLoading}
        isDisabled={
          updateProductIsLoading || noAvailabilityWasChosen || isDisabled
        }
        clickHandler={submitChooseDateModal}
        className="sm:self-end mx-5 sm:mr-10 bg-teal-900 min-w-[7.5rem]"
        type="primary-teal"
        size="medium"
      />
    </div>
  );
};

export { AvailabilitySubmit };
