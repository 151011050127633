import { PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions, TermOption } from '@stripe/stripe-js';

type Props = { setIsAgreeButtonDisable: any };
const PaymentMethod = ({ setIsAgreeButtonDisable }: Props) => {
  const terms: { [key: string]: TermOption } = {
    card: 'never',
    ideal: 'never',
    sofort: 'never',
    sepaDebit: 'never',
    bancontact: 'never',
    auBecsDebit: 'never',
    usBankAccount: 'never',
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
    terms,
    fields: {
      billingDetails: {
        address: {
          // NOTE: hidden dependency: make sure to send user
          // location in payment-submit-stripe.tsx
          country: 'never',
        },
      },
    },
  };

  return (
    <div className="p-5 md:p-10 md:pt-0 flex flex-col gap-5">
      <h2 className="font-semibold text-[1.5rem] md:text-2xl text-dark-800">
        Payment method
      </h2>
      <PaymentElement
        id="stripe-payment-element"
        options={paymentElementOptions}
        onLoaderStart={setIsAgreeButtonDisable(false)}
      />
    </div>
  );
};

export { PaymentMethod };
