import { PaymentOptionFallback } from '../payment-option/payment-option-fallback';

const PaymentOptionsFallback = () => {
  return (
    <>
      <h2 className="font-semibold text-[1.5rem] md:text-2xl text-dark-800">
        Booking options
      </h2>
      {[...Array(2)].map((_, index) => (
        <span key={index}>
          <PaymentOptionFallback />
        </span>
      ))}
    </>
  );
};

export { PaymentOptionsFallback };
