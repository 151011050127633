import { useQuery } from '@tanstack/react-query';
import { FakeTravellerQuery } from 'lib/types/traveller';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

const useFakeTravellerQuery = (isEnabled: boolean) => {
  const URL = '/public/config/fake-traveller';
  const sessionId = sessionIdService.get();

  return useQuery({
    queryKey: ['fake-traveller', sessionId],
    queryFn: async () => {
      const response = await BookingGateway.get<FakeTravellerQuery>(URL);
      return response;
    },
    enabled: isEnabled && !!sessionId.length,
    staleTime: Infinity,
  });
};

export { useFakeTravellerQuery };
