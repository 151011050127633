import { useIsMutating, useQuery } from '@tanstack/react-query';
import { stagePathConfig } from 'data/stage';
import { PromotionsQueryResult } from 'lib/types/promotion';
import { useLocation } from 'react-router-dom';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

const usePromotionsQuery = () => {
  const { pathname } = useLocation();
  const URL = '/public/promotion';

  const sessionId = sessionIdService.get();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  const isInPaymentStage = pathname === stagePathConfig.payment;
  const isCreateTravellerMutating = useIsMutating({
    mutationKey: ['create-traveller'],
  });

  const result = useQuery(
    ['promotions', sessionId],
    async () => {
      const res = await BookingGateway.get<PromotionsQueryResult>(URL);
      return res;
    },
    {
      staleTime: 20 * 1000,
      enabled: !!sessionId.length && currencyHasBeenSet,
    }
  );

  return {
    ...result,
    isLoading:
      result.isLoading || (isInPaymentStage && !!isCreateTravellerMutating),
  };
};

export { usePromotionsQuery };
