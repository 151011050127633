import { useQuery } from '@tanstack/react-query';
import { useCurrency } from 'lib/hooks/useCurrency';
import { PaymentClientConfigQueryResult } from 'lib/types/payment';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

type Props = {
  provider: string;
};

const usePaymentClientConfig = ({ provider }: Props) => {
  const { currency, currencyHasLoaded } = useCurrency();
  const URL = `/public/payment/provider/${provider}/currency/${currency}/client-config`;
  const sessionId = sessionIdService.get();

  return useQuery(
    ['payment-client-config', sessionId, provider, currency],
    async () => {
      const response = await BookingGateway.get<PaymentClientConfigQueryResult>(
        URL
      );
      return response;
    },
    {
      enabled: !!sessionId.length && currencyHasLoaded,
    }
  );
};

export { usePaymentClientConfig };
