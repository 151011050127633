import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BookingGateway } from 'services/http/booking-gateway';
import { PromotionsQueryResult } from 'lib/types/promotion';
import { QueryError } from 'lib/types/utility';
import { sessionIdService } from 'services/session-id/session-id.service';

const useRemovePromotionQuery = () => {
  const queryClient = useQueryClient();
  const sessionId = sessionIdService.get();

  const URL = '/public/promotion';

  return useMutation<PromotionsQueryResult, QueryError, string, unknown>({
    mutationKey: ['remove-promotion'],
    mutationFn: async (code: string) => {
      const lowercaseCode = code.toLowerCase();
      const response = await BookingGateway.delete<any>(
        `${URL}/${lowercaseCode}`
      );
      return response;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['promotions', sessionId], data);
    },
  });
};

export { useRemovePromotionQuery };
