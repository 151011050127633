const PaymentOptionFallback = () => {
  return (
    <div className="grid grid-cols-[1.7fr_0.05fr_1fr] md:grid-cols-[1fr_1.5fr_0.1fr_0.5fr] items-center md:px-10 bg-light-600 border rounded-md transition-colors overflow-hidden border-light-900 gap-y-12 py-7 px-5 pb-5">
      <div className="w-40 h-5 md:h-7 bg-light-800 rounded-full animate-pulse order-1"></div>
      <div className="flex flex-col gap-2.5 md:my-4 order-4 md:order-2">
        <div className="w-40 h-2.5 bg-light-800 rounded-full animate-pulse"></div>
        <div className="w-52 h-2.5 bg-light-800 rounded-full animate-pulse"></div>
        <div className="w-44 h-2.5 bg-light-800 rounded-full animate-pulse"></div>
        <div className="w-32 h-2.5 bg-light-800 rounded-full animate-pulse"></div>
      </div>
      <div className="self-stretch w-px md:border-l border-dashed border-dark-500 border-opacity-50 order-2 md:order-3 my-0"></div>
      <div className="justify-self-end w-20 md:w-28 h-5 md:h-7 bg-light-800 rounded-full animate-pulse order-3 md:order-4"></div>
    </div>
  );
};

export { PaymentOptionFallback };
