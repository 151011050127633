import { QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { QueryClientFactory } from './client-factory';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

type Props = {
  children: ReactNode;
};

const ReactQueryProvider = ({ children }: Props) => {
  const queryClient = QueryClientFactory();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export { ReactQueryProvider };
