const InstagramIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 1124 1054"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#737882"
        d="M512 101q102 0 134 .5t73 2.5q26 1 50 5.5t47 13.5l-2-1q17 6 32 16t27 22q12 12 22 26.5t16 31.5v1q8 21 12.5 45t5.5 50q2 41 2.5 72.5T932 521q0 103-.5 134.5T929 728q-1 26-5.5 50T910 825l1-2q-13 34-38 59t-58 38h-1q-21 8-45 12.5t-50 5.5q-41 2-72 2.5t-135 .5q-104 0-135-.5t-72-2.5q-26-1-50-5.5T208 919l2 1q-17-6-32-16t-27-22q-12-12-22-26.5T113 824v-1q-8-21-12.5-45T95 728q-2-41-2.5-73T92 521q0-102 .5-134t2.5-73q1-26 5.5-50t13.5-47l-1 2q6-17 16-32t22-27q12-12 26.5-22t31.5-16h1q21-8 45-12.5t50-5.5q41-2 73-2.5t134-.5zm0-92q-104 0-137 .5T301 12q-34 1-65.5 7T174 37l2-1q-26 10-48.5 25T86 95q-19 19-34 41t-24 48l-1 1q-11 28-17 59.5T3 310q-2 41-2.5 74T0 521q0 104 .5 137T3 732q1 34 7 65.5T28 859l-1-2q10 26 25 48.5T86 947q19 19 41 34t48 24l1 1q28 11 59.5 17t65.5 7q41 2 74 2.5t137 .5q104 0 137-.5t74-2.5q34-1 65.5-7t61.5-18l-2 1q51-20 89.5-58.5T996 858l1-1q11-28 17-59.5t7-65.5q2-41 2.5-74t.5-137q0-104-.5-137t-2.5-74q-1-34-7-65.5T996 183l1 2q-10-26-25-48.5T938 95q-19-19-41-34t-48-24l-1-1q-28-11-59.5-17T723 12q-41-2-74-2.5T512 9zm0 249q-54 0-102 21-48 20-84 56t-56 84q-21 48-21 102t21 102q20 48 56 84t84 56q48 21 102 21t102-21q48-20 84-56t56-84q21-48 21-102t-21-102q-20-48-56-84t-84-56q-48-21-102-21zm0 434q-71 0-121-50t-50-121q0-71 50-121t121-50q71 0 121 50t50 121q0 71-50 121t-121 50zm335-444q0 25-18 43t-44 18q-25 0-43-18t-18-43q0-26 18-44t43-18q26 0 44 18t18 44z"
      />
    </svg>
  );
};

export { InstagramIcon };
