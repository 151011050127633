import { forwardRef } from 'react';

const ClockIcon = ({ width = 17, height = 16, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33233 4.49712C5.83223 4.49712 3.80549 6.51199 3.80549 8.99747C3.80549 11.4829 5.83223 13.4978 8.33233 13.4978C10.8324 13.4978 12.8592 11.4829 12.8592 8.99747C12.8592 6.51199 10.8324 4.49712 8.33233 4.49712ZM8.33233 3.4975C5.2769 3.4975 2.79999 5.95992 2.79999 8.99747C2.79999 12.035 5.2769 14.4974 8.33233 14.4974C11.3878 14.4974 13.8647 12.035 13.8647 8.99747C13.8647 5.95992 11.3878 3.4975 8.33233 3.4975Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33233 5.49756C8.61 5.49756 8.83508 5.72134 8.83508 5.99737V8.66412C8.83508 8.94016 8.61 9.16393 8.33233 9.16393C8.05467 9.16393 7.82958 8.94016 7.82958 8.66412V5.99737C7.82958 5.72134 8.05467 5.49756 8.33233 5.49756Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48836 1.99724C6.48836 1.72121 6.71345 1.49744 6.99111 1.49744H9.67356C9.95122 1.49744 10.1763 1.72121 10.1763 1.99724C10.1763 2.27328 9.95122 2.49705 9.67356 2.49705H6.99111C6.71345 2.49705 6.48836 2.27328 6.48836 1.99724Z"
        fill="#575B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6711 3.64389C12.8675 3.4487 13.1858 3.4487 13.3821 3.64389L14.0527 4.31058C14.2491 4.50577 14.2491 4.82223 14.0527 5.01741C13.8564 5.2126 13.5381 5.2126 13.3417 5.01741L12.6711 4.35073C12.4748 4.15554 12.4748 3.83908 12.6711 3.64389Z"
        fill="#575B63"
      />
    </svg>
  );
};

export { ClockIcon };
