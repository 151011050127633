import { TValidation } from 'lib/types/traveller-form';
import { Input } from '../../../../Input/Input';
import { DatePicker } from './date-picker/date-picker';

type Props = {
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  firtNameValidation: TValidation;
  lastNameValidation: TValidation;
  date: Date | null;
  setDate: (value: Date) => void;
  dateValidation: TValidation;
};

const TravellerPersonalSection = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  firtNameValidation,
  lastNameValidation,
  date,
  setDate,
  dateValidation,
}: Props) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-5 mb-5">
        <Input
          tabIndex={0}
          placeholder="First name"
          value={firstName}
          changeInput={setFirstName}
          isError={!firtNameValidation.isValid}
          ErrorMessage={firtNameValidation.message}
          inputId="first-name-input"
        />
        <Input
          tabIndex={0}
          placeholder="Last name"
          value={lastName}
          changeInput={setLastName}
          isError={!lastNameValidation.isValid}
          ErrorMessage={lastNameValidation.message}
          inputId="last-name-input"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <DatePicker
          date={date}
          setDate={setDate}
          dateValidation={dateValidation}
        />
      </div>
    </div>
  );
};
export { TravellerPersonalSection };
