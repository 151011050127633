import dayjs from 'dayjs';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import { SelectionAvailability } from 'lib/types/availability';

export const getStartDate = (availability: SelectionAvailability) => {
  if (!availability) return;

  const date = AvailabilityHelper.getDepartureDate(availability);
  const isDayBased = AvailabilityHelper.isDayBased(availability);
  const dateFormat = isDayBased ? 'DD MMM YYYY' : 'MMM YYYY';

  return dayjs(date && date * 1000).format(dateFormat);
};
