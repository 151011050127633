import { useEffect } from 'react';
import log from 'loglevel';
import { generateErrorString } from 'lib/helper/error/error-string-generator';
import { sessionIdService } from 'services/session-id/session-id.service';
import { QueryParam } from 'services/query-param/query-param';
import { useAppDispatch } from 'state/hooks';
import { setHasBeenSet } from 'state/slices/currency';

const useAppQueryParams = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const sessionId = QueryParam.getFirst('session_id');

    if (!sessionId) {
      const error = generateErrorString({
        scope: 'useSessionQueryParam',
        message: 'No session_id was provided',
      });
      log.log(error);
      // if no param, re-read and store so in-memory value is present in service
      sessionIdService.set(sessionIdService.get());
      return;
    }

    sessionIdService.set(sessionId);
    log.log('Successfully written session_id into the localstorage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const origin = QueryParam.getFirst('origin');

    if (!origin) return;

    if (origin === 'gwat' || origin === 'da') {
      dispatch(setHasBeenSet(true));
      log.log(`Set currencyHasBeenSet to true since origin was ${origin}.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useAppQueryParams };
