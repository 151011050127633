import { Button } from 'components/Button/Button';
import { Anchor } from './Anchor';
import { TBorder, TStatus } from './types';
import { PropsWithChildren } from 'react';

type Props = {
  order: number;
  status: TStatus;
  border: TBorder;
  title: string;
  description: string;
  isLoading?: boolean;
  button?: {
    text: string;
    link: string;
  };
  excludeUnderline?: boolean;
};

const getBorderClasses = (border: TBorder, isLoading: boolean) => {
  if (isLoading) return ' border-r border-light-800';
  if (border === 'green') return ' border-r border-green-900';
  if (border === 'red') return ' border-r border-red-900';
  if (border === 'dashed')
    return ' border-r border-dashed border-dark-500 opacity-50';
  return '';
};

const getTextColor = (status: TStatus) => {
  if (status === 'complete') return ' text-green-900';
  if (status === 'todo') return ' text-yellow-900';
  if (status === 'failed') return ' text-red-900';
};

const Step = ({
  order,
  status,
  border,
  title,
  description,
  isLoading = false,
  button,
  excludeUnderline,
  children,
}: PropsWithChildren<Props>) => {
  const textColor = getTextColor(status);
  const borderClasses = getBorderClasses(border, isLoading);

  return (
    <div className="flex gap-2.5 md:gap-5">
      <div className="flex flex-col items-center">
        <Anchor order={order} status={status} isLoading={isLoading} />
        <div className={'flex-grow my-2.5' + borderClasses}></div>
      </div>
      <div className="flex-grow">
        {!false ? (
          <>
            <div className="flex min-h-[2rem] md:min-h-[2.5rem] items-center mb-2.5 md:mb-0">
              <div className={'text-lg font-semibold' + textColor}>{title}</div>
            </div>
            <div
              className="text-sm font-medium leading-[1.125rem] text-dark-700"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            {children}
          </>
        ) : (
          <>
            <div className="w-52 h-8 rounded-full bg-light-800 animate-pulse mb-2.5 md:mb-0"></div>
            <div className="w-36 h-4 rounded-full bg-light-800 animate-pulse"></div>
          </>
        )}
        {button ? (
          <a href={button.link} target="_blank" rel="noreferrer">
            <Button
              text={button.text}
              clickHandler={() => {}}
              className="mt-5 min-w-[11rem] h-12"
            />
          </a>
        ) : null}
        {!excludeUnderline ? (
          <div className="border-b border-light-800 w-full my-5"></div>
        ) : null}
      </div>
    </div>
  );
};

export { Step };
