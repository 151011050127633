import 'flagpack/dist/flagpack.css';

import { Dispatch, SetStateAction } from 'react';

import { ArrowRight } from 'assets/icons/ArrowRight';
import { ArrowUp } from 'assets/icons/ArrowUp';
import { GlobeIcon } from 'assets/icons/globe';
import { Image } from '../../components/Image/Image';
import { TDropDownItem } from '../../lib/types/dropdown';

type Props = {
  selected: TDropDownItem | null;
  placeholder?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  hasBorder: boolean;
  hasFlagImage: boolean;
  hasGlobeIcon?: boolean;
  hasArrow?: boolean;
  hasCustomArrow?: boolean;
  className: string;
  isLoading: boolean;
  disabled?: boolean;
};

const DropdownController = ({
  selected,
  placeholder,
  isOpen,
  setIsOpen,
  hasBorder,
  hasFlagImage,
  hasGlobeIcon = false,
  hasArrow = false,
  className,
  isLoading,
  hasCustomArrow = false,
  disabled = false,
}: Props) => {
  const toggleIsOpen = () => {
    !disabled && setIsOpen(!isOpen);
  };

  const keyPressHandler = (event: any) => {
    if (event.key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  if (isLoading) {
    return (
      <div className="p-4 rounded-md flex min-h-[3rem] md:min-h-[3.625rem] animate-pulse bg-light-800 "></div>
    );
  }

  return (
    <div
      tabIndex={0}
      data-testid="dropdown__controller"
      className={
        className +
        ' p-4 rounded-md flex min-h-[3rem] md:min-h-[3.625rem] realtive ' +
        (hasBorder ? ' border border-light-900' : '') +
        (disabled ? ' cursor-events-none cursor-default' : 'cursor-pointer')
      }
      style={{ boxShadow: '0px 3px 5px rgba(23, 24, 26, 0.03)' }}
      onClick={toggleIsOpen}
      onKeyPress={keyPressHandler}
    >
      {hasArrow && (
        <div className=" absolute top-[1.563rem] right-[1.563rem] w-[0.625rem] ">
          <ArrowUp color="#737882" cssClass={!isOpen ? ' rotate-180 ' : ''} />
        </div>
      )}
      {selected ? (
        <div className="flex items-center gap-1.5">
          {hasGlobeIcon && <GlobeIcon />}
          {!hasFlagImage && selected.image ? (
            <div className="w-5 h-3">
              <Image
                path={selected.image}
                alt={selected.label}
                crop={{
                  width: '300',
                  height: '200',
                }}
              />
            </div>
          ) : null}
          {hasFlagImage && selected.image ? (
            <span className={`fp fp-md fp-rounded ${selected.image}`}></span>
          ) : null}
          <div className="text-sm font-semibold text-dark-800">
            {selected.label}
          </div>
        </div>
      ) : (
        <div className="font-semibold text-dark-600 select-none">
          {placeholder}
        </div>
      )}
      {hasCustomArrow && (
        <div className="pl-2 bottom-1.5 relative">
          <div className="rotate-90">
            <ArrowRight color="#575B63" width="20" height="20" />
          </div>
        </div>
      )}
    </div>
  );
};

export { DropdownController };
