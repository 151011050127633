type Props = {
  year: number;
};

const Gwat = ({ year }: Props) => {
  return (
    <div className="w-full min-h-[6.875rem] bg-light-700 text-dark-600 font-semibold py-[1.25rem] flex flex-col">
      <div className="grid grid-cols-[1fr_0.1fr_1fr] md:flex items-center justify-center text-base leading-5 md:ml-1.5">
        <a
          href="https://www.globalworkandtravel.com/terms-and-conditions?tab=pp"
          target="_blank"
          rel="noreferrer"
          className="text-right text-sm md:text-base"
        >
          Privacy Policy
        </a>
        <div className="mx-5 border-r border-light-900 h-[1.875rem] "></div>
        <a
          href="https://www.globalworkandtravel.com/terms-and-conditions?tab=bc"
          target="_blank"
          rel="noreferrer"
          className="text-left text-sm md:text-base"
        >
          Booking Conditions
        </a>
      </div>
      <div className="mt-[1.25rem] text-xs sm:text-sm leading-5 flex items-center justify-center px-5 md:px-[1.25rem] text-center">
        <span>
          Copyright© {year} The Global Work &{' '}
          <span className="whitespace-nowrap">Travel Co.</span> All rights
          reserved.
        </span>
      </div>
    </div>
  );
};

export { Gwat };
