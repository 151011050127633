import { TLocation } from 'lib/types/location';
import { useAppSelector } from 'state/hooks';

const useUserLocation = (): TLocation | null => {
  const location = useAppSelector((state) => state.location.location);

  return location;
};

export { useUserLocation };
