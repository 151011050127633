import { AB_TEST_FEATURE } from 'data/flags';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useExperimentalFeatureVariant } from 'lib/hooks/useExperimentalFeature';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { LineContainer } from './LineContainer';

type Props = {
  total: number;
};

const TotalPrice = ({ total }: Props) => {
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();
  const { isEnabled, variant } = useExperimentalFeatureVariant(
    AB_TEST_FEATURE.due_now
  );
  const shouldShow = !isEnabled || (isEnabled && variant !== 'ar1');

  return shouldShow ? (
    <LineContainer>
      <div className="font-semibold text-dark-700">Total</div>
      <div className="flex items-center">
        <div className="font-semibold text-dark-700 text-lg">
          {numberFormatter.format(total)}
        </div>
        {currencyShouldBeShown ? (
          <div className="font-semibold text-dark-700 text-sm mt-px ml-0.5">
            ({currency})
          </div>
        ) : null}
      </div>
    </LineContainer>
  ) : null;
};

export { TotalPrice };
