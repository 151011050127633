const PaymentMethodFallback = () => {
  return (
    <div className="flex flex-col gap-5">
      <h3 className="font-semibold text-[1.5rem] md:text-2xl text-dark-800">
        Payment method
      </h3>
      <div>
        <div className="w-52 h-12 bg-light-800 rounded-3xl animate-pulse mb-5"></div>
        <div className="w-32 h-5 bg-light-800 rounded-3xl animate-pulse mb-2.5"></div>
        <div className="w-80 md:w-96 h-7 bg-light-800 rounded-3xl animate-pulse mb-5"></div>
        <div className="w-32 h-5 bg-light-800 rounded-3xl animate-pulse mb-2.5"></div>
        <div className="w-72 h-10 bg-light-800 rounded-3xl animate-pulse"></div>
      </div>
    </div>
  );
};

export { PaymentMethodFallback };
