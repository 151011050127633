import { TLocation } from 'lib/types/location';

export const defaultLocation: TLocation = {
  continent: 'Oceania',
  region: 'Pacific',
  country: {
    code: 'AU',
    name: 'Australia',
  },
};
