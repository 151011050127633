import AppointmentCalendar from '../../../GwatCalendar/AppointmentCalendar';
import { Step } from './Step';

type Props = {
  isLoading: boolean;
};

const GwatSteps = ({ isLoading }: Props) => {
  return (
    <>
      <div className="px-0 md:px-10 pb-0 md:pb-10">
        <Step
          order={3}
          status="todo"
          border="dashed"
          title="Setup your gWorld account & payment plan"
          description={`gWorld is your personalised portal & mobile app where you can get everything in order for your trip, access our Marketplace, Academy and meet others going to the same place. Please click on the link that has been sent to your email & take a few minutes to create your account.</br></br>Complete your profile, watch the welcome video inside & choose the payment plan you want.`}
          isLoading={isLoading}
        />
        <Step
          order={4}
          status="todo"
          border="none"
          title="Welcome call to meet your Trip Coordinator!"
          description="Choose a time that suits you to get to know your new personal Trip Coordinator, a work & travel expert who's been there & done that, there to ensure this trip is the best travel experience you could have. Choose a time now from the options below, and welcome on board!"
          excludeUnderline
          isLoading={isLoading}
        />
      </div>
      <div className="px-0 md:px-10 bg-[#FCFCFC] pt-5 pb-10 rounded-b-md">
        <AppointmentCalendar />
      </div>
    </>
  );
};

export default GwatSteps;
