import { TDropDownItem } from 'lib/types/dropdown';
import { TRoomSubmit } from 'lib/types/room';
import { SelectedRoom } from 'lib/types/trip';
import { useState } from 'react';
import { useSubmitRoom } from './useSubmitRoom';

const useRoom = (): TRoomSubmit => {
  const [travellerDropdown, setTravellerDropdown] =
    useState<TDropDownItem | null>(null);
  const [selectedRooms, setSelectedRooms] = useState<SelectedRoom[]>([]);
  const [submitRoomIsGotToGo, setSubmitRoomIsGotToGo] =
    useState<boolean>(false);

  const {
    isLoading,
    hasError,
    validationMessage,
    submitHandler,
    data,
    isError,
  } = useSubmitRoom({
    travellerDropdown,
    selectedRooms,
    isGotToGo: submitRoomIsGotToGo,
  });

  return {
    isRoomLoading: isLoading,
    roomData: data,
    isRoomError: isError,
    roomHasError: hasError,
    roomValidationMessage: validationMessage,
    submitRoom: submitHandler,
    selectedRooms,
    setSelectedRooms,
    travellerCountDropdown: travellerDropdown,
    setTravellerCountDropdown: setTravellerDropdown,
    submitRoomIsGotToGo,
    setSubmitRoomIsGotToGo,
  };
};

export { useRoom };
