import { CurrencyKeys } from 'lib/types/trip';

export const localeByCurrency: { [key in CurrencyKeys]: string } = {
  AUD: 'en-AU',
  CAD: 'en-CA',
  EUR: 'en-US',
  GBP: 'en-UK',
  NZD: 'en-NZ',
  USD: 'en-US',
};
