import { useMemo, useRef, useState } from 'react';
import { DatePickerController } from './date-picker-controller';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme, ThemeProvider } from '@mui/material';
import { TValidation } from 'lib/types/traveller-form';
import dayjs from 'dayjs';
import { getCommonTheme } from './config';
import { useTenantTheme } from 'lib/hooks/useTenantTheme';

const initDate = dayjs().subtract(18, 'years').toDate();

type Props = {
  date: Date | null;
  setDate: any;
  dateValidation?: TValidation;
};

const DatePicker = ({ date, setDate, dateValidation }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const controllerRef = useRef<HTMLDivElement>(null);
  const tenantTheme = useTenantTheme();

  const theme = createTheme({
    ...getCommonTheme(tenantTheme),
  });

  // NOTE: this is because if we don't memoize it
  // the whole page scrolls down to the date picker :|
  const popperOptions = useMemo(() => {
    return {
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
      ],
    };
  }, []);

  return (
    <div className="relative">
      <DatePickerController
        tabIndex={0}
        ref={controllerRef}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        date={date}
        isValid={dateValidation && dateValidation.isValid}
        setDate={setDate}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <MUIDatePicker
            slotProps={{
              layout: {
                sx: {
                  marginTop: '0.5rem',
                  '& .MuiPickersDay-root': {
                    fontSize: '14px',
                  },
                },
              },
              popper: {
                anchorEl: controllerRef.current,
                placement: 'bottom',
                popperOptions,
              },
            }}
            openTo="year"
            views={['year', 'month', 'day']}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            value={date ? dayjs(date) : dayjs(initDate)}
            disableFuture
            maxDate={dayjs(new Date())}
            onChange={(value) => value && setDate(value.toDate())}
            slots={{ field: () => <></> }}
          />
        </ThemeProvider>
      </LocalizationProvider>
      {dateValidation && !dateValidation.isValid && (
        <div className="text-red-900 text-xs text-semibold mt-2 ml-1 whitespace-nowrap">
          {dateValidation.message}
        </div>
      )}
    </div>
  );
};

export { DatePicker };
