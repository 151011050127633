import loglevel from 'loglevel';
import { stagePathConfig } from 'data/stage';

const checkError = async (response: Response, url: string) => {
  const isInFinishedPage =
    window.location.pathname === stagePathConfig.finished;

  const data = await response.json();

  if (response.status >= 500) {
    const status = response.status || '';
    const message = `${url.replace(/\d{2,20}/g, '<ID>')} -- ${status}`;
    loglevel.error(message, { isQueryError: true });
  }

  if (!isInFinishedPage) {
    if (response.status === 423) {
      if (data.code === 'SESSION_COMPLETED') {
        window.location.replace(
          `https://${process.env.REACT_APP_WEBSITE_DOMAIN_NAME}`
        );
      }
    }

    if (response.status === 403) {
      if (data.code === 'INVALID_SESSION') {
        window.location.replace(
          `https://${process.env.REACT_APP_WEBSITE_DOMAIN_NAME}`
        );
      }
    }

    if (response.status === 400) {
      if (data.code === 'MISSING_SESSION') {
        window.location.replace(
          `https://${process.env.REACT_APP_WEBSITE_DOMAIN_NAME}`
        );
      }
    }
  }

  throw data;
};

type Options = {
  getHeaders?: (additionalHeaders?: Headers) => Headers;
  getUrl?: (path: string) => string;
};

type Props = {
  gateway?: string;
  options?: Options;
};

class HttpService {
  gateway?: string;
  options?: Options;

  constructor({ gateway, options }: Props) {
    this.gateway = gateway;
    this.options = options;
  }

  getUrl(path: string): string {
    return this.options?.getUrl ? this.options?.getUrl(path) : path;
  }

  getHeaders(additionalHeaders?: Headers): Headers {
    return this.options?.getHeaders
      ? this.options?.getHeaders(additionalHeaders)
      : new Headers(additionalHeaders);
  }

  async get<T>(path: string, headers?: Headers) {
    return await this._fetch<T>('GET', path, undefined, headers);
  }

  async post<T>(path: string, data: unknown, headers?: Headers) {
    const combinedHeaders = new Headers(headers);
    combinedHeaders.set('Content-Type', 'application/json');
    return await this._fetch<T>('POST', path, data, combinedHeaders);
  }

  async put<T>(path: string, data: unknown, headers?: Headers) {
    const combinedHeaders = new Headers(headers);
    combinedHeaders.set('Content-Type', 'application/json');
    return await this._fetch<T>('PUT', path, data, combinedHeaders);
  }

  async delete<T>(path: string, headers?: Headers) {
    return await this._fetch<T>('DELETE', path, undefined, headers);
  }

  async _fetch<T>(
    method: string,
    path: string,
    data: unknown,
    additionalHeaders?: Headers
  ) {
    const url = this.getUrl(path);
    const headers = this.getHeaders(additionalHeaders);
    const stringifiedData =
      typeof data === 'string' ? data : JSON.stringify(data);

    const response = await fetch(url, {
      method,
      headers,
      body: stringifiedData,
    });

    if (!response.ok) await checkError(response, url);

    try {
      const res = await response.json();
      return res as T;
    } catch (error) {
      console.error(error);
      return {} as T;
    }
  }
}

export { HttpService };
