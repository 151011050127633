import { RudderStackPage } from 'components/RudderStack';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Retention } from '../../lib/helper/retention';

type Props = {
  children: React.ReactNode;
};

const RudderStackPageWrapper = ({ children }: Props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const href = window.location.href;
    const url = href.split('?')[0];

    RudderStackPage(pathname, url);
    Retention.page();
  }, [pathname]);

  return <>{children}</>;
};

export { RudderStackPageWrapper };
