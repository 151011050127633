import { Promotions } from 'lib/types/promotion';
import { PaymentDetailsLoadingFallback } from './PaymentDetails.fallback';
import { PaymentDetailsComponent } from './PaymentDetailsComponent';

type Props = {
  promotions: Promotions | undefined;
  addPromotion: (code: string) => void;
  removePromotion: (code: string) => void;
  addingPromotionLoading: boolean;
  removingPromotionLoading: boolean;
  eitherAddOrRemoveAreLoading: boolean;
  refetch: () => any;
  hasAltMobileDesign: boolean;
  isLoading: boolean;
  errorMessagePromo: string;
  setErrorMessagePromo: any;
};

const PaymentDetailsDesktop = ({
  promotions,
  addPromotion,
  removePromotion,
  addingPromotionLoading,
  removingPromotionLoading,
  eitherAddOrRemoveAreLoading,
  refetch,
  hasAltMobileDesign,
  isLoading,
  errorMessagePromo,
  setErrorMessagePromo,
}: Props) => {
  return (
    <div>
      {!isLoading && promotions ? (
        <PaymentDetailsComponent
          promotions={promotions}
          addPromotion={addPromotion}
          removePromotion={removePromotion}
          addingPromotionLoading={addingPromotionLoading}
          removingPromotionLoading={removingPromotionLoading}
          eitherAddOrRemoveAreLoading={eitherAddOrRemoveAreLoading}
          refetch={refetch}
          hasAltMobileDesign={hasAltMobileDesign}
          errorMessagePromo={errorMessagePromo}
          setErrorMessagePromo={setErrorMessagePromo}
        />
      ) : (
        <PaymentDetailsLoadingFallback />
      )}
    </div>
  );
};

export { PaymentDetailsDesktop };
