const TimeSlotsPickerSkeleton = () => {
  return (
    <>
      {[...Array(8)].map((_, index) => (
        <div
          key={index}
          className={`rounded-md text-center text-[14px] font-medium h-[55px] flex flex-col justify-center border-[1px] border-[#DBDCDF] bg-light-800 animate-pulse`} // Conditional styling
        ></div>
      ))}
    </>
  );
};

export default TimeSlotsPickerSkeleton;
