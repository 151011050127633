import { SelectedRoomFull } from 'lib/types/trip';
import { StringHelper } from 'lib/helper/string/string';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { LineContainer } from '../LineContainer';

type Props = {
  room: SelectedRoomFull;
};

const Room = ({ room }: Props) => {
  const numberFormatter = useNumberFormatter();
  const { currency, currencyShouldBeShown } = useCurrency();

  const price = numberFormatter.format(
    (room.prices[currency] ?? 0) * room.noOfTravellers
  );

  const priceCalculation = numberFormatter.format(room.prices[currency] ?? 0);

  return (
    <div className="mt-5">
      <LineContainer>
        <div className="font-semibold text-sm text-dark-600">
          {StringHelper.capitalize(room.type)}
        </div>
        <div className="flex items-center">
          <div className="font-semibold text-dark-700 text-base">{price}</div>
          {currencyShouldBeShown ? (
            <div className="font-semibold text-dark-700 text-sm mt-px ml-0.5">
              ({currency})
            </div>
          ) : null}
        </div>
      </LineContainer>
      <LineContainer>
        <div className="flex items-center">
          <div className="font-semibold text-sm text-dark-600">
            {priceCalculation}
          </div>
          {currencyShouldBeShown ? (
            <div className="font-semibold text-dark-600 text-xs mb-px ml-0.5">
              ({currency})
            </div>
          ) : null}
          <div className="font-semibold text-sm text-dark-600 ml-1">
            x {room.noOfTravellers}
          </div>
        </div>
      </LineContainer>
    </div>
  );
};

export { Room };
