import { ChangeEvent } from 'react';

export type Props = {
  filterString: string;
  handleFilterStringChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const DropdownSearch = ({ filterString, handleFilterStringChange }: Props) => {
  return (
    <input
      data-testid="dropdown__search"
      type="text"
      value={filterString}
      onChange={(e) => handleFilterStringChange(e)}
      placeholder="Search"
      className="bg-light-800 bg-opacity-40 p-4 rounded-md font-semibold w-full border-none outline-none text-sm text-semibold text-dark-700"
    />
  );
};
export { DropdownSearch };
