import { useQuery } from '@tanstack/react-query';
import { ReviewPlatformsQueryResult } from 'lib/types/review';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

const useGetReviewPlatforms = () => {
  const URL = '/public/inventory/trip/review/platforms/all';

  const sessionId = sessionIdService.get();

  return useQuery(
    ['get-review-platforms', sessionId],
    async () => {
      const response = await BookingGateway.get<ReviewPlatformsQueryResult>(
        URL
      );
      return response;
    },
    { enabled: !!sessionId.length, staleTime: Infinity }
  );
};

export { useGetReviewPlatforms };
