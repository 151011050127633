import { useLocation } from 'react-router-dom';
import { stages } from './StageIndicator.data';
import { Stage } from '../../lib/types/stageIndicator';
import { useEffect, useState } from 'react';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import { stagePathConfig } from 'data/stage';
import { useTenant } from 'lib/hooks/useTenant';

type Props = {
  hasRoomStage: boolean;
  isLoading: boolean;
};

function getShouldBeAbleToNavigate(
  currentPath: string,
  targetPath: string
): boolean {
  const previousPaths: string[] = [
    stagePathConfig.traveller,
    stagePathConfig.rooms,
  ];

  if (currentPath === stagePathConfig.payment) {
    if (previousPaths.includes(targetPath)) {
      return true;
    }
  }
  if (currentPath === stagePathConfig.traveller) {
    if (targetPath === stagePathConfig.rooms) return true;
  }
  return false;
}

function StageIndicator({ hasRoomStage, isLoading }: Props) {
  const [stageArray, setStageArray] = useState<Stage[]>(stages);
  const { isDA } = useTenant();

  const { pathname } = useLocation();
  const navigate = useCustomNavigate();

  const activeStageArray = stages.filter(
    (s) => `/${s.title.toLowerCase()}` === pathname
  );

  const activeStage: Stage = activeStageArray.length
    ? activeStageArray[0]
    : stages[0];

  const navigateHandler = (title: string) => {
    const pathToGoTo = `/${title}`;
    const shouldNavigate = getShouldBeAbleToNavigate(pathname, pathToGoTo);
    if (shouldNavigate) {
      navigate(pathToGoTo);
    }
  };

  const getCursorType = (title: string) => {
    const pathToGoTo = `/${title}`;
    const shouldNavigate = getShouldBeAbleToNavigate(pathname, pathToGoTo);

    if (shouldNavigate) {
      return 'cursor-pointer';
    }

    return 'cursor-auto';
  };

  useEffect(() => {
    if (!hasRoomStage) {
      setStageArray(stages.filter((item) => item.title !== 'Rooms'));
    } else {
      setStageArray(stages);
    }
  }, [hasRoomStage]);

  return !isLoading ? (
    /* tailwind ignores programatically-made classnames,
     * make sure to keep the next comment line:
     * grid-cols-1 grid-cols-2 grid-cols-3 */
    <div
      className={
        `grid grid-cols-${
          stageArray.length || 2
        }  text-base font-semibold leading-5 px-5 md:px-0 md:bg-opacity-0 py-5 md:py-0 md:pb-5 ` +
        (isDA ? ' bg-dealsaway-800 ' : ' bg-teal-500 ')
      }
    >
      {stageArray.map((item) => (
        <div
          className={
            'flex flex-col justify-between items-center relative h-[3rem] md:h-[3.75rem] ' +
            getCursorType(item.title.toLocaleLowerCase())
          }
          key={item.title}
          onClick={() => navigateHandler(item.title.toLocaleLowerCase())}
        >
          <div
            className={
              'transition-colors ' +
              (item.index <= activeStage?.index
                ? isDA
                  ? ' text-dark-800 '
                  : ' text-teal-900 '
                : 'text-dark-500') +
              (item.index < activeStage?.index ? ' opacity-50' : '')
            }
          >
            {item.label}
          </div>
          <div className="w-full mb-[0.563rem] ">
            <div
              className={' h-0.5  w-full rounded-[3rem] bg-light-800 '}
            ></div>
            {item.index <= activeStage.index && (
              <div
                className={
                  ' h-0.5 absolute bottom-[0.563rem]  w-full rounded-[3rem]  ' +
                  (item.loadAnimation && ' progress ') +
                  (isDA ? ' bg-dark-800 ' : ' bg-teal-900 ')
                }
              ></div>
            )}
            <div
              className={
                'border-2 absolute flex items-center justify-center left-0 right-0 m-auto bottom-0 transition-colors  md:bg-light-600 rounded-full w-[1.25rem] h-[1.25rem] ' +
                (isDA ? ' bg-dealsaway-800 ' : ' bg-teal-500 ') +
                (item.index === activeStage.index
                  ? isDA
                    ? ' border-dark-800 '
                    : ' border-teal-900 '
                  : ' text-light-900') +
                (item.index < activeStage.index ? ' border-none' : '')
              }
            >
              {item.index <= activeStage.index && (
                <div
                  className={
                    'rounded-full  w-[0.625rem] h-[0.625rem] ' +
                    (isDA ? ' bg-dark-800 ' : ' bg-teal-900 ')
                  }
                ></div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div>
      <div
        className={
          'flex justify-around items-center relative h-[3rem] md:h-[3.75rem] '
        }
      >
        <div
          className={
            'transition-colors bg-light-800 animate-pulse rounded-[3.75rem] w-[5.5rem] h-6'
          }
        ></div>
        <div
          className={
            'transition-colors bg-light-800 animate-pulse rounded-[3.75rem] w-[5.5rem] h-6'
          }
        ></div>
      </div>
      <div className="w-full bg-light-800 animate-pulse rounded-[3.75rem] h-0.5 mb-[1.813rem] "></div>
    </div>
  );
}

export { StageIndicator };
