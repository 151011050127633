import { SelectedRoomFull } from 'lib/types/trip';
import { PaymentDivider } from '../PaymentDivider';
import { Room } from './Room';

type Props = {
  rooms: SelectedRoomFull[];
};

const Rooms = ({ rooms }: Props) => {
  return (
    <>
      <PaymentDivider />
      <div className="font-semibold text-sm text-dark-800 text-left">Rooms</div>
      {rooms.map((room) => (
        <Room key={room.id} room={room} />
      ))}
    </>
  );
};

export { Rooms };
