import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { useCurrency } from 'lib/hooks/useCurrency';
import { LineContainer } from './LineContainer';

type Props = {
  noOfTravellers: number;
  retailPrice: number;
  paidPrice: number;
};

const TravellersNumber = ({
  noOfTravellers,
  retailPrice,
  paidPrice,
}: Props) => {
  const numberFormatter = useNumberFormatter();
  const { currency, currencyShouldBeShown } = useCurrency();

  const price = numberFormatter.format(retailPrice);
  const multipliedPrice = numberFormatter.format(retailPrice * noOfTravellers);
  const thereAreMultipleTravellers = noOfTravellers > 1;

  return (
    <>
      {thereAreMultipleTravellers ? (
        <>
          <LineContainer>
            <div className="font-semibold text-sm text-dark-600">Price</div>
            <div className="flex items-center">
              <div className="font-semibold text-dark-700 text-base">
                {multipliedPrice}
              </div>
              {currencyShouldBeShown ? (
                <div className="font-semibold text-dark-700 text-sm mt-px ml-0.5">
                  ({currency})
                </div>
              ) : null}
            </div>
          </LineContainer>
          <LineContainer>
            <div className="flex items-center">
              <div className="font-semibold text-sm text-dark-600">{price}</div>
              {currencyShouldBeShown ? (
                <div className="font-semibold text-xs text-dark-600 ml-0.5 mb-px">
                  ({currency})
                </div>
              ) : null}
              <div className="font-semibold text-sm text-dark-600 ml-1">
                x {noOfTravellers}
              </div>
            </div>
          </LineContainer>
        </>
      ) : (
        <LineContainer>
          <div className="font-semibold text-sm text-dark-600">Price</div>
          <div className="flex items-center">
            <div className="font-semibold text-dark-700 text-base">{price}</div>
            {currencyShouldBeShown ? (
              <div className="font-semibold text-dark-700 text-sm mt-px ml-0.5">
                ({currency})
              </div>
            ) : null}
          </div>
        </LineContainer>
      )}
      {paidPrice ? (
        <LineContainer>
          <div className="font-semibold text-sm text-dark-600">
            Previously paid
          </div>
          <div className="flex items-center">
            <div className="font-semibold text-dark-700 text-base">
              {numberFormatter.format(paidPrice)}
            </div>
            {currencyShouldBeShown ? (
              <div className="font-semibold text-dark-700 text-sm ml-0.5 mb-px">
                ({currency})
              </div>
            ) : null}
          </div>
        </LineContainer>
      ) : null}
    </>
  );
};

export { TravellersNumber };
