import { Dayjs } from 'dayjs';
import TimeSlotsPickerSkeleton from './TimeSlotsPickerSkeleton';
import { TimeslotsByDate } from '@/lib/react-query/Queries/gCalendar/useGetMonthTimeSlots';

type Props = {
  monthTimeSlots: TimeslotsByDate | null;
  date: Dayjs | null;
  selectedSlot: string | null;
  onChange?: (timeSlot: string) => void;
  loading?: boolean;
  disabled?: boolean;
};

const TimeSlotsPicker = ({
  monthTimeSlots,
  date,
  selectedSlot,
  onChange,
  loading = false,
  disabled = false,
}: Props) => {
  return (
    <div className="grid grid-cols-3 gap-[12px] overflow-y-auto h-full place-content-around">
      {loading && <TimeSlotsPickerSkeleton />}
      {monthTimeSlots &&
        !loading &&
        date &&
        monthTimeSlots[date.format('YYYY-MM-DD')]?.map((slot) => {
          const isSelected = selectedSlot === slot.displayTime;

          return (
            <div
              key={slot.from}
              onClick={() => onChange && onChange(slot.displayTime)}
              className={`rounded-md text-center text-[14px] font-medium h-[55px] flex flex-col justify-center border-[1px] border-[#DBDCDF] cursor-pointer 
          ${isSelected ? 'text-[#00C3EE] border-[#00C3EE]' : ''} ${
                disabled ? 'pointer-events-none' : ''
              }`} // Conditional styling
            >
              {slot.displayTime}
              {slot.displayMessage && (
                <>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: slot.displayMessage,
                    }}
                    className={` text-[10px] ${
                      isSelected ? 'text-[#00C3EE]' : 'text-[#575B63]'
                    }`}
                  />
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default TimeSlotsPicker;
