import { ReactNode, useState } from 'react';
import { TncApprovalContext } from './tnc-approval.context';

type Props = {
  children: ReactNode;
};

const TncApprovalProvider = ({ children }: Props) => {
  const [isApproved, setIsApproved] = useState<boolean>(false);

  return (
    <TncApprovalContext.Provider value={{ isApproved, setIsApproved }}>
      {children}
    </TncApprovalContext.Provider>
  );
};

export { TncApprovalProvider };
