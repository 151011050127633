import { ComponentMeta, ComponentStory } from '@storybook/react';
import { Button } from './Button';

type TButton = typeof Button;

export default {
  title: 'Components/Button',
  component: Button,
  argTypes: { clickHandler: { action: 'clicked' } },
} as ComponentMeta<TButton>;

const Template: ComponentStory<TButton> = (args) => <Button {...args} />;

export const Default = Template.bind({});
Default.args = {
  text: 'Text',
};

export const ExtraSmall = Template.bind({});
ExtraSmall.args = {
  ...Default.args,
  size: 'extra-small',
};

export const Medium = Template.bind({});
Medium.args = {
  ...Default.args,
  size: 'medium',
};

export const Large = Template.bind({});
Large.args = {
  ...Default.args,
  size: 'large',
};

export const Secondary = Template.bind({});
Secondary.args = {
  ...Default.args,
  type: 'secondary',
};

export const SecondaryBorder = Template.bind({});
SecondaryBorder.args = {
  ...Default.args,
  type: 'secondary-border',
};

export const ArrowLeft = Template.bind({});
ArrowLeft.args = {
  ...Default.args,
  arrow: 'left',
};

export const ArrowRightMedium = Template.bind({});
ArrowRightMedium.args = {
  ...Default.args,
  size: 'medium',
  arrow: 'right',
};

export const ArrowLeftLargeSecondary = Template.bind({});
ArrowLeftLargeSecondary.args = {
  ...Default.args,
  type: 'secondary',
  size: 'large',
  arrow: 'left',
};

export const DisabledWithArrowIcons = Template.bind({});
DisabledWithArrowIcons.args = {
  ...Default.args,
  size: 'medium',
  arrow: 'right',
  isDisabled: true,
};
