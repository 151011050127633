export const phoneNumbers: {
  [key: string]: string;
} = {
  Americas: '1877 744 5622',
  'North America': '1877 744 5622',
  'South America': '1877 744 5622',
  'Central America': '1877 744 5622',
  Europe: '(+44) 20 3002 2138',
  Ocenia: '(+61) 7 5528 4813',
  CA: '1877 744 5622',
  GB: '020 3002 2138',
  AU: '1300 40 11 90',
  NZ: '09 925 0387',
  BE: '02 320 55 07',
  NL: '020 532 0028',
  IE: '01 513 5536',
  DE: '0800 000 2735',
  default: '(+61) 7 5528 4813',
};
