import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { ThemeProvider, createTheme } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ArrowLeft } from '../Button/Button.stories';
import { ArrowRight } from '@/assets/icons/ArrowRight';
import CustomCalendarHeader from './GCDatePickerToolbar';
import { Dayjs } from 'dayjs';
import { PropsWithChildren } from 'react';
import { TimeslotsByDate } from '@/lib/react-query/Queries/gCalendar/useGetMonthTimeSlots';
import { getCommonTheme } from '../pages/Traveller/traveller-stage/personal-section/date-picker/config';
import { useTenantTheme } from '@/lib/hooks/useTenantTheme';

type Props = {
  date: Dayjs | null;
  monthTimeSlots: TimeslotsByDate | null;
  enabledMonthDays: string[] | null;
  onChange?: (date: Dayjs) => void;
  loading?: boolean;
  disabled?: boolean;
};

function CustomToolbar() {
  return null; // This component renders nothing
}

const GwatCalendarPicker = ({
  date,
  monthTimeSlots,
  enabledMonthDays,
  children,
  onChange,
  disabled,
  loading,
}: PropsWithChildren<Props>) => {
  const tenantTheme = useTenantTheme();

  const theme = createTheme({
    ...getCommonTheme(tenantTheme),
  });

  const shouldShowLoading =
    loading || !date || !monthTimeSlots || !enabledMonthDays;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <StaticDatePicker
          views={['day']}
          value={disabled || shouldShowLoading ? null : date}
          showDaysOutsideCurrentMonth={true}
          disabled={disabled || shouldShowLoading}
          //   loading={shouldShowLoading}
          slots={{
            calendarHeader: CustomCalendarHeader,
            toolbar: CustomToolbar,
            actionBar: CustomToolbar,
            leftArrowIcon: ArrowLeft,
            rightArrowIcon: ArrowRight,
          }}
          sx={{
            '& .MuiPickersLayout-root': {
              backgroundColor: 'red',
            },
            '& .MuiButtonBase-root': {
              fontSize: '16px !important',
              width: '42px',
              height: '42px',
              color: '#00C3EE !important',
            },
            '& .MuiButtonBase-root.Mui-disabled': {
              color: '#B1B4BA !important',
            },
            '& .MuiButtonBase-root.Mui-selected': {
              color: 'white !important',
              backgroundColor: '#00C3EE !important',
            },
            '& .MuiDayCalendar-weekDayLabel': {
              fontSize: '14px !important',
              fontWeight: 500,
              color: '#575B63 !important',
              width: '42px !important',
            },
            '& .MuiButtonBase-root.MuiPickersDay-dayOutsideMonth': {
              color: '#B6BCC0 !important',
            },
          }}
          disablePast
          disableHighlightToday
          shouldDisableDate={(date) => {
            const monthWithLeadingZero = date.format('MM');
            const year = date.year();
            const day = date.date();

            if (monthTimeSlots) {
              return !monthTimeSlots[date.format('YYYY-MM-DD')];
            }

            if (enabledMonthDays && enabledMonthDays) {
              return !enabledMonthDays.includes(
                `${year}-${monthWithLeadingZero}-${day}`
              );
            }

            return false;
          }}
          onChange={(date) => {
            if (date && onChange) {
              onChange(date);
            }
          }}
          dayOfWeekFormatter={(day, date) => {
            const weekdaysMap = {
              Su: 'Sun',
              Mo: 'Mon',
              Tu: 'Tue',
              We: 'Wed',
              Th: 'Thu',
              Fr: 'Fri',
              Sa: 'Sat',
            };
            return weekdaysMap[day as keyof typeof weekdaysMap] || day;
          }}
        />
        {children && <div className="flex flex-row">{children}</div>}
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default GwatCalendarPicker;
