import {
  CreateTravellerPayload,
  useCreateTraveller,
} from 'lib/react-query/Queries/useCreateTraveller';
import { TTravellerSubmit } from 'lib/types/traveller';
import { useReducer, useState } from 'react';
import { formUserInit, userValidationInit } from 'lib/reducers/traveller/init';
import { userReducer } from 'lib/reducers/traveller/user-reducer';
import { userValidationReducer } from 'lib/reducers/traveller/validation-reducer';
import { useTravellerValidation } from 'lib/hooks/useTravellerValidation';

const useTraveller = (): TTravellerSubmit => {
  const {
    isLoading,
    isError,
    data,
    error,
    mutate: submitCreateTraveller,
  } = useCreateTraveller();
  const [state, dispatch] = useReducer(userReducer, formUserInit);
  const [isGotToGo, setIsGotToGo] = useState<boolean>(false);

  const [validationState, validationDispatch] = useReducer(
    userValidationReducer,
    userValidationInit
  );

  const { checkFieldsValidation } = useTravellerValidation({
    state,
    validationDispatch,
  });

  function submit(submitData: CreateTravellerPayload) {
    if (submitData === null) {
      return;
    }
    submitCreateTraveller(submitData);
  }

  return {
    isCreateTravellerLoading: isLoading,
    createTravellerError: error,
    createTravellerDispatch: dispatch,
    createTravellerIsGotToGo: isGotToGo,
    setCreateTravellerIsGotToGo: setIsGotToGo,
    createTravellerData: data,
    submitCreateTraveller: submit,
    isCreateTravellerError: isError,
    travellerState: state,
    validateTraveller: checkFieldsValidation,
    travellerValidationState: validationState,
    travellerValidationDispatch: validationDispatch,
  };
};

export { useTraveller };
