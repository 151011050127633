import Close from 'assets/icons/Close';
import { ReferralFormT } from 'lib/types/referral';
import { Image } from 'components/Image/Image';

type Props = {
  referralArray: ReferralFormT[] | null;
  selectedIndex: number;
  updateArray: (item: ReferralFormT, index: number) => void;
  deleteFromArray: (index: number) => void;
  addHandler: () => void;
};

const ListActions = ({
  referralArray,
  selectedIndex,
  updateArray,
  deleteFromArray,
  addHandler,
}: Props) => {
  return (
    <div className="w-full relative">
      <div className="border-t border-light-800 absolute w-full top-6 h-6 md:h-0 bg-light-600 md:bg-none"></div>
      <div className="flex gap-x-2 px-6 md:px-10 pb-1 overflow-x-scroll webkit-custom-scrollbar overflow-y-visible">
        {referralArray?.map((item, index) => (
          <div
            key={index}
            className={
              'z-10 min-w-[5.688rem] w-[5.688rem] h-10 border rounded-md bg-light-600 flex justify-center items-center text-sm font-semibold text-dark-700' +
              (selectedIndex === index
                ? ' border-teal-900 tealshadow'
                : ' border-light-800 ')
            }
          >
            <div
              className=" cursor-pointer  whitespace-nowrap overflow-hidden text-ellipsis w-[3.75rem]"
              onClick={() => updateArray(item, index)}
            >
              {item.firstName} {index + 1}
            </div>
            <div
              className="w-4 h-4 cursor-pointer"
              onClick={() => deleteFromArray(index)}
            >
              <Close color="#575B63" />
            </div>
          </div>
        ))}
        <div
          onClick={addHandler}
          className="z-10 cursor-pointer bg-light-600  min-w-[2.5rem] h-10 w-10 rounded-md border flex justify-center items-center border-dark-500"
        >
          <div className="h-6 w-6">
            <Image path="booking/icons/plus.svg" alt="plus icon" />
          </div>
        </div>
        {referralArray?.length === 0 && (
          <div className="text-dark-700 text-xs font-semibold leading-4">
            Refer another friend
          </div>
        )}
      </div>
    </div>
  );
};

export { ListActions };
