import { configureStore } from '@reduxjs/toolkit';
import currencySlice from './slices/currency';
import flagSlice from './slices/flags';
import gcalendarSlice from './slices/gcalendar';
import locationSlice from './slices/location';
import modalSlice from './slices/modal';
import productSlice from './slices/product';
import roomSlice from './slices/room';
import userSlice from './slices/user';

export const store = configureStore({
  reducer: {
    user: userSlice,
    product: productSlice,
    modal: modalSlice,
    currency: currencySlice,
    location: locationSlice,
    room: roomSlice,
    flag: flagSlice,
    gcalendar: gcalendarSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
