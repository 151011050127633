import { usedQueryParams } from 'data/used-query-params';
import { ParamObject, ParamValue } from 'lib/types/query-param';
import omit from 'lodash/omit';

class QueryParam {
  private static getURLSearchParams(): URLSearchParams {
    const url = window.location.search;
    const splittedUrl = url.split('?');
    const params = splittedUrl.length > 1 ? splittedUrl[1] : '';
    return new URLSearchParams(params);
  }

  static getAll(): ParamObject {
    const urlSearchParams = this.getURLSearchParams();
    let params: ParamObject = {};
    Array.from(urlSearchParams.keys()).forEach((k) => {
      const values = urlSearchParams.getAll(k);
      const transformedValues = this.transformValues(values);
      if (transformedValues) {
        params[k] = transformedValues;
      }
    });
    return params;
  }

  static getAllUnused(): ParamObject {
    const all = this.getAll();
    const allUnused = omit(all, usedQueryParams);
    return allUnused;
  }

  static get(key: string): ParamValue | null {
    const urlSearchParams = this.getURLSearchParams();
    const values = urlSearchParams.getAll(key);
    return this.transformValues(values);
  }

  static getFirst(key: string): string | null {
    const urlSearchParams = this.getURLSearchParams();
    const values = urlSearchParams.getAll(key);
    const params = this.transformValues(values);

    if (typeof params === 'string') return params;
    if (params && params.length > 0) return params[0];
    return null;
  }

  static stringify(params: ParamObject): string {
    const transformed = this.transformParmasToArray(params);
    const urlSearchParams = new URLSearchParams(transformed);
    return urlSearchParams.toString();
  }

  // NOTE: URLSearchParmas only accepts multiple values in form of strign[][]
  // but nextjs uses objects with arrays as their values for this. so we have
  // to bend over backwards since they employ dunning-kruger biased soy devs
  private static transformParmasToArray(params: ParamObject): string[][] {
    let array: string[][] = [];

    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (typeof value === 'string') {
        array.push([key, value]);
      } else {
        value.forEach((v) => {
          array.push([key, v]);
        });
      }
    });

    return array;
  }

  // NOTE: the reason for these shenanigans is that nextjs
  // transforms query params to simple strings if only one
  // of the same key is present. And since we use this in
  // combination with next's router, we should try to
  // mimic that behavior to prevent further mistakes
  private static transformValues(values: string[]): ParamValue | null {
    if (values.length <= 0) {
      return null;
    }

    if (values.length === 1) {
      return values[0];
    }

    return values;
  }
}

export { QueryParam };
