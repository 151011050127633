import React from 'react';
import {
  DefaultAppToastIcon,
  ErrorAppToastIcon,
  InfoAppToastIcon,
  SuccessAppToastIcon,
  WarningAppToastIcon,
} from './AppToastIconset';

type Props = {
  type: 'success' | 'error' | 'info' | 'warning' | 'default';
  className?: string;
};

function AppToastIcon({ type, className = '' }: Props) {
  switch (type) {
    case 'success':
      return <SuccessAppToastIcon className={className} />;
    case 'error':
      return <ErrorAppToastIcon className={className} />;
    case 'info':
      return <InfoAppToastIcon className={className} />;
    case 'warning':
      return <WarningAppToastIcon className={className} />;
    default:
      return <DefaultAppToastIcon className={className} />;
  }
}

export { AppToastIcon };
