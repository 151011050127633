import { ToastContainer } from 'react-toastify';

const ToastProvider = () => {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        closeButton={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ boxSizing: 'border-box' }}
      />
    </>
  );
};

export { ToastProvider };
