type Props = {
  data?: string;
  hasBiggerValue: boolean;
  isLoading: boolean;
};
const Value = ({ data, hasBiggerValue, isLoading }: Props) => {
  return !isLoading ? (
    <div
      className={
        'font-semibold text-dark-800' +
        (hasBiggerValue ? ' text-base' : ' text-sm leading-5')
      }
    >
      {data}
    </div>
  ) : (
    <Fallback />
  );
};

const Fallback = () => {
  return (
    <div className="animate-pulse bg-light-800 rounded-md w-28 h-4 self-stretch"></div>
  );
};

export { Value };
