import { useQuery } from '@tanstack/react-query';
import { BookingGateway } from '../../../services/http/booking-gateway';
import { TravellerCount } from 'lib/types/traveller-count';
import { sessionIdService } from 'services/session-id/session-id.service';
import dayjs from 'dayjs';
import { QueryParam } from 'services/query-param/query-param';
import { useTenant } from '@/lib/hooks/useTenant';

type QueryError = {};

const timeRange = {
  gwat: {
    since: dayjs().subtract(2, 'week').unix(),
    until: dayjs().subtract(1, 'week').unix(),
  },
  dealsaway: {
    since: dayjs().subtract(3, 'week').unix(),
    until: dayjs().subtract(1, 'week').unix(),
  },
};

const useTravellerCountQuery = () => {
  const { isDA } = useTenant();

  const since = timeRange[isDA ? 'dealsaway' : 'gwat'].since;
  const until = timeRange[isDA ? 'dealsaway' : 'gwat'].until;

  const URL = `/public/config/bookings-count?since=${since}&until=${until}`;
  const sessionId = sessionIdService.get();

  const result = useQuery<TravellerCount, QueryError>(
    [URL, sessionId],
    async () => {
      const res = await BookingGateway.get<TravellerCount>(URL);
      return res;
    },
    {
      staleTime: Infinity,
      enabled: !!sessionId.length,
    }
  );

  return {
    ...result,
    shouldShow:
      QueryParam.getFirst('force-show') === 'social-proof' ||
      !!(result.data && result.data.result?.count > 9),
  };
};

export { useTravellerCountQuery };
