import { DonationButton } from './DonationButton';

type Props = {
  selected: number | null;
  setSelected: (value: number | null) => void;
  options: number[];
};

const DonationButtons = ({ selected, setSelected, options }: Props) => {
  return (
    <div className="grid grid-cols-2 md:flex gap-2.5">
      {options.map((o, index) => (
        <DonationButton
          key={index}
          value={o}
          setSelected={setSelected}
          isSelected={selected === o}
        />
      ))}
    </div>
  );
};

export { DonationButtons };
