import { useFlags } from 'flagsmith/react';
import { PaymentOption } from 'lib/types/payment';
import { useTenant } from './useTenant';

type Props = {
  isPromotionalEnabled: boolean;
  isStandardEnabled: boolean;
  isPayInFullEnabled: boolean;
};

const useRecommendedPaymentOption = ({
  isPromotionalEnabled,
  isStandardEnabled,
  isPayInFullEnabled,
}: Props): PaymentOption | null => {
  const allPaymentOptions = [
    isPromotionalEnabled,
    isStandardEnabled,
    isPayInFullEnabled,
  ];
  const enabledPaymentOptions = allPaymentOptions.filter((po) => po);

  const { isDA } = useTenant();

  const flags = useFlags(['Booking_Options_tag']);
  const flagEnabled = flags?.Booking_Options_tag?.enabled;
  const flagIsExperimental =
    flagEnabled && flags?.Booking_Options_tag?.value === 'new_version';

  if (!flagEnabled || !flagIsExperimental) return null;

  if (enabledPaymentOptions.length < 2) return null;

  if (isDA && isPayInFullEnabled) return 'trip_in_full';

  if (isStandardEnabled) return 'standard';
  if (isPayInFullEnabled) return 'trip_in_full';

  return null;
};

export { useRecommendedPaymentOption };
