import { Availability, Month } from 'lib/types/availability';
import { Radiobutton } from '../Form/Radiobutton/Radiobutton';
import { Tag } from './Tag';
import { getAvailabilityStatus, getComment, getLabel } from './helper';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { useCurrency } from 'lib/hooks/useCurrency';

export type Props = {
  availability: Month | Availability;
  isSelected: boolean;
  select: (availability: Month | Availability) => void;
  unit: string;
};

function AvailabilityCard({ availability, isSelected, select, unit }: Props) {
  const numberFormatter = useNumberFormatter();
  const { currency, currencyShouldBeShown } = useCurrency();
  const label = getLabel(availability);
  const tags = availability.tags;
  let computedTags = [...tags].filter((t) => t);

  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const modalHeaderElement =
      document.getElementById('modal-header-mobile') ||
      document.getElementById('modal-header-desktop');
    const modalFooterElement = document.getElementById('modal-footer');

    const modalViewPortTop = modalHeaderElement
      ? modalHeaderElement.getBoundingClientRect().bottom
      : 0;

    const modalViewPortBottom = modalFooterElement
      ? modalFooterElement.getBoundingClientRect().top - 20
      : 0;

    const element = event.target as HTMLDivElement;

    if (
      element.getBoundingClientRect().bottom > modalViewPortBottom ||
      element.getBoundingClientRect().top < modalViewPortTop
    ) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    select(availability);
  };

  const comment = getComment(availability);

  const isAvailable = getAvailabilityStatus(availability);

  if (!isAvailable) {
    computedTags = ['Sold out'];
  }

  return (
    <div
      onClick={clickHandler}
      className={
        'rounded-md p-2.5 flex flex-col items-stretch mt-2.5 bg-light-600 transition-colors' +
        (!isAvailable
          ? ' bg-light-800 pointer-events-none cursor-default '
          : '') +
        (!isAvailable && isSelected
          ? ' !border-dark-500 !shadow-primary-900'
          : '') +
        (isSelected
          ? ' border border-teal-900 shadow-primary-900 cursor-pointer '
          : ' border border-light-800 cursor-pointer ')
      }
    >
      <div className="flex flex-col gap-y-2.5  p-2.5">
        <div className="flex justify-between w-full">
          <Radiobutton
            id=""
            value=""
            name={label}
            isSelected={isSelected}
            label={label}
            labelClassName={!isAvailable ? 'text-dark-500' : ''}
            selectedOption={() => {}}
            isDisabled={!isAvailable}
          />
          <div>
            {unit === 'Days' && isAvailable ? (
              <div className="flex items-center justify-center">
                {numberFormatter.format(
                  (availability as Availability).duration.prices[currency]
                )}
                <>
                  {currencyShouldBeShown ? (
                    <span className="text-sm text-dark-700 font-medium ml-0.5 mb-px">
                      ({currency})
                    </span>
                  ) : null}
                </>
              </div>
            ) : null}
            {!isAvailable && computedTags.length > 0 ? (
              <div className="flex flex-row gap-0.5 md:gap-2.5 items-start">
                {computedTags.map((item, index) => (
                  <Tag key={index} text={item} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
        {computedTags.length > 0 && isAvailable ? (
          <div className="flex flex-row gap-0.5 md:gap-2.5 items-start">
            {computedTags.map((item, index) => (
              <Tag key={index} text={item} />
            ))}
          </div>
        ) : null}
      </div>
      {(isSelected || !isAvailable) && comment.length ? (
        <div
          className={
            'p-2.5 font-medium text-xs text-left rounded-md' +
            (isAvailable
              ? ' bg-teal-500 text-dark-700'
              : ' bg-light-900 text-dark-600')
          }
          dangerouslySetInnerHTML={{ __html: comment }}
        ></div>
      ) : null}
    </div>
  );
}

export { AvailabilityCard };
