import { Dispatch, SetStateAction, useEffect } from 'react';

import { Dropdown } from '../../../../Dropdown/Dropdown';
import { Input } from '../../../../Input/Input';
import { TDropDownItem } from '../../../../../lib/types/dropdown';
import { TValidation } from 'lib/types/traveller-form';
import { useDevice } from 'lib/hooks/useDevice';
import { usePhonePrefixQuery } from 'lib/react-query/Queries/usePhonePrefixQuery';
import { useUserLocation } from 'lib/hooks/useUserLocation';

type Props = {
  phonePrefix: TDropDownItem | null;
  setPhonePrefix: (value: TDropDownItem | null) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  phonePrefixValidation: TValidation;
  phoneNumberValidation: TValidation;
  defaultPhoneCode: string | null;
};

const Phone = ({
  phonePrefix,
  setPhonePrefix,
  phoneNumber,
  setPhoneNumber,
  phonePrefixValidation,
  phoneNumberValidation,
  defaultPhoneCode,
}: Props) => {
  const { isLoading, data } = usePhonePrefixQuery();
  const { isMobile } = useDevice();
  const location = useUserLocation();

  useEffect(() => {
    const userCountry = location ? location.country.code : null;
    if (data && defaultPhoneCode) {
      setPhonePrefix(
        data?.filter((item) => item.label === defaultPhoneCode)[0]
      );
    }
    //set by location
    if (data && !defaultPhoneCode) {
      if (userCountry) {
        setPhonePrefix(
          data?.filter(
            (item) => item.image === userCountry.toLocaleLowerCase()
          )[0]
        );
      } else {
        setPhonePrefix(data?.filter((item) => item.label === '+61')[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultPhoneCode, location]);

  return (
    <div>
      <div
        className={
          'md:border transition-all border-light-900 focus-within:md:border focus-within:border-teal-900 focus-within:md:shadow-primary-900 rounded-md flex items-center justify-between gap-5 md:gap-0' +
          (phoneNumberValidation.isValid
            ? ''
            : ' border-red-900 md:shadow-[4px_4px_4px_4px_#FE575410] focus-within:md:border-red-900 focus-within:md:shadow-primary-900 md:text-red-900')
        }
      >
        {!isLoading && data ? (
          <div
            className={
              'flex-grow ' +
              (!phoneNumberValidation.isValid &&
                isMobile &&
                ' border rounded-md border-red-900 shadow-[4px_4px_4px_4px_#FE575410] focus-within:border-red-900 focus-within:shadow-[4px_4px_4px_4px_#FE575410] text-red-900 ')
            }
          >
            {/* TODO: fix this casting */}
            <Dropdown
              options={data}
              selected={phonePrefix}
              setSelected={
                setPhonePrefix as Dispatch<SetStateAction<TDropDownItem | null>>
              }
              placeholder="Code"
              hasBorder={!!isMobile && !!phoneNumberValidation.isValid}
              hasFlagImage
              isFilterByValue
              isShowValue
            />
          </div>
        ) : null}
        <div className="hidden md:block w-px h-8 border-l border-light-900"></div>
        <div className="flex-grow">
          <Input
            placeholder="Mobile Number"
            value={phoneNumber}
            type="tel"
            changeInput={setPhoneNumber}
            hasBorder={!!isMobile}
            isError={isMobile && !phoneNumberValidation.isValid}
            inputId="phone-input"
          />
        </div>
      </div>
      {phoneNumberValidation && !phoneNumberValidation.isValid && (
        <div className="text-red-900 text-xs text-semibold mt-2 ml-1 whitespace-nowrap">
          {phoneNumberValidation.message}
        </div>
      )}
    </div>
  );
};
export { Phone };
