import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

class DateHelper {
  static getDifference(later: Dayjs, earlier: Dayjs) {
    return later.diff(earlier);
  }

  static getNow() {
    return dayjs(new Date());
  }

  static getNowUtc() {
    return dayjs(new Date()).utc();
  }

  static getTimezoneOffset() {
    const date = new Date();
    return date.getTimezoneOffset();
  }
}

export { DateHelper };
