import { useEffect, useRef, useState } from 'react';
import { TravellerForm } from './form';
import { TravellerSubmit } from './traveller-submit';
import { useFakeTraveller } from 'lib/hooks/useFakeTraveller';
import { ContinueCta } from 'components/MobileCta/Continue';
import { useDevice } from 'lib/hooks/useDevice';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { useFillTraveller } from 'lib/hooks/useFillTraveller';
import { TTravellerSubmit } from 'lib/types/traveller';
import { useSubmitTraveller } from 'lib/hooks/useSubmitTraveller';
import { ChangeDate as ChangeDateModal } from '../../../changeDate/ChangeDate';
import { SelectionAvailability } from 'lib/types/availability';
import { SelectedRoom } from 'lib/types/trip';
import { ValidationHelper } from 'lib/helper/validation/validationHelper';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useAppSelector } from 'state/hooks';

type AdditionalProps = {
  selectedRooms: SelectedRoom[];
};

const TravellerStage = ({
  submitCreateTraveller: submit,
  travellerState: state,
  createTravellerDispatch: dispatch,
  validateTraveller,
  travellerValidationState,
  travellerValidationDispatch,
  selectedRooms,
  isCreateTravellerLoading,
  createTravellerIsGotToGo,
}: TTravellerSubmit & AdditionalProps) => {
  const hasDepartureDate = useAppSelector(
    (state) => state.product.hasDepartureDate
  );
  const hasRestoredContext = useAppSelector(
    (state) => state.product.hasRestoredContext
  );
  const [isChooseDateOpen, setIsChooseDateOpen] = useState<boolean>(false);
  const { isMobile } = useDevice();
  const { isLoading: isRudderstackLoading, travellerPageInitiated } =
    useRudderStack();
  const travellerStageHasInitiated = useRef(false);
  const validationHelper = new ValidationHelper(travellerValidationDispatch);

  const [isEmailDisable, setIsEmailDisable] = useState(false);
  const [defaultPhoneCode, setDefaultPhoneCode] = useState<string | null>(null);
  const {
    isEnabled: fakeTravellerIsEnabled,
    isLoading: fakeTravellerIsLoading,
    fillWithFakeTraveller,
  } = useFakeTraveller({ dispatch });

  const {
    fillTraveller,
    isLoading: isFillTravellerLoading,
    hasTravellerData,
  } = useFillTraveller({
    dispatch,
    setDefaultPhoneCode,
    setIsEmailDisable,
  });
  const { submitTraveller } = useSubmitTraveller({
    state,
    validateTraveller,
    submit,
    selectedRooms,
  });
  const isTripQueryFetching = useIsFetching({ queryKey: ['trip'] });
  const isGotToGoLoading = useIsMutating({ mutationKey: ['got-to-go'] });
  const isGotToGoProcessing =
    !!isGotToGoLoading ||
    (createTravellerIsGotToGo && isCreateTravellerLoading);

  const bottomActionBarElement = document.getElementById('bottom-action-bar');
  const headerElement = document.getElementById('top-header');

  const viewportTop = headerElement
    ? headerElement.getBoundingClientRect().bottom
    : 0;
  const viewportBottom = bottomActionBarElement
    ? bottomActionBarElement.getBoundingClientRect().top
    : null;

  const scrollToInvalidField = () => {
    const inputErrors = [
      {
        id: 'first-name-input',
        isValid: validationHelper.checkFirstName(state.firstName),
      },
      {
        id: 'last-name-input',
        isValid: validationHelper.checkLastName(state.lastName),
      },
      {
        id: 'date-picker-container',
        isValid: validationHelper.checkDateOfBirth(state.date),
      },
      {
        id: 'phone-input',
        isValid: validationHelper.checkPhoneNumber(
          state.phoneNumber,
          state.phonePrefix?.value
        ),
      },
      {
        id: 'email-input',
        isValid: validationHelper.checkEmail(state.email),
      },
    ];

    const firstInvalidInput = inputErrors.find((i: any) => !i?.isValid);

    if (firstInvalidInput) {
      const inputElement = document.getElementById(firstInvalidInput.id);

      if (
        (inputElement &&
          inputElement.getBoundingClientRect().top < viewportTop) ||
        (inputElement &&
          viewportBottom &&
          inputElement.getBoundingClientRect().bottom > viewportBottom)
      ) {
        inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const clickHandler = () => {
    scrollToInvalidField();

    if (hasDepartureDate) {
      submitTraveller();
    } else {
      setIsChooseDateOpen(true);
    }
  };

  const afterSubmitModal = (
    flexibleDeparture: boolean,
    selectedAvailability: SelectionAvailability
  ) => {
    submitTraveller(flexibleDeparture, selectedAvailability);
  };

  const closeChooseDateModal = () => setIsChooseDateOpen(false);

  useEffect(() => {
    if (isRudderstackLoading || !hasRestoredContext) return;

    if (!travellerStageHasInitiated.current) {
      travellerPageInitiated();
      travellerStageHasInitiated.current = true;
    }
    fillTraveller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRudderstackLoading, hasRestoredContext]);
  return (
    <>
      <div className="bg-light-600 md:border border-light-800 md:shadow-[0px_5px_5px_0px_#17181a0d] rounded-md">
        <TravellerForm
          state={state}
          dispatch={dispatch}
          validationState={travellerValidationState}
          validationDispatch={travellerValidationDispatch}
          isEmailDisable={isEmailDisable}
          defaultPhoneCode={defaultPhoneCode}
        />
        <div>
          <hr className="hidden md:block border-none h-px w-full bg-light-800" />
          <TravellerSubmit
            clickHandler={clickHandler}
            isDisabled={
              isFillTravellerLoading ||
              !!isTripQueryFetching ||
              isGotToGoProcessing
            }
            isLoading={false}
            fillWithFakeTraveller={fillWithFakeTraveller}
            hasFakeTravellerButton={fakeTravellerIsEnabled && !hasTravellerData}
            fakeTravellerIsLoading={fakeTravellerIsLoading}
            submitButtonText={hasDepartureDate ? 'Continue' : 'Set Date'}
          />
        </div>

        {isMobile && (
          <div className="fixed z-50 bottom-0 left-0 right-0 bg-light-600">
            <ContinueCta
              submitHandler={clickHandler}
              isDisabled={
                isFillTravellerLoading ||
                !!isTripQueryFetching ||
                isGotToGoProcessing
              }
              isLoading={false}
              submitButtonText={hasDepartureDate ? 'Continue' : 'Set Date'}
            />
          </div>
        )}
      </div>
      <ChangeDateModal
        isOpen={isChooseDateOpen}
        close={closeChooseDateModal}
        afterSubmit={afterSubmitModal}
      />
    </>
  );
};

function isInViewport(elem: any) {
  var distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

export { TravellerStage };
