import { withErrorBoundary } from 'lib/HOC/withErrorBoundary/withErrorBoundary';
import { useDevice } from 'lib/hooks/useDevice';
import { usePromotionOperations } from 'lib/hooks/usePromotionOperations';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import { PaymentDtailsFallback } from './PaymentDetails.fallback';
import { PaymentDetailsDesktop } from './PaymentDetailsDesktop';
import { PaymentDetailsMobile } from './PaymentDetailsMobile';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { PromotionsQueryResult } from 'lib/types/promotion';
import { useState } from 'react';
import { AppToastService } from 'components/Toast/AppToastService';
import * as Sentry from '@sentry/react';
import { useIsFetching } from '@tanstack/react-query';

type Props = {
  isMobileViewOpen: boolean;
  closeMobileView: () => void;
  forceMobileView?: boolean;
  hasAltMobileDesign?: boolean;
};

const PaymentDetailsComponent = ({
  isMobileViewOpen,
  closeMobileView,
  forceMobileView = false,
  hasAltMobileDesign = false,
}: Props) => {
  const { isDesktop } = useDevice();
  const { isLoading, data, refetch, isFetching } = usePromotionsQuery();
  const { couponEntered, couponRemoved, couponApplied, couponDenied } =
    useRudderStack();
  const isTripQueryFetching = useIsFetching({ queryKey: ['trip'] });
  const [errorMessagePromo, setErrorMessagePromo] = useState('');

  const callback = (
    success: boolean,
    code: string,
    data: PromotionsQueryResult | string
  ) => {
    if (success) {
      const srPromotions = (data as PromotionsQueryResult).result
        .applied_promotions;
      const promotionsData = (data as PromotionsQueryResult).result.promotions;
      const fullPromoArray = [
        ...srPromotions,
        ...(promotionsData.deposit?.items ?? []),
        ...(promotionsData.standard?.items ?? []),
        ...(promotionsData.trip_in_full?.items ?? []),
      ];
      const promoObj = fullPromoArray.filter(
        (item) => item.code.toLowerCase() === code.toLowerCase()
      )[0];
      if (promoObj) {
        couponApplied(code, promoObj.id, promoObj.item_name);
      } else {
        AppToastService.error(
          `Couldn't add promotion ${code} due to an unexcpected error.`
        );
        Sentry.captureException(`Adding promotion ${code} failed.`, {
          tags: {
            query: 'add promotion',
          },
          extra: {
            description:
              'Provided code did not exist in response. False positive success response value.',
            code,
          },
        });
        couponDenied(code, 0, null, data as string);
      }
    } else {
      couponDenied(code, 0, null, data as string); //TODO: we don't have promoID and promoName, so if it's necessary we should ask gateway
      setErrorMessagePromo(data.toString() || "Couldn't add promotion");
    }
  };

  const {
    submitAddPromotion,
    submitRemovePromotion,
    addIsLoading,
    removeIsLoading,
    eitherAddOrRemoveAreLoading,
  } = usePromotionOperations({ callback });

  const addPromotion = (code: string) => {
    couponEntered(code);
    submitAddPromotion(code);
  };

  const removePromotion = (code: string) => {
    submitRemovePromotion(code);
    couponRemoved(code);
  };

  return (
    <>
      {isDesktop || forceMobileView ? (
        <PaymentDetailsDesktop
          isLoading={isLoading || isFetching || !!isTripQueryFetching}
          promotions={data?.result}
          addPromotion={addPromotion}
          removePromotion={removePromotion}
          addingPromotionLoading={addIsLoading}
          removingPromotionLoading={removeIsLoading}
          eitherAddOrRemoveAreLoading={eitherAddOrRemoveAreLoading}
          refetch={refetch}
          hasAltMobileDesign={hasAltMobileDesign}
          errorMessagePromo={errorMessagePromo}
          setErrorMessagePromo={setErrorMessagePromo}
        />
      ) : (
        <PaymentDetailsMobile
          isLoading={isLoading || isFetching || !!isTripQueryFetching}
          isOpen={isMobileViewOpen}
          close={closeMobileView}
          promotions={data?.result}
          addPromotion={addPromotion}
          removePromotion={removePromotion}
          addingPromotionLoading={addIsLoading}
          removingPromotionLoading={removeIsLoading}
          eitherAddOrRemoveAreLoading={eitherAddOrRemoveAreLoading}
          refetch={refetch}
          hasAltMobileDesign={hasAltMobileDesign}
          errorMessagePromo={errorMessagePromo}
          setErrorMessagePromo={setErrorMessagePromo}
        />
      )}
    </>
  );
};

export const PaymentDetails = withErrorBoundary({
  Component: PaymentDetailsComponent as any,
  Fallback: PaymentDtailsFallback,
});
