type Props = {
  color: string;
};

export const ArrowLeft = ({ color }: Props) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0303 9.46967C18.3232 9.76256 18.3232 10.2374 18.0303 10.5303L13.5607 15L18.0303 19.4697C18.3232 19.7626 18.3232 20.2374 18.0303 20.5303C17.7374 20.8232 17.2626 20.8232 16.9697 20.5303L11.9697 15.5303C11.6768 15.2374 11.6768 14.7626 11.9697 14.4697L16.9697 9.46967C17.2626 9.17678 17.7374 9.17678 18.0303 9.46967Z"
        fill={color}
      />
    </svg>
  );
};
