type Props = {
  color: string;
  style: any;
  width: string;
  height: string;
};

const StarOutline = ({ color, style, width, height }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.19995C12.3026 2.19995 12.5793 2.37067 12.715 2.64111L15.3442 7.88004L21.099 8.59811C21.4006 8.63574 21.6549 8.84105 21.7552 9.12798C21.8555 9.41491 21.7845 9.73389 21.572 9.95123L17.4784 14.1381L18.6058 19.8449C18.6644 20.1415 18.5508 20.4457 18.3122 20.6314C18.0736 20.817 17.7508 20.8523 17.4777 20.7226L12 18.1206L6.52222 20.7226C6.24916 20.8523 5.92632 20.817 5.68773 20.6314C5.44914 20.4457 5.33555 20.1415 5.39414 19.8449L6.52152 14.1381L2.42795 9.95123C2.21546 9.73389 2.14448 9.41491 2.24478 9.12798C2.34507 8.84105 2.5993 8.63574 2.90092 8.59811L8.65572 7.88004L11.285 2.64111C11.4207 2.37067 11.6974 2.19995 12 2.19995ZM12 4.78348L9.89398 8.97979C9.77416 9.21853 9.54309 9.38172 9.27803 9.4148L4.69969 9.98607L7.95999 13.3207C8.14429 13.5092 8.2239 13.7764 8.1728 14.035L7.27196 18.5951L11.6567 16.5123C11.8739 16.4092 12.126 16.4092 12.3432 16.5123L16.728 18.5951L15.8271 14.035C15.776 13.7764 15.8557 13.5092 16.0399 13.3207L19.3003 9.98607L14.7219 9.4148C14.4569 9.38172 14.2258 9.21853 14.106 8.97979L12 4.78348Z"
        fill={color}
      />
    </svg>
  );
};

export { StarOutline };
