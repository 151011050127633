const TripInfoFallback = () => {
  return (
    <div className="flex w-full h-[29.37rem] border border-light-800 rounded-md justify-center items-center">
      <div>An error has occured!</div>
    </div>
  );
};

const TripInfoLoadingFallback = () => {
  return (
    <div className="flex w-full h-[11.25rem] md:h-[29.37rem] border border-light-800 rounded-md bg-light-600 flex-col p-5">
      <div className="hidden md:block h-8 w-3/4 rounded-full bg-light-800 animate-pulse mb-6"></div>
      <div className="h-28 w-full rounded-3xl bg-light-800 animate-pulse mb-6"></div>
      <div className="h-5 w-1/2 rounded-3xl bg-light-800 animate-pulse md:mb-5"></div>
      <div className="hidden md:block">
        <div className="h-8 w-1/4 rounded-3xl bg-light-800 animate-pulse mb-9"></div>
        <div className="h-5 w-1/4 rounded-3xl bg-light-800 animate-pulse mb-5"></div>
        <div className="h-2.5 w-3/4 rounded-3xl bg-light-800 animate-pulse mb-2.5"></div>
        <div className="h-2.5 w-1/3 rounded-3xl bg-light-800 animate-pulse mb-9"></div>
        <div className="h-8 w-1/4 rounded-3xl bg-light-800 animate-pulse"></div>
      </div>
    </div>
  );
};

export { TripInfoFallback, TripInfoLoadingFallback };
