import { CheckMark } from 'assets/icons/checkmark';
import { useDevice } from 'lib/hooks/useDevice';
import { PaymentOption, descriptonT } from 'lib/types/payment';
import { Checkbox } from '../../../../Form/Checkbox/Checkbox';
import { PaymentOptionPrice } from './payment-option-price';
import { PaymentOptionPopup } from './payment-option-popup';

type Props = {
  id: PaymentOption;
  isEnabled: boolean;
  isSelected: boolean;
  setIsSelected?: (option: PaymentOption) => void;
  type: string;
  descriptions?: descriptonT[];
  price: {
    full?: number;
    final: number;
  };
  hasMorePaddingInType?: boolean;
  showPaidInsteadOfZero?: boolean;
  isLoading: boolean;
  isRecommended?: boolean;
};

const PaymentOptionComponent = ({
  id,
  isEnabled,
  isSelected,
  setIsSelected,
  type,
  descriptions,
  price,
  hasMorePaddingInType = false,
  showPaidInsteadOfZero = true,
  isLoading,
  isRecommended = false,
}: Props) => {
  const { isDesktop, isMobile } = useDevice();
  const clickHandler = () => isEnabled && setIsSelected && setIsSelected(id);

  return (
    <div
      onClick={clickHandler}
      className={
        'relative grid  items-center md:px-5 bg-light-600 border rounded-md transition-colors  overflow-hidden' +
        (!descriptions && isMobile
          ? ' grid-cols-[3fr_2fr_1fr]'
          : ' grid-cols-[1.7fr_0.05fr_1fr] md:grid-cols-[0.9fr_1.5fr_0fr_0.7fr]') +
        (!isEnabled ? ' bg-light-800' : ' cursor-pointer') +
        (isSelected
          ? ' border-teal-900 shadow-primary-900'
          : ' border-light-900') +
        (isRecommended ? ' pt-2.5 md:pt-0' : '')
      }
    >
      {isRecommended ? (
        <div className="absolute top-0 left-0 text-[#D97706] text-xs leading-3 font-semibold py-1.5 px-2.5 bg-[#FFF2DF] rounded-br-md">
          Recommended
        </div>
      ) : null}
      <div
        className={
          'flex items-center gap-2.5 order-1 ml-5 my-5 md:m-0' +
          (descriptions ? '' : ' col-span-2')
        }
      >
        <Checkbox
          id={`payment-option-${id}`}
          isChecked={isSelected}
          toggleChecked={() => {}}
          isEnabled={isEnabled}
        />
        <p
          className={
            'font-semibold text-sm' +
            (hasMorePaddingInType ? ' md:my-8' : '') +
            (isEnabled ? '' : ' !text-dark-500') +
            (isSelected ? ' text-teal-900' : ' text-dark-800')
          }
          dangerouslySetInnerHTML={{ __html: type }}
        ></p>
      </div>
      {descriptions ? (
        <div
          className={
            'flex flex-col gap-2 order-4 md:order-2 md:my-4 p-5 md:p-0 col-span-3 md:col-span-1  md:bg-opacity-0 border-t border-light-800 md:border-none' +
            (!isEnabled ? ' bg-light-800' : ' bg-light-700')
          }
        >
          {descriptions.map((desc, index) => (
            <div className="flex gap-1 items-center" key={index}>
              <div className="w-2.5 h-2.5">
                <CheckMark color={isEnabled ? '#575B63' : '#9CA0A8'} />
              </div>
              <div
                className={
                  'inline-block text-[0.8125rem] leading-4' +
                  (isEnabled ? ' text-dark-700 ' : ' text-dark-500') +
                  (desc.isBold ? ' font-bold ' : ' font-medium ')
                }
              >
                {desc.text}
                {desc.popup && (
                  <PaymentOptionPopup content={desc.popup.content} />
                )}
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {(descriptions || isDesktop) && (
        <div className="self-stretch w-px border-l border-dashed border-dark-500 border-opacity-50 order-2 md:order-3 my-5 md:my-0"></div>
      )}
      <PaymentOptionPrice
        isEnabled={isEnabled}
        isLoading={isLoading}
        price={price}
        showPaidInsteadOfZero={showPaidInsteadOfZero}
      />
    </div>
  );
};

export { PaymentOptionComponent };
