import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isCentered?: boolean;
  gap?: number;
};

const LineContainer = ({ children, isCentered = false, gap = 1 }: Props) => {
  return (
    <div
      className={
        'flex items-center' +
        (isCentered ? ` justify-center gap-${gap} ` : ' justify-between')
      }
    >
      {children}
    </div>
  );
};

export { LineContainer };
