import dayjs from 'dayjs';
import {
  getEmailValidation,
  getNumberValidation,
  getStringValidation,
} from 'lib/helper/validation/validation';
import {
  ReferralFormT,
  TReferralFormValidationAction,
} from 'lib/types/referral';
import { TValidation } from 'lib/types/traveller-form';
import { Dispatch } from 'react';

type Props = {
  state: ReferralFormT;
  validationDispatch: Dispatch<TReferralFormValidationAction>;
};

const useReferralValidation = ({ state, validationDispatch }: Props) => {
  const setFirstNameValidation = (value: TValidation) =>
    validationDispatch({ type: 'firtNameValidation', value });

  const setLastNameValidation = (value: TValidation) =>
    validationDispatch({ type: 'lastNamevalidation', value });

  const setPhoneNumberValidation = (value: TValidation) =>
    validationDispatch({ type: 'phoneNumberValidation', value });

  const setEmailValidation = (value: TValidation) =>
    validationDispatch({ type: 'emailValidation', value });

  const setLocationValidation = (value: TValidation) =>
    validationDispatch({ type: 'locationValidation', value });

  const checkFirstName = () => {
    if (state.firstName.length === 0) {
      setFirstNameValidation({
        isValid: false,
        message: `Please enter your friend's first name.`,
      });
      return false;
    }

    if (state.firstName.length > 0 && !getStringValidation(state.firstName)) {
      setFirstNameValidation({
        isValid: false,
        message: "Please enter your friend's valid first name.",
      });
      return false;
    } else if (
      state.firstName.length > 0 &&
      getStringValidation(state.firstName)
    ) {
      setFirstNameValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  };

  const checkLastName = () => {
    if (state.lastName.length === 0) {
      setLastNameValidation({
        isValid: false,
        message: `Please enter your friend's last name.`,
      });
      return false;
    }

    if (state.lastName.length > 0 && !getStringValidation(state.lastName)) {
      setLastNameValidation({
        isValid: false,
        message: "Please enter your friend's valid last name.",
      });
      return false;
    } else if (
      state.lastName.length > 0 &&
      typeof state.lastName === 'string'
    ) {
      setLastNameValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  };

  const checkPhoneNumber = () => {
    if (!state.phonePrefix || state.phonePrefix?.value.length === 0) {
      setPhoneNumberValidation({
        isValid: false,
        message: `Please select your friend's phone code.`,
      });
      return false;
    }
    if (
      state.phonePrefix &&
      state.phonePrefix?.value.length > 0 &&
      state.phoneNumber.length === 0
    ) {
      setPhoneNumberValidation({
        isValid: false,
        message: `Please select your friend's phone number.`,
      });
      return false;
    }

    if (
      state.phonePrefix &&
      state.phonePrefix?.value.length > 0 &&
      state.phoneNumber.length > 0 &&
      !getNumberValidation(state.phoneNumber)
    ) {
      setPhoneNumberValidation({
        isValid: false,
        message: `Please enter your friend's valid phone number.`,
      });
      return false;
    } else if (
      state.phonePrefix &&
      state.phonePrefix?.value.length > 0 &&
      state.phoneNumber.length > 0 &&
      getNumberValidation(state.phoneNumber)
    ) {
      setPhoneNumberValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  };

  const checkEmail = () => {
    if (state.email.length === 0) {
      setEmailValidation({
        isValid: false,
        message: `Please enter your friend's email.`,
      });
      return false;
    }

    if (state.email.length > 0 && !getEmailValidation(state.email)) {
      setEmailValidation({
        isValid: false,
        message: `Please enter your friend's valid email.`,
      });
      return false;
    } else if (state.email.length > 0 && getEmailValidation(state.email)) {
      setEmailValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  };

  const checkLocation = () => {
    console.log('LOCATION', !state.location);
    if (!state.location || state.location?.value.length === 0) {
      setLocationValidation({
        isValid: false,
        message: `Please enter your friend's location.`,
      });
      return false;
    } else {
      setLocationValidation({
        isValid: true,
        message: '',
      });
      return true;
    }
  };
  const checkFieldsValidation = () => {
    const firtNameResult = checkFirstName();
    const lastNameResult = checkLastName();
    const phoneNumberResult = checkPhoneNumber();
    const emailResult = checkEmail();
    const locationResult = checkLocation();
    return (
      firtNameResult &&
      lastNameResult &&
      phoneNumberResult &&
      emailResult &&
      locationResult
    );
  };

  return {
    checkFieldsValidation,
  };
};

export { useReferralValidation };
