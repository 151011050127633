import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ProductQueryResult,
  submitUpdateProductProps,
  UpdateProductPayload,
  UpdateProductQueryResult,
} from 'lib/types/product';
import { BookingGateway } from 'services/http/booking-gateway';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import { QueryError } from 'lib/types/utility';
import { sessionIdService } from 'services/session-id/session-id.service';

const useUpdateProductQuery = () => {
  const URL = '/public/booking/product';
  const sessionId = sessionIdService.get();

  const queryClient = useQueryClient();

  const query = useMutation<
    UpdateProductQueryResult,
    QueryError,
    UpdateProductPayload
  >(
    ['update-product', sessionId],
    async (payload: UpdateProductPayload) => {
      const response = await BookingGateway.put<UpdateProductQueryResult>(
        URL,
        payload
      );
      return response;
    },
    {
      onSuccess: (data) => {
        // since we use price on product query for retail price
        // and every other price from the promotions query
        const productData: ProductQueryResult = {
          success: true,
          result: data.result.product,
        };
        queryClient.setQueryData(['product', sessionId], productData);
        queryClient.invalidateQueries({ queryKey: ['promotions'] });
      },
    }
  );

  const submitUpdateProduct = ({
    availability,
    duration_id,
    rooms,
    is_departure_flexible,
    travellersNumber,
  }: submitUpdateProductProps) => {
    const availability_id =
      availability && 'availability_id' in availability
        ? availability.availability_id
        : null;
    const departure_date = AvailabilityHelper.getDepartureDate(availability);

    let payload: UpdateProductPayload = {
      availability_id,
      duration_id,
      departure_date,
      is_departure_flexible,
      inclusions: rooms,
      travellers_number: travellersNumber,
    };

    if (availability && !('availability_id' in availability)) {
      delete payload['availability_id'];
    }

    if (payload.is_departure_flexible) {
      payload.departure_date = null;
    }

    query.mutate(payload);
  };

  return {
    isLoading: query.isLoading,
    data: query.data,
    isError: query.isError,
    submitUpdateProduct,
    error: query.error,
  };
};

export { useUpdateProductQuery };
