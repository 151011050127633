const loggers = new Set<string>();

function log(
  type: string,
  message: string,
  logFunction: (message: string) => void
) {
  if (!loggers.has(type)) {
    logFunction(message);
    loggers.add(type);
  }
}

export function logOnce(type: string, message: string) {
  log(type, message, console.log);
}

logOnce.Error = (type: string, message: string) => {
  log(type, message, console.error);
};

logOnce.Warn = (type: string, message: string) => {
  log(type, message, console.warn);
};

logOnce.Info = (type: string, message: string) => {
  log(type, message, console.info);
};

logOnce.Log = logOnce;

// Clear the logOnce list
logOnce.Clear = () => {
  loggers.clear();
};

// Remove a log type from the logOnce list
logOnce.Remove = (type: string) => {
  loggers.delete(type);
};
