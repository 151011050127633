import { Image } from 'components/Image/Image';
import { ReviewPlatform } from 'lib/types/review';

type Props = {
  data: ReviewPlatform;
  omitSeparator?: boolean;
};

const Review = ({ data, omitSeparator = false }: Props) => {
  const isReviewsIO = data.platform === 'reviews.io';
  const isConssumer = data.platform === 'consumer-affairs';
  const isTrustpilot = data.platform === 'trustpilot';
  const isGoogle = data.platform === 'google';

  let height = 'h-7';
  if (isReviewsIO) height = 'h-5';
  if (isTrustpilot) height = 'h-6 md:h-[1.625rem]';
  if (isConssumer) height = 'h-3 md:h-[0.875rem]';
  if (isGoogle) height = 'h-[1.375rem] md:h-6';

  return (
    <div className="flex flex-col md:flex-row">
      <div
        className={
          'flex md:flex-col md:gap-2.5 justify-center items-center h-[3.5rem] md:h-8 ' +
          (isReviewsIO &&
            ' border-r border-b border-light-800 md:border-none ') +
          (isTrustpilot && ' border-b border-light-800 md:border-none ') +
          (isConssumer && ' border-r border-light-800 md:border-none')
        }
      >
        <div className={'max-w-[10rem] ' + height}>
          <Image
            path={`booking/finish-page/reviews/${data.platform}.svg`}
            alt={`logo of ${data.platform}`}
            className="max-h-full inline-flex items-center !object-contain"
            wrapperClassName="min-w-full flex flex-col justify-center"
          />
        </div>
      </div>
    </div>
  );
};

export { Review };
