import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedRoom, SelectedRoomFull } from 'lib/types/trip';

type RoomState = {
  rawRooms: SelectedRoom[];
  rooms: SelectedRoomFull[];
  noOfTravellers: number;
};

const initialState: RoomState = {
  rawRooms: [],
  rooms: [],
  noOfTravellers: 0,
};

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    setRawRooms(state, action: PayloadAction<SelectedRoom[]>) {
      state.rawRooms = action.payload;
    },
    setRooms(state, action: PayloadAction<SelectedRoomFull[]>) {
      state.rooms = action.payload;
    },
    setNoOfTravellers(state, action: PayloadAction<number>) {
      state.noOfTravellers = action.payload;
    },
  },
});

export const { setRawRooms, setRooms, setNoOfTravellers } = roomSlice.actions;
export default roomSlice.reducer;
