import {
  TravellerFormValidation,
  TTravellerFormValidationAction,
} from 'lib/types/traveller';

export const userValidationReducer = (
  state: TravellerFormValidation,
  action: TTravellerFormValidationAction
) => {
  switch (action?.type) {
    case 'firtNameValidation':
      return { ...state, firtNameValidation: action.value };
    case 'lastNamevalidation':
      return { ...state, lastNamevalidation: action.value };
    case 'phonePrefixValidation':
      return { ...state, phonePrefixValidation: action.value };
    case 'phoneNumberValidation':
      return { ...state, phoneNumberValidation: action.value };
    case 'emailValidation':
      return { ...state, emailValidation: action.value };
    case 'dateValidation':
      return { ...state, dateValidation: action.value };
    default:
      return state;
  }
};
