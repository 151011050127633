import * as Sentry from '@sentry/browser';
import { TripQueryResult } from '../../types/trip';
import { useQuery } from '@tanstack/react-query';
import { BookingGateway } from '../../../services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

const useTripQuery = () => {
  const URL = '/public/inventory/trip';

  const sessionId = sessionIdService.get();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  const result = useQuery(
    ['trip', sessionId],
    async () => {
      const res = await BookingGateway.get<TripQueryResult>(URL);

      Sentry.configureScope((scope) => {
        scope.setExtras({
          trip: {
            id: res.result.trip.id,
            name: res.result.trip.trip_name,
            isFlexible: res.result.trip.flexible_departure,
          },
        });
      });
      return res;
    },
    {
      staleTime: Infinity,
      enabled: !!sessionId.length && currencyHasBeenSet,
      retry: 3,
    }
  );
  return { ...result, hasLoaded: !result.isLoading && !!result.data };
};

export default useTripQuery;
