const BannerFallback = () => {
  return (
    <div className="flex flex-col items-center md:px-28">
      <div className="w-64 h-7 rounded-full bg-light-900 animate-pulse mb-5"></div>
      <div className="w-80 md:w-96 h-4 rounded-full bg-light-900 animate-pulse mb-2.5"></div>
      <div className="w-52 md:w-64 h-4 rounded-full bg-light-900 animate-pulse"></div>
    </div>
  );
};

export { BannerFallback };
