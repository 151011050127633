import { Radiobutton } from 'components/Form/Radiobutton/Radiobutton';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  tempFlexibleDeparture: boolean;
  setTempFlexibleDeparture: Dispatch<SetStateAction<boolean>>;
};

const FlexibleDeparture = ({
  tempFlexibleDeparture,
  setTempFlexibleDeparture,
}: Props) => {
  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const modalHeaderElement =
      document.getElementById('modal-header-mobile') ||
      document.getElementById('modal-header-desktop');
    const modalFooterElement = document.getElementById('modal-footer');

    const modalViewPortTop = modalHeaderElement
      ? modalHeaderElement.getBoundingClientRect().bottom
      : 0;

    const modalViewPortBottom = modalFooterElement
      ? modalFooterElement.getBoundingClientRect().top - 20
      : 0;

    const element = event.target as HTMLDivElement;

    if (
      element.getBoundingClientRect().bottom > modalViewPortBottom ||
      element.getBoundingClientRect().top < modalViewPortTop
    ) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setTempFlexibleDeparture(true);
  };

  const isSelected = tempFlexibleDeparture;

  return (
    <div
      onClick={clickHandler}
      className={
        'h-[3.75rem] rounded-md p-5 flex items-center justify-start bg-light-600 ' +
        (isSelected
          ? ' border border-teal-900 shadow-primary-900 cursor-pointer '
          : ' border border-light-800 cursor-pointer ')
      }
    >
      <Radiobutton
        id=""
        value=""
        name="flexible-departure"
        isSelected={isSelected}
        label=""
        selectedOption={() => {}}
      />
      <div className="flex flex-col items-start">
        <div className="font-semibold text-xs text-dark-800">Not sure yet?</div>
        <div className="font-semibold text-xs text-dark-700">
          Book now, choose dates later
        </div>
      </div>
    </div>
  );
};

export { FlexibleDeparture };
