import { useTenantTheme } from 'lib/hooks/useTenantTheme';
import React, { FormEvent } from 'react';
import { CheckMark } from '../../assets/icons/checkmark';

export type Props = {
  placeholder: string;
  inputId?: string;
  isError?: boolean;
  ErrorMessage?: string;
  isSuccess?: boolean;
  type?: string;
  value: string;
  changeInput: (input: string) => void;
  Button?: JSX.Element;
  hasBorder?: boolean;
  mouseLeave?: (input: string) => void;
  isDisable?: boolean;
  tabIndex?: number;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

function Input({
  placeholder,
  inputId,
  isError = false,
  ErrorMessage,
  isSuccess = false,
  type = 'text',
  value,
  changeInput,
  Button,
  hasBorder = true,
  isDisable = false,
  mouseLeave,
  tabIndex,
  onKeyUp,
}: Props) {
  const ref = React.useRef<HTMLInputElement>(null);
  const theme = useTenantTheme();

  const changeHandler = (e: FormEvent<HTMLInputElement>) => {
    changeInput(e.currentTarget.value);
  };

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) {
      onKeyUp(e);
    }
  };

  return (
    <div className="text-left">
      <div
        className={
          'relative user-input rounded-md flex items-center transition-all  bg-light-600' +
          (hasBorder
            ? ` border border-light-900 focus-within:border focus-within:border-teal-900 focus-within:shadow-primary-900`
            : '') +
          (isError && hasBorder
            ? ' border-red-900 shadow-[4px_4px_4px_4px_#FE575410] focus-within:border-red-900 focus-within:shadow-[4px_4px_4px_4px_#FE575410] text-red-900 '
            : '') +
          (isSuccess && hasBorder
            ? ' border-green-900 shadow-[4px_4px_4px_4px_#1AE68C10] focus-within:border-green-900 focus-within:shadow-[4px_4px_4px_4px_#1AE68C10]'
            : '')
        }
      >
        <input
          tabIndex={tabIndex}
          data-testid="input"
          ref={ref}
          type={type}
          value={value}
          className={
            'peer border-none flex-grow w-full pl-4 pr-2.5 py-5 focus:pt-[1.875rem] focus:pb-[0.625rem] pt-[1.875rem] pb-[0.625rem] flex items-center justify-center leading-4 text-dark-800 text-sm font-semibold focus:outline-none rounded-md '
          }
          onChange={changeHandler}
          disabled={isDisable}
          onBlur={(e: any) => (mouseLeave ? mouseLeave(e) : '')}
          onKeyUp={keyUpHandler}
          {...(inputId && { id: inputId })}
        />
        {Button}
        <span
          data-testid="placeholder"
          onClick={() => ref.current && ref.current.focus()}
          className={
            'absolute text-dark-600  text-sm leading-4 transition-all font-semibold ' +
            (isError && ' text-red-900') +
            (value.length
              ? ' !text-xs top-2.5 left-4 text-opacity-75'
              : ' top-5 left-4 peer-focus:top-2.5 peer-focus:text-xs peer-focus:text-opacity-75')
          }
        >
          {placeholder}
        </span>
        {isSuccess && (
          <div className="absolute w-[1.5rem] h-[1.5rem] rounded-full bg-green-800 top-4 right-4 flex justify-center items-center">
            <div className="w-4">
              <CheckMark color="#1AE68C" />
            </div>
          </div>
        )}
      </div>
      {isError && ErrorMessage && (
        <span
          data-testid="error-message"
          className="text-red-900 text-xs text-semibold mt-5 ml-1 whitespace-nowrap"
        >
          {ErrorMessage}
        </span>
      )}
    </div>
  );
}

export { Input };
