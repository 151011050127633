declare const rudderanalytics: any;

export const RudderStackPage = (path: string, url: string) => {
  if (typeof rudderanalytics === 'undefined') return;

  rudderanalytics.page(path, { path, url });
  console.debug('Page', { path, url });
};

export const RudderStackTrack = (key: any, data: any): void => {
  if (typeof rudderanalytics !== 'undefined') {
    rudderanalytics.track(key, data);
    console.debug(key, data);
  }
};

export const RudderStackSetUser = (userId: string, user: any): void => {
  typeof rudderanalytics !== 'undefined' &&
    rudderanalytics.identify(userId, user);
};

export const RudderStackGetUser = (): string => {
  return rudderanalytics.getUserId();
};
