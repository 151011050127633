import { TimerIcon } from 'assets/icons/Timer';
import { Timer } from './Timer';
import { useTimer } from './useTimer';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ExpiredModal } from './ExpiredModal';

const SessionTimer = () => {
  const duration = 20 * 60 * 1000; // 20 mins
  const urgentThreshold = 11 * 60 * 1000; // 11 mins
  const { time } = useTimer(duration, urgentThreshold);
  const formattedTime = dayjs.duration(time).format('mm:ss');
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState<boolean>(false);
  const isUrgent = true;
  const [isPinned, setIsPinned] = useState<boolean>(false);

  useEffect(() => {
    if (time <= 0) {
      setIsExpiredModalOpen(true);
    }
  }, [time]);

  useEffect(() => {
    const timerIntersector = document.querySelector(
      '#payment-timer-intersector'
    );
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.isIntersecting) {
          setIsPinned(false);
        } else {
          setIsPinned(true);
        }
      },
      { threshold: 0 }
    );

    if (timerIntersector) observer.observe(timerIntersector);

    return () => {
      if (timerIntersector) observer.unobserve(timerIntersector);
    };
  }, []);

  return (
    <>
      <ExpiredModal isOpen={isExpiredModalOpen} />
      <div
        className={
          'sm:sticky sm:top-[5rem]  sm:z-30 sm:w-[23.125rem] sm:bg-light-600 sm:border-l sm:border-r' +
          (isPinned
            ? ' sm:rounded-b-md sm:border-b sm:border-red-600'
            : ' sm:rounded-t-md sm:border-t  border-light-800')
        }
      >
        <div id="payment-timer-intersector" className="absolute -top-24"></div>
        <div
          className={
            'border-t md:border-none border-opacity-20  ' +
            (isUrgent ? ' border-red-900' : ' border-teal-900 ')
          }
        >
          <div
            className={
              'flex h-[3.75rem] md:h-20 items-center text-dark-700 text-xs leading-4 font-semibold px-5 gap-2.5' +
              (isUrgent ? ' bg-red-600 bg-opacity-20' : ' bg-teal-500')
            }
          >
            <div className="min-w-[2.5rem] min-h-[2.5rem] w-10 h-10 md:h-[1.875rem] md:w-[1.875rem] md:min-h-[1.875rem] md:min-w-[1.875rem] flex justify-center items-center">
              <div className="h-[1.875rem] w-[1.875rem] min-h-[1.875rem] min-w-[1.875rem] ">
                <TimerIcon color={isUrgent ? '#FE5754' : '#0083A066'} />
              </div>
            </div>
            <div className="flex-grow">Time left until spot lost.</div>
            <Timer time={formattedTime} isUrgent={isUrgent} />
          </div>
        </div>
      </div>
    </>
  );
};

export { SessionTimer };
