import { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import styles from './MonthCarousel.module.css';
import { Month } from 'lib/types/availability';
import { MonthCard } from './MonthCard';

export type Props = {
  months: Month[];
  selectedMonth: Month | null;
  setSelectedMonth: any;
};

function MonthCarousel({ months, selectedMonth, setSelectedMonth }: Props) {
  const carouselOptions: EmblaOptionsType = {
    containScroll: 'trimSnaps',
    dragFree: true,
  };
  const [carouselRef, emblaApi] = useEmblaCarousel(carouselOptions);

  const clickHandler = (month: Month,index:number) => {
    emblaApi?.scrollTo(index);
    document.getElementById('datemodal')?.scrollTo({ top: 0, behavior: 'smooth' });
    setSelectedMonth(month);
  };

  const [scrollProgress, setScrollProgress] = useState(0);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onScroll();
    emblaApi.on('scroll', onScroll);
    emblaApi.on('reInit', onScroll);
  }, [emblaApi, onScroll]);

  return (
    <div className="max-w-full md:mb-4">
      <div className="px-5 sm:px-10 text-lg font-semibold leading-5 text-dark-700 text-left">
        When
      </div>
      <div className="relative flex py-5 max-w-full cursor-grab">
        <div className={styles.embla} ref={carouselRef}>
          <div className={styles.emblaContainer}>
            {months
              .sort(function (a, b) {
                return a.startOfMonth - b.startOfMonth;
              })
              .map((item, index) => (
                <div className={styles.emblaSlide} key={index}>
                  <MonthCard
                    key={index}
                    month={item}
                    isActive={item === selectedMonth}
                    click={(month) => {
                      clickHandler(month,index);
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className={`hidden md:block ${styles.emblaProgressBar}`}>
          <div
            className={styles.emblaProgressBarFilled}
            style={{ transform: `translateX(${scrollProgress}%)` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export { MonthCarousel };
