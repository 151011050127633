import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import { useEffect } from 'react';
import { QueryParam } from 'services/query-param/query-param';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';
import { useCurrency } from './useCurrency';
import { useUserLocation } from './useUserLocation';

const useExternalSurvey = () => {
  const { isLoading: travellerLoading, data: traveller } = useGetTraveller({
    isEnabled: true,
  });
  const location = useUserLocation();
  const { currency } = useCurrency();
  const { data: promotions } = usePromotionsQuery();
  const activePaymentOption = useAppSelector(
    (state) => state.product.paymentOption
  );
  const activePromotions =
    promotions &&
    PromotionHelper.getSelectedPromotionObject(
      promotions.result.promotions,
      activePaymentOption
    );
  const invoice = activePromotions?.invoice;
  const sessionId = sessionIdService.get();

  const isLoading = travellerLoading;

  useEffect(() => {
    if (
      isLoading ||
      !traveller?.result.email ||
      !traveller?.result.cid ||
      !location ||
      !invoice ||
      !activePromotions?.items ||
      !currency ||
      !sessionId
    ) {
      return;
    }

    const API_KEY = process.env.REACT_APP_FAIRING_API_KEY;
    const TARGET_ELEMENT = document.getElementById('external-survey-container');

    // Setup the customer object. We use the customer object to decide which questions to
    // ask and to associate responses with a customer.
    const customer = {
      email: traveller.result.email,
      id: traveller.result.cid,
      order_count: 1,
    };

    // Setup the transaction object. The transaction object allows us to associate order volume
    // with responses in reporting, and is included in data exports for more granular analysis.
    const transaction = {
      country_code: location.country.code,
      coupon_amount: invoice.total_price_discount + invoice.applied_discount,
      coupon_code: activePromotions.items.map((i) => i.code).join(','),
      coupon_type: activePromotions.items
        .map((i) => (i.entity === 'percent' ? 'percentage' : 'fixed'))
        .join(','), // This property only accepts "fixed" or "percentage"
      created_at: new Date().toString(),
      currency_code: currency,
      id: sessionId,
      locality: location.continent,
      number: sessionId,
      platform: 'ecommerce platform',
      postcode: '',
      referring_url: document.referrer,
      region: location.region,
      source: 'booking platform',
      total: invoice.total_price,
      total_usd: invoice.total_price,
      utm_campaign: QueryParam.getFirst('utm_campaign'),
      utm_content: QueryParam.getFirst('utm_content'),
      utm_medium: QueryParam.getFirst('utm_medium'),
      utm_source: QueryParam.getFirst('utm_source'),
      utm_term: QueryParam.getFirst('utm_term'),
    };

    //@ts-ignore
    const fairing = Fairing(API_KEY, TARGET_ELEMENT, {
      transaction: transaction,
      customer: customer,
      config: {
        integrations: {
          ga: false,
          gtm: false,
          rockerbox: false,
        },
      },
    });

    fairing.nextQuestion();
  }, [
    isLoading,
    traveller?.result.email,
    traveller?.result.cid,
    location,
    invoice,
    activePromotions?.items,
    currency,
    sessionId,
  ]);
};

export { useExternalSurvey };
