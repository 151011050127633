type Props = {
  color: string;
};
export const TimerIcon = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.657 7.343C20.781 10.467 20.781 15.533 17.657 18.657C14.533 21.781 9.467 21.781 6.343 18.657C3.219 15.533 3.219 10.467 6.343 7.343C9.467 4.219 14.533 4.219 17.657 7.343"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 12.998C12.5 12.722 12.276 12.499 12 12.5C11.724 12.501 11.5 12.725 11.5 13.001C11.5 13.277 11.723 13.5 11.999 13.5C12.275 13.5 12.499 13.276 12.5 12.999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6L19 4L20 5L17.657 7.343"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
