import { BStar } from 'assets/icons/BStart';
import { StarOutline } from 'assets/icons/StartOutline';

interface Props {
  size?: number;
  rating: number;
  translateY?: string;
  spacingMultiplier?: number;
}

function BookingStar({
  size = 1,
  rating,
  translateY = '',
  spacingMultiplier = 1,
}: Props): JSX.Element {
  const numberOfStars = 5;
  const iconSize = size;
  const iconSpacing = (size / 3) * spacingMultiplier;
  let fillPercentage =
    (rating / numberOfStars) * (iconSize * numberOfStars) +
    Math.floor(rating) * iconSpacing;
  fillPercentage = Math.round(fillPercentage * 10) / 10;
  const totalWidth =
    iconSize * numberOfStars + iconSpacing * (numberOfStars - 1);

  return (
    <div className={`transform ${translateY}`}>
      <div
        className="relative"
        style={{
          width: totalWidth + 'rem',
          height: iconSize + 'rem',
          fontFamily: 'gwat !important',
        }}
      >
        <div
          className="absolute top-0 left-0 inline-flex overflow-hidden"
          style={{ width: fillPercentage + 'rem' }}
        >
          {[...Array(numberOfStars)].map((_, index) => (
            <BStar
              style={{
                marginRight: iconSpacing + 'rem',
                with: iconSize + 'rem',
                minWidth: iconSize + 'rem',
                maxWidth: iconSize + 'rem',
              }}
              width={iconSize + 'rem'}
              height={iconSize + 'rem'}
              key={index}
              color={'#FFC649'}
            />
          ))}
        </div>
        <div
          className="absolute top-0 left-0 inline-flex"
          style={{ width: totalWidth + 'rem' }}
        >
          {[...Array(numberOfStars)].map((_, index) => (
            <StarOutline
              style={{
                marginRight: iconSpacing + 'rem',
                with: iconSize + 'rem',
                minWidth: iconSize + 'rem',
                maxWidth: iconSize + 'rem',
              }}
              width={iconSize + 'rem'}
              height={iconSize + 'rem'}
              key={index}
              color={'#FFC649'}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export { BookingStar };
