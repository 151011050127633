type Props = {
  count: number;
  current: number;
  select: (page: number) => void;
};

const Pagination = ({ count, current, select }: Props) => {
  const clickHandler = (page: number) => {
    select(page);
  };

  return (
    <div className="flex items-center gap-1.5 overflow-x-auto hide-webkit-scrollbar">
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className={
            'min-w-[2rem] w-8 h-8 flex justify-center items-center rounded-md font-semibold text-sm cursor-pointer transition-colors' +
            (current === index + 1
              ? ' text-light-600 bg-teal-900'
              : ' text-dark-700 bg-light-600')
          }
          onClick={() => clickHandler(index + 1)}
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
};

export { Pagination };
