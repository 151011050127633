import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FlagState = {
  flagsAreLoading: boolean;
  userIdentifiedStatusForAnalytics: boolean;
};

const initialState: FlagState = {
  flagsAreLoading: true,
  userIdentifiedStatusForAnalytics: false,
};

const flagSlice = createSlice({
  name: 'flag',
  initialState,
  reducers: {
    setFlagsAreLoading(state, action: PayloadAction<boolean>) {
      state.flagsAreLoading = action.payload;
    },
    setUserIdentifiedStatusForAnalytics(state, action: PayloadAction<boolean>) {
      state.userIdentifiedStatusForAnalytics = action.payload;
    },
  },
});

export const { setFlagsAreLoading, setUserIdentifiedStatusForAnalytics } =
  flagSlice.actions;
export default flagSlice.reducer;
