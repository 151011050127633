import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { PromotionObject } from 'lib/types/promotion';
import Close from '../../../assets/icons/Close';
import styles from './Promo.module.css';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTimer } from './useTimer';
import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { AppToastService } from '../../Toast/AppToastService';
dayjs.extend(duration);

type Props = {
  promotion: PromotionObject;
  removePromo: (code: string) => void;
  removingPromotionLoading: boolean;
  isDisabled: boolean;
  isInRoomStage: boolean;
  totalDuePrice: number;
  isAppliedPromotion: boolean;
  refetch: () => any;
};

const Promo = ({
  promotion,
  removePromo,
  removingPromotionLoading,
  isDisabled,
  isAppliedPromotion,
  isInRoomStage,
  totalDuePrice,
  refetch,
}: Props) => {
  const numberFormatter = useNumberFormatter();
  const expiry = promotion.expire_at * 1000;
  const timerThreshold = dayjs.duration({
    hours: 48,
  });
  const callback = () => {
    refetch();
    AppToastService.warning(`Promotion ${promotion.code} has been expired!`);
  };
  useTimer({ expiry, timerThreshold, callback });

  const clickHandler = () => {
    !isDisabled && !removingPromotionLoading && removePromo(promotion.code);
  };

  const discount = isInRoomStage
    ? PromotionHelper.getPromotionDiscount(totalDuePrice, promotion)
    : promotion.discount;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-start justify-between gap-1">
        {!isAppliedPromotion && (
          <div
            className={
              'w-6 h-6' +
              (removingPromotionLoading
                ? ` ${styles.loading_indicator}`
                : ' cursor-pointer')
            }
            onClick={clickHandler}
          >
            <Close
              color={
                isDisabled || removingPromotionLoading
                  ? 'transparent'
                  : '#737882'
              }
            />
          </div>
        )}
        <div className="flex flex-col">
          <div className="font-semibold text-sm text-dark-600 text-left leading-[1.5625rem]">
            {promotion.code}
          </div>
          {promotion.message ? (
            <div className="font-medium text-xs text-dark-500">
              {promotion.message}
            </div>
          ) : null}
        </div>
        <div className="flex-grow flex items-center justify-end">
          <div
            className={
              'font-semibold text-sm leading-[1.5625rem] text-dark-700'
            }
          >
            {numberFormatter.format(discount)}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Promo };
