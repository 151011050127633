import { ReferralFormT, ReferralFormValidation } from 'lib/types/referral';
import { initValidation } from 'lib/types/traveller-form';

export const formReferralInit: ReferralFormT = {
  firstName: '',
  lastName: '',
  phonePrefix: null,
  phoneNumber: '',
  email: '',
  location: null,
};

export const referralValidationInit: ReferralFormValidation = {
  firtNameValidation: initValidation,
  lastNamevalidation: initValidation,
  phoneNumberValidation: initValidation,
  emailValidation: initValidation,
  locationValidation: initValidation,
  phonePrefixValidation: initValidation,
};
