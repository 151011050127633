import * as Sentry from '@sentry/react';

import { envs, getEnv } from '../../lib/helper/env/env';
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole,
} from '@sentry/integrations';
import { PartialSentryUser } from 'lib/types/sentry';
import merge from 'lodash/merge';
import { sessionIdService } from 'services/session-id/session-id.service';

const environment = getEnv();
const SentryReleaseTag = process.env.REACT_APP_SENTRY_RELEASE;

const safeUrls = [
  'api.gwat.co/book/public/promotion',
  'api.gwat.co/book/public/booking/setup',
  'api.gwat.co/book/public/session/geo',
  'api.gwat.co/book/public/inventory/trip/review/platforms/all',
  'api.gwat.co/book/public/traveller',
  'api.gwat.co/book/public/inventory/trip/review',
  'api.gwat.co/book/public/session/got-to-go',
  'api.gwat.co/book/public/inventory/trip/legal-declarations',
  'api.gwat.co/book/public/inventory/trip/payment-options',
  'api.gwat.co/book/public/config/phone-codes',
  'api.gwat.co/book/public/booking/product',
  'api.gwat.co/book/public/traveller/referred-friends',
  'api.gwat.co/book/public/payment/skip',
  'api.gwat.co/book/public/config/bookings-count',
  'api.gwat.co/book/public/inventory/trip',
  'api.gwat.co/book/public/inventory/trip/terms-conditions-mapping',
  'api.gwat.co/book/public/booking/product',
  'geoip.globalworkandtravel.com/api/geoip',
  'gwat-contents.globalworkandtravel.com/payment-options',
];

const ignoreErrors: string[] = [
  'chrome-extension',
  'safari-extension',
  'session is completed and finalized',
  'Identify is deprecated for Facebook Pixel',
  "Event doesn't match with Snap Pixel Events!",
  'Flagsmith: Fetch error:',
  'Load failed',
  'Failed to fetch',
  'Promotion code is not valid',
  'getUserId is not a function', // related to user network issue and rudderstack not being loaded
];

const denyUrls: RegExp[] = [
  /extensions\//,
  /^chrome:\/\//i,
  /^moz-extension:\/\//i,
  /^chrome-extension:\/\//i,
  /^safari-extension:\/\//i,
  /script\.hotjar.com/,
  /\/(gtm|ga|analytics)\.js/i,
  /googlesyndication\.com/i,
];

class SentryService {
  init() {
    if (environment !== envs.LOCAL && SentryReleaseTag) {
      Sentry.init({
        environment: getEnv(),
        release: SentryReleaseTag,
        dsn: 'https://3cc821ac085c459db3baf25955838b9c@o32955.ingest.sentry.io/4505509345099776',

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
          new CaptureConsole({ levels: ['error'] }),
          new ExtraErrorDataIntegration(),
          new Sentry.Replay({
            networkDetailAllowUrls: safeUrls,
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
          }),
        ],
        beforeSend: (event, hint) => {
          event.extra = {
            ...event.extra,
            originalException: hint.originalException,
          };
          event.tags = {
            ...event.tags,
            session_id: sessionIdService.get(),
            platform: process.env.REACT_APP_TENANT ?? 'gwat',
          };
          return event;
        },
        ignoreErrors,
        denyUrls,
      });
    }
  }

  setUser(incoming: PartialSentryUser) {
    Sentry.configureScope((scope) => {
      const existing = scope.getUser();
      const merged = merge(existing, incoming);
      scope.setUser(merged);
    });
  }

  setExtra(name: string, info: number | string | object) {
    Sentry.configureScope((scope) => {
      scope.setExtra(name, info);
    });
  }

  setTag(name: string, info: string) {
    Sentry.configureScope((scope) => {
      scope.setTag(name, info);
    });
  }
}

export const sentryService = new SentryService();
