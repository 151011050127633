import { envs, getEnv } from '../env/env';
import * as Sentry from '@sentry/react';
import log from 'loglevel';

const setLogLevel = () => {
  const env = getEnv();
  const originalFactory = log.methodFactory;

  log.methodFactory = function (methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);

    return function (...data) {
      // We are recording query errors for sentry in client-factory
      // No need to capture them here again
      if (methodName === 'error' && !data[1]?.isQueryError) {
        Sentry.captureException(data[0]);
      }

      rawMethod(...data);
    };
  };

  switch (env) {
    case envs.LOCAL:
      return log.setLevel(log.levels.TRACE);
    case envs.DEVELOPMENT:
      return log.setLevel(log.levels.TRACE);
    case envs.LIVE:
      return log.setLevel(log.levels.WARN);
    default:
      return log.setLevel(log.levels.WARN);
  }
};

export { setLogLevel };
