import { useFlagsmith } from 'flagsmith/react';
import { useCallback, useEffect, useState } from 'react';
import { useDevice } from '../useDevice';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setFlagsAreLoading } from 'state//slices/flags';

export const useAppFlags = () => {
  const dispatch = useAppDispatch();
  const flagsmith = useFlagsmith();
  const { isMobile } = useDevice();
  const [traitsQueue, setTraitsQueue] = useState({});
  const isUserIdentified = useAppSelector(
    (state) => state.flag.userIdentifiedStatusForAnalytics
  );
  const setFlagsLoading = useAppSelector((state) => state.flag.flagsAreLoading);

  const setTraits = useCallback(
    (traits: Object) => {
      if (!isUserIdentified) {
        setTraitsQueue({ ...traitsQueue, ...traits });
      } else {
        flagsmith.setTraits(traits as any);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flagsmith, isUserIdentified, setFlagsLoading]
  );

  useEffect(() => {
    setTraitsQueue({
      platform: !isMobile ? 'mobile' : 'desktop',
    });
  }, [isMobile]);

  useEffect(() => {
    if (isUserIdentified) {
      flagsmith.setTraits(traitsQueue as any);

      dispatch(setFlagsAreLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserIdentified]);

  return {
    setTraits,
  };
};
