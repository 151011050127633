import {
  TravellerFormUser,
  TTravellerFormUserAction,
} from 'lib/types/traveller';

export const userReducer = (
  state: TravellerFormUser,
  action: TTravellerFormUserAction
) => {
  switch (action.type) {
    case 'firstName':
      return { ...state, firstName: action.value };
    case 'lastName':
      return { ...state, lastName: action.value };
    case 'phonePrefix':
      return { ...state, phonePrefix: action.value };
    case 'phoneNumber':
      return { ...state, phoneNumber: action.value };
    case 'email':
      return { ...state, email: action.value };
    case 'date':
      return { ...state, date: action.value };
    default:
      return state;
  }
};
