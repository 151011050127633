import { Dispatch, SetStateAction, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { useRudderStack } from './useRudderStack';
import { scrollToElementById } from 'lib/helper/scroll/scroll';
import { AppToastService } from '../../components/Toast/AppToastService';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { paymentInitiationLocalStorageService } from 'services/localstorage/payment-initiation';
import { useAppDispatch } from 'state/hooks';
import { setError } from 'state/slices/modal';
import { Retention } from '../helper/retention';

type Props = {
  clientSecret: string;
  setStripeIsLoading: Dispatch<SetStateAction<boolean>>;
  setPaidAmount: Dispatch<SetStateAction<number>>;
  setIsCharity: Dispatch<SetStateAction<boolean>>;
  setCharityPaymentWasSuccessful: Dispatch<SetStateAction<boolean>>;
};

const usePaymentResult = ({
  clientSecret,
  setStripeIsLoading,
  setPaidAmount,
  setIsCharity,
  setCharityPaymentWasSuccessful,
}: Props) => {
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const { isLoading: isRudderstackLoading, orderComplete: _orderComplete } =
    useRudderStack();
  const { isLoading: travellerLoading, data: travellerData } = useGetTraveller({
    isEnabled: true,
  });
  const dispatch = useAppDispatch();

  const orderComplete = (
    cid: number | null | undefined,
    salesRegisterId: number | null | undefined,
    paid: number
  ) => {
    if (searchParams.get('donation')) return;

    const shouldSend =
      paymentInitiationLocalStorageService.get() === 'initiated';

    if (shouldSend) {
      _orderComplete(cid || 0, salesRegisterId || 0, paid);
      paymentInitiationLocalStorageService.remove();
    }
  };

  const handleDonationError = (message: string) => {
    if (searchParams.get('donation')) {
      dispatch(
        setError({
          isOpen: true,
          message,
          buttonText: 'Return to donation',
        })
      );
    }
  };

  const handleDonationSuccess = () => {
    if (searchParams.get('donation')) {
      setCharityPaymentWasSuccessful(true);
    }
  };

  const RetentionTrackOrder = (paid: string) => {
    Retention.trackOrder({
      order_number: travellerData?.result.sales_register_id?.toString() || '',
      order_amount: paid,
      order_email: travellerData?.result.email || '',
    });
  };

  useEffect(() => {
    if (!stripe || !clientSecret || isRudderstackLoading || travellerLoading) {
      return;
    }

    if (searchParams.get('donation')) {
      setIsCharity(true);
      scrollToElementById('donation-card');
    }

    stripe.retrievePaymentIntent(clientSecret).then((obj) => {
      const { paymentIntent } = obj;

      if (!paymentIntent) {
        AppToastService.error("Couldn't contact stripe!");
        setStripeIsLoading(false);
        return;
      }
      const paid = Math.floor(paymentIntent.amount / 100);
      switch (paymentIntent.status) {
        case 'succeeded':
          AppToastService.success('Payment succeeded!');
          setPaidAmount(paid);
          setStripeIsLoading(false);
          orderComplete(
            travellerData?.result.cid,
            travellerData?.result.sales_register_id,
            paid
          );
          RetentionTrackOrder(paid.toString());
          sessionIdService.degradeToSessionStorage();
          handleDonationSuccess();
          break;
        case 'processing':
          AppToastService.success('Your payment is processing.');
          setPaidAmount(paid);
          setStripeIsLoading(false);
          orderComplete(
            travellerData?.result.cid,
            travellerData?.result.sales_register_id,
            paid
          );
          RetentionTrackOrder(paid.toString());
          sessionIdService.degradeToSessionStorage();
          handleDonationSuccess();
          break;
        case 'requires_payment_method':
          setPaidAmount(0);
          setStripeIsLoading(false);
          handleDonationError(
            'Your donation failed, please select a different payment method and try again.'
          );
          break;
        case 'canceled':
          setPaidAmount(0);
          setStripeIsLoading(false);
          handleDonationError(
            'You cancelled your donation, please select a different payment method and try again.'
          );
          break;
        case 'requires_action':
          setPaidAmount(0);
          setStripeIsLoading(false);
          handleDonationError(
            'Your donation failed, please select a different payment method and try again.'
          );
          break;
        case 'requires_capture':
          setPaidAmount(0);
          setStripeIsLoading(false);
          handleDonationError(
            'Your donation failed, please select a different payment method and try again.'
          );
          break;
        case 'requires_confirmation':
          setPaidAmount(0);
          setStripeIsLoading(false);
          handleDonationError(
            'Your donation failed, please select a different payment method and try again.'
          );
          break;
        default:
          setPaidAmount(0);
          setStripeIsLoading(false);
          handleDonationError(
            'Your donation failed, please select a different payment method and try again.'
          );
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientSecret,
    searchParams,
    stripe,
    isRudderstackLoading,
    travellerLoading,
    travellerData,
  ]);
};

export { usePaymentResult };
