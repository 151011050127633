type Props = {
  color: string;
};

const Star = ({ color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="-10 0 1042 1024"
    >
      <path
        fill={color}
        d="M680 327l307 39q15 2 25 13.5t10 26.5q0 8 -3 15.5t-9 12.5v0l-225 215l58 304q1 1 1 2.5v3.5q0 17 -12 29t-29 12q-5 0 -9.5 -1t-8.5 -3v0l-273 -149l-273 149q-4 2 -9 3.5t-11 1.5q-17 0 -29 -12t-12 -28q0 -2 0.5 -4t0.5 -4v0l60 -304l-227 -213q-5 -6 -8 -13t-3 -15
q0 -15 10 -26.5t25 -13.5v0l307 -40l132 -280q5 -10 15 -17t22 -7t22 7t14 17h1z"
      />
    </svg>
  );
};

export { Star };
