type Props = {
  color: string;
};

const Warning = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 16.25C13 16.8023 12.5523 17.25 12 17.25C11.4477 17.25 11 16.8023 11 16.25C11 15.6977 11.4477 15.25 12 15.25C12.5523 15.25 13 15.6977 13 16.25Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12ZM12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5V12.5C12.75 12.9142 12.4142 13.25 12 13.25C11.5858 13.25 11.25 12.9142 11.25 12.5V7.5C11.25 7.08579 11.5858 6.75 12 6.75Z"
        fill={color}
      />
    </svg>
  );
};

export { Warning };
