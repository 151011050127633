import { CreateTravellerPayload } from 'lib/react-query/Queries/useCreateTraveller';
import { TravellerFormUser } from 'lib/types/traveller';
import { SelectionAvailability } from 'lib/types/availability';
import { SelectedRoom } from 'lib/types/trip';
import { useCustomNavigate } from './useCustomNavigate';
import { stagePathConfig } from 'data/stage';

type Props = {
  state: TravellerFormUser;
  validateTraveller: () => boolean | undefined;
  submit: (submitData: CreateTravellerPayload) => void;
  selectedRooms: SelectedRoom[];
};

const useSubmitTraveller = ({
  state,
  validateTraveller,
  submit,
  selectedRooms,
}: Props) => {
  const navigate = useCustomNavigate();

  const continueHandler = () => {
    navigate(stagePathConfig.payment);
  };

  function submitTraveller(
    isFlexible?: boolean,
    departureDate?: SelectionAvailability
  ) {
    const isValid = validateTraveller();
    if (!isValid) return;

    continueHandler();
    submit({
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      phonePrefix: state.phonePrefix?.label,
      phoneNumber: state.phoneNumber,
      // NOTE: We have checked for date not being null in the validation
      // above, so we provide `!` as typescript is dumb to understand it
      dateOfBirth: state.date!,
      flexibleDeparture: isFlexible,
      selectedAvailability: departureDate,
      selectedRooms,
    });
  }

  return { submitTraveller };
};

export { useSubmitTraveller };
