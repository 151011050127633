const AccomodationHead = () => {
  return (
    <div className="hidden md:flex bg-[#E8E8E870] w-full py-[0.75rem] px-5 justify-between text-xs text-dark-500 font-semibold rounded-md">
      <div>ROOM TYPE</div>
      <div className="flex justify-start w-[18.75rem] pl-[1.35rem]">
        <div>PRICE</div>
        <div className="ml-[6.6rem]">TRAVELLERS</div>
      </div>
    </div>
  );
};
export { AccomodationHead };
