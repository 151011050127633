const FallbackCard = () => {
  return (
    <div>
      <div className="border border-light-800 rounded-md p-4 md:px-5 md:pt-5 md:pb-0">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center md:pb-5 ">
          <div className="flex flex-row gap-5 flex-grow pb-4 md:pb-0">
            <div className="w-10 h-10 rounded-full bg-light-800 animate-pulse"></div>
            <div className="flex flex-col gap-[0.625rem] md:max-w-[17.5rem]">
              <div className="w-[11.25rem] h-5 bg-light-800 rounded-[3.75rem] animate-pulse"></div>
              <div className="w-[6.25rem] h-5 bg-light-800 rounded-[3.75rem] animate-pulse"></div>
            </div>
          </div>

          <div className="flex justify-between md:w-[18.75rem] md:pl-[3.125rem] border-t md:border-t-0 md:border-l border-light-700 items-center pt-4 md:pt-0">
            <div className="w-[6.25rem] h-5 bg-light-800 rounded-[3.75rem] animate-pulse"></div>

            <div className="w-[6.25rem] h-[1.875rem] bg-light-800 rounded-[3.75rem] animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FallbackCard };
