import { PaymentSubmit } from './payment-submit';
import { useTncApproval } from '../tnc-approval/useTncApproval';
import { AppToastService } from 'components/Toast/AppToastService';
import { scrollToElementById } from 'lib/helper/scroll/scroll';
import { useSkipPayment } from '../useSkipPayment';
import { PaymentOption } from 'lib/types/payment';

type Props = {
  paymentOption: PaymentOption;
};

const PaymentSubmitSkip = ({ paymentOption }: Props) => {
  const { isApproved, isRequired } = useTncApproval();
  const { skipPaymentHandler, skipPaymentIsLoading } =
    useSkipPayment(paymentOption);

  const mustApproveTnc = isRequired && !isApproved;

  const handleSubmit = async () => {
    if (mustApproveTnc) {
      AppToastService.error('Please approve terms and conditions.');
      scrollToElementById('terms-and-conditions');
      return;
    }

    skipPaymentHandler();
  };

  return (
    <PaymentSubmit
      text="Agree & Finish"
      submit={handleSubmit}
      isLoading={skipPaymentIsLoading}
      isDisabled={skipPaymentIsLoading}
      buttonType={mustApproveTnc ? 'disabled' : 'primary'}
    />
  );
};

export { PaymentSubmitSkip };
