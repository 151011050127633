import { TravellerData } from 'lib/types/create-traveller';

const checkTravellerData = (data: TravellerData) => {
  if (
    !data.cid ||
    !data.sales_register_id ||
    !data.date_of_birth ||
    !data.email ||
    !data.first_name ||
    !data.last_name ||
    !data.phone_code
  ) {
    return false;
  } else {
    return true;
  }
};

export { checkTravellerData };
