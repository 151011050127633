import { useCurrency } from '../useCurrency';
import { localeByCurrency } from './locale';

const useNumberFormatter = () => {
  const { currency } = useCurrency();

  const numberFormatter = new Intl.NumberFormat(localeByCurrency[currency], {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return numberFormatter;
};

export { useNumberFormatter };
