import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { PromotionObject, PromotionPayment } from 'lib/types/promotion';
import { LineContainer } from '../LineContainer';
import { Promo } from './Promo';

type Props = {
  activePromotions: PromotionPayment | undefined;
  appliedPromotions: PromotionObject[];
  removePromo: (code: string) => void;
  removingPromotionLoading: boolean;
  shouldNotLetUserRemovePromotions: boolean;
  noOfTravellers: number;
  isInRoomStage: boolean;
  totalPriceWithRooms: number;
  refetch: () => any;
};

const Promos = ({
  activePromotions,
  appliedPromotions,
  removePromo,
  removingPromotionLoading,
  shouldNotLetUserRemovePromotions,
  noOfTravellers,
  isInRoomStage,
  totalPriceWithRooms,
  refetch,
}: Props) => {
  const numberFormatter = useNumberFormatter();

  const discount =
    (activePromotions?.invoice.total_price_discount ?? 0) +
    (activePromotions?.invoice.applied_discount ?? 0);
  const promos = PromotionHelper.getActiveAndAppliedPromotions(
    activePromotions?.items,
    appliedPromotions
  );

  const isAppliedPromotion = (code: string) => {
    return appliedPromotions.some((promo) => promo.code === code);
  };

  const totalDuePrice =
    (activePromotions?.invoice.due_now ?? 0) * noOfTravellers;
  const total_discount = isInRoomStage
    ? PromotionHelper.getPromotionsDiscount(totalDuePrice, promos)
    : discount;

  return promos.length ? (
    <div className="bg-light-700 border-t border-b border-light-900 p-3 mx-5 my-2.5 border-l border-r rounded-md flex flex-col">
      {promos.map((promo, index) => (
        <div key={promo.code + String(index)}>
          <Promo
            promotion={promo}
            removePromo={removePromo}
            removingPromotionLoading={removingPromotionLoading}
            isDisabled={shouldNotLetUserRemovePromotions}
            isAppliedPromotion={isAppliedPromotion(promo.code)}
            isInRoomStage={isInRoomStage}
            totalDuePrice={totalDuePrice}
            refetch={refetch}
          />
          {index < promos.length - 1 ? (
            <div className="border-t border-dashed border-light-900 my-2"></div>
          ) : null}
        </div>
      ))}
      {total_discount ? (
        <>
          <hr className="h-px border-none bg-light-800 my-2" />
          <LineContainer>
            <div className="font-semibold text-sm text-dark-600">
              Total Discount
            </div>
            <div className="flex items-center">
              <div className="font-semibold text-dark-700">
                {numberFormatter.format(total_discount)}
              </div>
            </div>
          </LineContainer>
        </>
      ) : null}
    </div>
  ) : null;
};

export { Promos };
