import { useQuery } from '@tanstack/react-query';
import { HttpService } from '../../../services/http/http';
import { PaymentOptionsContent } from '../../types/payment';
import { useMemo } from 'react';
import { useTenant } from 'lib/hooks/useTenant';
import { dealsawayFallbackData, gwatFallbackData } from 'data/payment-option';

const httpService = new HttpService({});

const getByType = (
  paymentOptionContents: PaymentOptionsContent[],
  type: 'Promotional' | 'Standard' | 'VIP'
) => {
  return paymentOptionContents.find((poc) => poc.type === type);
};

export const usePaymentOptionContents = () => {
  const tenant = process.env.REACT_APP_TENANT || 'gwat';
  const URL = `https://gwat-contents.globalworkandtravel.com/payment-options?platform=${tenant}`;
  const { isDA } = useTenant();
  const fallbackData = isDA ? dealsawayFallbackData : gwatFallbackData;

  const { isLoading, data, isError } = useQuery(
    ['payment-option-contents'],
    async () => {
      return httpService.get<PaymentOptionsContent[]>(URL);
    },
    { staleTime: 1 * 60 * 1000 }
  );

  const promotionalPaymentOptionContent = useMemo(() => {
    if (data) {
      return getByType(data, 'Promotional');
    }

    if (isError) {
      return getByType(fallbackData, 'Promotional');
    }
  }, [data, isError, fallbackData]);

  const standardPaymentOptionContent = useMemo(() => {
    if (data) {
      return getByType(data, 'Standard');
    }

    if (isError) {
      return getByType(fallbackData, 'Standard');
    }
  }, [data, isError, fallbackData]);

  const vipPaymentOptionContent = useMemo(() => {
    if (isLoading) return null;

    if (data) {
      return getByType(data, 'VIP');
    }

    if (isError) {
      return getByType(fallbackData, 'VIP');
    }
  }, [isLoading, data, isError, fallbackData]);

  return {
    isLoading,
    isError,
    promotionalPaymentOptionContent,
    standardPaymentOptionContent,
    vipPaymentOptionContent,
  };
};
