import { FacebookIcon } from '@/assets/icons/Facebook';
import { InstagramIcon } from '@/assets/icons/Instagram';
import { LinkedinIcon } from '@/assets/icons/Linkdin';
import { Image } from 'components/Image/Image';

type Props = {
  year: number;
};

const Da = ({ year }: Props) => {
  return (
    <div className="bg-light-700">
      <div className="p-5">
        <div className="text-lg font-semibold leading-6 text-dark-800 text-center">
          The Global Work & Travel Group
        </div>
        <p className="text-dark-600 text-center text-sm font-semibold leading-5 mt-2.5">
          DealsAway.com is proudly part of the{' '}
          <span className="whitespace-nowrap">Global Work & Travel</span> family
          of brands, a worldwide leader in life-changing travel & technology.
        </p>
        <div className="mt-5  flex justify-center items-end">
          <a
            href="https://www.globalworkandtravel.com/"
            target="_blank"
            rel="noreferrer"
            className="box-border w-12 lg:w-20 mt-2 mx-2"
          >
            <div>
              <Image
                path="booking/global-work-and-travel-logo.svg"
                alt="global work and travel logo"
                className="!object-contain w-full"
                isCloudinary={true}
                isLazyLoad={false}
              />
            </div>
          </a>
          <a
            href="https://dealsaway.com/"
            target="_blank"
            rel="noreferrer"
            className="w-20 lg:w-32 mt-2 mx-2"
          >
            <div>
              <Image
                path="https://s3.amazonaws.com/dealsaway.website.assets/logo_2.svg"
                alt="dealsaway logo"
                className="!object-contain w-full "
                isCloudinary={false}
                isLazyLoad={false}
              />
            </div>
          </a>
          <a
            href="https://globaltravelcover.com"
            target="_blank"
            rel="noreferrer"
            className="w-12 lg:w-20 mt-2 mx-2"
          >
            <div>
              <Image
                path="https://s3.amazonaws.com/dealsaway.website.assets/gtc.png"
                alt="global travel cover logo"
                className="!object-contain w-full "
                isCloudinary={false}
                isLazyLoad={false}
              />
            </div>
          </a>
          <a
            href="https://www.globalworkandtravel.com/global-animal-welfare-fund"
            target="_blank"
            rel="noreferrer"
            className="w-12 lg:w-20 mt-2 mx-2 "
          >
            <div>
              <Image
                path="https://s3.amazonaws.com/dealsaway.website.assets/global_animal_welfare_fund.png"
                alt="global animal welfare fund logo"
                className="!object-contain w-full"
                isCloudinary={false}
                isLazyLoad={false}
              />
            </div>
          </a>
          <a
            href="https://rescuepawsthailand.org"
            target="_blank"
            rel="noreferrer"
            className="w-[1.6rem] lg:w-[2.75rem] mx-2 "
          >
            <div>
              <Image
                path="https://s3.amazonaws.com/dealsaway.website.assets/rescue_paws.png"
                alt="rescue paws logo"
                className="!object-contain w-full"
                isCloudinary={false}
                isLazyLoad={false}
              />
            </div>
          </a>
        </div>
      </div>
      <div className="p-5 bg-light-800 flex flex-col md:flex-row justify-center items-center gap-2.5">
        <div className="flex items-center gap-1">
          <a
            href="http://instagram.com/dealsaway"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.facebook.com/dealsaway"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/dealsaway"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinIcon />
          </a>
        </div>
        <div className="text-dark-600 text-center text-xs font-semibold leading-5">
          © Copyright {year} DealsAway.com Pty Ltd. ABN: 29 641 656 877. All
          Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export { Da };
