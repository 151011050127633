import { Step } from './Step';

type Props = {
  isLoading: boolean;
};

const DaSteps = ({ isLoading }: Props) => {
  return (
    <div className="px-0 md:px-10 pb-0 md:pb-10">
      <Step
        order={3}
        status="todo"
        border="none"
        title="Welcome call from your dedicated Deal Maker"
        description="We're going to assign you someone to personally look after you from here until you safely return; expect to hear from us within the next 48 hours. We'll double check your details, answer any new questions and make sure everything is ok. Then, the countdown to holiday time begins!"
        excludeUnderline
        isLoading={isLoading}
      />
    </div>
  );
};

export default DaSteps;
