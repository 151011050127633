import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type GCalendarState = {
  lastEnabledDate: string | null;
};

const initialState: GCalendarState = {
  lastEnabledDate: null,
};

const gcalendarSlice = createSlice({
  name: 'gcalendar',
  initialState,
  reducers: {
    setLastEnabledDate(state, action: PayloadAction<string | null>) {
      state.lastEnabledDate = action.payload;
    },
  },
});

export const { setLastEnabledDate } = gcalendarSlice.actions;
export default gcalendarSlice.reducer;
