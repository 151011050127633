import { Warning } from 'assets/icons/Warning';
import { Button } from 'components/Button/Button';
import { BookingModal } from 'components/Modal/BookingModal';
import { useState } from 'react';

const AccomodationSuplement = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className=" cursor-pointer py-3 md:p-3 border-t border-light-700 md:border-light-800 md:border-dashed text-sm font-semibold text-dark-700  flex items-center"
      >
        <div className="w-4 h-4 mr-[0.375rem]">
          <Warning color="#575B63" />
        </div>
        <div className="underline">Single Supplement</div>
      </div>
      <BookingModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title="What is single supplement?"
        cssClasses="sm:w-[35.75rem]"
      >
        <div className="px-5 md:px-10 pb-5 md:pb-10 text-dark-700 text-sm text-left">
          <p>
            This is a hotel charge paid by a solo traveller to compensate the
            hotel or cruise line for lost revenue because only one person is
            staying in the accommodation. Most accommodations are built under
            the assumption that at least two people will occupy them.
          </p>
          <p className="pt-5">
            If you have any questions please contact us directly.
          </p>
          <div className="pt-5 flex justify-end">
            <Button
              text="Got it"
              clickHandler={() => setIsOpen(false)}
              size="medium"
              type="primary"
              className="min-w-[6.25rem]"
            />
          </div>
        </div>
      </BookingModal>
    </>
  );
};
export { AccomodationSuplement };
