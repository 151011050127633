import { useUserLocation } from '../useUserLocation';
import { dealsAwayOfficeData, officeData, officeNameByLocation } from './data';
import { TOffice } from './types';
import { useTenant } from '../useTenant';

function useOffice(): TOffice {
  const location = useUserLocation();

  const { isDA } = useTenant();

  if (isDA) return dealsAwayOfficeData;

  if (!location) return officeData[officeNameByLocation.default];

  const officeName =
    officeNameByLocation[location.continent] || officeNameByLocation.default;

  const office = officeData[officeName];

  return office;
}

export { useOffice };
