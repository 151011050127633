import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CurrencyKeys } from 'lib/types/trip';

type UserState = {
  currency: CurrencyKeys | null;
  saleRegisterId: number | null;
  cid: number | null;
  tid: number | null;
};

const initialState: UserState = {
  currency: null,
  saleRegisterId: 0,
  cid: 0,
  tid: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrency(state, action: PayloadAction<CurrencyKeys>) {
      state.currency = action.payload;
    },
    setSalesRegisterId(state, action: PayloadAction<number | null>) {
      state.saleRegisterId = action.payload;
    },
    setCid(state, action: PayloadAction<number | null>) {
      state.cid = action.payload;
    },
    setTid(state, action: PayloadAction<number | null>) {
      state.tid = action.payload;
    },
  },
});

export const { setCurrency, setSalesRegisterId, setCid, setTid } =
  userSlice.actions;
export default userSlice.reducer;
