import { AppointmentsGateway } from 'services/http/appointments-gateway';
import { useQuery } from '@tanstack/react-query';
import { useOffice } from '../../../hooks/useOffice/useOffice';
import { EventDataResponse } from './types';

const useGetEventDataQuery = () => {
  const office = useOffice();

  const { data, isLoading, error } = useQuery<EventDataResponse, Error>(
    ['get-event-data', office.gCalendar.eventId],
    async () => {
      return await AppointmentsGateway.get<any>(
        `/public/event/${office.gCalendar.eventId}`
      );
    },
    {
      enabled: !!office.gCalendar.eventId,
      staleTime: Infinity,
    }
  );

  return {
    data,
    isLoading,
    error,
  };
};

export { useGetEventDataQuery };
