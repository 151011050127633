import { stageNameConfig, stagePathConfig } from 'data/stage';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useStageClassname = () => {
  const { pathname } = useLocation();

  const removeAll = () => {
    Object.values(stageNameConfig).forEach((stageName) => {
      document.body.classList.remove(`stage-${stageName}`);
    });
  };

  const addByPathname = (path: string) => {
    removeAll();
    Object.keys(stagePathConfig).forEach((stageKey) => {
      if (path === stagePathConfig[stageKey as keyof typeof stagePathConfig]) {
        const classname =
          stageNameConfig[stageKey as keyof typeof stageNameConfig];
        document.body.classList.add(`stage-${classname}`);
      }
    });
  };

  useEffect(() => {
    addByPathname(pathname);
    return () => {
      removeAll();
    };
  }, [pathname]);
};

export { useStageClassname };
