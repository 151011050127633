import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { useGetTraveller } from 'lib/react-query/Queries/useGetTraveller';
import { AppToastService } from 'components/Toast/AppToastService';

type Props = {
  clientSecret: string;
};

const usePaymentResult = ({ clientSecret }: Props) => {
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const { isLoading: travellerLoading, data: travellerData } = useGetTraveller({
    isEnabled: true,
  });

  useEffect(() => {
    if (!stripe || !clientSecret || travellerLoading) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then((obj) => {
      const { paymentIntent } = obj;

      if (!paymentIntent) {
        AppToastService.error("Couldn't contact stripe!");
        return;
      }

      switch (paymentIntent.status) {
        case 'requires_payment_method':
          AppToastService.warning(
            'Your payment was not successful, please try again.'
          );
          break;
        case 'canceled':
          AppToastService.error('Payment was canceled by user.');
          break;
        case 'requires_action':
          AppToastService.error(
            'Your payment was not successful, please try again.'
          );
          break;
        case 'requires_capture':
          AppToastService.error(
            'Your payment was not successful, please try again.'
          );
          break;
        case 'requires_confirmation':
          AppToastService.error(
            'Your payment was not successful, please try again.'
          );
          break;
        default:
          AppToastService.error('Something went wrong.');
          break;
      }
    });
  }, [clientSecret, searchParams, stripe, travellerLoading, travellerData]);
};

export { usePaymentResult };
