import React from 'react';
import { AppToastIcon } from './AppToastIcon';
import { CloseAppToastIcon } from './AppToastIconset';

type Props = {
  type: 'success' | 'error' | 'info' | 'warning' | 'default';
  title?: string;
  message: string;
};

function AppToast({ message, type, title }: Props) {
  return (
    <div className="flex inline justify-between items-center relative p-3 b-1 border-red-900">
      <div className="flex inline items-center">
        <div className="w-6 h-6">
          <AppToastIcon type={type} className="" />
        </div>
        <div className="flex flex-col justify-center pl-2 pr-3 gap-1">
          {title && (
            <div className="font-semibold text-base leading-[1.125rem] text-[#17181A]">
              {title}
            </div>
          )}
          <div className="font-medium text-sm sm:leading-4 text-[#17181A]">
            {message}
          </div>
        </div>
      </div>
      <div>
        <CloseAppToastIcon />
      </div>
    </div>
  );
}

export { AppToast };
