type Props = {
  color: string;
};

const CheckMark = ({ color }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 10l3 3l6-6"
      />
    </svg>
  );
};

export { CheckMark };
