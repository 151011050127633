import { useEffect } from 'react';
import { DateHelper } from 'lib/helper/date/date';
import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
dayjs.extend(duration);

type Props = {
  expiry: number;
  timerThreshold: Duration;
  callback: () => void;
};

const useTimer = ({ expiry, timerThreshold, callback }: Props) => {
  useEffect(() => {
    if (!expiry) return;

    // prevent adding timer if expiry is more than timerThreshold
    const diff = DateHelper.getDifference(
      dayjs(expiry),
      DateHelper.getNowUtc()
    );
    const diffDuration = dayjs.duration(diff);
    if (diffDuration.asMilliseconds() > timerThreshold.asMilliseconds()) return;

    const interval = setInterval(() => {
      const diff = DateHelper.getDifference(
        dayjs(expiry),
        DateHelper.getNowUtc()
      );
      if (diff <= 0) {
        clearInterval(interval);
        callback();
      }
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useTimer };
