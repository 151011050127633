import { Layout } from 'components/Layout/Finished/Finished';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';

type Props = {
  errorCode?: number;
};

const ErrorPage = ({ errorCode = 500 }: Props) => {
  const navigate = useCustomNavigate();
  return (
    <Layout showFAQ={false} pbFooter={true}>
      <div className="flex flex-col justify-center items-center px-[1.125rem] w-full min-h-error-mobile md:h-full md:p-0 bg-light-600">
        <div className=" flex flex-col justify-center items-center pb-10 pt-0 md:pt-12">
          <div className="w-[22.375rem] md:w-[27.5rem] h-[11rem] md:h-[13.75rem] bg-error-back-mobile md:bg-error-back rounded-t-full flex justify-center items-end relative">
            <div className="text-[7.5rem] md:text-[12.5rem] leading-[8.75rem] md:leading-[10rem] text-dark-500 font-extrabold absolute -bottom-10">
              {errorCode}
            </div>
          </div>
        </div>
        <div className="mt-5 text-[1.25rem] md:text-3xl !leading-6 md:!leading-10 font-bold text-dark-700 text-center">
          <span className="hidden md:block">
            Oh no! It looks like you’re lost in space...
          </span>
          <span className="md:hidden">
            Oh no!
            <br />
            It looks like you’re lost in space...
          </span>
        </div>
        <div className="mt-2 text-dark-500 !leading-5 md:!leading-6 text-sm md:text-lg font-normal text-center">
          Just hit that button below and we’ll take you back down to Earth.
        </div>
        <div className="mt-2 md:mt-6 w-full flex justify-center items-center mb-12">
          <button
            onClick={() => navigate('/')}
            className=" w-full md:w-[15.313rem] h-12 flex jus items-center justify-center rounded-md border border-dark-800 text-sm font-semibold text-dark-800 !leading-5"
          >
            To the homepage & beyond
          </button>
        </div>
      </div>
    </Layout>
  );
};
export { ErrorPage };
