import { useQuery } from '@tanstack/react-query';
import { TQueryLocation } from 'lib/types/location';
import { HttpService } from 'services/http/http';

const useFallbackLocationQuery = () => {
  const GATEWAY =
    process.env.BOOKING_GEOIP_DOMAIN_NAME ||
    'https://geoip.globalworkandtravel.com';
  const URL = '/api/geoip';

  const getUrl = (path: string): string => {
    return `${GATEWAY}${path}`;
  };

  const httpService = new HttpService({
    options: {
      getUrl,
    },
  });

  return useQuery({
    queryKey: [URL],
    staleTime: Infinity,
    queryFn: async () => {
      const res = await httpService.get<TQueryLocation>(URL);
      return res;
    },
    retry: 3,
  });
};

export { useFallbackLocationQuery };
