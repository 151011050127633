import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectionAvailability } from 'lib/types/availability';
import { PaymentOption } from 'lib/types/payment';
import { Duration, InclusionFormat } from 'lib/types/trip';
import * as Sentry from '@sentry/browser';

type ProductState = {
  hasDepartureDate: boolean;
  duration: Duration | null;
  availability: SelectionAvailability;
  flexibleDeparture: boolean;
  paymentOption: PaymentOption;
  invoiceId: number | null;
  travellerNumber: number;
  rooms: InclusionFormat[];
  hasRestoredContext: boolean;
};

const initialState: ProductState = {
  hasDepartureDate: false,
  duration: null,
  availability: null,
  flexibleDeparture: false,
  paymentOption: null,
  invoiceId: 0,
  travellerNumber: 1,
  rooms: [],
  hasRestoredContext: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setHasDepartureDate(state, action: PayloadAction<boolean>) {
      state.hasDepartureDate = action.payload;
    },
    setDuration(state, action: PayloadAction<Duration | null>) {
      state.duration = action.payload;
    },
    setAvailability(state, action: PayloadAction<SelectionAvailability>) {
      state.availability = action.payload;
    },
    setFlexibleDeparture(state, action: PayloadAction<boolean>) {
      state.flexibleDeparture = action.payload;
    },
    setPaymentOption(state, action: PayloadAction<PaymentOption>) {
      state.paymentOption = action.payload;
      Sentry.configureScope((scope) => {
        scope.setExtra('paymentOption', action.payload);
      });
    },
    setInvoiceId(state, action: PayloadAction<number | null>) {
      state.invoiceId = action.payload;
    },
    setTravellerNumber(state, action: PayloadAction<number>) {
      state.travellerNumber = action.payload;
    },
    setRooms(state, action: PayloadAction<InclusionFormat[]>) {
      state.rooms = action.payload;
    },
    setHasRestoredContext(state, action: PayloadAction<boolean>) {
      state.hasRestoredContext = action.payload;
    },
  },
});

export const {
  setHasDepartureDate,
  setDuration,
  setAvailability,
  setFlexibleDeparture,
  setPaymentOption,
  setInvoiceId,
  setTravellerNumber,
  setRooms,
  setHasRestoredContext,
} = productSlice.actions;
export default productSlice.reducer;
