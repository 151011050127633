import { ReactNode } from 'react';
import { useSetLocation } from './location';
import { useSetRoom } from './room';

type Props = {
  children: ReactNode;
};

const SetterProvider = ({ children }: Props) => {
  useSetLocation();
  useSetRoom();

  return <>{children}</>;
};

export { SetterProvider };
