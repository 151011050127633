const FacebookIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 1124 1054"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#737882"
        d="M751 585l28-185H601V279q0-38 22.5-69t82.5-31h79V22q-32-6-68-9t-73-4h-1q-55 0-100 16-45 17-76.5 48.5T418 152q-17 46-17 107v141H239v185h163v448h199V585h150z"
      />
    </svg>
  );
};

export { FacebookIcon };
