import { useUpdateProductQuery } from 'lib/react-query/Queries/useUpdateProductQuery';
import { TDropDownItem } from 'lib/types/dropdown';
import { InclusionFormat, SelectedRoom } from 'lib/types/trip';
import { useEffect, useState } from 'react';
import { AppToastService } from '../../components/Toast/AppToastService';
import { SelectionAvailability } from 'lib/types/availability';
import { useCustomNavigate } from './useCustomNavigate';
import { stagePathConfig } from 'data/stage';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setTravellerNumber } from 'state/slices/product';

type Props = {
  travellerDropdown: TDropDownItem | null;
  selectedRooms: SelectedRoom[];
  isGotToGo: boolean;
};

const useSubmitRoom = ({
  travellerDropdown,
  selectedRooms,
  isGotToGo,
}: Props) => {
  const [hasError, setHasError] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');
  const navigate = useCustomNavigate();
  const { isLoading, isError, data, submitUpdateProduct } =
    useUpdateProductQuery();
  const availability = useAppSelector((state) => state.product.availability);
  const flexibleDeparture = useAppSelector(
    (state) => state.product.flexibleDeparture
  );
  const selectedDuration = useAppSelector((state) => state.product.duration);
  const duration_id = selectedDuration?.id;
  const dispatch = useAppDispatch();

  const continueHandler = () => {
    navigate(stagePathConfig.traveller);
  };

  const submitHandler = (
    isFlexible?: boolean,
    departureDate?: SelectionAvailability
  ) => {
    if (!hasError) {
      const inclusionFormat: InclusionFormat[] = selectedRooms.map((item) => ({
        type: 'room',
        data: { id: item.id, travellers_number: item?.count },
      }));

      submitUpdateProduct({
        availability: departureDate ?? availability,
        duration_id,
        rooms: inclusionFormat,
        travellersNumber: travellerDropdown?.value
          ? parseInt(travellerDropdown.value)
          : 0,
        is_departure_flexible: isFlexible ?? flexibleDeparture,
      });

      travellerDropdown &&
        dispatch(setTravellerNumber(parseInt(travellerDropdown?.value)));
    }
  };

  useEffect(() => {
    if (travellerDropdown) {
      const selectedRoomsCount = selectedRooms
        .map((item) => item?.count)
        .reduce((partialSum, a) => partialSum + a, 0);
      const differenceCount =
        parseInt(travellerDropdown?.value) - selectedRoomsCount;
      if (differenceCount !== 0) {
        setHasError(true);
        setValidationMessage(`
Please select ${differenceCount} more traveller${
          differenceCount > 1 ? 's' : ''
        }`);
      } else if (
        selectedRooms
          .map((item) => item.hasError)
          .reduce((partialSum, a) => partialSum * (a === true ? 0 : 1), 1) === 0
      ) {
        setHasError(true);
        setValidationMessage(
          `Please select correct accommodation to continue.`
        );
      } else {
        setHasError(false);
        setValidationMessage(`You're good to go`);
      }
    }
  }, [travellerDropdown, selectedRooms]);

  useEffect(() => {
    if (isLoading) return;

    if (data && data.success && !isError && data.result.updated) {
      if (!isGotToGo) {
        continueHandler();
      }
    } else if (isError) {
      AppToastService.error('Oops! Something went wrong!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, isError]);

  return {
    isLoading,
    hasError,
    validationMessage,
    submitHandler,
    data,
    isError,
  };
};
export { useSubmitRoom };
