import { useQuery } from '@tanstack/react-query';
import { CharityPaymentSetupQueryResult } from 'lib/types/payment';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

type Props = {
  charity_amount: number;
  provider: string;
  timezoneOffset: number;
  method: string;
  isEnabled: boolean;
};

const useCharityPaymentSetup = ({
  charity_amount,
  provider,
  timezoneOffset,
  method,
  isEnabled,
}: Props) => {
  const URL = '/public/payment/charity';
  const sessionId = sessionIdService.get();

  const payload = {
    charity_amount,
    provider,
    timezone_offset: timezoneOffset,
    method,
  };

  return useQuery({
    queryKey: ['charity-payment-setup', sessionId, payload],
    queryFn: async () => {
      const response =
        await BookingGateway.post<CharityPaymentSetupQueryResult>(URL, payload);
      return response;
    },
    enabled: isEnabled && !!sessionId.length,
  });
};

export { useCharityPaymentSetup };
