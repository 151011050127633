import dayjs from 'dayjs';
import { Gwat } from './Gwat';
import { useTenant } from 'lib/hooks/useTenant';
import { Da } from './Da';

type Props = {
  pbFooter?: boolean;
};

function Footer({ pbFooter = false }: Props) {
  const currentYear = dayjs().year();
  const { isDA } = useTenant();

  return (
    <div className={'md:pb-0 ' + (pbFooter ? ' fixed bottom-0 w-full ' : ' ')}>
      {isDA ? <Da year={currentYear} /> : <Gwat year={currentYear} />}
    </div>
  );
}

export { Footer };
