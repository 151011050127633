import { useSearchParams } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { usePaymentClientConfig } from 'lib/react-query/Queries/usePaymentClientConfig';
import { useEffect, useState } from 'react';

const usePaymentQueryParams = () => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [searchParams] = useSearchParams();
  const { data: clientConfigData } = usePaymentClientConfig({
    provider: 'stripe',
  });

  const clientSecret = searchParams.get('payment_intent_client_secret');
  const publishableKey = clientConfigData?.result.publishable_key;

  useEffect(() => {
    try {
      const stripe = publishableKey ? loadStripe(publishableKey) : null;
      setStripePromise(stripe);
    } catch (e) {
      console.error(
        'usePaymentQueryParams: Loading stripe failed, trying again...'
      );
      setTimeout(() => {
        const stripe = publishableKey ? loadStripe(publishableKey) : null;
        setStripePromise(stripe);
      }, 2000);
    }
  }, [publishableKey]);

  if (!clientSecret || !publishableKey)
    return { clientSecret: null, stripePromise: null };

  return { clientSecret, stripePromise };
};

export { usePaymentQueryParams };
