import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CloudinaryService } from 'services/cloudinary/cloudinary';
import 'react-lazy-load-image-component/src/effects/blur.css';

export type Props = {
  cloud?: string;
  path: string;
  alt: string;
  transformation?: string;
  className?: string;
  wrapperClassName?: string;
  crop?: {
    width: string;
    height: string;
  };
  hasPlaceholder?: boolean;
  onLoad?: () => void;
  isCloudinary?: boolean;
  isLazyLoad?: boolean;
};

const Image = ({
  cloud,
  path,
  alt,
  transformation,
  className,
  wrapperClassName,
  crop,
  hasPlaceholder = false,
  isCloudinary = true,
  isLazyLoad = false,
  onLoad,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const cloudName =
    cloud || process.env.REACT_APP_WEBSITE_CLOUDINARY_CLOUD_NAME || 'gwatco';

  const afterLoad = () => {
    setIsLoaded(true);
    onLoad && onLoad();
  };

  const generatedPath = isCloudinary
    ? new CloudinaryService(cloudName).getImage(path, transformation, crop)
    : path;

  return (
    <span
      data-testid="image"
      className={
        'w-full h-full flex items-center' +
        (isLoaded
          ? ' bg-transparent'
          : hasPlaceholder
          ? ' bg-light-900 animate-pulse'
          : ' bg-transparent')
      }
    >
      {isLazyLoad ? (
        <LazyLoadImage
          className={'object-cover object-center ' + (className || '')}
          wrapperClassName={'w-full h-full ' + wrapperClassName}
          alt={alt}
          src={generatedPath}
          effect="blur"
          afterLoad={afterLoad}
          width="100%"
          style={{ width: '100%' }}
        />
      ) : (
        <img
          className={'object-cover object-center ' + (className || '')}
          alt={alt}
          src={generatedPath}
          width="100%"
        />
      )}
    </span>
  );
};

export { Image };
