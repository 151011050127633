import { stagePathConfig } from 'data/stage';
import { usePaymentOptions } from 'lib/react-query/Queries/usePaymentOptions';
import { usePromotionsQuery } from 'lib/react-query/Queries/usePromotionsQuery';
import { PaymentOption } from 'lib/types/payment';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setPaymentOption } from 'state/slices/product';

type PaymentOptionStateMachine = 'init' | 'generic' | 'payment';

const useSetPaymentOption = () => {
  const { pathname } = useLocation();
  const paymentOptionStateMachine = useRef<PaymentOptionStateMachine>('init');
  const selected = useAppSelector((state) => state.product.paymentOption);
  const dispatch = useAppDispatch();
  const setSelected = (option: PaymentOption) => {
    dispatch(setPaymentOption(option));
  };
  const { isLoading: promotionsLoading, data: promotionsData } =
    usePromotionsQuery();
  const promotion = promotionsData?.result.promotions;
  const hasDepositDiscount = promotion?.deposit?.invoice.deposit_discount !== 0;

  const hasPromotionalDepositEnabled = promotion?.deposit !== undefined;
  const hasStandardDepositEnabled = promotion?.standard !== undefined;
  const hasPayInFullEnabled = promotion?.trip_in_full !== undefined;

  const hasPaidPromotionalDeposit = promotion?.deposit?.invoice.due_now === 0;
  const hasPaidStandardDeposit = promotion?.standard?.invoice.due_now === 0;
  const hasPaidFull = promotion?.trip_in_full?.invoice.due_now === 0;
  const hasPaidDeposit = hasPaidPromotionalDeposit || hasPaidStandardDeposit;

  const {
    isPromotionalBookingActive,
    isTripInFullBookingActive,
    isStandardBookingActive,
  } = usePaymentOptions();

  useEffect(() => {
    if (!setSelected) return;
    if (promotionsLoading || !promotionsData) return;

    // NOTE: the reason for this is that user can remove deposit
    // promos and cause the promotional option to disappear, in
    // that case we have to fall back on other options regardless
    // of once having set the payment option
    if (
      paymentOptionStateMachine.current === 'payment' &&
      !(!hasDepositDiscount && selected === 'deposit')
    ) {
      return;
    }

    if (
      paymentOptionStateMachine.current === 'init' &&
      pathname !== stagePathConfig.payment
    ) {
      setSelected('generic');
      paymentOptionStateMachine.current = 'generic';
      return;
    }

    // only execute below in payment stage
    if (pathname !== stagePathConfig.payment) {
      return;
    }

    if (
      !hasPaidDeposit &&
      hasDepositDiscount &&
      isPromotionalBookingActive &&
      hasPromotionalDepositEnabled
    ) {
      setSelected('deposit');
      paymentOptionStateMachine.current = 'payment';
      return;
    } else if (
      !hasPaidDeposit &&
      isStandardBookingActive &&
      hasStandardDepositEnabled
    ) {
      setSelected('standard');
      paymentOptionStateMachine.current = 'payment';
      return;
    } else if (isTripInFullBookingActive && hasPayInFullEnabled) {
      setSelected('trip_in_full');
      paymentOptionStateMachine.current = 'payment';
      return;
    }

    if (hasPaidDeposit && !hasPaidFull) {
      setSelected('paid-deposit');
      paymentOptionStateMachine.current = 'payment';
      return;
    }
    if (hasPaidFull) {
      setSelected('paid-full');
      paymentOptionStateMachine.current = 'payment';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    promotionsLoading,
    promotionsData,
    hasDepositDiscount,
    hasPaidDeposit,
    hasPaidFull,
    isPromotionalBookingActive,
    isStandardBookingActive,
    isTripInFullBookingActive,
    pathname,
  ]);

  useEffect(() => {
    if (promotionsLoading || !promotionsData) return;

    if (hasPaidDeposit && !hasPaidFull) {
      setSelected && setSelected('paid-deposit');
    }
    if (hasPaidFull) {
      setSelected && setSelected('paid-full');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionsLoading, promotionsData, hasPaidStandardDeposit, hasPaidFull]);
};

export { useSetPaymentOption };
