import { Button } from 'components/Button/Button';
import { Image } from 'components/Image/Image';
import { useState } from 'react';
import { Referral } from '../Referral/referral';

const PromotionCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-light-600 border border-light-800 rounded-md flex flex-col">
      <Image
        path="booking/finish-page/promotion-card.png"
        alt="Young people on the back of a truck in the countryside"
        className="rounded-t-md h-44"
      />
      <div className="p-5 flex flex-col">
        <div className="font-semibold text-xl text-teal-800 text-center mb-2.5">
          GET 100, GIVE 100!
        </div>
        <p className="font-medium text-sm leading-5 text-dark-700 mb-5">
          Help a friend save 100 on their trip when they book using your code
          and you'll receive a 100 Global Dollars to spend on flights, tours or
          hotels!
        </p>
        <Button
          text="Share My Code"
          clickHandler={() => setIsOpen(true)}
          size="medium"
          className="w-full py-2"
        />
        {isOpen ? <Referral isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
      </div>
    </div>
  );
};

export { PromotionCard };
