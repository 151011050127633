import React from 'react';
import { ReactComponent as InfoIcon } from './icons/snackbar-info.svg';
import { ReactComponent as ErrorIcon } from './icons/snackbar-error.svg';
import { ReactComponent as SuccessIcon } from './icons/snackbar-checkmark.svg';
import { ReactComponent as WarningIcon } from './icons/snackbar-warning.svg';
import { ReactComponent as DefaultIcon } from './icons/snackbar-trumpet.svg';
import { ReactComponent as CloseIcon } from './icons/snackbar-close.svg';

type Props = {
  size?: number;
  className?: string;
};

export const InfoAppToastIcon = ({ size = 30, className }: Props) => {
  return <InfoIcon className={className} />;
};
export const ErrorAppToastIcon = ({ size = 30, className }: Props) => {
  return <ErrorIcon className={className} />;
};

export const SuccessAppToastIcon = ({ size = 30, className }: Props) => {
  return <SuccessIcon className={className} />;
};
export const WarningAppToastIcon = ({ size = 30, className }: Props) => {
  return <WarningIcon className={className} />;
};
export const DefaultAppToastIcon = ({ size = 30, className }: Props) => {
  return <DefaultIcon className={className} />;
};

export const CloseAppToastIcon = () => {
  return <CloseIcon xmlns="http://www.w3.org/2000/svg" />;
};
