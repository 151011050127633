import { APPOINTMENTS_GATEWAY } from 'config';
import { HttpService } from './http';

const getUrl = (path: string): string => {
  return `${APPOINTMENTS_GATEWAY}/api${path}`;
};

export const AppointmentsGateway = new HttpService({
  gateway: APPOINTMENTS_GATEWAY,
  options: {
    getUrl,
  },
});
