import { BookingModal } from 'components/Modal/BookingModal';
import { useState } from 'react';
import styles from './title.module.css';

type Props = {
  title: string;
  description: string;
};
const AccomodationCardTitle = ({ title, description }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => description && setIsOpen(true)}
        className={
          'font-semibold text-base text-dark-800 underline' +
          (description ? ' cursor-pointer' : '')
        }
      >
        {title}
      </div>
      {description ? (
        <BookingModal
          isOpen={isOpen}
          close={() => setIsOpen(false)}
          title={title}
          cssClasses="sm:w-[41.25rem]"
        >
          <div
            className={`px-5 md:px-10 pb-5 md:pb-10 text-dark-700 text-sm text-left ${styles.title}`}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </BookingModal>
      ) : null}
    </>
  );
};

export { AccomodationCardTitle };
