class StringHelper {
  static startsWith(str: string, word: string) {
    return str.lastIndexOf(word, 0) === 0;
  }

  static endsWith(str: string, word: string) {
    return str.endsWith(word);
  }

  static capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  static generateRandomString(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
      counter += 1;
    }
    return result;
  }
}

export { StringHelper };
