import { PaymentOption } from 'lib/types/payment';
import {
  Promotion,
  PromotionObject,
  PromotionObjectKeys,
  PromotionPayment,
} from 'lib/types/promotion';
import { CurrencyKeys, SelectedRoomFull } from 'lib/types/trip';

class PromotionHelper {
  static getSelectedPromotionObject(
    promotions: Promotion,
    paymentOption: PaymentOption
  ): PromotionPayment | undefined {
    const mainOptions = ['deposit', 'standard', 'trip_in_full', 'generic'];

    if (paymentOption !== null && mainOptions.includes(paymentOption)) {
      return promotions[paymentOption as PromotionObjectKeys];
    }

    if (paymentOption === 'paid-deposit') {
      return promotions?.standard;
    }

    return promotions?.trip_in_full;
  }

  static getRoomsPrice(
    rooms: SelectedRoomFull[],
    currency: CurrencyKeys
  ): number {
    const roomsPrice = rooms.reduce((acc, curr) => {
      return acc + curr.noOfTravellers * (curr.prices[currency] ?? 0);
    }, 0);

    return roomsPrice;
  }

  static getTotalPriceWithRooms(
    noOfTravellers: number,
    retailPrice: number,
    rooms: SelectedRoomFull[],
    currency: CurrencyKeys,
    discount: number
  ): number {
    const tripPriceForAllTravellers = noOfTravellers * retailPrice;
    const roomsPrice = PromotionHelper.getRoomsPrice(rooms, currency);

    return tripPriceForAllTravellers + roomsPrice - discount;
  }

  static getDueNow(
    noOfTravellers: number,
    retailPrice: number,
    retailDeposit: number,
    activePaymentOption: PaymentOption,
    discount: number,
    roomsPrice: number
  ): number {
    let priceToCalculate: number = 0;
    if (
      activePaymentOption !== null &&
      ['trip_in_full', 'paid-full'].includes(activePaymentOption)
    ) {
      priceToCalculate = noOfTravellers * retailPrice + roomsPrice;
    } else {
      priceToCalculate = noOfTravellers * retailDeposit;
    }
    priceToCalculate -= discount;
    return priceToCalculate;
  }

  static getPromotionsDiscount(
    totalPriceWithRooms: number,
    promotions: PromotionObject[]
  ): number {
    const applicablePromotions = promotions.filter((p) => p.applicable);
    const discounts = applicablePromotions.map((p) =>
      this.getPromotionDiscount(totalPriceWithRooms, p)
    );

    return discounts.reduce((acc, curr) => acc + curr, 0);
  }

  static getPromotionDiscount(
    totalPriceWithRooms: number,
    promotion: PromotionObject
  ): number {
    let amount = 0;

    if (promotion.entity === 'percent') {
      const percentage = promotion.value;
      const inAmount = Math.floor((totalPriceWithRooms * percentage) / 100);
      amount += inAmount;
    }

    if (promotion.entity === 'amount') {
      // amount += noOfTravellers * promotion.discount;
      amount += promotion.discount;
    }

    return amount;
  }

  static getActiveAndAppliedPromotions(
    active: PromotionObject[] | undefined,
    applied: PromotionObject[] | undefined
  ): PromotionObject[] {
    let promos: PromotionObject[] = [];
    if (active) {
      promos = [...promos, ...active];
    }
    if (applied) {
      promos = [...promos, ...applied];
    }
    return promos.filter((p) => p.applicable);
  }
}

export { PromotionHelper };
