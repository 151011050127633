type Props = {
  color: string;
};

const Notification = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9955 3.74561C14.6656 3.74391 16.2679 4.40606 17.4496 5.58627C18.6314 6.76658 19.2956 8.36822 19.2959 10.0385V13.4711L20.3338 14.5099C20.3341 14.5102 20.3344 14.5105 20.3347 14.5108C20.8531 15.0269 21.145 15.7281 21.1459 16.4597L21.1459 16.463C21.1413 17.9233 19.9562 19.1046 18.4959 19.1046L7.50388 19.1016C7.50381 19.1016 7.50395 19.1016 7.50388 19.1016C6.04369 19.1015 4.8585 17.9202 4.85388 16.46L4.85388 16.4562C4.8553 15.7246 5.14752 15.0237 5.6661 14.5077C5.66647 14.5074 5.66685 14.507 5.66722 14.5066L6.70488 13.4681V10.0386C6.70551 6.56444 9.52135 3.74771 12.9955 3.74561C12.9956 3.74561 12.9954 3.74561 12.9955 3.74561ZM16.3896 6.6476C15.4895 5.74859 14.2689 5.24423 12.9967 5.24561C10.3504 5.24704 8.20543 7.39237 8.20488 10.0386C8.20488 10.0387 8.20488 10.0386 8.20488 10.0386V13.7786C8.20488 13.9774 8.12594 14.1681 7.98542 14.3087L6.72642 15.5687L6.72454 15.5706C6.48835 15.8053 6.35504 16.1242 6.35388 16.4571C6.35691 17.09 6.8708 17.6016 7.50388 17.6016L18.4959 17.6046C18.4959 17.6046 18.4958 17.6046 18.4959 17.6046C19.129 17.6045 19.643 17.0927 19.6459 16.4598C19.645 16.127 19.512 15.8081 19.276 15.5733L19.2743 15.5717L18.0153 14.3117C17.8748 14.1711 17.7959 13.9804 17.7959 13.7816V10.0387C17.7959 10.0387 17.7959 10.0388 17.7959 10.0387C17.7956 8.7666 17.2897 7.54658 16.3896 6.6476ZM10.7669 21.4956C10.7669 21.0814 11.1027 20.7456 11.5169 20.7456H14.4739C14.8881 20.7456 15.2239 21.0814 15.2239 21.4956C15.2239 21.9098 14.8881 22.2456 14.4739 22.2456H11.5169C11.1027 22.2456 10.7669 21.9098 10.7669 21.4956Z"
        fill={color}
      />
    </svg>
  );
};

export { Notification };
