import { Warning } from 'assets/icons/Warning';
import { Button } from 'components/Button/Button';
import { BookingModal } from 'components/Modal/BookingModal';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setError } from 'state/slices/modal';

const ErrorModalProvider = () => {
  const errorModalState = useAppSelector((state) => state.modal.error);
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(setError({ ...errorModalState, isOpen: false }));
  };

  return (
    <BookingModal
      close={close}
      isOpen={errorModalState.isOpen}
      title=""
      cssClasses="max-w-[31.25rem]"
      isCloseButtonHidden
      position="center"
    >
      <div>
        <div className="w-16 h-16 mx-auto mt-5 md:mt-10 mb-2">
          <Warning color="#FE5754" />
        </div>
        <h2 className="h-10 flex justify-center items-center text-xl !leading-6 md:text-[1.5rem] text-dark-900 font-medium md:font-bold w-full mb-3 md:mb-5">
          Oh no!
        </h2>
        <div className="mx-5 md:mx-10 mb-5">
          <p className="font-medium leading-5 text-sm md:text-base text-dark-700 text-center">
            {errorModalState.message}
          </p>
        </div>
        <div className="border-t border-light-800 mb-5 mx-5 md:mx-10"></div>
        <div className="flex flex-col md:flex-row px-5 md:px-10 mb-5 md:mb-10 gap-2.5 md:gap-5 w-full">
          <Button
            text={errorModalState.buttonText}
            type="primary"
            clickHandler={close}
            size="medium"
            className="!bg-teal-900 w-full"
          />
        </div>
      </div>
    </BookingModal>
  );
};

export { ErrorModalProvider };
