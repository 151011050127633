import { useQuery } from '@tanstack/react-query';
import { ReviewQueryResult } from 'lib/types/review';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

const useGetTripReview = () => {
  const URL = '/public/inventory/trip/review';

  const sessionId = sessionIdService.get();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  return useQuery(
    ['get-trip-review', sessionId],
    async () => {
      const response = await BookingGateway.get<ReviewQueryResult>(URL);

      return response;
    },
    {
      staleTime: Infinity,
      enabled: !!sessionId.length && currencyHasBeenSet,
    }
  );
};

export { useGetTripReview };
