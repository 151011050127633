import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DateHelper } from 'lib/helper/date/date';
import { useEffect, useRef, useState } from 'react';
dayjs.extend(duration);

const getUrgencyStatus = (
  duration: number,
  diff: number,
  urgentThreshold: number
) => duration - diff <= urgentThreshold;

const useTimer = (duration: number, urgentThreshold: number) => {
  const { current: init } = useRef(DateHelper.getNow());
  const [diff, setDiff] = useState<number>(
    DateHelper.getDifference(DateHelper.getNow(), init)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const difference = DateHelper.getDifference(DateHelper.getNow(), init);
      if (difference >= duration) {
        clearInterval(interval);
        setDiff(duration);
      }
      setDiff(difference);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remaining = duration - diff;
  const time = remaining >= 0 ? remaining : 0;

  return {
    time,
    isUrgent: getUrgencyStatus(duration, diff, urgentThreshold),
  };
};

export { useTimer };
