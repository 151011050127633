import { StringHelper } from '../../lib/helper/string/string';
import { Cloudinary } from '@cloudinary/url-gen';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { Dpr } from '@cloudinary/url-gen/qualifiers/dpr';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import dayjs from 'dayjs';

class CloudinaryService {
  cloud: Cloudinary;

  constructor(cloudName: string) {
    this.cloud = new Cloudinary(this._init(cloudName));
  }

  _init(cloudName: string) {
    return {
      cloud: {
        cloudName,
      },
      url: {
        analytics: false,
      },
    };
  }

  getImage(
    path: string,
    transformation?: string,
    crop?: {
      width: string;
      height: string;
    }
  ) {
    const version = dayjs().startOf('day').unix();

    try {
      const startsWithHttp = StringHelper.startsWith(path, 'http');
      if (startsWithHttp) return path;

      const image = this.cloud.image(path);
      image.setVersion(version);
      image.delivery(dpr(Dpr.auto()));

      if (crop) {
        image.resize(fill(crop.width, crop.height));
      }

      if (transformation) {
        image.namedTransformation(name(transformation));
      }

      if (StringHelper.endsWith(path, '.svg')) {
        return image.toURL();
      }

      return image.format('auto').toURL();
    } catch (e) {
      return '';
    }
  }
}

export { CloudinaryService };
