import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientConfig,
} from '@tanstack/react-query';
import { captureException, withScope } from '@sentry/react';

const config: QueryClientConfig = {
  mutationCache: new MutationCache({
    onError: (err: any, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });

        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey) as string[]
          );
        }
        captureException(new Error(err.message), err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err: any, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });

        const queryIdentifier = [query.queryKey[0] as string, err.message];
        scope.setFingerprint(queryIdentifier);
        captureException(new Error(err.message), err);
      });
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
};

const QueryClientFactory = () => {
  return new QueryClient(config);
};

export { QueryClientFactory };
