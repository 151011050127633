import { TimerIcon } from 'assets/icons/Timer';
import { Button } from 'components/Button/Button';
import { BookingModal } from 'components/Modal/BookingModal';
import { stagePathConfig } from 'data/stage';
import { useDevice } from 'lib/hooks/useDevice';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';

type Props = {
  isOpen: boolean;
};

const ExpiredModal = ({ isOpen }: Props) => {
  const { data } = useTripQuery();
  const { isMobile } = useDevice();

  const hasRoomStage = data?.result.rooms && data.result.rooms.length > 0;
  const stageToGoTo = hasRoomStage
    ? stagePathConfig.rooms
    : stagePathConfig.traveller;

  const paymentStageLink = `${window.location.origin}${stageToGoTo}`;
  const websiteDomain = process.env.REACT_APP_WEBSITE_DOMAIN_NAME;
  const websiteTripUrl = `https://${websiteDomain}${data?.result.trip.marketing_url}`;

  return (
    <BookingModal
      close={() => {}}
      isOpen={isOpen}
      title=""
      cssClasses="max-w-[31.25rem]"
      isCloseButtonHidden
      position="center"
    >
      <div>
        <div className="w-16 h-16 mx-auto mt-5 md:mt-10 mb-2">
          <TimerIcon color="#FE5754" />
        </div>
        <h2 className="h-10 flex justify-center items-center text-xl !leading-6 md:text-[1.5rem] text-dark-900 font-medium md:font-bold w-full mb-3 md:mb-5">
          Your booking has expired
        </h2>
        <div className="mx-5 md:mx-10 mb-5">
          <p className="font-medium leading-5 text-sm md:text-base text-dark-700 text-center">
            You can start your booking again by pressing ‘Restart Booking’ or
            you can return to our website.
          </p>
        </div>
        <div className="border-t border-light-800 mb-5 mx-5 md:mx-10"></div>
        <div className="flex flex-col md:flex-row px-5 md:px-10 mb-5 md:mb-10 gap-2.5 md:gap-5 w-full">
          <a href={websiteTripUrl} className="flex-grow">
            <Button
              text="Go to Website"
              clickHandler={() => {}}
              size={isMobile ? 'small' : 'medium'}
              type="secondary-border"
              className="w-full h-[3.125rem]"
              textClassName="text-lg"
            />
          </a>
          <a href={paymentStageLink} className="flex-grow">
            <Button
              text="Restart Booking"
              clickHandler={() => {}}
              size={isMobile ? 'small' : 'medium'}
              className="!bg-teal-900 w-full h-[3.125rem]"
              textClassName="text-lg"
            />
          </a>
        </div>
      </div>
    </BookingModal>
  );
};

export { ExpiredModal };
