export const stagePathConfig = {
  rooms: '/rooms',
  traveller: '/traveller',
  payment: '/payment',
  paymentProcessing: '/payment-processing',
  transactionFailed: '/transaction-failed',
  finished: '/finished',
  error404: '/404',
  error500: '/500',
} as const;

// TODO: make this as a transformation on the prev one
// the keys need to always be the same
export const stageNameConfig = {
  rooms: 'rooms',
  traveller: 'traveller',
  payment: 'payment',
  paymentProcessing: 'payment-processing',
  transactionFailed: 'transaction-failed',
  finished: 'finished',
  error404: '404',
  error500: '500',
} as const;
