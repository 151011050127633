import { PaymentFancyDivider } from './PaymentFancyDivider';

export const PaymentDtailsFallback = () => {
  return (
    <div className="relative flex w-full h-[27.06rem] border border-light-800 rounded-md justify-center items-center">
      <div>An error has occured!</div>
    </div>
  );
};

export const PaymentDetailsLoadingFallback = () => {
  return (
    <div className="relative flex w-full h-[29.62rem] border border-light-800 rounded-md flex-col bg-light-600">
      <div className="p-5 pb-0">
        <div className="h-6 w-1/2 rounded-full animate-pulse bg-light-800 mb-4"></div>
        <div className="h-4 w-2/3 rounded-full animate-pulse bg-light-800 mb-6"></div>
        <div className="h-8 w-full rounded-full animate-pulse bg-light-800 mb-9"></div>
        <div className="h-32 w-full rounded-3xl animate-pulse bg-light-800 mb-9"></div>
        <div className="h-5 w-2/3 rounded-full animate-pulse bg-light-800 "></div>
      </div>
      <PaymentFancyDivider />
      <div className="flex justify-center items-center h-full relative -top-1">
        <div className="h-8 w-2/3 rounded-full animate-pulse bg-light-800 "></div>
      </div>
    </div>
  );
};
