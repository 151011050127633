import { Pagination } from 'components/Pagination/Pagination';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import { useDevice } from 'lib/hooks/useDevice';
import { usePagination } from 'lib/hooks/usePagination';
import {
  Availability,
  Month,
  SelectionAvailability,
} from 'lib/types/availability';
import { Duration } from 'lib/types/trip';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { AvailabilityCard } from './AvailabilityCard';
import { FlexibleDeparture } from './FlexibleDeparture';
import { getIsSelected } from './helper';

export type Props = {
  avilabilities: Month[] | Availability[];
  selectedAvailability: SelectionAvailability;
  setTempSelectedAvailability: Dispatch<SetStateAction<SelectionAvailability>>;
  tempFlexibleDeparture: boolean;
  setTempFlexibleDeparture: Dispatch<SetStateAction<boolean>>;
  tempSelectedDuration: Duration | null;
  unit: string;
  tempSelectedMonth: Month | null;
};

function AvailabilityList({
  avilabilities,
  selectedAvailability,
  setTempSelectedAvailability,
  tempFlexibleDeparture,
  setTempFlexibleDeparture,
  tempSelectedDuration,
  tempSelectedMonth,
  unit,
}: Props) {
  const { isMobile } = useDevice();
  const availabilityListRef = useRef<HTMLDivElement>(null);
  const [isFakeLoading, setIsFakeLoading] = useState<boolean>(false);
  const pageSize = isMobile ? 4 : 3;

  const filteredAvailabilities = AvailabilityHelper.filterByDuration(
    avilabilities,
    tempSelectedDuration
  );

  const { current, pageCount, selectPage, currentPageItems } = usePagination<
    Month | Availability
  >({
    items: filteredAvailabilities,
    initialDefaultItem: selectedAvailability as Month | Availability,
    itemComparator: AvailabilityHelper.comparator,
    pageSize,
  });

  const selectPageHandler = (page: number) => {
    if (availabilityListRef.current) {
      availabilityListRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    selectPage(page);
    setIsFakeLoading(true);
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 500);
  };

  const setSelectedAvailability = (availability: Month | Availability) => {
    setTempFlexibleDeparture(false);
    setTempSelectedAvailability(availability);
  };

  useEffect(() => {
    selectPage(1);
  }, [tempSelectedMonth]);

  return (
    <div ref={availabilityListRef} className="flex flex-col">
      <div className="flex flex-col sm:gap-x-2.5">
        <FlexibleDeparture
          tempFlexibleDeparture={tempFlexibleDeparture}
          setTempFlexibleDeparture={setTempFlexibleDeparture}
        />
      </div>
      <div className="flex flex-col sm:gap-x-2.5">
        {!isFakeLoading ? (
          currentPageItems.map((item, index) => (
            <AvailabilityCard
              key={index}
              availability={item}
              isSelected={
                getIsSelected(selectedAvailability, item) &&
                !tempFlexibleDeparture
              }
              select={setSelectedAvailability}
              unit={unit}
            />
          ))
        ) : (
          <>
            {[...new Array(pageSize)].map((_, index) => (
              <div
                key={index}
                className="rounded-md h-[3.875rem] flex flex-col items-stretch mt-2.5 bg-light-800 animate-pulse transition-colors"
              ></div>
            ))}
          </>
        )}
      </div>
      <div className="mt-5 w-full flex justify-center items-center">
        <Pagination
          count={pageCount}
          current={current}
          select={selectPageHandler}
        />
      </div>
    </div>
  );
}

export { AvailabilityList };
