const PaymentElementSkeleton = () => {
  return (
    <div className="flex flex-col gap-5 md:gap-10">
      <div>
        <div className="w-52 h-12 bg-light-800 rounded-3xl animate-pulse mb-5"></div>
        <div className="w-32 h-5 bg-light-800 rounded-3xl animate-pulse mb-2.5"></div>
        <div className="w-64 md:w-96 h-7 bg-light-800 rounded-3xl animate-pulse mb-5"></div>
        <div className="w-32 h-5 bg-light-800 rounded-3xl animate-pulse mb-2.5"></div>
        <div className="w-72 h-10 bg-light-800 rounded-3xl animate-pulse mb-5 md:mb-10"></div>
        <div className="w-full md:w-48 h-12 bg-light-800 rounded-md animate-pulse mb-0 md:mb-10"></div>
      </div>
    </div>
  );
};

export { PaymentElementSkeleton };
