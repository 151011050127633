import { Image } from 'components/Image/Image';

type Props = {
  text: string;
};

const TAGS = {
  popular: 'popular',
  limitedSpots: 'limited spots',
  mostPopular: 'most popular',
  noAvailability: 'no availability',
  recommended: 'recommended',
  offSeason: 'off season',
  soldOut: 'sold out',
};

const Tag = ({ text }: Props) => {
  const limitedSpotStyle = ' text-red-900 bg-transparent ';
  const mostPopularStyle = ' text-dark-900 bg-yellow-800   ';
  const noAvailabilityStyle = ' text-dark-700 bg-light-600 ';
  const offSeasonStyle = ' text-teal-800 bg-transparent';
  const soldOutStyle = ' text-dark-700 bg-light-600 ';

  const textLowerCase = text.toLowerCase();

  if (!text.length) {
    return <></>;
  }

  return (
    <div
      className={
        ' text-xs !leading-[0.625rem] h-5 transform-none px-2 py-[0.313rem] rounded-full flex justify-center items-center flex-end w-fit font-bold whitespace-nowrap  ' +
        (textLowerCase === TAGS.popular && mostPopularStyle) +
        (textLowerCase === TAGS.mostPopular && mostPopularStyle) +
        (textLowerCase === TAGS.recommended && mostPopularStyle) +
        (textLowerCase === TAGS.limitedSpots && limitedSpotStyle) +
        (textLowerCase === TAGS.noAvailability && noAvailabilityStyle) +
        (textLowerCase === TAGS.offSeason && offSeasonStyle) +
        (textLowerCase === TAGS.soldOut && soldOutStyle)
      }
    >
      {textLowerCase === TAGS.limitedSpots && (
        <span className="w-2.5 h-2.5 mr-0.5">
          <Image path="booking/icons/fire.svg" alt="fire icon" />
        </span>
      )}
      <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
    </div>
  );
};

export { Tag };
