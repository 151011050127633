import { Image } from 'components/Image/Image';

const ReferralTip = () => {
  return (
    <div className="text-sm font-semibold leading-4 px-4 h-[3.5rem] bg-yellow-800 rounded-md flex items-center md:mt-6">
      <div className="w-6 h-6 min-w-[1.5rem] min-h-[1.5rem]">
        <Image path="booking/icons/tip.svg" alt="light bulb icon" />
      </div>
      <div className="text-dark-800">
        <span className="text-dark-900">Tip.</span> Refer a friend that books
        with us and get 100 off your trip!
      </div>
    </div>
  );
};
export { ReferralTip };
