import { Notification } from 'assets/icons/Notification';

type Props = {
  text: string;
  title: string;
  titleColor: 'red' | 'yellow';
  icon: 'notification';
};

const RoomTip = ({ text, title, titleColor, icon }: Props) => {
  return (
    <div className=" bg-light-700 rounded-md w-full p-[0.625rem] flex items-center">
      <div className="flex items-center">
        <div className="w-5  mr-[0.313rem]">
          {icon === 'notification' && (
            <Notification
              color={titleColor === 'red' ? '#FE5754' : '#FFC649'}
            />
          )}
        </div>
        <div className={`text-sm font-semibold text-${titleColor}-900`}>
          {title}
        </div>
      </div>

      <div className="text-xs text-dark-600 font-semibold ml-[0.625rem]">
        {text}
      </div>
    </div>
  );
};
export { RoomTip };
