import { FormSection } from '../../form-section';
import { TravellerPersonalSection } from './personal-section/personal-section';
import { Dispatch } from 'react';
import { TravellerContactSection } from './contact-section/contact-section';
import { TDropDownItem } from '../../../../lib/types/dropdown';
import {
  TravellerFormUser,
  TravellerFormValidation,
  TTravellerFormUserAction,
  TTravellerFormValidationAction,
} from 'lib/types/traveller';
import { ValidationHelper } from '../../../../lib/helper/validation/validationHelper';
import { Image } from 'components/Image/Image';
import { useTenant } from '@/lib/hooks/useTenant';

type Props = {
  state: TravellerFormUser;
  dispatch: Dispatch<TTravellerFormUserAction>;
  validationState: TravellerFormValidation;
  validationDispatch: Dispatch<TTravellerFormValidationAction>;
  isEmailDisable: boolean;
  defaultPhoneCode: string | null;
};

const TravellerForm = ({
  state,
  dispatch,
  validationState,
  validationDispatch,
  isEmailDisable,
  defaultPhoneCode,
}: Props) => {
  const validationHelper = new ValidationHelper(validationDispatch);
  const { isDA } = useTenant();

  const setFirstName = (value: string) => {
    dispatch({ type: 'firstName', value });
    validationHelper.checkFirstName(value);
  };
  const setLastName = (value: string) => {
    dispatch({ type: 'lastName', value });
    validationHelper.checkLastName(value);
  };
  const setDate = (value: Date) => {
    dispatch({ type: 'date', value });
    validationHelper.checkDateOfBirth(value);
  };
  const setEmail = (value: string) => {
    dispatch({ type: 'email', value });
    validationHelper.checkEmail(value);
  };
  const setPhonePrefix = (value: TDropDownItem | null) => {
    dispatch({ type: 'phonePrefix', value });
  };
  const setPhoneNumber = (value: string) => {
    dispatch({ type: 'phoneNumber', value });
    validationHelper.checkPhoneNumber(value, state.phonePrefix?.value);
  };

  return (
    <div className="p-5 md:p-10 flex flex-col gap-5 ">
      <h2 className="font-semibold text-[1.5rem] md:text-2xl text-dark-800">
        {isDA ? 'Lead Traveller' : 'Traveller'}
      </h2>
      <FormSection
        icon={<Image path="booking/icons/profile.svg" alt="profile icon" />}
        text="Personal"
      >
        <TravellerPersonalSection
          firstName={state.firstName}
          setFirstName={setFirstName}
          lastName={state.lastName}
          setLastName={setLastName}
          firtNameValidation={validationState.firtNameValidation}
          lastNameValidation={validationState.lastNamevalidation}
          date={state.date}
          setDate={setDate}
          dateValidation={validationState.dateValidation}
        />
      </FormSection>
      <FormSection
        icon={<Image path="booking/icons/location.svg" alt="location icon" />}
        text="Contact"
      >
        <TravellerContactSection
          phonePrefix={state.phonePrefix}
          setPhonePrefix={setPhonePrefix}
          phoneNumber={state.phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={state.email}
          setEmail={setEmail}
          phonePrefixValidation={validationState.phonePrefixValidation}
          phoneNumberValidation={validationState.phoneNumberValidation}
          emailValidation={validationState.emailValidation}
          isEmailDisable={isEmailDisable}
          defaultPhoneCode={defaultPhoneCode}
        />
      </FormSection>
    </div>
  );
};

export { TravellerForm };
