import { useEffect, useState } from 'react';

type Props<Type> = {
  items: Type[];
  initialDefaultItem: Type;
  itemComparator: (a: Type, b: Type) => boolean;
  pageSize: number;
};

const usePagination = <Type,>({
  items,
  pageSize,
  initialDefaultItem,
  itemComparator,
}: Props<Type>) => {
  const [current, setCurrent] = useState<number>(1);

  const itemsCount = items.length;
  const pageCount = Math.ceil(itemsCount / pageSize);

  const pageStart = (current - 1) * pageSize;
  const pageEnd =
    pageCount !== current ? current * pageSize - 1 : items.length - 1;

  const selectPage = (page: number) => {
    if (page > pageCount) return setCurrent(pageCount);
    if (page < 1) return setCurrent(1);
    setCurrent(page);
  };

  const currentPageItems = items.filter(
    (_, index) => pageStart <= index && index <= pageEnd
  );

  useEffect(() => {
    const selectedIndex = items.findIndex((i) =>
      itemComparator(i, initialDefaultItem)
    );
    if (selectedIndex > -1) {
      const pageToSet = Math.ceil((selectedIndex + 1) / pageSize);
      if (pageToSet < 1) {
        return setCurrent(1);
      }
      setCurrent(pageToSet);
    }
    // keep this dependency array empty, this should only
    // run when opening the modal which happens on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { pageCount, current, selectPage, currentPageItems };
};

export { usePagination };
