import { PaymentOption } from 'lib/types/payment';
import { PromotionObject, PromotionsQueryResult } from 'lib/types/promotion';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';
import { getDescriptionsWithDiscounts } from 'lib/helper/payment-options/payment-options';
import { PaymentOptionComponent } from '../payment-option/payment-option';
import { usePaymentOptions } from 'lib/react-query/Queries/usePaymentOptions';
import { usePaymentOptionContents } from '../../../../../lib/react-query/Queries/usePaymentOptionContents';
import { useEffect, useState } from 'react';
import { useTenant } from 'lib/hooks/useTenant';
import { useRecommendedPaymentOption } from 'lib/hooks/useRecommendedPaymentOption';

type Props = {
  selected: PaymentOption;
  setSelected: ((paymentOption: PaymentOption) => void) | undefined;
  promotions: PromotionsQueryResult;
  isLoading: boolean;
};

const PaymentOptions = ({
  selected,
  setSelected,
  promotions,
  isLoading,
}: Props) => {
  const numberFormatter = useNumberFormatter();
  const promotion = promotions.result.promotions;
  const { isGwat } = useTenant();

  const [depositVouchers, setDepositVouchers] = useState<PromotionObject[]>([]);
  const [standardVouchers, setStandardVouchers] = useState<PromotionObject[]>(
    []
  );
  const [vipVouchers, setVipVouchers] = useState<PromotionObject[]>([]);

  const {
    standardPaymentOptionContent,
    promotionalPaymentOptionContent,
    vipPaymentOptionContent,
  } = usePaymentOptionContents();

  const {
    isPromotionalBookingActive,
    isTripInFullBookingActive,
    isStandardBookingActive,
  } = usePaymentOptions();

  const hasDepositDiscount = promotion.deposit?.invoice.deposit_discount !== 0;

  const deposit = promotion.deposit?.invoice;
  const standard = promotion.standard?.invoice;
  const payInFull = promotion.trip_in_full?.invoice;

  const hasPaidPromotionalDeposit = deposit?.due_now === 0;
  const hasPaidStandardDeposit = standard?.due_now === 0;
  const hasPaidFull = payInFull?.due_now === 0;
  const hasPaidDeposit = hasPaidPromotionalDeposit || hasPaidStandardDeposit;

  const isPromotionalEnabled =
    isPromotionalBookingActive && hasDepositDiscount && deposit;
  const isStandardEnabled = isStandardBookingActive && standard;
  const isPayInFullEnabled = isTripInFullBookingActive && payInFull;

  const recommendedPaymentOption = useRecommendedPaymentOption({
    isPromotionalEnabled: !!isPromotionalEnabled,
    isStandardEnabled: !!isStandardEnabled,
    isPayInFullEnabled: !!isPayInFullEnabled,
  });

  const isVoucherPromotion = (promo: PromotionObject) => {
    return !['discount', 'reduced_deposit'].includes(promo.type);
  };

  useEffect(() => {
    if (promotions && promotions.result.promotions) {
      setDepositVouchers(
        promotions.result.promotions.deposit?.items.filter(
          isVoucherPromotion
        ) || []
      );

      setStandardVouchers(
        promotions.result.promotions.standard?.items.filter(
          isVoucherPromotion
        ) || []
      );

      setVipVouchers(
        promotions.result.promotions.trip_in_full?.items.filter(
          isVoucherPromotion
        ) || []
      );
    }
  }, [promotions]);

  return (
    <>
      <h2 className="font-semibold text-[1.5rem] md:text-2xl text-dark-800">
        Booking options
      </h2>
      <div className="space-y-5">
        {isPromotionalEnabled && (
          <PaymentOptionComponent
            id="deposit"
            isLoading={isLoading}
            isEnabled={!hasPaidPromotionalDeposit && !hasPaidFull}
            isSelected={selected === 'deposit'}
            setIsSelected={setSelected}
            type={promotionalPaymentOptionContent?.title || ''}
            descriptions={getDescriptionsWithDiscounts(
              isGwat,
              promotionalPaymentOptionContent?.entries,
              numberFormatter,
              deposit.total_price_discount,
              depositVouchers
            )}
            price={
              hasPaidFull
                ? { full: deposit.deposit, final: 0 }
                : { full: deposit.deposit, final: deposit.due_now }
            }
            isRecommended={recommendedPaymentOption === 'deposit'}
          />
        )}

        {isStandardEnabled && (
          <PaymentOptionComponent
            id="standard"
            isLoading={isLoading}
            isEnabled={!hasPaidStandardDeposit && !hasPaidFull}
            isSelected={selected === 'standard'}
            setIsSelected={setSelected}
            type={standardPaymentOptionContent?.title || ''}
            descriptions={getDescriptionsWithDiscounts(
              isGwat,
              standardPaymentOptionContent?.entries,
              numberFormatter,
              standard.total_price_discount,
              standardVouchers
            )}
            price={
              hasPaidFull
                ? { full: standard.due_now, final: 0 }
                : { final: standard.due_now }
            }
            isRecommended={recommendedPaymentOption === 'standard'}
          />
        )}

        {isPayInFullEnabled && (
          <PaymentOptionComponent
            id="trip_in_full"
            isLoading={isLoading}
            isEnabled={!hasPaidFull}
            isSelected={selected === 'trip_in_full'}
            setIsSelected={setSelected}
            type={vipPaymentOptionContent?.title || ''}
            descriptions={getDescriptionsWithDiscounts(
              isGwat,
              vipPaymentOptionContent?.entries,
              numberFormatter,
              payInFull.total_price_discount,
              vipVouchers
            )}
            price={
              payInFull.total_price === payInFull.due_now
                ? { final: payInFull.due_now }
                : { full: payInFull.total_price, final: payInFull.due_now }
            }
            isRecommended={recommendedPaymentOption === 'trip_in_full'}
          />
        )}

        {hasPaidDeposit && !hasPaidFull && payInFull && (
          <PaymentOptionComponent
            id="paid-deposit"
            isLoading={isLoading}
            isEnabled={hasPaidDeposit}
            isSelected={selected === 'paid-deposit'}
            setIsSelected={setSelected}
            type={`<div> I don't want a <span class="text-red-900">${numberFormatter.format(
              payInFull.total_price_discount
            )} discount</span>, I'd prefer to pay my trip balance later. </div>`}
            price={{ final: 0 }}
            hasMorePaddingInType
            showPaidInsteadOfZero={false}
          />
        )}
        {hasPaidFull && (
          <PaymentOptionComponent
            id="paid-full"
            isLoading={isLoading}
            isEnabled={hasPaidDeposit || hasPaidFull}
            isSelected={selected === 'paid-full'}
            setIsSelected={setSelected}
            type="Finalise your trip"
            price={{ final: 0 }}
            hasMorePaddingInType
            showPaidInsteadOfZero={false}
          />
        )}
      </div>
    </>
  );
};

export { PaymentOptions };
