import { useGetTripReview } from 'lib/react-query/Queries/useGetTripReview';
import { TripQueryResult } from 'lib/types/trip';
import { Image } from '../Image/Image';
import { Stars } from './TripInfo.stars';

type Props = {
  isLoading: boolean;
  data: TripQueryResult | undefined;
};

const TripInfoImage = ({ isLoading, data }: Props) => {
  const tripName = !isLoading && data ? data.result.trip.trip_name : '';
  const tripImagePath = !isLoading && data ? data.result.trip.banner : '';
  const { data: reviewsData } = useGetTripReview();
  const ratingNumber = Number(reviewsData?.result.rating ?? 5);
  const rating = Number(ratingNumber.toFixed(1));
  const noOfReviews = reviewsData?.result?.count ?? 0;

  return (
    <div className="relative w-full">
      <Image
        path={tripImagePath}
        alt={tripName}
        transformation="banner-sm"
        className="h-[12.5rem] w-full"
      />
      <div className="w-full h-[8.75rem] absolute bottom-0 left-0 trio-info-gradiant"></div>
      <div className="absolute bottom-5 left-5 text-light-900 space-y-2">
        <div className="font-semibold leading-5 text-lg">{tripName}</div>
        {noOfReviews && !(noOfReviews < 10 && ratingNumber < 4) ? (
          <div className="flex items-center gap-1">
            <Stars rating={rating} />
            <div className="text-sm font-bold !leading-4 mt-0.5">{rating}</div>
            <div className="text-sm font-medium !leading-4 mt-0.5">
              ({noOfReviews} reviews)
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { TripInfoImage };
