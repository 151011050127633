type Props = {
  color: string;
  cssClass?: string;
};

const ArrowUp = ({ color, cssClass }: Props) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cssClass}
    >
      <path
        d="M12 6.5L7 1.5L2 6.5"
        stroke={color}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { ArrowUp };
