// session_id => user's session id coming into booking
// donation => used for indicating the payment was donation in finished page
// payment_intent_client_secret, payment_intent, redirect_status => stripe stuff returned to verify payment
// origin => used for figuring out what platform created session
export const usedQueryParams = [
  'session_id',
  'donation',
  'payment_intent',
  'redirect_status',
  'payment_intent_client_secret',
  'origin',
  'opi',
];
