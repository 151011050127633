import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  region: string;
};

const TimeZoneName = ({ region }: Props) => {
  // Get the city name and format it
  const cityName = region.split('/')[1].replace('_', ' ');
  return <div>{`${cityName} time`}</div>;
};

export default function Home() {
  return <TimeZoneName region="Asia/Tehran" />;
}

export { TimeZoneName };
