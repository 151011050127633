import { Button } from 'components/Button/Button';
import { MouseEvent } from 'react';

type Props = {
  clickHandler: (e: MouseEvent<HTMLElement>) => void;
  isDisabled: boolean;
  isLoading: boolean;
  submitButtonText: string;
};

const RoomSubmit = ({
  clickHandler,
  isDisabled,
  isLoading,
  submitButtonText,
}: Props) => {
  return (
    <div className="flex justify-end p-5 bg-light-700 rounded-md">
      <Button
        text={submitButtonText}
        clickHandler={clickHandler}
        size="medium"
        type="primary"
        isDisabled={isDisabled}
        isLoading={isLoading}
        arrow="right"
        className="min-w-[15.625rem]"
      />
    </div>
  );
};

export { RoomSubmit };
