const LoadingSkeletoon = () => {
  return (
    <div className="md:shadow-[0px_5px_5px_0px_#17181a0d]">
      <div className="p-10 border bg-light-600 border-light-800 rounded-t-md">
        <div className="w-[3rem] md:w-[6.875rem] h-6 bg-light-800 animate-pulse rounded-[3.75rem] "></div>
        <div className="mt-12 gap-y-4 flex flex-col">
          <div className="w-[6rem] md:w-[13.75rem] h-4 bg-light-800 animate-pulse rounded-[3.75rem] "></div>
          <div className="w-[13rem] md:w-[26.25rem] h-4 bg-light-800 animate-pulse rounded-[3.75rem] "></div>
          <div className="w-[13rem] md:w-[26.25rem] h-4 bg-light-800 animate-pulse rounded-[3.75rem] "></div>
          <div className="w-[13rem] md:w-[26.25rem] h-4 bg-light-800 animate-pulse rounded-[3.75rem] "></div>
        </div>
      </div>
      <div className="p-5  border-x border-b border-light-800 bg-light-700 flex justify-end rounded-b-md">
        <div className="w-[10rem] md:w-[15.625rem] h-[3.125rem] bg-light-800 rounded-md flex justify-center items-center">
          <div className="loader small-loader "></div>
        </div>
      </div>
    </div>
  );
};

export { LoadingSkeletoon };
