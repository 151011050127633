import { useWindowDimensions } from './useWindowDimensions';

const useDevice = () => {
  const { width } = useWindowDimensions();
  const MD = 768;

  const isMobile = !!(width && width < MD);
  const isDesktop = !!(width && width >= MD);

  return { isMobile, isDesktop };
};

export { useDevice };
