type Props = {
  omitSeparator: boolean;
};

const ReviewSkeleton = ({ omitSeparator }: Props) => {
  return (
    <div className="flex flex-col md:flex-row items-center">
      <div className="flex md:flex-col justify-center items-center">
        <div className="w-36 md:w-40 h-5 md:mb-3 rounded-full bg-light-800 animate-pulse"></div>
        <div className="flex flex-col gap-3 md:gap-2.5 items-center">
          {/* <div className="w-28 h-4 md:mb-1 rounded-full bg-light-800 animate-pulse"></div> */}
          {/* <div className="border border-light-900 rounded-md flex">
            <div className="w-3 h-3 md:w-5 md:h-5 rounded-full bg-light-800 animate-pulse my-2 md:my-1.5 mx-2"></div>
            <div className="border-r border-light-900"></div>
            <div className="min-h-full flex flex-col justify-center items-center">
              <div className="w-20 md:w-24 h-4 rounded-full bg-light-800 animate-pulse mx-3"></div>
            </div>
          </div> */}
        </div>
      </div>
      {!omitSeparator && <div className=" border-opacity-10 my-5"></div>}
    </div>
  );
};

export { ReviewSkeleton };
