import { useEffect } from 'react';
import { sentryService } from 'services/sentry/sentry';
import { withErrorBoundary } from './lib/HOC/withErrorBoundary/withErrorBoundary';
import { Router } from './router';
import { setLogLevel } from './lib/helper/logger/logger';
import { useAppQueryParams } from 'lib/hooks/useAppQueryParams';
import { ToastProvider } from 'components/Toast/ToastProvider/ToastProvider';
import { usePaymentOptions } from './lib/react-query/Queries/usePaymentOptions';
import { usePaymentOptionContents } from './lib/react-query/Queries/usePaymentOptionContents';
import { AppError } from 'components/Error/app-error';
import { ErrorModalProvider } from 'components/ErrorModal/ErrorModal';
import { useRedirectIfNoSid } from 'lib/hooks/useRedirectIfNoSid';
import { ABTestProvider } from 'lib/hooks/ab-test/ab-test.provider';

function App() {
  useAppQueryParams();
  useRedirectIfNoSid();
  const { isLoading: isPaymentOptionsLoading } = usePaymentOptions();
  const { isLoading: isPaymentOptionContentsLoading } =
    usePaymentOptionContents();

  useEffect(() => {
    setLogLevel();
    sentryService.init();
  }, []);

  return (
    <>
      <ABTestProvider>
        <Router />
        <ToastProvider />
        <ErrorModalProvider />
      </ABTestProvider>
    </>
  );
}

export default withErrorBoundary({
  Component: App,
  Fallback: AppError,
});
