import dayjs from 'dayjs';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import {
  Availability,
  Month,
  SelectionAvailability,
} from 'lib/types/availability';

export const getLabel = (availability: Month | Availability) => {
  const startDate = AvailabilityHelper.getDepartureDate(availability);
  if ('availability_id' in availability) {
    return `${dayjs.unix(availability.start!).format('ddd, MMM DD')} -
      ${dayjs.unix(availability.end!).format('ddd, MMM DD')}`;
  } else {
    return dayjs.unix(startDate!).format('MMMM YYYY');
  }
};

export const getIsSelected = (
  selectionAvailability: SelectionAvailability,
  item: Month | Availability
) => {
  if (!selectionAvailability) return false;

  if ('availability_id' in selectionAvailability) {
    // day based availability
    return (
      selectionAvailability.availability_id ===
      (item as Availability).availability_id
    );
  } else {
    // month based availability
    return selectionAvailability.startOfMonth === (item as Month).startOfMonth;
  }
};

export const getComment = (availability: Month | Availability): string => {
  if ('availability_id' in availability) {
    return availability.comment ?? '';
  } else {
    return availability.comments.reduce((acc, cur) => (acc += `${cur}\n`), '');
  }
};

export const getAvailabilityStatus = (
  availability: Month | Availability | SelectionAvailability
): boolean => {
  if (!availability) return true;

  const availableStatuses = ['Available', 'Limited Availability'];

  if (availability.tags.includes('sold out')) {
    return false;
  }

  if ('availability_id' in availability) {
    return availableStatuses.includes(availability.status);
  } else {
    return availability.statuses.some((status) =>
      availableStatuses.includes(status)
    );
  }
};
