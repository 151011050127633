import { BookingModal } from 'components/Modal/BookingModal';
import { ReferralTip } from './tip';
import { ReferralForm } from './form/form';
import { Button } from 'components/Button/Button';
import { useEffect, useReducer, useState } from 'react';
import { ReferralReducer } from './form/state/referral-reducer';
import { formReferralInit, referralValidationInit } from './form/state/init';
import { referralValidationReducer } from './form/state/validation-reducer';
import { useReferralValidation } from 'lib/hooks/useReferralValidation';
import { ReferralFormT, ReferralPayLoad } from 'lib/types/referral';
import { TDropDownItem } from 'lib/types/dropdown';
import { ListActions } from './list-actions/list-actions';
import { useReferredFriends } from 'lib/react-query/Queries/useReferredFriend';
import { AppToastService } from '../../../Toast/AppToastService';
import { Image } from 'components/Image/Image';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};
const Referral = ({ isOpen, setIsOpen }: Props) => {
  const [referralArray, setRefferalArray] = useState<ReferralFormT[] | null>(
    null
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [state, dispatch] = useReducer(ReferralReducer, formReferralInit);
  const [validationState, validationDispatch] = useReducer(
    referralValidationReducer,
    referralValidationInit
  );
  const setFirstName = (value: string) =>
    dispatch({ type: 'firstName', value });
  const setLastName = (value: string) => dispatch({ type: 'lastName', value });
  const setEmail = (value: string) => dispatch({ type: 'email', value });
  const setPhonePrefix = (value: TDropDownItem | null) =>
    dispatch({ type: 'phonePrefix', value });
  const setPhoneNumber = (value: string) =>
    dispatch({ type: 'phoneNumber', value });
  const setLocation = (value: TDropDownItem | null) =>
    dispatch({ type: 'location', value });
  const { checkFieldsValidation } = useReferralValidation({
    state,
    validationDispatch,
  });

  const {
    isLoading,
    isError,
    data,
    mutate: submitReferredFriends,
  } = useReferredFriends();

  const submitHandler = () => {
    if (referralArray && referralArray?.length > 0 && checkFromIsEmpty()) {
      sumbmit();
    }
    if (
      referralArray &&
      referralArray.length > 0 &&
      !checkFromIsEmpty() &&
      checkFieldsValidation()
    ) {
      const newArray = addHandler();
      sumbmit(newArray);
    }
    if (!referralArray && checkFieldsValidation()) {
      const newArray = addHandler();
      sumbmit(newArray);
    }
  };

  const sumbmit = (array?: ReferralFormT[]) => {
    const newArrayFormat: ReferralPayLoad[] = [];
    const finalArray = array ? array : referralArray;
    finalArray?.forEach((item) => {
      const code = item.phonePrefix ? item.phonePrefix.label.toString() : '';
      const location = item.location ? item.location.value.toString() : '';
      newArrayFormat.push({
        first_name: item.firstName,
        last_name: item.lastName,
        phone_code: code,
        phone: item.phoneNumber,
        location: location,
        email: item.email,
      });
    });
    submitReferredFriends({ referralArray: newArrayFormat });
  };

  const checkFromIsEmpty = () => {
    const checkFirstName = state.firstName.length === 0 ? true : false;
    const checkLastName = state.lastName.length === 0 ? true : false;
    const checkPhoneNumber = state.phoneNumber.length === 0 ? true : false;
    const checkEmail = state.email.length === 0 ? true : false;
    const checkLocation = state.location === null ? true : false;
    const checkPhonePrefix = state.phonePrefix === null ? true : false;
    return (
      checkFirstName &&
      checkLastName &&
      checkPhoneNumber &&
      checkEmail &&
      checkLocation &&
      checkPhonePrefix
    );
  };

  const addHandler = () => {
    if (checkFieldsValidation()) {
      if (selectedIndex !== -1 && referralArray) {
        const newArray = [...referralArray];
        newArray[selectedIndex].firstName = state.firstName;
        newArray[selectedIndex].lastName = state.lastName;
        newArray[selectedIndex].email = state.email;
        newArray[selectedIndex].phoneNumber = state.phoneNumber;
        newArray[selectedIndex].phonePrefix = state.phonePrefix;
        setRefferalArray(newArray);
        resetArray();
        return newArray;
      } else {
        const newObj: ReferralFormT = {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          phoneNumber: state.phoneNumber,
          phonePrefix: state.phonePrefix,
          location: state.location,
        };

        const newArray = referralArray ? [...referralArray, newObj] : [newObj];
        setRefferalArray(newArray);
        resetArray();
        return newArray;
      }
    }
  };

  const resetArray = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setPhonePrefix(null);
    setLocation(null);
    setSelectedIndex(-1);
  };

  const deleteFromArray = (index: number) => {
    if (index > -1) {
      const newArray = referralArray?.filter((_, i) => i !== index);
      newArray && newArray?.length > 0
        ? setRefferalArray(newArray)
        : setRefferalArray(null);
    }
  };

  const updateArray = (item: ReferralFormT, index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1);
      resetArray();
    } else {
      setSelectedIndex(index);
      setFirstName(item.firstName);
      setLastName(item.lastName);
      setEmail(item.email);
      setPhoneNumber(item.phoneNumber);
      setPhonePrefix(item.phonePrefix);
      setLocation(item.location);
    }
  };

  const fullResetForm = () => {
    resetArray();
    setRefferalArray(null);
  };

  useEffect(() => {
    if (isLoading) return;
    if (data && data.success && !isError) {
      if (data.result) {
        AppToastService.success('Successfully done!');
        fullResetForm();
        setIsOpen(false);
      } else {
        AppToastService.error('Oops! Something went wrong!');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, isError]);

  return (
    <BookingModal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      title="Get your friends on board!"
      cssClasses="sm:w-[38.75rem]"
      titleCssCalss=" pt-10 px-10 "
      mobileTitleCss=" !text-lg "
    >
      <div>
        <div>
          <div className=" px-6 md:px-10">
            <ReferralTip />
          </div>

          <div className="pt-4 md:pt-6">
            <div className="px-6 md:px-10 text-base md:text-lg leading-[1.125rem] text-dark-800 text-left font-semibold pb-[1.625rem]">
              Enter your friend's info
            </div>
            <div>
              <div className="md:hidden">
                <ListActions
                  referralArray={referralArray}
                  selectedIndex={selectedIndex}
                  updateArray={updateArray}
                  deleteFromArray={deleteFromArray}
                  addHandler={addHandler}
                />
              </div>
              <div className=" px-6 md:px-10 py-6 md:pb-6 md:pt-0 bg-light-600 md:bg-light-700">
                <ReferralForm
                  state={state}
                  validationState={validationState}
                  setFirstName={setFirstName}
                  setLastName={setLastName}
                  setEmail={setEmail}
                  setPhonePrefix={setPhonePrefix}
                  setPhoneNumber={setPhoneNumber}
                  setLocation={setLocation}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <ListActions
            referralArray={referralArray}
            selectedIndex={selectedIndex}
            updateArray={updateArray}
            deleteFromArray={deleteFromArray}
            addHandler={addHandler}
          />
        </div>
        <div className="px-10 pb-6 pt-[1.25rem] md:pt-0 flex justify-center md:justify-end gap-x-4">
          <div
            onClick={addHandler}
            className="md:hidden rounded-md border border-dark-500 whitespace-nowrap h-12 flex px-4 bg-light-600 items-center"
          >
            <div className="w-6 h-6">
              <Image path="booking/icons/plus.svg" alt="plus icon" />
            </div>
            <div className="text-xs font-semibold text-dark-700">
              Refer another friend
            </div>
          </div>
          <Button
            text="Submit"
            clickHandler={submitHandler}
            size="medium"
            type="primary"
            isLoading={isLoading}
            isDisabled={isLoading}
            className="min-w-[9.7rem] "
          />
        </div>
      </div>
    </BookingModal>
  );
};

export { Referral };
