import dayjs from 'dayjs';
import {
  Availability,
  AvailabilityData,
  Month,
  SelectionAvailability,
} from 'lib/types/availability';
import { Duration } from 'lib/types/trip';
import { WithOptionalKey } from 'lib/types/utility';

class AvailabilityHelper {
  static getYears(data: AvailabilityData[] | undefined): string[] {
    if (!data) return [];
    const years: string[] = [];
    data.forEach((item) => {
      item.months.forEach((month) => {
        month.dates.forEach((date) => {
          if (
            !(date.status === 'Not Available' || date.tags.includes('sold out'))
          ) {
            years.filter((y) => y === item.year).length === 0 &&
              years.push(item.year);
          }
        });
      });
    });

    return years;
  }

  static getMonths(data: AvailabilityData[] | undefined): Month[] {
    if (!data) return [];
    const arr = data.map((a) => a.months);
    return arr.flat();
  }

  static getByYear(data: AvailabilityData[], year: string): AvailabilityData {
    return data.filter((d) => d.year === year)[0];
  }

  static getDayBased(
    data: AvailabilityData[] | undefined,
    selectedYear: string,
    selectedDuration: Duration | null,
    selectedMonth: Month | null
  ): Availability[] {
    if (!data || !selectedDuration || !selectedYear || !selectedMonth)
      return [];

    return selectedMonth.dates.filter(
      (item) =>
        !(item.status === 'Not Available' || item.tags.includes('sold out'))
    );
  }

  static getMonthBased(
    data: AvailabilityData[] | undefined,
    selectedYear: string,
    selectedDuration: Duration | null
  ): Month[] {
    if (!data || data.length === 0 || !selectedDuration || !selectedYear)
      return [];
    const currentYearAvailabilities = this.getByYear(data, selectedYear);

    const monthsFilteredByDuration = currentYearAvailabilities.months.filter(
      (month) => {
        let shouldInclude = false;
        month.dates.forEach((d) => {
          if (d.duration.id === selectedDuration.id) {
            shouldInclude = true;
          }
        });
        return shouldInclude;
      }
    );

    return monthsFilteredByDuration.filter(
      (item) =>
        !(
          item.statuses.includes('Not Available') ||
          item.tags.includes('sold out')
        )
    );
  }

  static getAll(
    data: AvailabilityData[] | undefined,
    selectedYear: string,
    selectedDuration: Duration | null,
    unit: string,
    selectedMonth: Month | null
  ): Month[] | Availability[] {
    if (unit === 'Days') {
      return this.getDayBased(
        data,
        selectedYear,
        selectedDuration,
        selectedMonth
      );
    } else {
      return this.getMonthBased(data, selectedYear, selectedDuration);
    }
  }

  static getDayBasedByIdOrDepartureDate(
    availabilities: AvailabilityData[],
    availability_id: number | undefined,
    departureDate: number,
    duration: Duration
  ): WithOptionalKey<Availability, 'duration'> | null {
    const all = AvailabilityHelper.flattenDayAvailabilities(availabilities);

    const filtered_by_duration = all.filter(
      (a) => a.duration.id === duration.id
    );

    if (availability_id) {
      const found_availabilities = filtered_by_duration.filter(
        (a) => a.availability_id === availability_id
      );
      const found_availabilty = found_availabilities.length
        ? found_availabilities[0]
        : null;

      if (found_availabilty) {
        return found_availabilty;
      }
    }

    if (departureDate) {
      const found_availabilities = filtered_by_duration.filter(
        (a) => a.start === departureDate
      );

      const found_availabilty = found_availabilities.length
        ? found_availabilities[0]
        : null;

      if (found_availabilty) {
        return found_availabilty;
      }
    }

    return null;
  }

  static flattenDayAvailabilities(
    availabilities: AvailabilityData[]
  ): Availability[] {
    return availabilities.flatMap((y) =>
      y.months.flatMap((m) => m.dates.map((a) => a))
    );
  }

  static getMonthBasedByDepartureDate(
    data: AvailabilityData[] | undefined,
    departure_date: number
  ): Month | null {
    if (!data) return null;

    for (let year of data) {
      for (let month of year.months) {
        if (month.startOfMonth === departure_date) {
          return month;
        }
      }
    }

    return null;
  }

  static getActiveYear(
    selectedAvailability: SelectionAvailability
  ): string | null {
    if (!selectedAvailability) return null;

    const startOfMonth = this.getDepartureDate(selectedAvailability);

    const year = dayjs(startOfMonth && startOfMonth * 1000).format('YYYY');

    return year;
  }

  static comparator(a: Month | Availability, b: Month | Availability): boolean {
    if (!a || !b) return false;

    if ('availability_id' in a && 'availability_id' in b) {
      return a.availability_id === b.availability_id;
    }

    if ('startOfMonth' in a && 'startOfMonth' in b) {
      return a.startOfMonth === b.startOfMonth;
    }

    return false;
  }

  static isDayBased(availability: SelectionAvailability): boolean {
    if (!availability) return false;
    if ('availability_id' in availability) return true;
    return false;
  }

  static getDepartureDate(availability: SelectionAvailability): number | null {
    return (
      availability &&
      ('availability_id' in availability
        ? availability.start
        : availability.startOfMonth)
    );
  }

  static filterByDuration(
    availabilities: Month[] | Availability[],
    duration: Duration | null
  ): Month[] | Availability[] {
    const filteredAvailabilities = (
      availabilities as Array<Month | Availability>
    ).filter((a: Month | Availability) => {
      if (!duration) return true;

      if ('availability_id' in a) {
        return a.duration.id === duration.id;
      }

      if ('startOfMonth' in a) {
        let datesDurationIds = a.dates.map((d) => d.duration.id);
        return datesDurationIds.includes(duration.id);
      }

      return false;
    });

    return filteredAvailabilities as Month[] | Availability[];
  }

  static getYearOfFirstAvailable(
    availabilities: AvailabilityData[] | undefined,
    duration: Duration | null
  ): string | null {
    if (!availabilities) return null;

    for (const y of availabilities) {
      for (const m of y.months) {
        for (const d of m.dates) {
          if (d.status === 'Not Available' || d.duration.id !== duration?.id) {
            continue;
          }
          const year = dayjs(d.start * 1000).year();
          return String(year);
        }
      }
    }

    return null;
  }
}

export { AvailabilityHelper };
