import { cloneElement, isValidElement, MouseEvent, ReactElement } from 'react';
import { ArrowLeft } from '../../assets/icons/ArrowLeft';
import { ArrowRight } from '../../assets/icons/ArrowRight';
import styles from './Button.module.css';
import { Children } from 'react';
import { ArrowUp } from 'assets/icons/ArrowUp';
import { TButtonArrow, TButtonSize, TButtonType } from './Button.type';
import { useTenant } from 'lib/hooks/useTenant';

export type Props = {
  text: string;
  type?: TButtonType;
  size?: TButtonSize;
  arrow?: TButtonArrow;
  icon?: ReactElement<{ color: string }>;
  clickHandler: (e: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  disabledTooltip?: string;
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
};

const getColorByType = (type: TButtonType, isDisabled: boolean): string => {
  if (isDisabled && type === 'primary') return '#FFFFFF';
  if (type === 'disabled') return '#FFFFFF';
  if (isDisabled) return '#575B63';

  switch (type) {
    case 'primary':
      return '#FFFFFF';
    case 'primary-teal':
      return '#FFFFFF';
    case 'primary-border':
      return '#0083A0';
    case 'secondary':
      return '#FFFFFF';
    case 'secondary-border':
      return '#575B63';
    case 'red':
      return '#FE5754';
    default:
      return '';
  }
};

const getIconSize = (size: string) => {
  switch (size) {
    case 'extra-small':
      return 'w-5 h-5 flex items-center';
    case 'small':
      return 'w-6 h-6 flex items-center';
    case 'medium':
      return 'w-8 h-8 flex items-center';
  }
};

function Button({
  text,
  type = 'primary',
  size = 'small',
  arrow,
  icon,
  clickHandler,
  isDisabled = false,
  disabledTooltip,
  isLoading = false,
  className,
  textClassName,
}: Props) {
  const { isDA } = useTenant();
  const primaryBackground = isDA ? 'dealsaway-button' : 'bg-teal-800';

  return (
    <button
      className={
        `${styles.button} ${className}` +
        ' whitespace-nowrap rounded-md flex justify-center items-center' +
        (type === 'primary' ? ` ${primaryBackground} ` : '') +
        (type === 'primary-teal' ? ' bg-teal-900' : '') +
        (type === 'primary-border'
          ? ` bg-light-600 border ${
              isDA ? ' border-dark-500' : ' border-teal-900 '
            }`
          : '') +
        (type === 'secondary-border' ? ' border border-light-900' : '') +
        (type === 'secondary' ? ' bg-dark-600' : '') +
        (type === 'red' ? ' bg-red-900 text-light-600' : '') +
        (size === 'extra-small' ? ' text-xs h-8 px-2.5 space-x-0.5' : '') +
        (size === 'small' ? ' text-sm h-10 px-4 space-x-1' : '') +
        (size === 'medium' ? ' text-lg h-12 px-4 space-x-1.5' : '') +
        (size === 'large' ? ' text-xl h-14 px-4 space-x-1.5' : '') +
        (type === 'disabled' ? ' !bg-dark-500 !bg-none' : '') +
        (isDisabled && type === 'primary' ? ' !bg-dark-500 !bg-none' : '') +
        (isDisabled && type !== 'primary'
          ? ' !bg-light-800 !bg-none opacity-50'
          : '') +
        (isDisabled ? ' cursor-not-allowed' : '') +
        (isLoading
          ? ` ${styles.loading_indicator} !bg-light-800 !text-light-800`
          : '')
      }
      onClick={!isDisabled ? clickHandler : () => {}}
      title={isDisabled ? disabledTooltip : undefined}
    >
      {arrow === 'left' && !isLoading && (
        <div className={getIconSize(size)} data-testid="button--left-arrow">
          <ArrowLeft color={getColorByType(type, isDisabled)} />
        </div>
      )}
      {arrow === 'up' && !isLoading && (
        <div
          className={getIconSize(size) + ' flex justify-center items-center'}
          data-testid="button--left-arrow"
        >
          <ArrowUp color={getColorByType(type, isDisabled)} />
        </div>
      )}
      {text.length ? (
        <div
          className={
            `${textClassName} font-semibold transition-all` +
            (type === 'primary' ? ' text-light-600' : '') +
            (type === 'primary-teal' ? ' text-light-600' : '') +
            (type === 'primary-border'
              ? ` ${isDA ? ' text-dark-700 ' : ' text-teal-900 '} `
              : '') +
            (type === 'secondary' ? ' text-light-600' : '') +
            (type === 'secondary-border' ? ' text-dark-700' : '') +
            (size === 'extra-small' ? ' text-xs' : '') +
            (size === 'small' ? ' text-sm' : '') +
            (size === 'medium' ? ' text-lg' : '') +
            (size === 'large' ? ' text-xl' : '') +
            (type === 'disabled' ? ' !text-light-600' : '') +
            (isDisabled && type === 'primary' ? ' !text-light-600' : '') +
            (isDisabled && type !== 'primary' ? ' !text-dark-700' : '') +
            (isLoading ? ' !text-opacity-0' : '')
          }
        >
          {text}
        </div>
      ) : null}
      {arrow === 'right' && !isLoading && (
        <div className={getIconSize(size)} data-testid="button--right-arrow">
          <ArrowRight color={getColorByType(type, isDisabled)} />
        </div>
      )}
      {icon && !isLoading && (
        <div className={getIconSize(size)} data-testid="custom-icon">
          {Children.map(icon, (child) => {
            if (isValidElement(child)) {
              return cloneElement(
                child
                /* TODO */
                /* isDisabled ? { color: '#ffffff' } : undefined */
              );
            }
            return child;
          })}
        </div>
      )}
    </button>
  );
}

export { Button };
