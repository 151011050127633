import { AB_TEST_FEATURE } from 'data/flags';
import { useFlags } from 'flagsmith/react';

const useExperimentalFeatureVariant = (
  feature: typeof AB_TEST_FEATURE[keyof typeof AB_TEST_FEATURE]
): {
  isEnabled: boolean;
  variant: string | number | boolean | null | undefined;
} => {
  const flags = useFlags([feature]);
  const flag = flags[feature];

  const isEnabled = Boolean(flags && flag && flag.enabled);

  return { isEnabled, variant: flag.value };
};

export { useExperimentalFeatureVariant };
