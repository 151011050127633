import { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { usePaymentClientConfig } from 'lib/react-query/Queries/usePaymentClientConfig';
// import { useRudderStack } from './useRudderStack';
import { useCharityPaymentSetup } from 'lib/react-query/Queries/useCharityPaymentSetup';
import { DateHelper } from 'lib/helper/date/date';
import { AppToastService } from '../../components/Toast/AppToastService';

type Props = {
  amount: number;
};

const useCharityStripe = ({ amount }: Props) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string>('');
  const { isLoading: isClientConfigLoading, data: clientConfigData } =
    usePaymentClientConfig({ provider: 'stripe' });

  const {
    isLoading: isPaymentSetupLoading,
    data: paymentSetupData,
    isRefetching: isPaymentSetupRefetching,
  } = useCharityPaymentSetup({
    charity_amount: amount,
    method: 'stripe_card',
    provider: 'stripe',
    timezoneOffset: DateHelper.getTimezoneOffset(),
    isEnabled: amount > 0,
  });
  // TODO:
  // const { initiatePayment } = useRudderStack();

  useEffect(() => {
    if (isClientConfigLoading || !clientConfigData) return;

    if (!clientConfigData.success) {
      AppToastService.error("Uh oh! Couldn't load payment config!");
      return;
    }

    try {
      setStripePromise(loadStripe(clientConfigData.result.publishable_key));
    } catch (e) {
      console.error('useCharityStripe: Loading stripe failed, trying again...');
      setTimeout(() => {
        setStripePromise(loadStripe(clientConfigData.result.publishable_key));
      }, 2000);
    }
  }, [isClientConfigLoading, clientConfigData]);

  useEffect(() => {
    if (isPaymentSetupLoading || !paymentSetupData) return;

    if (!paymentSetupData.success) {
      AppToastService.error("Uh oh! Couldn't load payment setup data!");
      return;
    }

    setClientSecret(paymentSetupData.result.client_secret);
  }, [isPaymentSetupLoading, paymentSetupData]);

  // useEffect(() => {
  // initiatePayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [amount]);

  if (amount <= 0) {
    return {
      stripePromise: null,
      clientSecret: '',
      isLoading: true,
    };
  }

  return {
    stripePromise,
    clientSecret,
    isLoading: isPaymentSetupLoading || isPaymentSetupRefetching,
  };
};

export { useCharityStripe };
