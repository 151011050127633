import { useCurrency } from 'lib/hooks/useCurrency';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';

type Props = {
  isEnabled: boolean;
  isLoading: boolean;
  price: {
    full?: number;
    final: number;
  };
  showPaidInsteadOfZero: boolean;
};

const PaymentOptionPrice = ({
  isEnabled,
  isLoading,
  price,
  showPaidInsteadOfZero,
}: Props) => {
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();

  return (
    <div className="flex justify-start items-center order-3 md:order-4 mr-5 my-5 md:m-0 md:ml-5 min-h-[2.5rem] md:min-h-fit">
      <div className="flex flex-col items-start">
        {!isLoading ? (
          <>
            {price.full ? (
              <div
                className={
                  'font-semibold text-sm leading-5 line-through' +
                  (isEnabled ? ' text-dark-600' : ' text-light-900')
                }
              >
                {numberFormatter.format(price.full)}
              </div>
            ) : null}
            <div className="flex items-center">
              <div
                className={
                  'font-semibold text-lg leading-5' +
                  (isEnabled ? ' text-dark-800' : ' text-dark-500')
                }
              >
                {showPaidInsteadOfZero && !price.final
                  ? 'PAID'
                  : numberFormatter.format(price.final)}
              </div>
              {price.final && currencyShouldBeShown ? (
                <div
                  className={
                    'font-semibold text-sm ml-0.5' +
                    (isEnabled ? ' text-dark-800' : ' text-dark-500')
                  }
                >
                  ({currency})
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {isLoading ? (
          <div className="flex flex-col items-start gap-2.5">
            <div className="animate-pulse bg-light-800 rounded-md w-11 h-5"></div>
            <div className="animate-pulse bg-light-800 rounded-md w-16 h-5"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { PaymentOptionPrice };
