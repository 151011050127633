import { Banner } from 'components/pages/Finished/Banner/Banner';
import { BookingSummary } from 'components/pages/Finished/BookingSummary/BookingSummary';
import { DonationCard } from 'components/pages/Finished/DonationCard/DonationCard';
import { PromotionCard } from 'components/pages/Finished/PromotionCard/PromotionCard';
import { Reviews } from 'components/pages/Finished/Reviews/Reviews';
import { Steps } from 'components/pages/Finished/Steps/Steps';
import { useDevice } from 'lib/hooks/useDevice';
import { withErrorBoundary } from '../../lib/HOC/withErrorBoundary/withErrorBoundary';
import { Layout } from 'components/Layout/Finished/Finished';
import { usePaymentQueryParams } from 'lib/hooks/usePaymentQueryParams';
import { Elements } from '@stripe/react-stripe-js';
import { Payment } from 'components/pages/Finished/Payment/Payment';
import { useEffect, useState } from 'react';
import { useRestoreContext } from 'lib/hooks/useRestoreContext';
import { useHandleSkipPayment } from './useHandleSkipPayment';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import { QueryParam } from 'services/query-param/query-param';
import { useTenant } from 'lib/hooks/useTenant';
import { useAppDispatch } from 'state/hooks';
import { setHasBeenSet } from 'state/slices/currency';
import { SurveyFairing } from 'components/pages/Finished/SurveyFairing/SurveyFairing';
import { getEnv } from '../../lib/helper/env/env';

const FinishedComponent = () => {
  useRestoreContext();
  const navigate = useCustomNavigate();
  const { isDesktop } = useDevice();
  const { clientSecret, stripePromise } = usePaymentQueryParams();
  const [stripeIsLoading, setStripeIsLoading] = useState<boolean>(true);
  const [paidAmount, setPaidAmount] = useState<number>(0);
  const [isCharity, setIsCharity] = useState<boolean>(false);
  const [charityPaymentWasSuccessful, setCharityPaymentWasSuccessful] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { isDA } = useTenant();

  const env = getEnv();
  const isLive = env === 'live';

  // TODO: move to useGeo itself, you can get pathname there, let's not
  // keep finished page concerned with these shenanigans
  // NOTE: since some queries depend on currency being set, coming back
  // to finished page (which isn't part of the <Home/>) we need to
  // switch this global state so they won't hang forever
  // also we can't move the location setter out of home since we were
  // told not to set location unnecessarily from gateway
  useEffect(() => {
    dispatch(setHasBeenSet(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { skipState } = useHandleSkipPayment({ setStripeIsLoading });

  const PICS = QueryParam.getFirst('payment_intent_client_secret');

  if (!(PICS || skipState)) {
    navigate('/');
  }

  const alreadyDonated = isCharity && charityPaymentWasSuccessful;

  return (
    <Layout>
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <Payment
            clientSecret={clientSecret}
            setStripeIsLoading={setStripeIsLoading}
            setPaidAmount={setPaidAmount}
            setIsCharity={setIsCharity}
            setCharityPaymentWasSuccessful={setCharityPaymentWasSuccessful}
          />
        </Elements>
      )}
      <Banner isLoading={stripeIsLoading} />
      <div className="max-w-screen-lg md:mx-auto p-5 md:p-0 md:-mt-28 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-[9fr_4fr] gap-5 md:gap-10 md:mb-10">
          <div className="space-y-5">
            <Steps stripeIsLoading={stripeIsLoading} />
            {isLive && isDesktop && !isDA ? <SurveyFairing /> : null}
            {isDesktop && !isDA && (
              <DonationCard
                isLoading={stripeIsLoading}
                alreadyDonated={alreadyDonated}
              />
            )}
          </div>
          <div className="space-y-5">
            <BookingSummary
              isSkipPayment={skipState === 'skip-success'}
              stripeIsLoading={stripeIsLoading}
              charityPaymentWasSuccessful={charityPaymentWasSuccessful}
              paidAmount={paidAmount}
              isCharity={isCharity}
            />
            {isLive && !isDesktop && !isDA ? <SurveyFairing /> : null}
            {!isDA && <PromotionCard />}
            {!isDesktop && !isDA && (
              <DonationCard
                isLoading={stripeIsLoading}
                alreadyDonated={alreadyDonated}
              />
            )}
          </div>
        </div>
      </div>
      {!isDA && <Reviews />}
    </Layout>
  );
};

export const Finished = withErrorBoundary({ Component: FinishedComponent });
