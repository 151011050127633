import { Warning } from 'assets/icons/Warning';
import { CheckMark } from 'assets/icons/checkmark';

type Props = {
  type: 'success' | 'error';
  message: string;
};

const RoomValidation = ({ type, message }: Props) => {
  return (
    <div className="bg-light-600 w-full h-full pb-5 md:pb-0">
      <div className="mx-5 md:mx-10 md:-mt-5 md:mb-10 bg-light-700 flex justify-start items-center h-10 p-[0.625rem] rounded-md">
        <div className="w-4 h-4">
          {type === 'success' ? (
            <CheckMark color="#1AE68C" />
          ) : (
            <Warning color="#FE5754" />
          )}
        </div>
        <div className="flex items-baseline">
          <div
            className={
              'text-sm font-semibold ml-1 ' +
              (type === 'success' ? ' text-green-900' : ' text-red-900')
            }
          >
            {type === 'success' ? 'Done' : 'Wait'}
          </div>
          <div className="text-semibold text-xs text-dark-600 ml-1 ">
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};
export { RoomValidation };
