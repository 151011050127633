import { Dropdown } from '@/components/Dropdown/Dropdown';

type Props = {
  options: { value: string; label: string }[];
  currentTz: string;
  disabled?: boolean;
  onChange: (date: string) => void;
};

const GwatCalendarTimezonePicker = ({
  options,
  currentTz,
  onChange,
  disabled,
}: Props) => {
  return (
    <Dropdown
      options={options}
      selected={{
        value: currentTz,
        label: currentTz,
      }}
      hasGlobeIcon={true}
      setSelected={(tz: any) => {
        onChange(tz.value);
      }}
      placeholder="Select Timezone"
      hasBorder={false}
      hasCustomArrow={true}
      controllerClassName={`!shadow-none py-0 !h-[1rem] !min-h-[1rem] !max-h-[1rem] !pr-0`}
      disabled={disabled}
    />
  );
};

export default GwatCalendarTimezonePicker;
