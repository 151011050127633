import { useQuery } from '@tanstack/react-query';
import { TTripTermsAndConditionsResult } from 'lib/types/terms-and-conditions';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';
import { useAppSelector } from 'state/hooks';

const useTripTermsAndConditions = () => {
  const URL = '/public/inventory/trip/terms-conditions-mapping';

  const sessionId = sessionIdService.get();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );

  return useQuery(
    ['terms-conditions-mapping', sessionId],
    async () => {
      const response = await BookingGateway.get<TTripTermsAndConditionsResult>(
        URL
      );

      return response;
    },
    {
      staleTime: Infinity,
      enabled: !!sessionId.length && currencyHasBeenSet,
    }
  );
};

export { useTripTermsAndConditions };
