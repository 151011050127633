import { ReactNode } from 'react';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

type Props = {
  children: ReactNode;
  showFAQ?: boolean;
  pbFooter?: boolean;
};

const Layout = ({ children, showFAQ, pbFooter }: Props) => {
  return (
    <div>
      <Header showFAQ={showFAQ} />
      <div>{children}</div>
      <Footer pbFooter={pbFooter} />
    </div>
  );
};

export { Layout };
