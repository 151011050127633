import { Elements } from '@stripe/react-stripe-js';
import { LoadingSkeletoon } from 'components/LoadingSkeletoon/loadingSkeletoon';
import { usePaymentQueryParams } from 'lib/hooks/usePaymentQueryParams';
import { Payment } from './payment';

const Processing = () => {
  const { clientSecret, stripePromise } = usePaymentQueryParams();

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <Payment clientSecret={clientSecret} />
        </Elements>
      )}
      <LoadingSkeletoon />
    </>
  );
};
export { Processing };
