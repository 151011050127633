import { Image } from 'components/Image/Image';

const DonationResult = () => {
  return (
    <div className="bg-light-700 border border-dashed border-teal-800 rounded-md">
      <div className="p-5 md:p-10 bg-light-600 border-b md:border-none border-light-800 flex flex-col items-center rounded-md">
        <div className="w-14 h-14 mb-4">
          <Image
            path="booking/icons/donate-heart.svg"
            alt="heart in hand icon"
          />
        </div>
        <h3 className="font-semibold text-sm md:text-lg text-teal-800 mb-2 text-center">
          Thank you so much for your generous donation!
        </h3>
        <p className="text-xs md:text-sm text-dark-700 text-center">
          Your contribution has made a difference and we are truly grateful for
          your support.
        </p>
      </div>
    </div>
  );
};

export { DonationResult };
