import { useDevice } from 'lib/hooks/useDevice';
import { useGetReviewPlatforms } from 'lib/react-query/Queries/useGetReviewPlatforms';
import { Review } from './Review';
import { ReviewSkeleton } from './ReviewSkeleton';
import { BookingStar } from './BookingStart';
import { Fragment } from 'react';

const validPlatforms = [
  'consumer-affairs',
  'trustpilot',
  'reviews.io',
  'google',
];

const Reviews = () => {
  const { isDesktop } = useDevice();
  const { isLoading, data } = useGetReviewPlatforms();

  const filteredData = data?.result.filter((rp) =>
    validPlatforms.includes(rp.platform)
  );

  const reviewPlatformCount =
    isLoading || !filteredData ? 5 : filteredData.length;

  return (
    <div className="bg-teal-500 bg-opacity-60">
      <div className="max-w-screen-lg md:mx-auto px-5 md:px-0 pt-5 md:pt-10">
        <div className="flex flex-col md:flex-row justify-between items-center md:border-b border-light-800 pb-0 md:pb-6">
          <div className="flex flex-col gap-[0.3125rem] md:gap-2.5">
            <div className="text-dark-900 text-lg md:text-xl leading-6 font-bold text-center md:text-left">
              Global Travellers love our better trips!
            </div>
            <div className="text-base md:text-lg font-medium leading-6 text-dark-900 text-center md:text-left">
              They’ve given us an average rating of 4.7 from over 11,155 real &
              verified reviews!
            </div>
          </div>
          <div className="border border-light-900 px-4 py-2 rounded-[6.25rem] mt-3 md:mt-0">
            <BookingStar rating={4.7} size={1.375} spacingMultiplier={0.5} />
          </div>
        </div>
      </div>
      <div className="max-w-screen-lg mx-5 md:mx-auto pt-6 pb-5 md:px-0 grid grid-cols-2 md:flex md:flex-row md:justify-center">
        {!isLoading &&
          filteredData &&
          filteredData.map((reviewProvider, index) => (
            <Fragment key={index}>
              <Review
                data={reviewProvider}
                omitSeparator={index === reviewPlatformCount - 1}
              />
              {isDesktop && index !== reviewPlatformCount - 1 && (
                <div className="border-r justify-self-stretch border-light-800  mx-6 my-0"></div>
              )}
            </Fragment>
          ))}
        {(isLoading || !filteredData) &&
          [...Array(reviewPlatformCount)].map((_, index) => (
            <Fragment key={index}>
              <ReviewSkeleton
                key={index}
                omitSeparator={index === reviewPlatformCount - 1}
              />
              {isDesktop && index !== reviewPlatformCount - 1 && (
                <div className="border-r justify-self-stretch border-light-800 mx-5 my-0"></div>
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export { Reviews };
