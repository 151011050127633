import { useUserLocation } from '../useUserLocation';
import { phoneNumbers } from './data';
import { useTenant } from '../useTenant';
import { dealsAwayOfficeData } from '../useOffice/data';

const usePhoneNumber = () => {
  const location = useUserLocation();

  const { isDA } = useTenant();

  if (isDA)
    return {
      phoneNumber: dealsAwayOfficeData.phone[0].number,
    };

  if (!location) return { phoneNumber: phoneNumbers.default };

  const phoneNumber: string =
    phoneNumbers[location.country.code] ||
    phoneNumbers[location.continent] ||
    phoneNumbers.default;

  return { phoneNumber };
};

export { usePhoneNumber };
