type Props = {
  color: string;
};

const InfoIcon = ({ color }: Props) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4.5C9.20046 4.5 4.5 9.20046 4.5 15C4.5 20.7995 9.20046 25.5 15 25.5C20.7995 25.5 25.5 20.7995 25.5 15C25.5 9.20046 20.7995 4.5 15 4.5ZM3 15C3 8.37204 8.37204 3 15 3C21.628 3 27 8.37204 27 15C27 21.628 21.628 27 15 27C8.37204 27 3 21.628 3 15Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 15C13 14.5858 13.3358 14.25 13.75 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15V21.25C15.75 21.6642 15.4142 22 15 22C14.5858 22 14.25 21.6642 14.25 21.25V15.75H13.75C13.3358 15.75 13 15.4142 13 15Z"
        fill={color}
      />
      <path
        d="M15.8 10.3C15.8 10.9075 15.3075 11.4 14.7 11.4C14.0925 11.4 13.6 10.9075 13.6 10.3C13.6 9.69244 14.0925 9.19995 14.7 9.19995C15.3075 9.19995 15.8 9.69244 15.8 10.3Z"
        fill={color}
      />
    </svg>
  );
};

export { InfoIcon };
