import {
  CurrencyKeys,
  InclusionFormat,
  Room,
  SelectedRoom,
  SelectedRoomFull,
} from 'lib/types/trip';
import { ArrayHelper } from '../array/array';

export const constructRoomPayloadForUpdateProduct = (
  rooms: InclusionFormat[]
) => {
  return rooms.map((r) => ({
    type: r.type,
    data: {
      id: r.data.id,
      travellers_number: r.data.travellers_number,
    },
  }));
};

export function getSelectedRoomsWithFullData(
  rooms: InclusionFormat[],
  tripRooms: Room[] | null
) {
  if (!tripRooms) return [];
  const selectedRoomsOrNull: (SelectedRoomFull | null)[] = rooms.map((room) => {
    const matchingFromTrip = tripRooms.find((r) => r.id === room.data.id);
    if (!matchingFromTrip) return null;

    return {
      id: room.data.id,
      type: matchingFromTrip.arr_name,
      prices: matchingFromTrip.prices,
      noOfTravellers: room.data.travellers_number,
    };
  });
  const selectedRooms = selectedRoomsOrNull.filter(ArrayHelper.notNull);
  return selectedRooms;
}

export function getSelectedRoomsFull(
  rooms: SelectedRoom[],
  tripRooms: Room[],
  currency: CurrencyKeys
) {
  const selectedRoomsOrNull: (SelectedRoomFull | null)[] = rooms.map((room) => {
    const matchingFromTrip = tripRooms.find((r) => r.id === room.id);
    if (!matchingFromTrip) return null;

    return {
      id: room.id,
      type: matchingFromTrip.arr_name,
      prices: matchingFromTrip.prices,
      noOfTravellers: room?.count,
    };
  });
  const selectedRooms = selectedRoomsOrNull
    .filter(ArrayHelper.notNull)
    .sort((a, b) => a.prices[currency] - b.prices[currency]);
  return selectedRooms;
}
