import { useScrollOnPathChange } from 'lib/hooks/useScrollOnPathChange';
import { useSetSentryUser } from 'lib/hooks/useSetSentryUser';
import { useStageClassname } from 'lib/hooks/useStageClassname';
import { ReactNode } from 'react';
import { useGeoData } from 'lib/hooks/useGeoData';

type Props = {
  children: ReactNode;
};

const AppLevelRouterAccessWrapper = ({ children }: Props) => {
  useSetSentryUser();
  useScrollOnPathChange();
  useStageClassname();
  useGeoData();

  return <>{children}</>;
};

export { AppLevelRouterAccessWrapper };
