import { setCid, setSalesRegisterId, setTid } from 'state/slices/user';

import { BookingGateway } from 'services/http/booking-gateway';
import { GetTravellerResult } from 'lib/types/create-traveller';
import { RudderStackGetUser } from 'components/RudderStack';
import { sentryService } from 'services/sentry/sentry';
import { sessionIdService } from 'services/session-id/session-id.service';
import { setInvoiceId } from 'state/slices/product';
import { useAppDispatch } from 'state/hooks';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useQuery } from '@tanstack/react-query';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { useUserLocation } from 'lib/hooks/useUserLocation';

type Props = {
  isEnabled: boolean;
};

const useGetTraveller = ({ isEnabled }: Props) => {
  const URL = '/public/traveller';
  const { customerRegistration } = useRudderStack();
  const dispatch = useAppDispatch();

  const sessionId = sessionIdService.get();
  const { currency } = useCurrency();
  const location = useUserLocation();
  const countryName = location ? location.country.name : '';

  return useQuery(
    ['get-traveller-info', sessionId],
    async () => {
      const response = await BookingGateway.get<GetTravellerResult>(URL);

      // NOTE: makes sure to identify users when they come back to booking
      // through different flows, so that rudderstack has enough info
      if (
        response.success &&
        response.result.email &&
        response.result.email.length > 0 &&
        RudderStackGetUser() !== response.result.email
      ) {
        customerRegistration(
          response.result.email,
          response.result.first_name ?? '',
          response.result.last_name ?? '',
          response.result.phone_code
            ? response.result.phone_code + response.result.phone_number
            : response.result.phone_number,
          response.result.cid ?? 0,
          response.result.sales_register_id ?? 0
        );
      }

      return response;
    },
    {
      staleTime: 20 * 1000,
      enabled: isEnabled && !!sessionId.length,
      onSuccess: (data) => {
        dispatch(setSalesRegisterId(data.result.sales_register_id));
        dispatch(setCid(data.result.cid));
        dispatch(setTid(data.result.tid));
        dispatch(setInvoiceId(data.result.invoice_id));

        sentryService.setUser({
          id: data.result.cid ? String(data.result.cid) : undefined,
          email: data.result?.email,
          username:
            data.result?.first_name || data.result?.last_name
              ? data.result?.first_name + ' ' + data.result?.last_name
              : undefined,
          extra: {
            salesRegisterId: data.result.sales_register_id
              ? String(data.result.sales_register_id)
              : undefined,
            tid: data.result.tid ? String(data.result.tid) : undefined,
            currency,
            location: countryName,
            sessionId: sessionIdService.get(),
          },
        });
      },
      retry: 3,
    }
  );
};

export { useGetTraveller };
