import { ReactNode } from 'react';

type Props = {
  icon: JSX.Element;
  text: string;
  children: ReactNode;
};

const ReferralFormSection = ({ icon, text, children }: Props) => {
  return (
    <div>
      <div className="flex items-center gap-1 mb-5 ">
        <div className="w-8 h-8">{icon}</div>
        <h3 className="font-semibold text-dark-500">{text}</h3>
      </div>
      {children}
    </div>
  );
};

export { ReferralFormSection };
