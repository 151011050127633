import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { Room } from 'lib/types/trip';
import { RoomTip } from './accomodation/tip';
import { TDropDownItem } from 'lib/types/dropdown';
import { useAppSelector } from 'state/hooks';

type Props = {
  minCompanion: number;
  maxCompanion: number;
  isLoading: boolean;
  isShowTip?: boolean;
  travellerDropdown: TDropDownItem | null;
  setTravellerDropdown: Dispatch<SetStateAction<TDropDownItem | null>>;
  onChangeTravellerDropdown: any;
  rooms: Room[] | null | undefined;
};

const TravellerNumber = ({
  minCompanion,
  maxCompanion,
  isLoading,
  isShowTip = true,
  travellerDropdown,
  setTravellerDropdown,
  onChangeTravellerDropdown,
  rooms,
}: Props) => {
  const [data, setData] = useState<TDropDownItem[] | null>(null);
  const travellerNumber = useAppSelector(
    (state) => state.product.travellerNumber
  );
  const [filteredMin, setFilteredMin] = useState(minCompanion);
  const [filteredMax, setFilteredMax] = useState(maxCompanion);
  useEffect(() => {
    // Check if all rooms have even capacities and none can be shared
    const allRoomsEvenAndNoSharing = rooms?.every(
      (room) => room.capacity % 2 === 0 && !room.can_share
    );

    const array: TDropDownItem[] = Array.from(
      { length: maxCompanion - minCompanion + 1 },
      (v, k): TDropDownItem => {
        return {
          label: (k + minCompanion).toString(),
          value: (k + minCompanion).toString(),
        };
      }
    );

    // Only filter for even numbers if all rooms have even capacities and none can be shared
    const filteredArray = allRoomsEvenAndNoSharing
      ? array.filter((item) => Number(item.value) % 2 === 0)
      : array;

    setData(filteredArray);

    // Set the filtered min and max based on the filtered array
    if (filteredArray.length > 0) {
      setFilteredMin(Number(filteredArray[0].value));
      setFilteredMax(Number(filteredArray[filteredArray.length - 1].value));
    }

    const value = travellerNumber > 1 ? travellerNumber : filteredMin;
    const tempDefault: TDropDownItem | null = {
      label: `${value}`,
      value: `${value}`,
    };

    if (travellerDropdown?.value !== tempDefault.value) {
      setTravellerDropdown(tempDefault);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxCompanion, minCompanion, travellerNumber, rooms, filteredMin]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travellerNumber]);

  return (
    data && (
      <div>
        <h3 className="text-lg font-semibold text-dark-800 mb-5">
          Select No. of Travellers
        </h3>
        <div className="w-full md:w-[20.313rem]">
          <Dropdown
            options={data}
            selected={travellerDropdown}
            setSelected={setTravellerDropdown}
            placeholder="Number of Travellers"
            hasBorder={true}
            hasArrow={true}
            hasSearch={false}
            menuStretchesToController
            onChange={onChangeTravellerDropdown}
            isLoading={isLoading}
          />
        </div>
        {isShowTip && (
          <div className="mt-[0.625rem] md:mt-5">
            <RoomTip
              text={`Please be aware you can book for a minimum of ${filteredMin} and maximum of
          ${filteredMax} people`}
              title="Tip"
              titleColor="yellow"
              icon="notification"
            />
          </div>
        )}
      </div>
    )
  );
};

export { TravellerNumber };
