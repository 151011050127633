import { ReactNode, useEffect } from 'react';
import { useCurrency } from '../useCurrency';
import { useAppFlags } from './useAppFlags';
import { useUserLocation } from '../useUserLocation';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';

type Props = {
  children: ReactNode;
};

const ABTestSetter = ({ children }: Props) => {
  const { setTraits } = useAppFlags();
  const location = useUserLocation();
  const { currency } = useCurrency();
  const { isLoading, data } = useTripQuery();

  useEffect(() => {
    if (isLoading || !data) return;

    setTraits({
      country: location?.country.name,
      last_url: window.location.href,
      last_visit: Math.ceil(new Date().getTime() / 1000),
      division: data.result.division.division,
      currency: currency,
      product_name: data.result.trip.trip_name,
      destination: data.result.destination.destination,
      original_price: data.result.prices.total[currency],
      original_deposit: data.result.prices.deposit[currency],
      trip_id: data.result.trip.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  return <>{children}</>;
};

export { ABTestSetter };
