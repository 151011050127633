import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PromotionsQueryResult } from 'lib/types/promotion';
import { QueryError } from 'lib/types/utility';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

type Props = {
  callback: (
    success: boolean,
    code: string,
    message: PromotionsQueryResult | string
  ) => void;
};
const useAddPromotionQuery = ({ callback }: Props) => {
  const queryClient = useQueryClient();
  const sessionId = sessionIdService.get();

  const URL = '/public/promotion';

  return useMutation<PromotionsQueryResult, QueryError, string, unknown>({
    mutationKey: ['add-promotion'],
    mutationFn: async (code: string) => {
      const response = await BookingGateway.post<PromotionsQueryResult>(URL, {
        promotion: code,
      });

      return response;
    },
    onSuccess: (data, variable) => {
      callback(data.success, variable, data);
      queryClient.setQueryData(['promotions', sessionId], data);
    },
    onError: (data, variable) => {
      callback(data.success, variable, data.message);
    },
  });
};

export { useAddPromotionQuery };
