import { Radiobutton } from 'components/Form/Radiobutton/Radiobutton';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useNumberFormatter } from 'lib/hooks/useNumberFormatter/useNumberFormatter';

type Props = {
  value: number;
  isSelected: boolean;
  setSelected: (value: number | null) => void;
};

const DonationButton = ({ value, isSelected, setSelected }: Props) => {
  const { currency, currencyShouldBeShown } = useCurrency();
  const numberFormatter = useNumberFormatter();

  const handler = () => {
    setSelected(value);
  };

  return (
    <button
      className={
        'p-4 bg-light-600 border rounded-md flex items-center flex-grow transition-colors justify-start md:justify-center' +
        (isSelected
          ? ' border-teal-900 shadow-[0_5px_10px_0_rgba(0,131,160,0.15)]'
          : ' border-light-800')
      }
      onClick={handler}
    >
      <Radiobutton
        id={'donation-' + value}
        value={String(value)}
        name={String(value)}
        isSelected={isSelected}
        label=""
        selectedOption={() => {}}
      />
      <div className="flex items-center mt-0.5">
        <div className="font-semibold text-sm text-dark-700">
          {numberFormatter.format(value)}
        </div>
        {currencyShouldBeShown ? (
          <div className="text-xs font-semibold ml-0.5 mb-0.5 text-dark-700">
            ({currency})
          </div>
        ) : null}
      </div>
    </button>
  );
};

export { DonationButton };
