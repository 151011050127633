import { useLocation } from 'react-router-dom';
import { TravellerStage } from '../../components/pages/Traveller/traveller-stage/traveller-stage';
import { FAQ } from '../../components/FAQ/FAQ';
import { GotToGo } from '../../components/got-to-go/got-to-go';
import { TripInfo } from '../../components/TripInfo/TripInfo';
import { withErrorBoundary } from '../../lib/HOC/withErrorBoundary/withErrorBoundary';
import { StageIndicator } from '../../components/StageIndicator/StageIndicator';
import { PaymentDetails } from 'components/PaymentDetails/PaymentDetails';
import { PaymentStage } from 'components/pages/Payment/payment-stage/payment-stage';
import { useDevice } from 'lib/hooks/useDevice';
import { useEffect, useRef } from 'react';
import { Layout } from 'components/Layout/Home/Home';
import { RoomStage } from 'components/pages/room/room-stage';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useRudderStack } from 'lib/hooks/useRudderStack';
import { useRestoreContext } from 'lib/hooks/useRestoreContext';
import { useNavigator } from 'lib/hooks/useNavigator';
import { useSetPaymentOption } from 'lib/hooks/useSetPaymentOption';
import { useTraveller } from 'lib/hooks/useTraveller';
import { useRoom } from 'lib/hooks/useRoom';
import { ErrorPage } from 'pages/Errors/Error';
import { LoadingSkeletoon } from 'components/LoadingSkeletoon/loadingSkeletoon';
import { TransactionFailedStage } from 'components/pages/TransactionFailed/transaction-failed-stage';
import { Processing } from 'pages/Processing/Processing';
import { stagePathConfig } from 'data/stage';
import { useAppSelector } from 'state/hooks';

const HomeComponent = () => {
  const checkoutStartedHasBeenCalled = useRef(false);

  useRestoreContext();
  const { isMobile, isDesktop } = useDevice();
  const { pathname } = useLocation();
  const { isLoading: tripLoading, data: tripData } = useTripQuery();

  useNavigator({ forceReload: tripLoading });

  useSetPaymentOption();
  const submitTraveller = useTraveller();
  const submitRoom = useRoom();
  const currencyHasBeenSet = useAppSelector(
    (state) => state.currency.hasBeenSet
  );
  const availability = useAppSelector((state) => state.product.availability);

  const { isLoading: isRudderstackLoading, checkoutStarted } = useRudderStack();

  const shouldShowGotToGo = pathname !== stagePathConfig.payment;
  const shouldShowStageIndicator =
    pathname !== stagePathConfig.transactionFailed &&
    pathname !== stagePathConfig.paymentProcessing;
  const showSkeleton =
    pathname !== stagePathConfig.rooms &&
    pathname !== stagePathConfig.traveller &&
    tripLoading;

  useEffect(() => {
    if (isRudderstackLoading) {
      return;
    }

    if (checkoutStartedHasBeenCalled.current !== true && availability) {
      checkoutStartedHasBeenCalled.current = true;
      checkoutStarted();
    }
  }, [isRudderstackLoading, pathname, availability]);

  return (
    <>
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-[2fr_1fr] md:gap-10 md:py-10 ">
          <div>
            {shouldShowStageIndicator && (
              <StageIndicator
                isLoading={tripLoading}
                hasRoomStage={
                  tripData?.result.rooms && tripData.result.rooms.length > 0
                    ? true
                    : false
                }
              />
            )}
            {isMobile && <TripInfo />}
            {showSkeleton && <LoadingSkeletoon />}
            {!showSkeleton && currencyHasBeenSet && (
              <>
                {pathname === stagePathConfig.traveller && (
                  <TravellerStage
                    {...submitTraveller}
                    selectedRooms={submitRoom.selectedRooms}
                  />
                )}
                {pathname === stagePathConfig.payment && <PaymentStage />}
                {pathname === stagePathConfig.rooms && (
                  <RoomStage {...submitRoom} />
                )}
                {pathname === stagePathConfig.transactionFailed && (
                  <TransactionFailedStage />
                )}
                {pathname === stagePathConfig.paymentProcessing && (
                  <Processing />
                )}
              </>
            )}

            {isDesktop && <div className="my-5"></div>}

            {pathname !== stagePathConfig.payment && <FAQ />}
            {isMobile && shouldShowGotToGo && (
              <GotToGo {...submitTraveller} {...submitRoom} />
            )}
          </div>
          <div>
            {isDesktop && <TripInfo />}
            {isDesktop && <div className="my-5"></div>}
            {pathname === stagePathConfig.payment ? (
              <PaymentDetails
                isMobileViewOpen={false}
                closeMobileView={() => {}}
              />
            ) : null}
            {isDesktop && <div className="my-5"></div>}
            {isDesktop && shouldShowGotToGo && (
              <GotToGo {...submitTraveller} {...submitRoom} />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Home = withErrorBoundary({
  Component: HomeComponent,
  Fallback: ErrorPage,
});
